import { useEffect, useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  HospitalReservationStatusID,
} from "../../../constants/common";
import {
  getAggregateMid002HospitalUser,
  getAggregateMid002Modality,
  getAggregateMid002User,
  getMongoDb,
} from "../../../utils/query";
import useId from "../../common/useId";
import {
  redirectToNoDataPage,
  checkFetchErr,
  checkActionErr,
} from "../../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import { FileType } from "../../../components/FileUpload/FileUpload";
import { convertUrlsToBase64 } from "../../../utils/utils";

const useMid002DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchUserResult, setFetchUserResult] =
    useState<Mid002UserStateType | null>(null);
  const [fetchModalityResult, setFetchModalityResult] = useStateCustomObj<
    Mid002ModalityStateType[]
  >([]);
  const [fetchHospitalUserResult, setFetchHospitalUserResult] =
    useStateCustomObj<Mid002HospitalUserStateType[]>([]);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // ユーザ情報
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(currentUser, Collection.USERS);

        const conditions = { _id: id };
        const aggregate = getAggregateMid002User(conditions);
        const result = (await collection.aggregate(
          aggregate,
        )) as Mid002UserStateType[];

        if (result.length < 1) redirectToNoDataPage();
        setFetchUserResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, setFetchUserResult]);

  // 検査履歴
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );

        // 予約確定のみ、全ての病院
        const conditions = {
          patient_id: id,
          status: HospitalReservationStatusID.CONFIRM,
        };
        const aggregate = getAggregateMid002Modality(conditions);
        const result = (await collection.aggregate(
          aggregate,
        )) as Mid002ModalityStateType[];

        setFetchModalityResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, setFetchModalityResult]);

  // 担当医リスト取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(currentUser, Collection.USERS);
        // 病院ID
        const hospitalId: string = currentUser
          ? (currentUser.customData.hospital_id as string)
          : "";

        const conditions = { hospital_id: hospitalId };
        const aggregate = getAggregateMid002HospitalUser(conditions);
        const result = (await collection.aggregate(
          aggregate,
        )) as Mid002HospitalUserStateType[];

        setFetchHospitalUserResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, setFetchHospitalUserResult]);

  // 更新処理
  const handleUpdateModality = (
    modalityId: string,
    hospitalUserId: string,
    doctorReview: string,
    doctorNote: string,
    uploadFiles: FileType[],
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        if (uploadFiles.length) {
          const imageUrls = uploadFiles.map((file) => file.url);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const base64Urls = await convertUrlsToBase64(imageUrls);
        }
        // FIXME: API
        const updateData = {
          _id: modalityId,
          attending_hospital_user_id: hospitalUserId,
          mri_doctor_review: doctorReview,
          mri_doctor_note: doctorNote,
        };
        await currentUser?.functions.actionDummyApi(updateData);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchUserResult,
    fetchModalityResult,
    fetchHospitalUserResult,
    handleUpdateModality,
    fetchError,
    actionError,
  };
};

export default useMid002DbActions;
