import { useCallback, useRef, useState } from "react";
import DetailsMenuItem from "./DetailsMenuItem";
import Button from "../Button/Button";
import Popover from "./Popover";

export type DetailsMenuProps = {
  items: {
    id?: string;
    label: string;
    value: string;
    icon?: string;
    danger?: boolean;
  }[];
  buttonSize?: "small" | "medium" | "large";
  width?: number;
  onClick?: (value: string) => void | (() => void);
  direction?:
    | "top"
    | "right"
    | "under"
    | "left"
    | "topLeft"
    | "topRight"
    | "underLeft"
    | "underRight";
};

const DetailsMenu = ({
  items = [],
  buttonSize = "small",
  width = 160,
  onClick = () => {},
  direction = "underRight",
}: DetailsMenuProps) => {
  const activatorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleItemClick = useCallback(
    (label: string) => {
      if (onClick) {
        onClick(label);
      }
      setIsOpen(false);
    },
    [onClick, setIsOpen],
  );

  return (
    <>
      <div ref={activatorRef}>
        <Button
          type="sub"
          size={buttonSize}
          color="neutral"
          iconType="outlined"
          onClick={() => setIsOpen(true)}
          icon="more_horiz"
        />
      </div>
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        width={width}
        direction={direction}
        forceDirection
        withShadow
        toggleOpen={toggleOpen}
      >
        <div className="details-menu">
          {items.map((item) => (
            <DetailsMenuItem
              {...item}
              onClick={() => handleItemClick(item.label)}
              key={`details-menu-item-${item.value}`}
            />
          ))}
        </div>
      </Popover>
    </>
  );
};

DetailsMenu.defaultProps = {
  onClick: () => {},
  buttonSize: "small",
  width: 160,
  direction: "underRight",
};

export default DetailsMenu;
