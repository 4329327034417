import { useState } from "react";
import {
  UserTypeID,
  Collection,
  UserStatusID,
  ROLE_KEY,
} from "../../../constants/common";

import { useRealmApp } from "../../../contexts/RealmApp";

import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openDeleteActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import { setUserIdError } from "../../input/useUserDetailInput";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

type HospitalUserDataType = {
  _id: string;
  hospital_id: string;
  hospital_user_id: string;
  doctor_name: string;
  email: string;
  created_at: Date;
  updated_at: Date;
};

const useMie006DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDb = getMongoDb(currentUser, Collection.USERS);

  // 新規 hospitalUserIdがすでに存在するか確認
  const checkExistingHospitalUserId = async (
    hospitalUserId: string,
    hospitalId: string,
  ): Promise<boolean> => {
    const existingDoc = (await mongoDb.findOne({
      user_id: hospitalUserId,
      user_type: UserTypeID.HOSPITAL,
      hospital_id: hospitalId,
    })) as HospitalUserDataType | null;
    if (existingDoc) {
      setUserIdError();

      return true;
    }

    return false;
  };

  // 更新 hospitalUserIdがすでに存在するか確認
  const checkUpdateExistingHospitalUserId = async (
    id: string,
    hospitalUserId: string,
    hospitalId: string,
  ): Promise<boolean> => {
    const existingDoc = (await mongoDb.findOne({
      hospital_user_id: hospitalUserId,
      _id: { $ne: id },
      user_type: UserTypeID.HOSPITAL,
      hospital_id: hospitalId,
    })) as HospitalUserDataType | null;
    if (existingDoc) {
      setUserIdError();

      return true;
    }

    return false;
  };

  // 医療機関ユーザ登録
  const handleRegisterHospitalUser = (
    hospitalId: string | undefined,
    hospitalUserId: string,
    doctorName: string,
    email: string,
    password: string,
    callbackOnSuccess: () => void,
  ) => {
    if (!hospitalId) redirectToInvalidAccessPage();

    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_ADD, currentUser);

        // hospitalUserIdがすでに存在するか確認
        if (
          await checkExistingHospitalUserId(
            hospitalUserId,
            hospitalId as string,
          )
        )
          return;

        // 登録データ
        const insertData = {
          _id: email,
          hospital_id: hospitalId,
          user_id: hospitalUserId,
          full_name: doctorName,
          email,
          user_type: UserTypeID.HOSPITAL,
          status: UserStatusID.EMAIL_VERIFYING,
          created_at: new Date(),
        };
        await mongoDb.insertOne(insertData);

        // ファンクション
        await realmAppContext.app.emailPasswordAuth.registerUser({
          email,
          password,
        });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        await mongoDb.deleteOne({ _id: email });
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 医療機関ユーザ更新
  const handleUpdateHospitalUser = (
    _id: string,
    hospitalUserId: string,
    doctorName: string,
    hospitalId: string | undefined,
    callbackOnSuccess: () => void,
  ) => {
    if (!hospitalId) redirectToInvalidAccessPage();

    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_EDIT, currentUser);

        // hospitalUserIdがすでに存在するか確認
        if (
          await checkUpdateExistingHospitalUserId(
            _id,
            hospitalUserId,
            hospitalId as string,
          )
        )
          return;

        // 更新データ
        const updateData = {
          full_name: doctorName,
          user_id: hospitalUserId,
          updated_at: new Date(),
        };
        await mongoDb.updateOne({ _id }, { $set: updateData });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // ユーザ削除
  const handleDeleteUser = (id: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_EDIT, currentUser);

        // ファンクション
        await realmAppContext.app.currentUser?.functions.deleteUser(id);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospitalUser,
    handleUpdateHospitalUser,
    handleDeleteUser,
  };
};

export default useMie006DbActions;
