import { useEffect, useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection, InspectionTabID } from "../../../constants/common";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  checkFetchErr,
  redirectToNoDataPage,
} from "../../../contexts/CustomErrorBoundary";
import useMie001Conditions from "./useMie001Conditions";
import { getAggregateMie001, getMongoDb } from "../../../utils/query";
import { TimeTableItem } from "../../../components/Table/TimeTableRow";

type TimeTableStateType = {
  id: string;
  hours: number;
  minutes: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
};

// 病院枠登録用に変換
const convertIntervalForUpdate = (value: string): number => {
  switch (value) {
    case "45":
      return 15;
    case "30":
      return 30;
    case "0":
      return 60;
    case "90":
      return 90;
    case "120":
      return 120;
    default:
      return 0;
  }
};

// 病院枠表示用に変換
const convertIntervalForDisplay = (value: number): string => {
  switch (value) {
    case 15:
      return "45";
    case 30:
      return "30";
    case 60:
      return "0";
    case 90:
      return "90";
    case 120:
      return "120";
    default:
      return "";
  }
};

const useMie001DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchResult, setFetchResult] = useState<Mie001StateType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  const [
    {
      mriTimeItems,
      petTimeItems,
      mriTimeInterval,
      petTimeInterval,
      inspectionType,
    },
    setCondition,
  ] = useMie001Conditions();

  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  // 病院検索
  useEffect(() => {
    const fetchData = async () => {
      try {
        const mongoDb = getMongoDb(currentUser, Collection.HOSPITALS);
        const conditions = { _id: hospitalId };
        const aggregate = getAggregateMie001(conditions);
        const result = (await mongoDb.aggregate(
          aggregate,
        )) as Mie001StateType[];

        if (result.length < 1) redirectToNoDataPage();
        setFetchResult(result[0]);

        // MRI 枠、時間単位をセット
        if (result[0].mri_schedule_capacity && result[0].mri_interval) {
          // 型を画面表示用に合わせる
          const convertedData: TimeTableItem[] = (
            result[0].mri_schedule_capacity as TimeTableStateType[]
          ).map((item) => ({
            ...item,
            monday: String(item.monday),
            tuesday: String(item.tuesday),
            wednesday: String(item.wednesday),
            thursday: String(item.thursday),
            friday: String(item.friday),
            saturday: String(item.saturday),
            sunday: String(item.sunday),
          }));
          setCondition((prevCondition) => ({
            ...prevCondition,
            mriTimeItems: convertedData,
            mriTimeInterval: convertIntervalForDisplay(result[0].mri_interval),
            mriTimeFrame: Number(
              convertIntervalForDisplay(result[0].mri_interval),
            ),
            originData: {
              ...prevCondition.originData,
              mriTimeItems: convertedData,
              mriTimeInterval: convertIntervalForDisplay(
                result[0].mri_interval,
              ),
              mriTimeFrame: Number(
                convertIntervalForDisplay(result[0].mri_interval),
              ),
            },
          }));
        }

        // PET 枠、時間単位をセット
        if (result[0].pet_schedule_capacity && result[0].pet_interval) {
          // 型を画面表示用に合わせる
          const convertedData: TimeTableItem[] = (
            result[0].pet_schedule_capacity as TimeTableStateType[]
          ).map((item) => ({
            ...item,
            monday: String(item.monday),
            tuesday: String(item.tuesday),
            wednesday: String(item.wednesday),
            thursday: String(item.thursday),
            friday: String(item.friday),
            saturday: String(item.saturday),
            sunday: String(item.sunday),
          }));
          setCondition((prevCondition) => ({
            ...prevCondition,
            petTimeItems: convertedData,
            petTimeInterval: convertIntervalForDisplay(result[0].pet_interval),
            petTimeFrame: Number(
              convertIntervalForDisplay(result[0].pet_interval),
            ),
            originData: {
              ...prevCondition.originData,
              petTimeItems: convertedData,
              petTimeInterval: convertIntervalForDisplay(
                result[0].pet_interval,
              ),
              petTimeFrame: Number(
                convertIntervalForDisplay(result[0].pet_interval),
              ),
            },
          }));
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, hospitalId, setCondition]);

  // 病院枠更新
  const handleUpdateScheduleCapacity = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const collection = getMongoDb(currentUser, Collection.HOSPITALS);
        const timeItems =
          inspectionType === InspectionTabID.MRI ? mriTimeItems : petTimeItems;
        const capacityKey =
          inspectionType === InspectionTabID.MRI
            ? "mri_schedule_capacity"
            : "pet_schedule_capacity";
        const timeInterval =
          inspectionType === InspectionTabID.MRI
            ? mriTimeInterval
            : petTimeInterval;
        const intervalKey =
          inspectionType === InspectionTabID.MRI
            ? "mri_interval"
            : "pet_interval";

        // 型をスキーマに合わせる
        const convertedData = timeItems.map((item) => ({
          ...item,
          monday: Number(item.monday),
          tuesday: Number(item.tuesday),
          wednesday: Number(item.wednesday),
          thursday: Number(item.thursday),
          friday: Number(item.friday),
          saturday: Number(item.saturday),
          sunday: Number(item.sunday),
        }));

        await collection.updateOne(
          { _id: hospitalId },
          {
            $set: {
              [capacityKey]: convertedData,
              [intervalKey]: convertIntervalForUpdate(timeInterval),
              updated_at: new Date(),
            },
          },
        );
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    actionError: error || fetchError,
    handleUpdateScheduleCapacity,
  };
};

export default useMie001DbActions;
