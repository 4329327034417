import React, { useEffect, useState } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import {
  INIT_PAGE,
  MASTER_MANAGEMENT_OPTIONS,
  ROLE_KEY,
} from "../constants/common";
import {
  downloadCsvNli016,
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectMasterMgmtSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNli016DbActions from "../hooks/pages/NLI016/useNli016DbActions";
import { convertNli016Table } from "../utils/convertDisplay";
import useNli016Conditions from "../hooks/pages/NLI016/useNli016Conditions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import usePagination, {
  setPage,
} from "../hooks/component/pagination/usePagination";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { ADMIN } from "../constants/pagePaths";
import InformationDialog from "../components/Dialog/InformationDialog";
import MIE005 from "./MIE005";
import { setId } from "../hooks/common/useId";
import { setInitHospitalInput } from "../hooks/input/useHospitalInput";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLI016 = () => {
  const [conditions, setConditions] = useNli016Conditions();
  const [hospitals, setHospitals] = useStateCustomObj<Nli016TableType[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const { fetchResult, fetchError, actionError, handleHospitalDelete } =
    useNli016DbActions({
      submittedSearchCriteria,
    });
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [checkedRows, setCheckedRows] = React.useState<string[]>([]);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [{ page, perPage }] = usePagination();
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);

  // 権限チェック
  const hasCsvDownloadRole = useCheckHasRole(
    ROLE_KEY.MASTER_HOSPITAL_CSV_DOWNLOAD,
  );
  const hasAddRole = useCheckHasRole(ROLE_KEY.MASTER_HOSPITAL_ADD);
  const hasEditRole = useCheckHasRole(ROLE_KEY.MASTER_HOSPITAL_EDIT);

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError, actionError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNli016Table(fetchResult);
    setHospitals(convertResult);
  }, [fetchResult, setHospitals]);

  // 検索ボックス
  const handleChangeSearch = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, search: value }));
  };

  // 検索ボタン押下イベント
  const handleSearch = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // CSVダウンロードボタン押下イベント
  const handleCsvDownload = () => {
    downloadCsvNli016(hospitals, window.location.pathname);
  };

  // 新規登録ボタン押下イベント
  const handleNewRegistration = () => {
    setInitHospitalInput();
    setIsRegisterDialogOpen(true);
  };

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToHospitalDetail = (id: string) => {
    setConditions({
      search: submittedSearchCriteria,
    });
    setId(id);
    navigate(ADMIN.NLI017);
  };

  // 削除ダイアログopen
  const openDeleteConfirm = () => {
    setIsDeleteConfirmOpen(true);
  };

  // 削除ダイアログclose
  const closeDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  // 削除処理
  const handleDelete = () => {
    handleHospitalDelete(checkedRows);
    closeDeleteConfirm();
  };

  const handleCheckUserId = (checked: boolean, hospitalId: string) => {
    const newUsers = hospitals.map((hospital) => {
      if (hospital.id === hospitalId) {
        return { ...hospital, checked };
      }

      return hospital;
    });

    setHospitals(newUsers);

    const visibleUsers = newUsers.slice(startIndex, endIndex);
    const isAllChecked = visibleUsers.every((user) => user.checked);
    setIsCheckAllUserIds(isAllChecked);

    if (checked) {
      setCheckedRows((prevCheckedRows) => [...prevCheckedRows, hospitalId]);
    } else {
      setCheckedRows((prevCheckedRows) =>
        prevCheckedRows.filter((rowId) => rowId !== hospitalId),
      );
    }
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = hospitals.map((hospital) => ({ ...hospital, checked }));
    setHospitals(newUsers);

    if (checked) {
      const checkedIds = newUsers
        .slice(startIndex, endIndex)
        .map((user) => user.id);
      setCheckedRows(checkedIds);
    } else {
      setCheckedRows([]);
    }
  };

  const isCheckEachId = React.useMemo(
    () => hospitals.some((hospital) => hospital.checked === true),
    [hospitals],
  );

  const isCheckAllId = React.useMemo(
    () => hospitals.every((hospital) => hospital.checked === true),
    [hospitals],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-py-12">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox align="center" justify="start" fullWidth>
                  <div className="util-mr-16">
                    <ToggleButton
                      size="large"
                      items={MASTER_MANAGEMENT_OPTIONS}
                      selectedButton="4"
                      onClick={handleSelectMasterMgmtSubTab}
                    />
                  </div>
                  <Input
                    value={searchCriteria.search}
                    placeholder="検索"
                    width="360px"
                    onChange={handleChangeSearch}
                  />
                  <div className="util-ml-8">
                    <Button
                      type="primary"
                      size="large"
                      icon="search"
                      width="88px"
                      onClick={handleSearch}
                    >
                      検索
                    </Button>
                  </div>
                </LayoutBox>
                <LayoutBox align="center" justify="between" fullWidth>
                  <LayoutBox align="center" justify="start">
                    <Button
                      color="danger"
                      type="secondary"
                      size="large"
                      icon="delete"
                      width="120px"
                      onClick={openDeleteConfirm}
                      disabled={checkedRows.length === 0 || !hasEditRole}
                    >
                      一括削除
                    </Button>
                  </LayoutBox>
                  <LayoutBox align="center" justify="end">
                    {/* 不要になったため、コメントアウト
                    <Button
                      type="secondary"
                      size="large"
                      icon="upload"
                      width="182px"
                      onClick={handleSVGUpload}
                    >
                      CSVアップロード
                    </Button> */}
                    <Button
                      type="secondary"
                      size="large"
                      icon="download"
                      width="184px"
                      onClick={handleCsvDownload}
                      disabled={!hasCsvDownloadRole}
                    >
                      CSVダウンロード
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      icon="add"
                      width="120px"
                      onClick={handleNewRegistration}
                      disabled={!hasAddRole}
                    >
                      新規登録
                    </Button>
                  </LayoutBox>
                </LayoutBox>
                <Table
                  type="condensed"
                  width="100%"
                  roleKey={ROLE_KEY.MASTER_HOSPITAL_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="161px" id="col-1">
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn width="517.5px" id="col-2">
                        病院
                      </TableColumn>
                      <TableColumn width="184px" id="col-3">
                        電話番号
                      </TableColumn>
                      <TableColumn width="517.5px" id="col-4">
                        住所
                      </TableColumn>
                      <TableColumn width="57px" id="col-5">
                        月
                      </TableColumn>
                      <TableColumn width="57px" id="col-6">
                        火
                      </TableColumn>
                      <TableColumn width="57px" id="col-7">
                        水
                      </TableColumn>
                      <TableColumn width="57px" id="col-8">
                        木
                      </TableColumn>
                      <TableColumn width="57px" id="col-9">
                        金
                      </TableColumn>
                      <TableColumn width="57px" id="col-10">
                        土
                      </TableColumn>
                      <TableColumn width="57px" id="col-11">
                        日
                      </TableColumn>
                      <TableColumn width="57px" id="col-12" />
                    </TableRow>
                  }
                  body={hospitals.map((hospital) => (
                    <TableRow key={hospital.id} isSelected={hospital.checked}>
                      <TableCell>
                        <Checkbox
                          label={hospital.id}
                          checked={hospital.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, hospital.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{hospital.hospitalName}</TableCell>
                      <TableCell>{hospital.tel}</TableCell>
                      <TableCell>{hospital.address}</TableCell>
                      <TableCell>{hospital.monday}</TableCell>
                      <TableCell>{hospital.tuesday}</TableCell>
                      <TableCell>{hospital.wednesday}</TableCell>
                      <TableCell>{hospital.thursday}</TableCell>
                      <TableCell>{hospital.friday}</TableCell>
                      <TableCell>{hospital.saturday}</TableCell>
                      <TableCell>{hospital.sunday}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          onClick={() => navigateToHospitalDetail(hospital.id)}
                          type="sub"
                          size="small"
                          width="38px"
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
                <InformationDialog
                  alertLevel="error"
                  open={isDeleteConfirmOpen}
                  closeDialog={closeDeleteConfirm}
                  title="削除してもよろしいですか？"
                  footer={
                    <LayoutBox>
                      <Button
                        color="danger"
                        size="medium"
                        onClick={handleDelete}
                      >
                        削除
                      </Button>
                      <Button
                        color="neutral"
                        type="sub"
                        size="medium"
                        onClick={closeDeleteConfirm}
                      >
                        キャンセル
                      </Button>
                    </LayoutBox>
                  }
                >
                  この操作は取り消せません。
                </InformationDialog>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isRegisterDialogOpen && (
        <MIE005
          isOpen={isRegisterDialogOpen}
          setIsOpen={setIsRegisterDialogOpen}
          title="病院情報を登録"
        />
      )}
    </>
  );
};

export default NLI016;
