import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc014, getMongoDb } from "../../../utils/query";
import { Collection, MriStatusID } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useNlc014Conditions from "./useNlc014Conditions";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";

// MRIのテスト結果を取得
const useNlc014DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;
  // 結果、ステータスのセレクトボックス
  const [{ problem, status }] = useNlc014Conditions();
  // 患者ID
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [mriTestResult, setMriTestResult] = useState<PatientNlc014StateType[]>(
    [],
  );
  const [error, setError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const aggregate = getAggregateNlc014(
          id,
          problem,
          status,
          skip,
          perPage,
        );
        const aggregateResult = (await collection.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNlc014StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setMriTestResult(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, perPage, problem, skip, status, forceUpdate]);

  // 結果送信
  const sendResult = (id: string) => {
    void (async () => {
      try {
        openUpdateActionLoading();
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        await collection.updateOne(
          { _id: id },
          {
            $set: {
              mri_status: MriStatusID.USER_REPORTED,
              updated_at: new Date(),
            },
          },
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, mriTestResult, actionError, sendResult };
};

export default useNlc014DbActions;
