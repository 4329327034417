import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  userId: ValidationResultType<string>;
  userName: ValidationResultType<string>;
  email: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  role: string;
  status: string;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  userId: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  userName: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  role: "3",
  status: "1",
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useUserDetailInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setUserDetailValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setUserId = (value: DefaultValidationType["userId"]["value"]) => {
  _setUserDetailValidation("userId", value, VALIDATION_TYPE.USER_ID_REQUIRED);
};
export const setUserName = (
  value: DefaultValidationType["userName"]["value"],
) => {
  _setUserDetailValidation(
    "userName",
    value,
    VALIDATION_TYPE.USER_NAME_REQUIRED,
  );
};
export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setUserDetailValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};
export const setRole = (value: DefaultType["role"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    role: value,
  });
};
export const setStatus = (value: DefaultType["status"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    status: value,
  });
};

// 値をすべて更新
export const setUserDetailInput = ({
  hospitalUserId,
  userName,
  email,
  role,
  status,
}: {
  hospitalUserId: DefaultValidationType["userId"]["value"];
  userName: DefaultValidationType["userName"]["value"];
  email: DefaultValidationType["email"]["value"];
  role: DefaultType["role"];
  status: DefaultType["status"];
}) => {
  setUserId(hospitalUserId);
  setUserName(userName);
  setEmail(email);
  setRole(role);
  setStatus(status);
};

// 初期化
export const setInitUserDetailInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkUserDetailInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { role: _role, status: _status, ...validationInfos } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

// 同一ユーザチェック
export const setUserIdError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    userId: {
      ...currentInput.userId,
      validationMessage: "すでに存在するIDです",
    },
  });
};

export default useUserDetailInput;
