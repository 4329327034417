import React from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Tag from "../components/Tag/Tag";
import Link from "../components/Link/Link";
import Image from "../components/Image/Image";
import Video from "../components/Video/Video";
import Switch from "../components/Switch/Switch";
import Divider from "../components/Divider/Divider";
import Toast from "../components/Toast/Toast";
import Tooltip from "../components/Tooltip/Tooltip";
import { MASTER_MANAGEMENT_OPTIONS } from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";

const INIT_SEARCH_CRITERIA = {
  id: "",
  name: "",
  phoneNum: "",
  status: {
    isShowing: true,
    isNewReservation: true,
    isConfirmedReservation: true,
    isSettled: true,
  },
};

const NLE002 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const toastActivatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([mainTabError]);

  const number = 1000;

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(ADMIN.NLE001);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Button
              type="sub"
              size="large"
              icon="arrow_back"
              onClick={handleGoBack}
            >
              一覧へ戻る
            </Button>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox justify="between" gap="2x" direction="row" fullWidth>
                <LayoutBox direction="column" gap="2x" fullWidth>
                  <FormSet
                    label="動画"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    <Video url="https://player.vimeo.com/video/463351174" />
                  </FormSet>
                  <FormSet
                    label="ステータス"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    <Tag state="success" label="公開中" showIcon={false} />
                  </FormSet>
                  <FormSet
                    label="再生回数"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    {number.toLocaleString()}
                  </FormSet>
                  <FormSet
                    label="動画タイトル"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    動画タイトル動画タイトル動画タイトル動画タイトル
                  </FormSet>
                  <FormSet
                    label="動画URL"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    <Link external={false}>
                      https://xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    </Link>
                    <div className="util-ml-8">
                      <Tooltip content="URLをコピー">
                        <div
                          ref={toastActivatorRef}
                          onClick={() => setIsToastOpen(true)}
                        >
                          <Button
                            icon="content_copy"
                            shape="circle"
                            size="small"
                            type="sub"
                            borderLess
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </FormSet>
                  <FormSet
                    label="詳細説明"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト
                  </FormSet>
                  <Divider margin="0" />
                  <FormSet
                    label="出演者名"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    林家 何某
                  </FormSet>
                  <FormSet
                    label="出演者プロフィール
画像"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    <Image type="avatar" />
                  </FormSet>
                  <FormSet
                    label="略歴"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト
                  </FormSet>
                  <Divider margin="0" />
                  <FormSet
                    label="配信日"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    2022/1/1
                  </FormSet>
                  <FormSet
                    label="配信期間"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    2ヶ月
                  </FormSet>
                  <FormSet
                    label="アーカイブ期間"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    配信から１ヶ月後
                  </FormSet>
                </LayoutBox>
                <LayoutBox justify="end" flexShrink={0}>
                  <Switch onChange={() => {}}>配信停止</Switch>
                  <Divider vertical height="32px" width="1px" />
                  <Button
                    icon="delete"
                    type="secondary"
                    color="danger"
                    width="70px"
                  >
                    削除
                  </Button>
                  <Button
                    icon="edit"
                    type="secondary"
                    color="main"
                    width="70px"
                  >
                    編集
                  </Button>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <LayoutBox align="center" fullWidth>
              <Toast onClose={() => setIsToastOpen(false)} open={isToastOpen}>
                URLをクリップボードにコピーしました
              </Toast>
            </LayoutBox>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLE002;
