import { MriStatusID, MriProblemID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  problem: MriProblemID;
  status: MriStatusID;
};

// 結果、ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  problem: MriProblemID.ALL,
  status: MriStatusID.ALL,
});
const useNlc014Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setProblem = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    problem: Number(selectStr),
  });
};

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export default useNlc014Conditions;
