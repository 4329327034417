export enum ADMIN {
  NLA001 = "/login",
  NLB001 = "/dashboard",
  NLC001 = "/user-management-all",
  NLC004 = "/user-management-once",
  NLC005 = "/user-management-secondary",
  NLC006 = "/user-management-tertiary",
  NLC007 = "/user-management-interview",
  NLC008 = "/user-detail-registration-info",
  NLC011 = "/user-detail-test-screening",
  NLC014 = "/user-detail-test-mri",
  NLC017 = "/user-detail-test-pet",
  NLC018 = "/user-detail-costs-benefits",
  NLC019 = "/user-detail-payment",
  NLC020 = "/user-detail-reservation",
  NLC023 = "/user-detail-order",
  NLD002 = "/inspection-fee-payment",
  NLD003 = "/hospital-payment",
  NLD004 = "/user-payment",
  NLE001 = "/vaudeville-list",
  NLE002 = "/vaudeville-detail",
  NLF001 = "/order-management-list",
  NLF005 = "/order-management-detail",
  NLF006 = "/sales-management-list",
  NLF007 = "/sales-management-detail",
  NLG001 = "/notice-list",
  NLG003 = "/notice-detail",
  NLH001 = "/campaign-list",
  NLH003 = "/campaign-detail",
  NLI001 = "/authority-management",
  NLI003 = "/user-management-list",
  NLI007 = "/product-management-type-list",
  NLI009 = "/product-management-sku-list",
  NLI011 = "/product-detail",
  NLI016 = "/hospital-list",
  NLI017 = "/hospital-detail",
  LOGIN_PROCESSING = "/login/processing",
}

export enum HOSPITAL {
  MIB001 = "/hospital-reservation-acceptance-list",
  MIC001 = "/hospital-schedule",
  MID001 = "/hospital-patient-list",
  MID002 = "/hospital-patient-detail",
  MIE001 = "/hospital-frame-management",
  MIE004 = "/hospital-doctor-list",
  LOGIN_PROCESSING = "/login/processing",
}

export enum COMMON {
  NLJ001 = "/error",
  NLJ003 = "/verify",
  NLJ004 = "/resend",
  NLJ005 = "/sent",
  NLJ006 = "/password/reset",
  NLJ007 = "/auth/error",
  NLJ008 = "/password/completion",
  LOGIN_PROCESSING = "/login/processing",
  NOT_FOUND = "/notfound",
}

export const HEADER_LINK =
  process.env.NODE_ENV === "development" ? "/" : COMMON.LOGIN_PROCESSING;
