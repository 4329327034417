import { useState, useEffect } from "react";
import {
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
  SEARCH_INFO,
  Collection,
} from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { getMongoDb, getAggregateNld003 } from "../../../utils/query";
import useId from "../../common/useId";
import useOneHospitalFetch from "../../common/useOneHospitalFetch";
import useStateCustomObj from "../../base/useStateCustomObj";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nld003FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLD003;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLD003;
};

const useNld003Actions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nld003FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchResult, setFetchResult] = useStateCustomObj<
    PatientNld003StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  // 病院情報取得
  const { fetchResult: fetchHospitalResult, fetchError: fetchHospitalError } =
    useOneHospitalFetch();

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [id] = useId();

  // 検索条件をquery用に整形
  const { startDate, endDate } = submittedSearchCriteria;
  const name = convertSearchName(submittedSearchCriteria.name);

  // 検索のチェック状態をquery用に整形
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLD002,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合はIDの昇順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "due_date", sortDirection = "desc" } = currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );

        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNld003(
          id,
          name,
          memoStatusArr,
          startDate,
          endDate,
          skip,
          perPage,
          colName,
          sortDirection,
        );

        // mongoDBのデータ
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNld003StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    colName,
    currentUser,
    id,
    memoStatusArr,
    name,
    perPage,
    skip,
    sortDirection,
    startDate,
    endDate,
    setFetchResult,
  ]);

  return {
    fetchResult,
    fetchError,
    fetchHospitalResult,
    fetchHospitalError,
  };
};

export default useNld003Actions;
