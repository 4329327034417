// 表示用変換処理定義
import { format } from "date-fns";

import {
  AdminsRoleID,
  CampaignsStatusID,
  DeliveryMethodID,
  EmbeddedLocationID,
  EmbeddedPageID,
  EmbeddedStr,
  HospitalInspectionStatusID,
  HospitalReservationStatusID,
  INIT_EVALUATION,
  InspectionFeesStatusID,
  ItemsSkusStatusID,
  ModalityID,
  NotificationStatusID,
  OrderStatusID,
  PatientInterviewStatusID,
  PatientNouKnowStatusID,
  SEARCH_INFO,
  PatientTypeID,
  MriStatusID,
  PlanSpanID,
  EventHistoriesPriorityID,
  SalesStatusID,
  SalesCategoryID,
  UserStatusID,
  JST_OFFSET_HOURS,
} from "../constants/common";
import { ADMIN } from "../constants/pagePaths";
import { redirectToInvalidFunctionPage } from "../contexts/CustomErrorBoundary";

// 患者のステータスと対応する文字列をページに合わせて取得
const _getTargetPatientStatus = () => {
  const pathName = window.location.pathname;
  switch (pathName) {
    case ADMIN.NLC001:
      return SEARCH_INFO.NLC001;
    default:
      return redirectToInvalidFunctionPage();
  }
};

// 年月日の数値を0埋め/区切りの文字列に変換
const _convertDateNumberToStr = (year: number, month: number, day: number) => {
  const yearStr = year.toString();
  const monthStr = month.toString().padStart(2, "0");
  const dayStr = day.toString().padStart(2, "0");

  return `${yearStr}/${monthStr}/${dayStr}`;
};

// 性別を表示用に変換
const _convertGender = (gender: string) => {
  switch (gender) {
    case "male":
      return "男";
    case "female":
      return "女";
    default:
      return "その他";
  }
};

// Adminsの権限を表示用に変換
export const convertAdminRole = (role: number) => {
  switch (role) {
    case AdminsRoleID.ADMIN:
      return "Admin";
    case AdminsRoleID.CMC:
      return "ドクター";
    case AdminsRoleID.PUBLIC_USER:
      return "一般ユーザー";
    default:
      return "";
  }
};

const _getTargetPatientInterviewStatus = () => {
  const pathName = window.location.pathname;
  switch (pathName) {
    case ADMIN.NLC007:
      return SEARCH_INFO.NLC007;
    default:
      return redirectToInvalidFunctionPage();
  }
};

// スラッシュで区切られたカナ付きのフルネームに変換
const _convertFullNameSlash = (
  firstName: string,
  firstNameKana: string,
  lastName: string,
  lastNameKana: string,
) => `${lastName}${firstName} / ${lastNameKana}${firstNameKana}`;

// フォーマットに合わせて日付を変換
export const convertDate = (dateStr: Date, formatStr = "yyyy/MM/dd") => {
  const date = new Date(dateStr);

  return format(date, formatStr);
};

// ステータスから午前、午後、終日に変換
const _getDayStatus = (day: DayStatus) => {
  if (day.AM === 1 && day.PM === 1) {
    return "終日";
  }
  if (day.AM === 1 && day.PM === 0) {
    return "午前";
  }
  if (day.AM === 0 && day.PM === 1) {
    return "午後";
  }

  return "";
};

// 営業日、定休日を取得
const _getBusinessAndClosedDays = (
  data: HospitalWeek,
): { closed: string; open: string } => {
  const dayMap: { [key: string]: string } = {
    mon: "月",
    tue: "火",
    wed: "水",
    thu: "木",
    fri: "金",
    sta: "土",
    sun: "日",
  };

  const closedDays = Object.entries(data)
    .filter(([_, value]) => value.AM === 0 && value.PM === 0)
    .map(([key]) => dayMap[key]);

  const openDays = Object.entries(data)
    .filter(([_, value]) => value.AM === 1 || value.PM === 1)
    .map(([key]) => dayMap[key]);

  return {
    closed: closedDays.join("、"),
    open: openDays.join("、"),
  };
};

// 診療時間を表示用に変換
const _convertHospitalHours = (hours: HospitalHour): string => {
  const amString = `${hours.am.start}-${hours.am.end}`;
  const pmString = `${hours.pm.start}-${hours.pm.end}`;

  return `${amString}/${pmString}`;
};

// 現在の日付と引数の日付の差分変換
const _convertDateDifferenceToCurrent = (updatedAt: Date) => {
  const now = new Date();

  // JST変換
  now.setHours(now.getHours() + JST_OFFSET_HOURS);
  updatedAt.setHours(updatedAt.getHours() + JST_OFFSET_HOURS);

  // 現在の日付とupdatedAtの日付をYYYY-MM-DDの形式で比較
  const nowDateStr = now.toISOString().slice(0, 10);
  const updatedAtDateStr = updatedAt.toISOString().slice(0, 10);

  if (nowDateStr === updatedAtDateStr) {
    // 本日の場合、hh:mmの形式
    return updatedAt.toISOString().slice(11, 16);
  }
  // 前日以前の場合、〇日前
  const timeDifference = now.getTime() - updatedAt.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

  return `${dayDifference}日前`;
};

// 金額変換
const _convertToCurrency = (value: number) => {
  const stringValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `¥${stringValue}`;
};

// カンマ区切りに変換
const _convertToCommaSeparatedString = (value: number) =>
  value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// 配達方法変換
const _convertDeliveryMethod = (
  deliveryMethodNum: number,
  deliveryDate: string | null,
) => {
  switch (deliveryMethodNum) {
    case DeliveryMethodID.NORMAL:
      return "通常配送";
    case DeliveryMethodID.SPECIFIED:
      if (!deliveryDate) return redirectToInvalidFunctionPage();

      return `お届け日指定:${deliveryDate}`;
    default:
      return redirectToInvalidFunctionPage();
  }
};

// 注文ステータス変換
const _convertOrderStatusStr = (status: number) => {
  switch (status) {
    case OrderStatusID.PAYMENT_COMPLETED:
      return "支払完了";
    case OrderStatusID.SHIPPED:
      return "出荷済";
    case OrderStatusID.DELIVERY_COMPLETED:
      return "配送完了";
    default:
      return "";
  }
};

// 検査内容変換
const _convertModalityStr = (status: number) => {
  switch (status) {
    case ModalityID.MRI:
      return "MRI検査";
    case ModalityID.PET:
      return "PET検査";
    default:
      return "";
  }
};

// 予約受付ステータス変換
const _convertHospitalReservationStatusStr = (status: number) => {
  switch (status) {
    case HospitalReservationStatusID.NEW:
      return "新規予約";
    case HospitalReservationStatusID.CONFIRM:
      return "確定";
    case HospitalReservationStatusID.NG:
      return "NG";
    case HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL:
      return "取り下げ申請";
    case HospitalReservationStatusID.WITHDRAWAL:
      return "取り下げ";
    default:
      return "";
  }
};

// 未検査、検査変換
const _convertHospitalInspectionStatusStr = (status: number) => {
  switch (status) {
    case HospitalInspectionStatusID.NOT_INSPECTED:
      return "未検査";
    case HospitalInspectionStatusID.INSPECTED:
      return "検査済";
    default:
      return "";
  }
};

// inspection_fees ステータス変換
const _convertInspectionFeesStatusStr = (status: number) => {
  switch (status) {
    case InspectionFeesStatusID.UNPAID:
      return "未払い";
    case InspectionFeesStatusID.PAID:
      return "支払い済";
    default:
      return "-";
  }
};

// event_histories  priority ステータス変換
const _convertEventHistoriesPriorityStr = (priority: number) => {
  switch (priority) {
    case EventHistoriesPriorityID.LOW:
      return "低";
    case EventHistoriesPriorityID.MEDIUM:
      return "中";
    case EventHistoriesPriorityID.HIGH:
      return "高";
    default:
      return "";
  }
};

// 文字列の時間を午前・午後に変換
const _convertToAmPmStr = (dateTime: string): string => {
  // "HH:MM" の部分を取得
  const timePart = dateTime.split(" ")[1];
  const hour = parseInt(timePart.split(":")[0], 10);

  if (hour < 12) {
    // "YYYY/MM/DD午前"
    return `${dateTime.split(" ")[0]}午前`;
  }

  // "YYYY/MM/DD午後"
  return `${dateTime.split(" ")[0]}午後`;
};

// sales ステータス変換
const _convertSalesStatusStr = (status: number) => {
  switch (status) {
    case SalesStatusID.UNPAID:
      return "未払い";
    case SalesStatusID.PAID:
      return "支払い済";
    default:
      return "-";
  }
};

// 病院の時間範囲変換
const _convertHospitalTimeRange = (
  startTime: Date,
  hospitalFrameMinute: number,
): string => {
  const weekDays: Weekday[] = ["日", "月", "火", "水", "木", "金", "土"];

  // 曜日を取得
  const dayOfWeek: Weekday = weekDays[startTime.getDay()];

  // 開始時刻の日付・時間部分を取得
  const datePart = `${(startTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${startTime.getDate()}(${dayOfWeek})`;
  const formattedStartTime = `${startTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${startTime.getMinutes().toString().padStart(2, "0")}`;

  // 継続時間を加算して終了時刻を取得
  const endDate = new Date(startTime);
  endDate.setMinutes(endDate.getMinutes() + hospitalFrameMinute);
  const formattedEndTime = `${endDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`;

  return `${datePart} ${formattedStartTime}～${formattedEndTime}`;
};

const _convertTask = (category: string) => {
  switch (category) {
    case "interview":
      return {
        tag1: "面談",
        tag2: "申込",
        description: "面談の予約がありました。予約を確定してください。",
      };
    case "mri":
      return {
        tag1: "MRI",
        tag2: "済:AI診断済",
        description: "画像をもとに読影してください。",
      };
    case "ec":
      return {
        tag1: "EC",
        tag2: "注文済",
        description: "注文の支払いが完了しました。配送手配をしてください。",
      };
    case "nouknow":
      return {
        tag1: "スクリーニングテスト",
        tag2: "テスト終了",
        description: "テスト結果を確認してください。",
      };
    default:
      return {
        tag1: "",
        tag2: "",
        description: "",
      };
  }
};

export const convertPatientInfo = (patientInfo: PatientsType | null) => {
  if (patientInfo) {
    const {
      _id: id,
      full_name_slash: fullNameSlash,
      birth,
      gender,
      tel,
      email,
      evaluation,
      is_enabled_modality: isEnabledModality,
      is_notification: isNotification,
      patient_type: patientType,
      plan_name: planName,
      price: priceNum,
      span,
      zipcode,
      pref,
      city,
      town1,
      town2,
      name_sei: nameSei,
      name_mei: nameMei,
      name_sei_kana: nameSeiKana,
      name_mei_kana: nameMeiKana,
      birth_year: birthYear,
      birth_month: birthMonth,
      birth_day: birthDay,
    } = patientInfo;

    return {
      id,
      name: fullNameSlash,
      birth,
      genderStr: _convertGender(gender),
      tel,
      email,
      evaluation: evaluation ? evaluation.toString() : INIT_EVALUATION,
      planName,
      planType: `${
        span === PlanSpanID.YEAR ? "年契約" : "月契約"
      }月払い:${_convertToCommaSeparatedString(priceNum)}円/月`,
      mriPetConditions: isEnabledModality ? "当てはまる" : "当てはまらない",
      notification: isNotification ? "希望する" : "希望しない",
      invitation: patientType === PatientTypeID.MEMBER ? "なし" : "あり",
      address: `${zipcode} ${pref}${city}${town1}${town2 || ""}`,
      zipcode,
      pref,
      city,
      town1,
      town2,
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      birthYear: birthYear.toString(),
      birthMonth: birthMonth.toString(),
      birthDay: birthDay.toString(),
    };
  }

  return {
    id: "",
    name: "",
    birth: "",
    genderStr: "",
    tel: "",
    email: "",
    evaluation: INIT_EVALUATION,
    planName: "",
    planType: "",
    mriPetConditions: "",
    notification: "",
    invitation: "",
    address: "",
    zipcode: "",
    pref: "",
    city: "",
    town1: "",
    town2: "",
    nameSei: "",
    nameMei: "",
    nameSeiKana: "",
    nameMeiKana: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
  };
};

type SearchInfo<IDType> = {
  id: IDType;
  label: string;
  flgKey: string;
};
// ステータスの番号を文字列に変換
const convertStatusStr = <IDType>(
  statusNumber: number,
  searchInfo: SearchInfo<IDType>[],
  statusIds: { [key: string]: IDType },
): string => {
  const statusNum = statusNumber as IDType;
  if (!(statusNumber in statusIds)) {
    return redirectToInvalidFunctionPage();
  }

  const foundStatus = searchInfo.find(({ id }) => id === statusNum);

  if (!foundStatus) {
    return redirectToInvalidFunctionPage();
  }

  return foundStatus.label;
};

// MongoDBの値を表示用に変換
export const convertNlc001Table = (queryResults: PatientNlc001StateType[]) => {
  const convertResults: Nlc001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      full_name_slash: name,
      email,
      tel,
      // TODO: Backlog No108
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      insuranse_entry_date: insuranseEntryDate,
    } = queryResult;

    return {
      id,
      name,
      email,
      phoneNum: tel,
      applicationDate: "convertApplicationDate",
      status: "",
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlc004Table = (queryResults: PatientNlc004StateType[]) => {
  const convertResults: Nlc004TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      nouknowId,
      full_name_slash: name,
      finished_at: lastInspectionDate,
      score,
      status,
      tel,
      email,
    } = queryResult;

    const reportStatus = convertStatusStr(
      status,
      SEARCH_INFO.NLC004,
      PatientNouKnowStatusID,
    );

    return {
      id,
      nouknowId,
      name,
      email,
      phoneNum: tel,
      lastInspectionDate,
      score,
      reportStatus,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlc005Table = (queryResults: PatientNlc005StateType[]) => {
  const convertResults: Nlc005TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      userId,
      full_name_slash: name,
      tel,
      email,
      mriStatus,
      mriProblem,
      appointmentDateMRI,
      hospital,
    } = queryResult;

    const status = convertStatusStr(mriStatus, SEARCH_INFO.NLC005, MriStatusID);

    let result: string;
    if (mriProblem === 2) {
      result = "done";
    } else if (mriProblem === 1) {
      result = "undone";
    } else {
      result = "other";
    }

    return {
      id,
      userId,
      name,
      status,
      result,
      appointmentDateMRI,
      hospital,
      phoneNum: tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlc007Table = (queryResults: PatientNlc007StateType[]) => {
  const convertResults: Nlc007TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      interviewId,
      full_name_slash: name,
      email,
      tel,
      interviewDate,
      interviewStatus,
      manager = "-",
    } = queryResult;

    const status = convertStatusStr(
      interviewStatus,
      SEARCH_INFO.NLC007,
      PatientInterviewStatusID,
    );

    return {
      id,
      interviewId,
      name,
      email,
      dateAndTime: interviewDate,
      status,
      phoneNum: tel,
      manager,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値をお知らせ一覧画面(NLG001)表示用に変換
export const convertNlg001Table = (
  queryResults: NotificationsNlg001StateType[],
) => {
  const convertResults: Nlg001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      title,
      status,
      deliveried_at: deliveriedAt,
      last_updated_at: lastUpdatedAt,
      full_name: fullName,
    } = queryResult;

    return {
      id,
      title,
      status: status === NotificationStatusID.UNPUBLISHED ? "未公開" : "公開中",
      deliveriedAt,
      lastUpdatedAt,
      changer: fullName,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld001Table = (queryResults: PatientNld001StateType[]) => {
  const convertResults: Nld001TableType[] = queryResults.map((queryResult) => {
    const { patient_id: id, full_name_slash: name, email, tel } = queryResult;

    return {
      id,
      name,
      // 2次以降フェーズ対応: No162 ステータス、検査、金額、支払い期日、支払い日、PET検査日
      status: "未払い",
      test: "FDG PET検査",
      amount: "¥500,000",
      dueDate: "2023/1/1",
      paymentDate: "2022/1/1",
      petScanDate: "2022/1/1",
      phoneNum: tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld002Table = (queryResults: PatientNld002StateType[]) => {
  const convertResults: Nld002TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      hospital_id: hospitalId,
      hospital_name: hospitalName,
      name,
      status: statusNum,
      amount: amountNum,
      due_date_display: dueDateDisplay,
      payment_date: paymentDate,
      tel,
      email,
    } = queryResult;

    return {
      id,
      hospitalId,
      hospitalName,
      name,
      statusNum,
      statusStr: _convertInspectionFeesStatusStr(statusNum),
      amount: _convertToCurrency(amountNum),
      dueDateDisplay,
      paymentDate,
      tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld003Table = (queryResults: PatientNld003StateType[]) => {
  const convertResults: Nld003TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      name,
      status: statusNum,
      amount: amountNum,
      payment_date_display: paymentDateDisplay,
      execution_date_display: executionDateDisplay,
    } = queryResult;

    return {
      id,
      name,
      statusNum,
      statusStr: _convertInspectionFeesStatusStr(statusNum),
      amount: _convertToCurrency(amountNum),
      paymentDateDisplay,
      executionDateDisplay,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNld004Table = (queryResults: PatientNld004StateType[]) => {
  const convertResults: Nld004TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_id: patientId,
      full_name_slash: name,
      status: statusNum,
      amount: amountNum,
      interview_fix_date: interviewFixDate,
      email,
      tel,
    } = queryResult;

    return {
      id,
      patientId,
      name,
      status: _convertSalesStatusStr(statusNum),
      amount: _convertToCurrency(amountNum),
      interviewFixDate,
      tel,
      email,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値をNLF001表示用に変換
export const convertNlf001Table = (queryResults: ordersNlf001StateType[]) => {
  const convertResults: Nlf001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_name: patientName,
      status: statusNum,
      delivery_date: deliveryDate,
      settlement_time: settlementTime,
      total_amount: totalAmountBigInt,
      delivery_method: deliveryMethodNum,
      updated_at: updatedAt,
    } = queryResult;

    const renewal = _convertDateDifferenceToCurrent(updatedAt);
    const totalAmount = _convertToCurrency(totalAmountBigInt);
    const deliveryMethod = _convertDeliveryMethod(
      deliveryMethodNum,
      deliveryDate,
    );
    const status = _convertOrderStatusStr(statusNum);

    return {
      id,
      patientName,
      renewal,
      totalAmount,
      status,
      deliveryMethod,
      settlementTime,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値をNLF005表示用に変換
export const convertNlf005 = (
  queryResult: Nlf005TableType | null,
  deliveryConsumptionTaxRate: number,
) => {
  if (queryResult) {
    const {
      _id: id,
      status: statusNum,
      settlement_time: settlementTime,
      delivery_method: deliveryMethodNum,
      delivery_date: deliveryDate,
      delivery_fee: deliveryFeeNum,
      total_item_amount: totalItemAmountNum,
      total_amount: totalAmountNum,
      patient_name: patientName,
      patient_address: patientAddress,
      patient_tel: patientTel,
      patient_email: patientEmail,
      transactions_info: transactionsInfoObj,
    } = queryResult;

    const transactionsInfo: TransactionInfoDisplay[] = transactionsInfoObj.map(
      (transaction) => ({
        ...transaction,
        total_amount: _convertToCurrency(transaction.total_amount),
        quantity: transaction.quantity.toString(),
        sku_amount: _convertToCurrency(
          transaction.total_amount / transaction.quantity,
        ),
        checked: true,
      }),
    );
    const deliveryFee = _convertToCommaSeparatedString(deliveryFeeNum);
    const totalItemAmount = _convertToCommaSeparatedString(totalItemAmountNum);
    const totalAmount = _convertToCurrency(totalAmountNum);
    const status = _convertOrderStatusStr(statusNum);
    const deliveryMethod = _convertDeliveryMethod(
      deliveryMethodNum,
      deliveryDate,
    );

    // 税金計算・概要
    const calculateTaxesAndAmounts = (
      items: TransactionInfo[],
    ): { [key: number]: { totalAmount: number; totalTaxes: number } } => {
      // 各消費税率ごとに合計金額を計算
      const taxRateSums = items.reduce((acc, item) => {
        const taxRate = item.consumption_tax_rate;

        if (!acc[taxRate]) {
          acc[taxRate] = { totalAmount: 0, totalTaxes: 0 as number };
        }

        acc[taxRate].totalAmount += item.total_amount;

        return acc;
      }, {} as { [key: number]: { totalAmount: number; totalTaxes: number } });

      // 配送料税金
      const deliveryTax = {
        total_amount: deliveryFeeNum,
        consumption_tax_rate: deliveryConsumptionTaxRate,
      };
      if (taxRateSums[deliveryTax.consumption_tax_rate]) {
        taxRateSums[deliveryTax.consumption_tax_rate].totalAmount +=
          deliveryTax.total_amount;
      } else {
        taxRateSums[deliveryTax.consumption_tax_rate] = {
          totalAmount: deliveryTax.total_amount,
          totalTaxes: 0,
        };
      }

      // 各消費税率ごとに税金を計算
      return Object.entries(taxRateSums).reduce((acc, [key, value]) => {
        const rate = parseFloat(key);
        acc[rate] = {
          totalAmount: value.totalAmount,
          totalTaxes: (value.totalAmount * rate) / 100,
        };

        return acc;
      }, {} as { [key: number]: { totalAmount: number; totalTaxes: number } });
    };

    const taxesAndAmounts = calculateTaxesAndAmounts(transactionsInfoObj);
    const consumptionTaxAmount = Object.values(taxesAndAmounts).reduce(
      (sum, { totalTaxes }) => sum + totalTaxes,
      0,
    );

    // ソート
    const sortedTaxRates = Object.keys(taxesAndAmounts)
      .map(Number)
      .sort((a, b) => b - a);

    // 表示用に変換
    const taxSummary = sortedTaxRates.map((taxRateStr, index) => {
      const taxRate = Number(taxRateStr);

      return {
        id: index.toString(),
        contents: `商品税${taxRate}%対象`,
        taxRate: `${taxRate}%`,
        taxableSubject: _convertToCurrency(
          taxesAndAmounts[taxRate].totalAmount,
        ),
        totalTaxes: _convertToCurrency(taxesAndAmounts[taxRate].totalTaxes),
      };
    });

    return {
      id,
      status,
      patientName,
      patientAddress,
      patientTel,
      patientEmail,
      deliveryMethod,
      settlementTime,
      transactionsInfo,
      deliveryFee,
      totalItemAmount,
      totalAmount,
      consumptionTaxAmount,
      taxSummary,
      statusNum,
    };
  }

  return {
    id: "",
    status: "",
    patientName: "",
    patientAddress: "",
    patientTel: "",
    patientEmail: "",
    deliveryMethod: "",
    settlementTime: "",
    transactionsInfo: [],
    deliveryFee: "",
    totalItemAmount: "",
    totalAmount: "",
    consumptionTaxAmount: "",
    taxSummary: [],
    statusNum: 99,
  };
};

// MongoDBの値をNLF006表示用に変換
export const convertNlf006Table = (queryResults: SalesNlf006StateType[]) => {
  const convertResults: Nlf006TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      payment_date: paymentDate,
      count,
      totalAmount: totalAmountNum,
    } = queryResult;

    const totalAmount = _convertToCurrency(totalAmountNum);

    return {
      id,
      paymentDate,
      count: count.toString(),
      totalAmount,
      checked: false,
    };
  });

  return convertResults;
};

export const convertNlf007Table = (queryResults: SalesNlf007StateType[]) => {
  const totalAmountNum = queryResults.reduce(
    (accum, curr) => accum + curr.amount,
    0,
  );
  const formattedTotalAmount = _convertToCurrency(totalAmountNum);

  const convertResult: Nlf007TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      order_id: orderId,
      amount: amountNum,
      patient_name: patientName,
      settlement_time: settlementTime,
    } = queryResult;

    const amount = _convertToCurrency(amountNum);

    return {
      id,
      orderId,
      amount,
      patientName,
      settlementTime,
      checked: false,
    };
  });

  return {
    convertResult,
    formattedTotalAmount,
  };
};

// MongoDBの値を表示用に変換
export const convertNlh001Table = (
  queryResults: CampaignsNlh001StateType[],
) => {
  const convertResults: Nlh001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      embedded_page: page,
      embedded_location: location,
      title,
      url,
      pc_image: picture,
      status: statusNum,
      delivery_date: deliveryDate,
      updated_at: deliveryDateUpdate,
      is_registered: isRegistered,
    } = queryResult;
    const update = queryResult.user_info?.full_name || "";

    const status = convertStatusStr(
      statusNum,
      SEARCH_INFO.NLH001,
      CampaignsStatusID,
    );

    const pageNum = page as EmbeddedPageID;
    const locationNum = location as EmbeddedLocationID;
    const locationStr = `${EmbeddedStr.PAGE[pageNum]}${EmbeddedStr.LOCATION[locationNum]}`;

    return {
      id,
      location: locationStr,
      campaignTitle: title,
      url,
      picture,
      status,
      deliveryDate,
      deliveryDateUpdate,
      update,
      isRegistered,
    };
  });

  return convertResults;
};

// MongoDBの値を表示用に変換
export const convertNlh003Table = (
  queryResult: CampaignsNlh003StateType,
): Nlh003TableType => {
  const {
    title,
    embedded_page: page,
    embedded_location: location,
    pc_image: pcImg,
    sp_image: spImg,
    status: statusNum,
    delivery_date: deliveryDate,
    is_limited: isLimited,
    suspend_date: suspendDate,
  } = queryResult;

  const status = convertStatusStr(
    statusNum,
    SEARCH_INFO.NLH001,
    CampaignsStatusID,
  );

  const pageNum = page as EmbeddedPageID;
  const locationNum = location as EmbeddedLocationID;
  const locationStr = `${EmbeddedStr.PAGE[pageNum]}${EmbeddedStr.LOCATION[locationNum]}`;

  const suspendDateStr = isLimited ? `制限あり ${suspendDate}` : "無制限";

  const isDeliveryStopped = statusNum === CampaignsStatusID.UNPUBLISHED;

  return {
    title,
    embedded: locationStr,
    pcImg,
    spImg,
    status,
    deliveryDate,
    suspendDate: suspendDateStr,
    isDeliveryStopped,
  };
};

// MongoDBの値をお知らせ詳細画面(NLG003)表示用に変換
export const convertNlg003 = (notification: NotificationsTableType | null) => {
  if (notification) {
    const {
      _id: id,
      title,
      detail,
      image_url: imageUrl,
      status: statusNum,
      deliveried_at: deliveriedAt,
    } = notification;

    return {
      id,
      title,
      detail,
      imageUrl: imageUrl ? `${imageUrl}?timestamp=${Date.now()}` : "",
      statusStr:
        statusNum === NotificationStatusID.UNPUBLISHED ? "未公開" : "公開中",
      statusNum,
      deliveriedAt,
    };
  }

  return {
    id: "",
    title: "",
    detail: "",
    imageUrl: "",
    statusStr: "",
    statusNum: 0,
    deliveriedAt: "",
  };
};

export const convertNlg004 = (notification: NotificationsTableType | null) => {
  if (notification) {
    const {
      _id: id,
      title,
      detail,
      image_url: imageUrl,
      status,
      deliveried_at: deliveriedAt,
      last_updated_at: lastUpdatedAt,
      full_name: fullName,
      last_updated_admin_id: lastUpdatedAdminId,
      suspend,
    } = notification;

    const dateObject = new Date(deliveriedAt);
    // 日付
    const deliveriedDate = new Date(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate(),
    );
    const currentDate = new Date();
    // 時間
    const deliveriedTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      dateObject.getHours(),
      dateObject.getMinutes(),
      dateObject.getSeconds(),
    );

    return {
      id,
      title,
      detail,
      imageUrl: imageUrl ? `${imageUrl}?timestamp=${Date.now()}` : "",
      status,
      deliveriedDate,
      deliveriedTime,
      lastUpdatedAt,
      changer: fullName,
      lastUpdatedAdminId,
      suspend,
    };
  }

  return {
    id: "",
    title: "",
    detail: "",
    imageUrl: "",
    status: 0,
    deliveriedDate: new Date(),
    deliveriedTime: new Date(),
    lastUpdatedAt: "",
    changer: "",
    lastUpdatedAdminId: "",
    suspend: false,
  };
};

export const convertNli003Table = (queryResults: Nli003StateType[]) => {
  const convertResults: Nli003TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      user_id: userId,
      full_name: fullName,
      admin_role: role,
      email,
      status: statusNum,
    } = queryResult;

    const roleStr = convertAdminRole(role);

    const statusCsv = statusNum === UserStatusID.ACTIVE ? "✓" : "";

    return {
      id,
      userId,
      fullName,
      roleStr,
      role,
      email,
      statusNum,
      statusStr: statusNum.toString(),
      statusCsv,
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を商品種別一覧画面(NLI007)表示用に変換
export const convertNli007Table = (queryResults: ItemsNli007StateType[]) => {
  const convertResults: Nli007TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      item_code: itemCode,
      item_name: itemName,
      sort,
      status,
      item_tags_info: itemTagsInfo,
      skuCount,
      image_urls: images,
    } = queryResult;

    return {
      id,
      itemCode,
      itemName,
      sort,
      status: status === ItemsSkusStatusID.VISIBLE,
      itemTagsInfo,
      checked: false,
      skuCount,
      image: images?.[0] ?? "",
    };
  });

  return convertResults;
};

// MongoDBの値をSKU一覧画面(NLI009)表示用に変換
export const convertNli009Table = (queryResults: SkusNli009StateType[]) => {
  const convertResults: Nli009TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      images,
      sku_code: code,
      sku_name: skuName,
      amount,
      stock,
      status,
      item_tags_info: itemTagsInfo,
      sort,
    } = queryResult;
    const { item_name: name } = queryResult.items_info;

    return {
      id,
      code,
      url: images[0],
      skuName,
      name,
      amount,
      stock,
      itemTagsInfo,
      isShow: status === ItemsSkusStatusID.VISIBLE,
      checked: false,
      sort,
    };
  });

  return convertResults;
};

// MongoDBの値を商品詳細一覧画面(NLI011)表示用に変換
export const convertNli011Display = (queryResults: ItemsNli011StateType) => {
  const {
    // 商品種別情報
    item_code: itemCode,
    item_name: itemName,
    item_tags_info: itemTags,
    item_description: itemDesc,
    images: itemImages,
    // SKU情報
    skus_info: skusInfo,
    // オプション情報
    option_configs_info: optionConfigInfos,
  } = queryResults;

  // 商品種別情報
  const convertItemInfo: Nli011ItemInfoType = {
    itemCode,
    itemName,
    itemTags,
    itemDesc,
    itemImages: itemImages.map((url, index) => ({
      id: index.toString(),
      url,
    })),
  };

  // SKU情報
  const convertSkusInfo: Nli011SkuInfoType[] = skusInfo
    .sort((a, b) => b.sort - a.sort)
    .map(
      ({
        _id: skuId,
        sku_code: skuCode,
        sku_name: skuName,
        amount,
        stock,
        images: skuImages,
        status: skuStatus,
        option_contents_info: optionContentsInfo,
        consumption_tax_rate: tax,
      }) => ({
        skuId,
        skuCode,
        skuName,
        amount: amount.toString(),
        stock: stock.toString(),
        skuImages,
        tax,
        isShow: skuStatus === ItemsSkusStatusID.VISIBLE,
        options: optionContentsInfo.map(
          ({
            _id: optionContentsId,
            description,
            option_config_id: optionConfigId,
          }) => ({
            optionContentsId,
            optionConfigId,
            description,
          }),
        ),
      }),
    );

  const convertOptionConfigInfos = optionConfigInfos.map(
    ({ _id: optionConfigId, option_title: optionTitle }) => ({
      optionConfigId,
      optionTitle,
    }),
  );

  return { convertItemInfo, convertSkusInfo, convertOptionConfigInfos };
};

// MongoDBの値を医療機関一覧画面(NLI016)表示用に変換
export const convertNli016Table = (queryResults: hospitalNli016StateType[]) => {
  const convertResults: Nli016TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      hospital_name: hospitalName,
      tel,
      address,
      hospital_week: hospitalWeek,
    } = queryResult;

    const hospitalWeekObj: HospitalWeek = JSON.parse(
      hospitalWeek,
    ) as HospitalWeek;

    return {
      id,
      hospitalName,
      tel,
      address,
      monday: _getDayStatus(hospitalWeekObj.mon),
      tuesday: _getDayStatus(hospitalWeekObj.tue),
      wednesday: _getDayStatus(hospitalWeekObj.wed),
      thursday: _getDayStatus(hospitalWeekObj.thu),
      friday: _getDayStatus(hospitalWeekObj.fri),
      saturday: _getDayStatus(hospitalWeekObj.sta),
      sunday: _getDayStatus(hospitalWeekObj.sun),
      checked: false,
    };
  });

  return convertResults;
};

// MongoDBの値を医療機関一覧画面(NLI017)表示用に変換
export const convertNli017Hospital = (
  queryResult: hospitalNli016StateType | null,
) => {
  if (queryResult) {
    const {
      _id: id,
      hospital_name: hospitalName,
      tel,
      address,
      hospital_week: hospitalWeek,
      hospital_hour: hospitalHour,
      email,
    } = queryResult;

    // 診療スケジュール
    const hospitalWeekObj: HospitalWeek = JSON.parse(
      hospitalWeek,
    ) as HospitalWeek;
    // 診療時間
    const hospitalHourObj: HospitalHour = JSON.parse(
      hospitalHour,
    ) as HospitalHour;
    // 営業日、定休日
    const businessAndClosedDays = _getBusinessAndClosedDays(hospitalWeekObj);

    return {
      id,
      hospitalName,
      tel,
      address,
      businessDay: `${_convertHospitalHours(hospitalHourObj)}(${
        businessAndClosedDays.open
      })`,
      regularHoliday: businessAndClosedDays.closed,
      email,
    };
  }

  return {
    id: "",
    hospitalName: "",
    tel: "",
    address: "",
    regularHoliday: "",
    hospitalHour: "",
    email: "",
  };
};

// MongoDBの値を医療機関詳細画面(NLL017)表示用に変換
export const convertNli017Table = (
  queryResults: hospitalUserNli017StateType[],
) => {
  const convertResults: Nli017TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      full_name: fullName,
      email,
      user_id: userId,
      status,
    } = queryResult;

    return {
      id,
      userId,
      fullName,
      authority: "一般ユーザー",
      email,
      checked: false,
      status,
    };
  });

  return convertResults;
};

export const convertMib001Table = (queryResults: Mib001StateType[]) => {
  const convertResults: Mib001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_name: patientName,
      patient_id: patientId,
      modality: modalityNum,
      patient_tel: patientTel,
      created_at: createdAt,
      status: statusNum,
      patient_birthday: patientBirthday,
      patient_email: patientEmail,
      patient_gender: patientGenderStr,
      candidate_book_dates: candidateBookDates,
      fix_book_date: fixBookDate,
      fix_book_date_db: fixBookDateOrg,
      note,
    } = queryResult;

    // 検査内容変換
    const modalityStr = _convertModalityStr(modalityNum);
    // ステータス
    const status = _convertHospitalReservationStatusStr(statusNum);
    // 性別
    const patientGender = _convertGender(patientGenderStr);

    // 予約候補日表示用
    const candidateBookDatesDisplayObj = {
      firstDisplay: candidateBookDates?.first
        ? _convertToAmPmStr(candidateBookDates.first)
        : "",
      secondDisplay: candidateBookDates?.second
        ? _convertToAmPmStr(candidateBookDates.second)
        : "",
      thirdDisplay: candidateBookDates?.third
        ? _convertToAmPmStr(candidateBookDates.third)
        : "",
      forthDisplay: candidateBookDates?.forth
        ? _convertToAmPmStr(candidateBookDates.forth)
        : "",
      fifthDisplay: candidateBookDates?.fifth
        ? _convertToAmPmStr(candidateBookDates.fifth)
        : "",
    };

    return {
      id,
      patientName,
      patientId,
      modalityNum,
      modalityStr,
      patientTel,
      createdAt,
      status,
      statusNum,
      patientBirthday,
      patientEmail,
      patientGender,
      candidateBookDates,
      candidateBookDatesDisplayObj,
      fixBookDate,
      fixBookDateOrg,
      note: note || "",
      checked: false,
    };
  });

  return convertResults;
};

export const convertMib002Table = (
  fixBookDate: string,
  statusNum: number,
  hospitalInfo: HospitalTimeIntervalType | null,
  modalityNum: number,
  candidateDates?: {
    first?: string;
    second?: string;
    third?: string;
    forth?: string;
    fifth?: string;
  },
) => {
  if (!candidateDates)
    return {
      convertCandidateDates: [],
      otherCandidateDates: {
        label: "その他の時間",
        value: "other",
      },
    };

  // 病院の枠時間(分)
  const hospitalFrameMinute = hospitalInfo
    ? modalityNum === ModalityID.MRI
      ? hospitalInfo.mri_interval
      : modalityNum === ModalityID.PET
      ? hospitalInfo.pet_interval
      : 0
    : 0;

  const numberSymbols = ["①", "②", "③", "④", "⑤"];
  const orderKeys: (keyof typeof candidateDates)[] = [
    "first",
    "second",
    "third",
    "forth",
    "fifth",
  ];

  const convertCandidateDates = orderKeys
    .filter((key) => candidateDates[key])
    .map((key, index) => {
      const dateObj = new Date(candidateDates[key]!);
      const endTimeObj = new Date(dateObj);
      endTimeObj.setMinutes(dateObj.getMinutes() + hospitalFrameMinute);
      const options: Intl.DateTimeFormatOptions = {
        month: "2-digit",
        day: "numeric",
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formattedStartDate = dateObj.toLocaleDateString("ja-JP", options);
      const formattedEndDate = endTimeObj.toLocaleTimeString("ja-JP", {
        hour: "2-digit",
        minute: "2-digit",
      });
      if (
        fixBookDate &&
        statusNum &&
        (statusNum === HospitalReservationStatusID.CONFIRM ||
          statusNum ===
            HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
          statusNum === HospitalReservationStatusID.WITHDRAWAL)
      ) {
        if (fixBookDate !== candidateDates[key]) {
          return {
            label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
            value: candidateDates[key]!,
            disabled: true,
          };
        }

        return {
          label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
          value: candidateDates[key]!,
        };
      }

      return {
        label: `${numberSymbols[index]} ${formattedStartDate}～${formattedEndDate}`,
        value: candidateDates[key]!,
      };
    });

  // 候補日にない場合、その他
  const isDateInCandidateDates =
    Object.values(candidateDates).includes(fixBookDate);

  const otherCandidateDates =
    (statusNum === HospitalReservationStatusID.CONFIRM ||
      statusNum === HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
      statusNum === HospitalReservationStatusID.WITHDRAWAL) &&
    isDateInCandidateDates
      ? { label: "その他の時間", value: "other", disabled: true }
      : { label: "その他の時間", value: "other" };

  return {
    convertCandidateDates,
    otherCandidateDates,
    isDateInCandidateDates,
  };
};

export const convertMic001Table = (
  queryResults: Mic001StateType[],
  hospitalInfo: HospitalTimeIntervalType | null,
) => {
  const convertResults: Mic001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      patient_name: patientName,
      status: statusNum,
      fix_book_date: fixBookDate,
      patient_tel: patientTel,
      patient_email: patientEmail,
      modality,
    } = queryResult;

    // ステータス
    const statusStr = _convertHospitalReservationStatusStr(statusNum);

    // 病院の枠時間(分)
    const hospitalFrameMinute = hospitalInfo
      ? modality === ModalityID.MRI
        ? hospitalInfo.mri_interval
        : modality === ModalityID.PET
        ? hospitalInfo.pet_interval
        : 0
      : 0;

    // 予約日
    const fixBookDateDisplay = fixBookDate
      ? _convertHospitalTimeRange(fixBookDate, hospitalFrameMinute)
      : "";

    return {
      id,
      patientName,
      statusNum,
      statusStr,
      fixBookDateDisplay,
      patientTel,
      patientEmail,
    };
  });

  return convertResults;
};

export const convertMid001Table = (
  queryResults: Mid001StateType[],
  hospitalInfo: HospitalTimeIntervalType | null,
) => {
  const convertResults: Mid001TableType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      status: statusNum,
      patient_name: patientName,
      fix_book_date: fixBookDate,
      tel,
      email,
      last_modality: lastModality,
    } = queryResult;

    // 病院の枠時間(分)
    const hospitalFrameMinute = hospitalInfo
      ? lastModality === ModalityID.MRI
        ? hospitalInfo.mri_interval
        : lastModality === ModalityID.PET
        ? hospitalInfo.pet_interval
        : 0
      : 0;

    // 予約日
    const fixBookDateDisplay = fixBookDate
      ? _convertHospitalTimeRange(fixBookDate, hospitalFrameMinute)
      : "";

    return {
      id,
      statusNum,
      statusStr: _convertHospitalInspectionStatusStr(statusNum),
      patientName,
      fixBookDateDisplay,
      tel,
      email,
    };
  });

  return convertResults;
};

export const convertMid002User = (user: Mid002UserStateType | null) => {
  if (user) {
    const {
      _id: id,
      full_name_slash: fullNameSlash,
      email,
      gender,
      tel,
      birthDay,
    } = user;

    return {
      id,
      fullNameSlash,
      email,
      gender: _convertGender(gender),
      tel,
      birthDay,
    };
  }

  return {
    id: "",
    fullNameSlash: "",
    email: "",
    gender: "",
    tel: "",
    birthDay: "",
  };
};

export const convertMid002Modality = (
  queryResults: Mid002ModalityStateType[],
  hospitalInfo: HospitalTimeIntervalType | null,
) => {
  const convertResults: Mid002ModalityTableType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        fix_book_date: fixBookDate,
        exam_date: examDate,
        hospital_name: hospitalName,
        modality: modalityNum,
        status: statusNum,
        attending_hospital_user_id: attendingHospitalUserId,
        mri_dicom_image_path: mriDicomImagePath,
        mri_doctor_review: mriDoctorReview,
        mri_doctor_note: mriDoctorNote,
      } = queryResult;

      // 病院の枠時間(分)
      const hospitalFrameMinute = hospitalInfo
        ? modalityNum === ModalityID.MRI
          ? hospitalInfo.mri_interval
          : modalityNum === ModalityID.PET
          ? hospitalInfo.pet_interval
          : 0
        : 0;

      // 予約日
      const fixBookDateDisplay = fixBookDate
        ? _convertHospitalTimeRange(fixBookDate, hospitalFrameMinute)
        : "";

      return {
        id,
        fixBookDateDisplay,
        examDate,
        hospitalName,
        modalityNum,
        modalityStr: _convertModalityStr(modalityNum),
        statusNum,
        statusStr: _convertHospitalInspectionStatusStr(statusNum),
        attendingHospitalUserId,
        mriDicomImagePath,
        mriDoctorReview,
        mriDoctorNote,
      };
    },
  );

  return convertResults;
};

export const convertMid002HospitalUser = (
  queryResults: Mid002HospitalUserStateType[],
) => {
  const convertResults: SelectBoxType[] = queryResults.map((queryResult) => {
    const { user_id: userId, full_name: fullName } = queryResult;

    return {
      label: fullName,
      value: userId,
    };
  });

  return convertResults;
};

export const convertNlc019Payments = (
  queryResults: paymentsNlc019StateType[],
) => {
  const convertResults: Nlc019PaymentsType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        payment_date: paymentDate,
        amount: amountNum,
        category: categoryNum,
        subscription_span: subscriptionSpan,
      } = queryResult;

      const category =
        categoryNum === SalesCategoryID.INTERVIEW
          ? "面談"
          : subscriptionSpan === PlanSpanID.YEAR
          ? "年契約月払い"
          : "月契約月払い";

      return {
        id,
        paymentDate,
        amount: _convertToCurrency(amountNum),
        category,
      };
    },
  );

  return convertResults;
};

export const convertNlc023Orders = (queryResults: ordersNlf001StateType[]) => {
  const convertResults: Nlc023OrdersType[] = queryResults.map((queryResult) => {
    const {
      _id: id,
      status: statusNum,
      settlement_time: settlementTime,
      total_amount: totalAmountBigInt,
      updated_at: updatedAt,
    } = queryResult;

    const renewal = _convertDateDifferenceToCurrent(updatedAt);
    const totalAmount = _convertToCurrency(totalAmountBigInt);
    const status = _convertOrderStatusStr(statusNum);

    return {
      id,
      renewal,
      totalAmount,
      status,
      settlementTime,
    };
  });

  return convertResults;
};

export const convertEventHistories = (
  queryResults: EventHistoriesStateType[],
) => {
  const convertResults: EventHistoriesDisplayType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        category,
        description,
        priority: priorityNum,
        registered_date_display: registeredDateDisplay,
        registered_user_name: registeredUserName,
      } = queryResult;

      return {
        id,
        registeredDateDisplay,
        category,
        priorityStr: _convertEventHistoriesPriorityStr(priorityNum),
        priorityNum,
        description,
        registeredUserName,
      };
    },
  );

  return convertResults;
};

export const convertNli001 = (queryResults: MasterRolesStateType[]) => {
  const groupedByCategory1 = queryResults.reduce<{
    [key: string]: MasterRolesStateType[];
  }>((acc, curr) => {
    (acc[curr.category1] = acc[curr.category1] || []).push(curr);

    return acc;
  }, {});

  return Object.entries(groupedByCategory1).map(([category1, items1]) => {
    const groupedByCategory2 = items1.reduce<{
      [key: string]: MasterRolesStateType[];
    }>((acc, curr) => {
      (acc[curr.category2] = acc[curr.category2] || []).push(curr);

      return acc;
    }, {});

    return {
      category1,
      category2: Object.entries(groupedByCategory2).map(
        ([category2, items2]) => ({
          value: category2,
          details: items2.map((item) => ({
            id: item._id,
            categoryFunc: item.category_func,
            hasAdmin: item.has_admin,
            hasDoctor: item.has_doctor,
            hasUser: item.has_user,
          })),
        }),
      ),
    };
  });
};

export const convertNlb001Task = (queryResults: Nlb001TaskStateType[]) => {
  const convertResults: Nlb001TaskDisplayType[] = queryResults.map(
    (queryResult) => {
      const {
        _id: id,
        patient_id: patientId,
        patient_name: patientName,
        category,
        updated_at: updatedAt,
      } = queryResult;

      // 画面表示用に変換
      const categoryObj = _convertTask(category);

      return {
        id,
        patientId,
        patientName,
        tag1: categoryObj.tag1,
        tag2: categoryObj.tag2,
        description: categoryObj.description,
        updatedAt,
        category,
      };
    },
  );

  return convertResults;
};
