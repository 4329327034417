import React from "react";
import Button from "../Button/Button";
import Image from "../Image/Image";

export type ImageDialogProps = {
  images: string[];
  currentIndex: number;
  width?: string;
  height?: string;
  onClose: () => void;
};

const ImageDialog = ({
  images,
  currentIndex,
  onClose,
  width,
  height,
}: ImageDialogProps) => {
  const [currentImageIndex, setCurrentImageIndex] =
    React.useState(currentIndex);
  const dialogImageRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogImageRef.current &&
        !dialogImageRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [onClose]);

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1,
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0,
    );
  };

  return (
    <div className="image-dialog">
      <div className="image-dialog__container" ref={dialogImageRef}>
        <Button
          type="sub"
          icon="keyboard_arrow_left"
          shape="circle"
          color="background-dark"
          onClick={handlePreviousImage}
        />
        <div className="image-dialog__inner">
          <Image
            url={images[currentImageIndex]}
            width={width}
            height={height}
            alt={`img${currentImageIndex + 1}`}
          />
          <span>{`${currentImageIndex + 1} / ${images.length}`}</span>
        </div>
        <Button
          type="sub"
          icon="keyboard_arrow_right"
          shape="circle"
          color="background-dark"
          onClick={handleNextImage}
        />
      </div>
    </div>
  );
};

ImageDialog.defaultProps = {
  width: "694px",
  height: "764px",
};

export default ImageDialog;
