import { PatientNouKnowStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

export const NOUKNOW_RESULT_OPTIONS = [
  {
    value: "All",
    label: "すべて",
    concentrationRank: "",
    memoryRank: "",
  },
  {
    value: "concentrationA",
    label: "集中力スコア:A",
    concentrationRank: "A",
    memoryRank: "",
  },
  {
    value: "concentrationB",
    label: "集中力スコア:B",
    concentrationRank: "B",
    memoryRank: "",
  },
  {
    value: "concentrationC",
    label: "集中力スコア:C",
    concentrationRank: "C",
    memoryRank: "",
  },
  {
    value: "memoryA",
    label: "記憶力スコア:A",
    concentrationRank: "",
    memoryRank: "A",
  },
  {
    value: "memoryB",
    label: "記憶力スコア:B",
    concentrationRank: "",
    memoryRank: "B",
  },
  {
    value: "memoryC",
    label: "記憶力スコア:C",
    concentrationRank: "",
    memoryRank: "C",
  },
  {
    value: "bothA",
    label: "両スコア:A",
    concentrationRank: "A",
    memoryRank: "A",
  },
  {
    value: "bothB",
    label: "両スコア:B",
    concentrationRank: "B",
    memoryRank: "B",
  },
  {
    value: "bothC",
    label: "両スコア:C",
    concentrationRank: "C",
    memoryRank: "C",
  },
];

type ConditionsType = {
  problem: string;
  status: PatientNouKnowStatusID;
  concentrationRank: string;
  memoryRank: string;
};

// 結果、ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  problem: NOUKNOW_RESULT_OPTIONS[0].value,
  status: PatientNouKnowStatusID.ALL,
  concentrationRank: "",
  memoryRank: "",
});
const useNlc014Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setProblem = (selectStr: string) => {
  const currentState = stateReactiveVar();
  // 選択肢から集中力スコア記憶力スコアを取得しセット
  const selectedOption = NOUKNOW_RESULT_OPTIONS.find(
    (option) => option.value === selectStr,
  );
  if (selectedOption) {
    const { concentrationRank, memoryRank } = selectedOption;
    stateReactiveVar({
      ...currentState,
      problem: selectStr,
      concentrationRank,
      memoryRank,
    });
  }
};

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export default useNlc014Conditions;
