import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc015, getMongoDb } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import useNlc015Conditions, { setNlc15Data } from "./useNlc015Conditions";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import useNlc015Dialog, { closeNlc015Dialog } from "./useNlc015Dialog";

// MRIのテスト結果を取得
const useNlc015DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [isOpen] = useNlc015Dialog();

  // 結果、ステータスのセレクトボックス
  const [{ modalityId }] = useNlc015Conditions();
  // 患者ID
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const aggregate = getAggregateNlc015(modalityId);
        const result = (await collection.aggregate(
          aggregate,
        )) as PatientNlc015StateType[];

        if (result[0]) setNlc15Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [currentUser, id, modalityId, isOpen]);

  // 結果送信
  const confirmSelection = () => {
    void (async () => {
      try {
        // 2次以降フェーズ対応: テーブル固まり次第実装
        openUpdateActionLoading();
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        await collection.updateOne(
          { _id: modalityId },
          {
            $set: {
              // mri_status: MriStatusID.USER_REPORTED,
              updated_at: new Date(),
            },
          },
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc015Dialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { fetchError, actionError, confirmSelection };
};

export default useNlc015DbActions;
