import React from "react";
import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import Divider from "../components/Divider/Divider";
import DatePicker from "../components/DatePicker/DatePicker";
import useNlg002DbActions from "../hooks/pages/NLG002/useNlg002DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import TimePicker from "../components/TimePicker/TimePicker";
import { combineDateAndTime } from "../utils/utils";

type NLG002Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  updateData: () => void;
};

// 時間初期化
const getInitialDateTime = () => {
  const initialDateTime = new Date();
  initialDateTime.setHours(0, 0, 0, 0);

  return initialDateTime;
};

const NLG002: React.FC<NLG002Props> = ({ isOpen, setIsOpen, updateData }) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [time, setTime] = React.useState(new Date());
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);
  const [notificationTitle, setNotificationTitle] = React.useState("");
  const [notificationDetail, setNotificationDetail] = React.useState("");
  const { actionError, handleRegisterNotification } = useNlg002DbActions();

  useCheckErrorThrowError([actionError]);

  const handleChangeTitle = (value: string) => {
    setNotificationTitle(value);
  };

  const handleChangeDetail = (value: string) => {
    setNotificationDetail(value);
  };

  const handleChangeDate = React.useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  const onChangeTime = React.useCallback((date: Date) => {
    setTime(date);
  }, []);

  // クローズ、キャンセル
  const handleCloseButton = React.useCallback(() => {
    setIsOpen(false);
    setNotificationTitle("");
    setNotificationDetail("");
    setSelectedDate(new Date());
    setTime(getInitialDateTime());
  }, [setIsOpen]);

  // 登録
  const handleRegisterNotificationButton = () => {
    const date = combineDateAndTime(selectedDate, time);
    handleRegisterNotification(
      notificationTitle,
      notificationDetail,
      date,
      uploadFiles,
    );
    setIsOpen(false);
    setNotificationTitle("");
    setNotificationDetail("");
    setSelectedDate(new Date());
    setTime(getInitialDateTime());
    updateData();
  };

  return (
    <DialogCustom
      title="新規お知らせ登録"
      open={isOpen}
      size="large"
      height="837px"
      closeDialog={handleCloseButton}
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={handleCloseButton}
          >
            キャンセル
          </Button>
          <Button size="large" onClick={handleRegisterNotificationButton}>
            登録
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox gap="2x" direction="column" fullWidth>
        <FormSet
          label="お知らせタイトル"
          labelWidth="90px"
          minWidth="259px"
          vertical
        >
          <Input
            placeholder="お知らせタイトル"
            width="100%"
            value={notificationTitle}
            onChange={handleChangeTitle}
          />
        </FormSet>
        <FormSet label="画像" labelWidth="90px" minWidth="259px" vertical>
          <FileUpload
            type="single"
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
          />
        </FormSet>
        <FormSet
          label="お知らせ詳細"
          labelWidth="90px"
          minWidth="259px"
          vertical
        >
          <Input
            placeholder="お知らせ詳細"
            width="100%"
            multiLine
            multiLineHeight="216px"
            value={notificationDetail}
            onChange={handleChangeDetail}
          />
        </FormSet>
        <Divider />

        <FormSet label="配信日時" labelWidth="90px" minWidth="165px" vertical>
          <LayoutBox gap="2x">
            <div>
              <DatePicker
                placeholder="配信日時"
                width="165px"
                selectedDate={selectedDate}
                onChangeDate={handleChangeDate}
              />
            </div>
            <TimePicker
              value={time}
              onChange={onChangeTime}
              dropdownWidth="74px"
              showButton={false}
            />
          </LayoutBox>
        </FormSet>
      </LayoutBox>
    </DialogCustom>
  );
};
export default NLG002;
