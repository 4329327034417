import React from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import StepNavigation from "../components/StepNavigation/StepNavigation";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Image from "../components/Image/Image";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Divider from "../components/Divider/Divider";
import ContentFooter from "../components/ContentFooter/ContentFooter";
import {
  MASTER_MANAGEMENT_OPTIONS,
  ORDER_STEPS,
  OrderStatusID,
  ROLE_KEY,
} from "../constants/common";
import {
  downloadCsvNlf005,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useNlf005DbActions from "../hooks/pages/NLF005/useNlf005DbActions";
import { convertNlf005 } from "../utils/convertDisplay";
import InformationDialog from "../components/Dialog/InformationDialog";
import Input from "../components/Input/Input";
import useSettingsFetch from "../hooks/common/useSettingsFetch";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";
import useTrackingNumberInput, {
  checkTrackingNumberInputError,
  setTrackingNumber,
} from "../hooks/input/useTrackingNumberInput";

const NLF005 = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const {
    fetchResult,
    fetchError,
    handleOrderDelete,
    handleItemDelivery,
    deliveryFix,
    actionError,
  } = useNlf005DbActions();
  const { fetchSettingsResult, fetchSettingsError } = useSettingsFetch();
  const [{ trackingNumber }] = useTrackingNumberInput();

  // FIXME: 取消API、配送手配API
  const {
    id,
    patientName,
    patientAddress,
    patientTel,
    patientEmail,
    status,
    deliveryFee,
    totalItemAmount,
    totalAmount,
    transactionsInfo,
    taxSummary,
    settlementTime,
    deliveryMethod,
    consumptionTaxAmount,
    statusNum,
  } = convertNlf005(
    fetchResult,
    fetchSettingsResult?.delivery_consumption_tax_rate || 0,
  );

  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([
    mainTabError,
    fetchError,
    actionError,
    fetchSettingsError,
  ]);

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(ADMIN.NLF001);

  // 削除ダイアログopen
  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  // 削除ダイアログ閉じる
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  // 明細出力
  const handleDetailedOutput = () => {
    downloadCsvNlf005(transactionsInfo, window.location.pathname);
  };

  // 配送完了
  const handleDeliveryFix = () => {
    deliveryFix(id, trackingNumber.value);
  };

  // 権限チェック
  const hasDetailRole = useCheckHasRole(ROLE_KEY.EC_ORDER_DETAIL);
  const hasDeliveryRole = useCheckHasRole(ROLE_KEY.EC_ORDER_DELIVERY);
  const hasDeliveryNumRole = useCheckHasRole(ROLE_KEY.EC_ORDER_DELIVERY_NUM);
  const hasCancelRole = useCheckHasRole(ROLE_KEY.EC_ORDER_CANCEL);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner ">
          <LayoutBox direction="column">
            <Button
              type="sub"
              icon="arrow_back"
              size="large"
              iconPosition="left"
              onClick={handleGoBack}
            >
              一覧へ戻る
            </Button>
            <LayoutBox direction="row" gap="2x" fullHeight>
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="2x" justify="end">
                  <Sheet
                    type="header"
                    className="util-px-12 util-py-12"
                    padding="8px"
                  >
                    <Text bold size="xl">
                      注文内容
                    </Text>
                  </Sheet>
                  <LayoutBox fullWidth direction="row">
                    <FormSet
                      label="ステータス"
                      base
                      flex="1"
                      alignLabel="center"
                      formVertical={false}
                    >
                      <Tag
                        label={status}
                        showIcon={false}
                        state={
                          status === "配送完了"
                            ? "success"
                            : status === "出荷済"
                            ? "warning"
                            : "error"
                        }
                      />
                    </FormSet>
                    <FormSet label="コード" base flex="2" formVertical={false}>
                      {id}
                    </FormSet>
                    <Button
                      color="main"
                      type="secondary"
                      size="medium"
                      icon="download"
                      iconPosition="left"
                      onClick={handleDetailedOutput}
                      disabled={!hasDetailRole}
                    >
                      明細出力
                    </Button>
                  </LayoutBox>
                  <div className="util-mb-24">
                    <Table
                      type="relaxed"
                      width="100%"
                      head={
                        <TableRow>
                          <TableColumn width="212px" id="col-1" />
                          <TableColumn width="212px" id="col-2">
                            SKU名
                          </TableColumn>
                          <TableColumn width="212px" id="col-3">
                            商品名
                          </TableColumn>
                          <TableColumn
                            width="212px"
                            id="col-4"
                            textAlign="right"
                          >
                            数量
                          </TableColumn>
                          <TableColumn
                            width="212px"
                            textAlign="right"
                            id="col-6"
                          >
                            金額
                          </TableColumn>
                          <TableColumn
                            width="212px"
                            textAlign="right"
                            id="col-5"
                          >
                            合計
                          </TableColumn>
                        </TableRow>
                      }
                      body={transactionsInfo.map((transaction) => (
                        <TableRow key={transaction._id}>
                          <TableCell textAlign="center">
                            <LayoutBox fullWidth justify="center">
                              <Image
                                width="85px"
                                height="48px"
                                url={
                                  transaction.image_urls?.length > 0
                                    ? transaction.image_urls[0]
                                    : ""
                                }
                              />
                            </LayoutBox>
                          </TableCell>
                          <TableCell>{transaction.sku_name}</TableCell>
                          <TableCell>{transaction.item_name}</TableCell>
                          <TableCell textAlign="right">
                            {transaction.quantity}
                          </TableCell>
                          <TableCell textAlign="right">
                            {transaction.sku_amount}
                          </TableCell>
                          <TableCell textAlign="right">
                            {transaction.total_amount}
                          </TableCell>
                        </TableRow>
                      ))}
                    />
                  </div>
                </LayoutBox>
                <div className="util-mb-24">
                  <LayoutBox justify="end" fullWidth>
                    <Text size="large" width="150px" bold>
                      商品
                    </Text>
                    <LayoutBox align="end">
                      <Text size="large" width="140px" textAlign="right">
                        {totalItemAmount}
                      </Text>
                    </LayoutBox>
                  </LayoutBox>
                  <Divider margin="16px 0 16px" />
                  <LayoutBox justify="end" fullWidth>
                    <Text size="large" width="150px" bold>
                      配送料
                    </Text>
                    <Text size="large" width="140px" textAlign="right">
                      {deliveryFee}
                    </Text>
                  </LayoutBox>
                  <Divider margin="16px 0 16px" />
                  <LayoutBox justify="end" fullWidth>
                    <Text size="large" width="150px" bold>
                      内税
                    </Text>
                    <Text size="large" width="140px" textAlign="right">
                      {consumptionTaxAmount}
                    </Text>
                  </LayoutBox>

                  <Divider margin="16px 0 16px" />
                  <LayoutBox justify="end" fullWidth>
                    <Text size="2xl" width="150px" bold>
                      注文合計
                    </Text>
                    <Text size="2xl" width="140px" textAlign="right" bold>
                      {totalAmount}
                    </Text>
                  </LayoutBox>
                </div>
                <div className="util-mb-24">
                  <LayoutBox direction="column" gap="2x" justify="end">
                    <Sheet
                      type="header"
                      className="util-px-12 util-py-12"
                      padding="8px"
                    >
                      <Text bold size="xl">
                        税金概要
                      </Text>
                    </Sheet>

                    <Table
                      type="condensed"
                      width="100%"
                      head={
                        <TableRow>
                          <TableColumn width="318px" id="col-1">
                            内容
                          </TableColumn>
                          <TableColumn
                            width="318px"
                            id="col-2"
                            textAlign="right"
                          >
                            税率
                          </TableColumn>
                          <TableColumn
                            width="318px"
                            id="col-3"
                            textAlign="right"
                          >
                            内税対象額
                          </TableColumn>
                          <TableColumn
                            width="318px"
                            id="col-4"
                            textAlign="right"
                          >
                            税金合計
                          </TableColumn>
                        </TableRow>
                      }
                      body={taxSummary.map((tax) => (
                        <TableRow key={tax.id}>
                          <TableCell>{tax.contents}</TableCell>
                          <TableCell textAlign="right">{tax.taxRate}</TableCell>
                          <TableCell textAlign="right">
                            {tax.taxableSubject}
                          </TableCell>
                          <TableCell textAlign="right">
                            {tax.totalTaxes}
                          </TableCell>
                        </TableRow>
                      ))}
                    />
                    <Sheet
                      type="header"
                      className="util-px-12 util-py-12"
                      padding="8px"
                    >
                      <Text bold size="xl">
                        状況
                      </Text>
                    </Sheet>
                    <LayoutBox direction="row" fullWidth align="baseline">
                      <StepNavigation
                        currentStepId="content-1"
                        steps={ORDER_STEPS}
                        isVertical
                      />
                      <LayoutBox direction="column" gap="1x">
                        <LayoutBox direction="column" gap="1/2x">
                          <Text
                            color="neutralLight"
                            size="small"
                            lineHeight="19.5px"
                          >
                            {settlementTime}
                          </Text>
                          <Text size="large">支払い完了</Text>
                        </LayoutBox>
                        <LayoutBox direction="column" gap="1/2x">
                          <Text
                            color="neutralLight"
                            size="small"
                            lineHeight="19.5px"
                          >
                            {settlementTime}
                          </Text>
                          <Text size="large">注文</Text>
                        </LayoutBox>
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                </div>
              </Sheet>
              <LayoutBox>
                <Sheet
                  className="util-px-24 util-py-24"
                  minWidth="520px"
                  minHeight="1008px"
                >
                  <LayoutBox fullWidth direction="column" gap="2x">
                    <Sheet type="header" padding="8px">
                      <Text bold size="xl">
                        顧客情報
                      </Text>
                    </Sheet>
                    <LayoutBox fullWidth direction="column">
                      <FormSet label="名前" base formVertical={false}>
                        {patientName}
                      </FormSet>
                      <FormSet label="住所" base formVertical={false}>
                        {patientAddress}
                      </FormSet>
                      <FormSet label="電話番号" base formVertical={false}>
                        {patientTel}
                      </FormSet>
                      <FormSet label="メールアドレス" base formVertical={false}>
                        {patientEmail}
                      </FormSet>
                    </LayoutBox>
                  </LayoutBox>
                  <LayoutBox fullWidth direction="column" gap="2x">
                    <Sheet type="header" className="util-mt-24" padding="8px">
                      <Text bold size="xl">
                        支払い
                      </Text>
                    </Sheet>
                    <LayoutBox fullWidth direction="column">
                      <FormSet label="配送" base formVertical={false}>
                        {deliveryMethod}
                      </FormSet>
                      <FormSet label="金額" base formVertical={false}>
                        {totalAmount}
                      </FormSet>
                      <FormSet label="メールアドレス" base formVertical={false}>
                        {patientEmail}
                      </FormSet>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </div>
      </div>
      <ContentFooter
        footerCenter={
          <>
            {statusNum !== OrderStatusID.SHIPPED ? (
              <>
                <Button
                  color="danger"
                  type="secondary"
                  icon="do_disturb"
                  size="large"
                  onClick={openDeleteDialog}
                  disabled={!hasCancelRole}
                >
                  取消
                </Button>
                <InformationDialog
                  alertLevel="error"
                  open={isDeleteDialogOpen}
                  closeDialog={closeDeleteDialog}
                  title="削除してもよろしいですか？"
                  footer={
                    <LayoutBox>
                      <Button
                        color="danger"
                        size="medium"
                        onClick={handleOrderDelete}
                      >
                        削除
                      </Button>
                      <Button
                        color="neutral"
                        type="sub"
                        size="medium"
                        onClick={closeDeleteDialog}
                      >
                        キャンセル
                      </Button>
                    </LayoutBox>
                  }
                >
                  この操作は取り消せません。
                </InformationDialog>
                <Button
                  onClick={handleItemDelivery}
                  color="main"
                  type="primary"
                  size="large"
                  disabled={
                    !hasDeliveryRole ||
                    statusNum === OrderStatusID.DELIVERY_COMPLETED
                  }
                >
                  配送手配
                </Button>
              </>
            ) : (
              <LayoutBox gap="2x" fullWidth justify="center">
                <Button
                  type="secondary"
                  width="245px"
                  size="large"
                  icon="upload"
                  iconPosition="left"
                  disabled={!hasDeliveryNumRole}
                >
                  配送番号CSVアップロード
                </Button>
                <Divider vertical height="40px" margin="0" />
                <FormSet
                  label="配送番号"
                  labelWidth="90px"
                  base
                  formVertical={false}
                >
                  <LayoutBox fullWidth gap="1x" flexShrink={0}>
                    <Input
                      width="169px"
                      placeholder="配送番号"
                      size="default"
                      value={trackingNumber.value}
                      onChange={(value) => setTrackingNumber(value)}
                      error={trackingNumber.isError}
                    />
                    <Button
                      width="96px"
                      size="large"
                      disabled={
                        !hasDeliveryNumRole || checkTrackingNumberInputError()
                      }
                      onClick={handleDeliveryFix}
                    >
                      配送完了
                    </Button>
                  </LayoutBox>
                </FormSet>
              </LayoutBox>
            )}
          </>
        }
      />
    </>
  );
};

export default NLF005;
