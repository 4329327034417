import React, { useEffect, useState } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import Dropdown from "../components/Dropdown/Dropdown";
import DetailsMenu from "../components/Popover/DetailsMenu";
import Text from "../components/Text/Text";
import {
  MASTER_MANAGEMENT_OPTIONS,
  INIT_SEARCH_CRITERIA,
  INIT_PAGE,
  ROLE_KEY,
} from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useNlg001DbActions from "../hooks/pages/NLG001/useNlg001DbActions";
import { convertNlg001Table } from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import NLG002 from "./NLG002";
import { ADMIN } from "../constants/pagePaths";
import useNlg001Conditions from "../hooks/pages/NLG001/useNlg001Conditions";
import InformationDialog from "../components/Dialog/InformationDialog";
import { useCheckErrorThrowError } from "../utils/checkError";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setId } from "../hooks/common/useId";
import NLG004 from "./NLG004";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const DETAILS_ITEM = [
  {
    label: "編集",
    value: "0",
    icon: "edit",
  },
  {
    label: "削除",
    value: "1",
    icon: "delete",
    danger: true,
  },
];

const NLG001 = () => {
  const [conditions, setConditions] = useNlg001Conditions();
  const [notifications, setNotifications] = useStateCustomObj<
    Nlg001TableType[]
  >([]);
  // ソートの状態
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  // 入力中の検索条件
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // 配信日時
  const [startDate, setStartDate] = React.useState<Date | undefined>(
    conditions.startDate,
  );
  const [endDate, setEndDate] = React.useState<Date | undefined>(
    conditions.endDate,
  );
  // 更新日時
  const [startUpdateDate, setStartUpdateDate] = React.useState<
    Date | undefined
  >(conditions.startUpdateDate);
  const [endUpdateDate, setEndUpdateDate] = React.useState<Date | undefined>(
    conditions.endUpdateDate,
  );
  // 更新者
  const [selectedChanger, setSelectedChanger] = React.useState<
    string | undefined
  >(undefined);
  // 新規ダイアログ
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  // 編集ダイアログ
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState("");
  const {
    fetchResult,
    changerList,
    fetchError,
    actionError,
    handleNotificationDelete,
  } = useNlg001DbActions({
    sortExamples,
    submittedSearchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // 権限チェック
  const hasAddRole = useCheckHasRole(ROLE_KEY.NOTICE_LIST_ADD);
  const hasEditRole = useCheckHasRole(ROLE_KEY.NOTICE_DETAIL_EDIT);

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError, actionError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNlg001Table(fetchResult);
    setNotifications(convertResult);
  }, [fetchResult, setNotifications]);

  // 検索項目アクション
  // お知らせ
  const handleChangeTitle = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, title: value }));
  };
  // 配信日時
  const handleChangeRangeDate = React.useCallback(
    (startDate: Date, endDate: Date) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        deliveryStartDate: startDate,
        deliveryEndDate: endDate,
      }));
    },
    [],
  );
  // 更新日時
  const handleChangeUpdateRangeDate = React.useCallback(
    (startDate: Date, endDate: Date) => {
      setStartUpdateDate(startDate);
      setEndUpdateDate(endDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        lastUpdatedStartDate: startDate,
        lastUpdatedEndDate: endDate,
      }));
    },
    [],
  );
  // 更新者
  const handleChangeChanger = (value: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      lastUpdatedAdminId: value,
    }));
    setSelectedChanger(value);
  };
  // ステータス
  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // お知らせ検索
  const handleSearchNotifications = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下時のイベント
  const handleResetSearchCriteria = () => {
    const { status: _, ...restSearchCriteria } = INIT_SEARCH_CRITERIA.NLG001;
    setSearchCriteria((prevState) => ({ ...prevState, ...restSearchCriteria }));
    setStartDate(undefined);
    setEndDate(undefined);
    setStartUpdateDate(undefined);
    setEndUpdateDate(undefined);
    setSelectedChanger(undefined);
  };

  // ポップアップ新規登録用イベント
  const updateData = () => {
    const { status: _, ...restSearchCriteria } = INIT_SEARCH_CRITERIA.NLG001;
    setSearchCriteria((prevState) => ({ ...prevState, ...restSearchCriteria }));
    setSubmittedSearchCriteria(conditions.search);
    setStartDate(undefined);
    setEndDate(undefined);
    setStartUpdateDate(undefined);
    setEndUpdateDate(undefined);
    setSelectedChanger(undefined);
    setPage(INIT_PAGE);
  };

  const handleCheckNotificationId = (
    checked: boolean,
    notificationId: string,
  ) => {
    const newNotifications = notifications.map((notification) => {
      if (notification.id === notificationId) {
        return { ...notification, checked };
      }

      return notification;
    });

    setNotifications(newNotifications);

    setIsCheckAllUserIds(
      newNotifications.every((notification) => notification.checked),
    );
  };

  const handleCheckIsAllNotificationsIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newNotifications = notifications.map((notification) => ({
      ...notification,
      checked,
    }));
    setNotifications(newNotifications);
  };

  const isCheckEachId = React.useMemo(
    () => notifications.some((notification) => notification.checked === true),
    [notifications],
  );

  const isCheckAllId = React.useMemo(
    () => notifications.every((notification) => notification.checked === true),
    [notifications],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  // ソートクリックイベント
  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // 削除ダイアログopen
  const openDialog = () => {
    setDialogOpen(true);
  };

  // 削除ダイアログclose
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // 新規お知らせ登録ポップアップ
  const handleOpenRegisterPopUp = () => {
    setIsRegisterDialogOpen(true);
  };

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToNotificationDetail = (id: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
      startDate,
      endDate,
      startUpdateDate,
      endUpdateDate,
    });
    setId(id);
    navigate(ADMIN.NLG003);
  };

  // 編集・削除ポップアップ
  const handleDetailsMenuItemClick = (
    notificationId: string,
    label: string,
  ) => {
    const selectedItem = DETAILS_ITEM.find((item) => item.label === label);
    if (selectedItem) {
      if (selectedItem.value === "0") {
        // 更新ポップアップ
        setId(notificationId);
        setIsEditDialogOpen(true);
      } else if (selectedItem.value === "1") {
        setDeleteNotificationId(notificationId);
        // 削除ポップアップ
        openDialog();
      }
    }
  };

  // 削除処理
  const handleDelete = () => {
    handleNotificationDelete(deleteNotificationId);
    closeDialog();
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={5}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <LayoutBox align="center" justify="between" gap="3x" fullWidth>
                  <FormSet label="お知らせ" labelWidth="96px" base>
                    <Input
                      value={searchCriteria.title}
                      placeholder="お知らせ"
                      width="100%"
                      onChange={handleChangeTitle}
                    />
                  </FormSet>
                  <FormSet label="配信日時" labelWidth="96px" base>
                    <DatePicker
                      type="range"
                      placeholder="開始日"
                      placeholderEnd="終了日"
                      width="100%"
                      onChangeRangeDate={handleChangeRangeDate}
                      selectedDateRange={{ startDate, endDate }}
                    />
                  </FormSet>
                  <FormSet label="更新日時" labelWidth="96px" base>
                    <DatePicker
                      type="range"
                      placeholder="開始日"
                      placeholderEnd="終了日"
                      width="100%"
                      onChangeRangeDate={handleChangeUpdateRangeDate}
                      selectedDateRange={{
                        startDate: startUpdateDate,
                        endDate: endUpdateDate,
                      }}
                    />
                  </FormSet>
                  <FormSet label="更新者" labelWidth="96px" base>
                    <Dropdown
                      items={changerList}
                      placeholder="更新者"
                      width="100%"
                      value={selectedChanger}
                      onChange={handleChangeChanger}
                    />
                  </FormSet>
                </LayoutBox>
                <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                  <FormSet label="ステータス" labelWidth="96px" base>
                    <LayoutBox align="center" justify="start" gap="3x">
                      <Checkbox
                        label="未公開"
                        checked={searchCriteria.status.isUnpublished}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isUnpublished")
                        }
                      />
                      <Checkbox
                        label="公開中"
                        checked={searchCriteria.status.isNowOpen}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isNowOpen")
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchNotifications}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox align="center" justify="end" fullWidth>
                  <Button
                    type="secondary"
                    size="medium"
                    icon="add"
                    onClick={handleOpenRegisterPopUp}
                    disabled={!hasAddRole}
                  >
                    新規お知らせ登録
                  </Button>
                </LayoutBox>

                <Table
                  type="condensed"
                  fixedLayout
                  width="100%"
                  roleKey={ROLE_KEY.NOTICE_LIST_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="100%" maxWidth="953px" id="col-1">
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="お知らせ"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllNotificationsIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="177px"
                        maxWidth="177px"
                        id="col-2"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        onClickSort={onClickSort}
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        width="190px"
                        maxWidth="190px"
                        id="col-3"
                        onClickSort={onClickSort}
                      >
                        配信日時
                      </TableColumn>
                      <TableColumn
                        width="190px"
                        maxWidth="190px"
                        id="col-4"
                        canSort
                        sortedDirection={sortExamples[2].sortDirection}
                        onClickSort={onClickSort}
                      >
                        最終更新日時
                      </TableColumn>
                      <TableColumn
                        width="177px"
                        maxWidth="177px"
                        id="col-5"
                        canSort
                        sortedDirection={sortExamples[3].sortDirection}
                        onClickSort={onClickSort}
                      >
                        更新者
                      </TableColumn>
                      <TableColumn width="77px" maxWidth="77px" id="col-6" />
                      <TableColumn width="77px" maxWidth="77px" id="col-7" />
                    </TableRow>
                  }
                  body={notifications.map((notification) => (
                    <TableRow
                      key={notification.id}
                      isSelected={notification.checked}
                    >
                      <TableCell tooltipText={notification.title}>
                        <LayoutBox gap="none" align="center">
                          <Checkbox
                            checked={notification.checked}
                            onChecked={(checked) =>
                              handleCheckNotificationId(
                                checked,
                                notification.id,
                              )
                            }
                          />
                          <Text>{notification.title}</Text>
                        </LayoutBox>
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={notification.status}
                          showIcon={false}
                          state={
                            notification.status === "未公開"
                              ? "error"
                              : "success"
                          }
                        />
                      </TableCell>
                      <TableCell>{notification.deliveriedAt}</TableCell>
                      <TableCell>{notification.lastUpdatedAt}</TableCell>
                      <TableCell>{notification.changer}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          onClick={() =>
                            navigateToNotificationDetail(notification.id)
                          }
                          type="sub"
                          size="small"
                        >
                          詳細
                        </Button>
                      </TableCell>

                      <TableCell textAlign="center">
                        {hasEditRole && (
                          <DetailsMenu
                            items={DETAILS_ITEM}
                            width={133}
                            onClick={(label) => {
                              handleDetailsMenuItemClick(
                                notification.id,
                                label,
                              );
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      <InformationDialog
        alertLevel="error"
        open={dialogOpen}
        closeDialog={closeDialog}
        title="削除してもよろしいですか？"
        footer={
          <LayoutBox>
            <Button color="danger" size="medium" onClick={handleDelete}>
              削除
            </Button>
            <Button
              color="neutral"
              type="sub"
              size="medium"
              onClick={closeDialog}
            >
              キャンセル
            </Button>
          </LayoutBox>
        }
      >
        この操作は取り消せません。
      </InformationDialog>
      {isRegisterDialogOpen && (
        <NLG002
          isOpen={isRegisterDialogOpen}
          setIsOpen={setIsRegisterDialogOpen}
          updateData={updateData}
        />
      )}
      {isEditDialogOpen && (
        <NLG004 isOpen={isEditDialogOpen} setIsOpen={setIsEditDialogOpen} />
      )}
    </>
  );
};

export default NLG001;
