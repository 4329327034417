import React, { useEffect } from "react";

import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Dropdown from "../components/Dropdown/Dropdown";
import TableRow from "../components/Table/TableRow";
import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import Checkbox from "../components/Checkbox/Checkbox";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import DialogCustom from "../components/Dialog/DialogCustom";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { ADMIN_SELECT_ROLE, ADMIN_SELECT_STATUS } from "../constants/common";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import useNli006DbActions from "../hooks/pages/NLI006/useNli006DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";

type NLI006Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectUsers: Nli003TableType[];
};

const initRole = "3";
const initStatus = "1";

const NLI006: React.FC<NLI006Props> = ({ isOpen, setIsOpen, selectUsers }) => {
  const [users, setUsers] = useStateCustomObj<Nli003TableType[]>([]);
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState(initRole);
  const [selectedStatus, setSelectedStatus] = React.useState(initStatus);
  const { handleUpdateUser, actionError } = useNli006DbActions();

  useCheckErrorThrowError([actionError]);

  useEffect(() => {
    setUsers(selectUsers);
    setIsCheckAllUserIds(true);
  }, [setUsers, selectUsers]);

  // スタータス
  const handleChangeRadio = React.useCallback((value: string) => {
    setSelectedStatus(value);
  }, []);

  // 登録ボタン制御 チェックがない場合、非活性
  const isAnyUserChecked = React.useMemo(
    () => users.some((user) => user.checked),
    [users],
  );

  // 登録ボタン
  const handleUpdateUsersButton = () => {
    // チェック有のみ
    const idArray = users
      .filter((user) => user.checked === true)
      .map((user) => user.id);
    handleUpdateUser(idArray, selectedRole, selectedStatus);
    setIsOpen(false);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);
    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = users.map((user) => ({ ...user, checked }));
    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  return (
    <>
      <DialogCustom
        open={isOpen}
        closeDialog={() => setIsOpen(false)}
        size="medium"
        title="一括編集"
        height="520px"
        footerRight={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={() => setIsOpen(false)}
              >
                キャンセル
              </Button>
              <Button
                type="primary"
                size="large"
                width="64px"
                disabled={!isAnyUserChecked}
                onClick={handleUpdateUsersButton}
              >
                登録
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox fullWidth direction="column">
          <FormSet label="権限" vertical>
            <Dropdown
              items={ADMIN_SELECT_ROLE}
              onChange={(value) => setSelectedRole(value)}
              value={selectedRole}
              width="152px"
            />
          </FormSet>
          <FormSet label="ステータス" vertical>
            <LayoutBox fullWidth gap="2x" justify="start">
              <RadioButtonGroup
                vertical
                column={6}
                name="radio"
                items={ADMIN_SELECT_STATUS}
                selectedValue={selectedStatus}
                onChange={handleChangeRadio}
              />
            </LayoutBox>
          </FormSet>
          <div className="util-mt-8" />
          <Table
            type="condensed"
            width="100%"
            head={
              <TableRow>
                <TableColumn width="144px" id="col-1">
                  <LayoutBox align="center" justify="start">
                    <Checkbox
                      checked={isCheckAllUserIds}
                      label="ID"
                      indeterminate={isIndeterminate}
                      onChecked={(checked) => handleCheckIsAllUserIds(checked)}
                    />
                  </LayoutBox>
                </TableColumn>
                <TableColumn width="144px" id="col-2">
                  ユーザー名
                </TableColumn>
                <TableColumn width="144px" id="col-3">
                  権限
                </TableColumn>
                <TableColumn width="144px" id="col-4">
                  メールアドレス
                </TableColumn>
              </TableRow>
            }
            body={users.map((user) => (
              <TableRow key={user.id} isSelected={user.checked}>
                <TableCell>
                  <LayoutBox gap="none" fullWidth>
                    <Checkbox
                      checked={user.checked}
                      onChecked={(checked) =>
                        handleCheckUserId(checked, user.id)
                      }
                    />
                    <Text size="xs">{user.userId}</Text>
                  </LayoutBox>
                </TableCell>
                <TableCell>
                  <Text size="xs">{user.fullName}</Text>
                </TableCell>
                <TableCell>
                  <Tag
                    label={user.roleStr}
                    showIcon={false}
                    state={user.roleStr === "CMC" ? "success" : "neutral"}
                  />
                </TableCell>
                <TableCell>
                  <Text size="xs">{user.email}</Text>
                </TableCell>
              </TableRow>
            ))}
          />
        </LayoutBox>
      </DialogCustom>
    </>
  );
};

export default NLI006;
