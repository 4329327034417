import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import TableCell from "../components/Table/TableCell";
import {
  INIT_EVALUATION,
  MASTER_MANAGEMENT_OPTIONS,
  USER_EVALUATION,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import {
  convertNlc019Payments,
  convertPatientInfo,
} from "../utils/convertDisplay";
import Label from "../components/FormSet/Label";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import Divider from "../components/Divider/Divider";
import useNlc019DbActions from "../hooks/pages/NLC019/useNlc019DbActions";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";

const NLC019 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(INIT_EVALUATION);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();

  // 患者情報取得
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { id, name, birth, genderStr, tel, email, address, evaluation } =
    convertPatientInfo(patientInfo);

  // 支払い情報取得
  const { payments, error: paymentsError } = useNlc019DbActions();
  const convertPayments = convertNlc019Payments(payments);

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
    paymentsError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLD004}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <span className="util-font-neutral--light font-component-chip">
                          {id}
                        </span>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          <div className="util-half-width">
                            <LayoutBox align="center">
                              <Label base size="medium" width="120px">
                                ユーザー評価
                              </Label>
                              <LayoutBox>
                                <RadioButtonGroup
                                  name="userRatingRadioButton"
                                  rowItems={USER_EVALUATION}
                                  onChange={handleChangeEvaluation}
                                  selectedValue={selectedValue}
                                  radioGap="16px"
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <FormSet base label="生年月日">
                                    {birth}
                                  </FormSet>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <FormSet base label="性別">
                                    {genderStr}
                                  </FormSet>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <FormSet base label="  電話番号">
                                    {tel}
                                  </FormSet>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <FormSet base label="メールアドレス">
                                    {email}
                                  </FormSet>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <LayoutBox align="center">
                                <FormSet base label="住所1">
                                  {address}
                                </FormSet>
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <Tabs
                    selectedTab={3}
                    tabs={userDetailTabItems}
                    size="medium"
                    bottomBorder
                    onClick={handleSelectAdminUserDetailTab}
                  />
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="3x">
                      <div className="util-full-width">
                        <LayoutBox direction="column" gap="2x">
                          <LayoutBox direction="column">
                            <Table
                              type="regular"
                              width="100%"
                              head={
                                <TableRow>
                                  <TableColumn width="269.5px" id="col-1">
                                    項目
                                  </TableColumn>
                                  <TableColumn width="269.5px" id="col-2">
                                    支払い日
                                  </TableColumn>
                                  <TableColumn
                                    textAlign="right"
                                    width="269.5px"
                                    id="col-3"
                                  >
                                    金額
                                  </TableColumn>
                                </TableRow>
                              }
                              body={convertPayments.map((convertPayment) => (
                                <TableRow key={convertPayment.id}>
                                  <TableCell>
                                    {convertPayment.category}
                                  </TableCell>
                                  <TableCell>
                                    {convertPayment.paymentDate}
                                  </TableCell>
                                  <TableCell textAlign="right">
                                    {convertPayment.amount}
                                  </TableCell>
                                </TableRow>
                              ))}
                            />
                          </LayoutBox>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <EventHistories />
        </div>
      </div>
    </>
  );
};

export default NLC019;
