import React, { useEffect, useState } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Text from "../components/Text/Text";
import {
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
  handleSelectHospitalMasterTab,
} from "../utils/utils";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import {
  convertNli017Hospital,
  convertNli017Table,
} from "../utils/convertDisplay";
import {
  SORT_EXAMPLES,
  INIT_SEARCH_CRITERIA,
  INIT_PAGE,
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
} from "../constants/common";
import { setPage } from "../hooks/component/pagination/usePagination";
import MenuList from "../components/MenuList/MenuList";
import Popover from "../components/Popover/Popover";
import useMie004DbActions from "../hooks/pages/MIE004/useMie004DbActions";
import MIE005 from "./MIE005";
import MIE006 from "./MIE006";

// NLI017と同様の為、同じ定義を使用
const MIE004 = () => {
  const [hospitalUsers, setHospitalUsers] = useStateCustomObj<
    Nli017TableType[]
  >([]);
  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES.NLI017);
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLI017,
  );
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLI017,
  );
  const {
    fetchHospitalResult,
    fetchHospitalError,
    fetchHospitalUserResult,
    fetchHospitalUserError,
  } = useMie004DbActions({ sortExamples, submittedSearchCriteria });
  const { hospitalName, tel, address, businessDay, regularHoliday } =
    convertNli017Hospital(fetchHospitalResult);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHospitalDialogOpen, setIsHospitalDialogOpen] = useState(false);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [userDialogTitle, setUserDialogTitle] = useState("");
  const [selectHospitalUser, setSelectHospitalUser] =
    useState<Nli017TableType>();

  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  useCheckErrorThrowError([
    mainTabError,
    fetchHospitalError,
    fetchHospitalUserError,
  ]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNli017Table(fetchHospitalUserResult);
    setHospitalUsers(convertResult);
  }, [fetchHospitalUserResult, setHospitalUsers]);

  // 検索条件 医療機関ユーザID
  const handleChangeHospitalUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, hospitalUserId: value }));
  };

  // 検索条件 医師名
  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, doctorName: value }));
  };

  // 検索条件 メール
  const handleChangeEmail = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, email: value }));
  };

  // 検索ボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () => {
    const { role: _, ...restSearchCriteria } = INIT_SEARCH_CRITERIA.NLI017;
    setSearchCriteria((prevState) => ({
      ...prevState,
      ...restSearchCriteria,
    }));
  };

  // 病院情報を編集ボタン押下
  const handleEditHospital = () => {
    setIsHospitalDialogOpen(true);
  };

  // 詳細ボタン押下
  const handleUpdateUser = (hospitalUser: Nli017TableType) => {
    setUserDialogTitle("更新");
    setSelectHospitalUser(hospitalUser);
    setIsUserDialogOpen(true);
  };

  // 医師を追加ボタン押下
  const handleAddUser = () => {
    setUserDialogTitle("登録");
    setIsUserDialogOpen(true);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = hospitalUsers.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setHospitalUsers(newUsers);

    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);

    const newUsers = hospitalUsers.map((user) => ({ ...user, checked }));

    setHospitalUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => hospitalUsers.some((user) => user.checked === true),
    [hospitalUsers],
  );

  const isCheckAllId = React.useMemo(
    () => hospitalUsers.every((user) => user.checked === true),
    [hospitalUsers],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div
                className="util-mr-16 util-mb-8"
                ref={activatorRef}
                onClick={() => setIsOpen(true)}
              >
                <Button type="secondary" size="large" icon="settings">
                  マスタ管理
                </Button>
              </div>
              <Popover
                open={isOpen}
                activatorRef={activatorRef}
                width={160}
                direction="under"
                withShadow
                toggleOpen={() => setIsOpen(!isOpen)}
              >
                <MenuList
                  items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                  onClick={handleSelectHospitalMasterInfo}
                />
              </Popover>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mb-8">
              <Text size="2xl">マスタ管理</Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <Sheet
                padding=""
                type="border-blue"
                className="util-px-20 util-py-16"
              >
                <LayoutBox direction="column" gap="2x">
                  <LayoutBox align="center" justify="between" fullWidth>
                    <Text size="2xl" bold>
                      {hospitalName}
                    </Text>
                    <div className="util-mr-4">
                      <Button
                        type="secondary"
                        icon="edit"
                        width="140px"
                        onClick={handleEditHospital}
                      >
                        病院情報を編集
                      </Button>
                    </div>
                  </LayoutBox>
                  <LayoutBox direction="column" fullWidth>
                    <LayoutBox
                      align="center"
                      justify="between"
                      gap="3x"
                      fullWidth
                    >
                      <FormSet label="診療時間" labelWidth="120px" base>
                        <Text>{businessDay}</Text>
                      </FormSet>
                      <FormSet label="定休日" labelWidth="120px" base>
                        <Text>{regularHoliday}</Text>
                      </FormSet>
                      <div className="util-full-width" />
                    </LayoutBox>
                    <LayoutBox align="center" justify="start" gap="2x">
                      <FormSet label="電話番号" labelWidth="120px" base>
                        <Text>{tel}</Text>
                      </FormSet>
                    </LayoutBox>
                    <LayoutBox align="center" justify="start" gap="2x">
                      <FormSet label="住所" labelWidth="120px" base>
                        <Text>{address}</Text>
                      </FormSet>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <Sheet
                padding=""
                type="default"
                className="util-mt-20  util-border-bottom-gray util-bg-neutral--white"
              >
                <Tabs
                  size="medium"
                  selectedTab={1}
                  onClick={handleSelectHospitalMasterTab}
                  tabs={HOSPITAL_MASTER_MANAGEMENT_OPTIONS.map((option) => ({
                    title: option.label,
                  }))}
                />
              </Sheet>
              <Sheet type="gray" className="util-mt-24 util-px-24 util-py-24">
                <LayoutBox direction="column">
                  <LayoutBox
                    align="center"
                    justify="between"
                    gap="3x"
                    fullWidth
                  >
                    <FormSet label="ID" labelWidth="120px" base>
                      <Input
                        value={searchCriteria.hospitalUserId}
                        placeholder="ID"
                        width="494px"
                        onChange={handleChangeHospitalUserId}
                      />
                    </FormSet>
                    <FormSet label="医師名" labelWidth="120px" base>
                      <Input
                        value={searchCriteria.doctorName}
                        placeholder="医師名"
                        width="494px"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet label="メールアドレス" labelWidth="120px" base>
                      <Input
                        value={searchCriteria.email}
                        placeholder="メールアドレス"
                        width="494px"
                        onChange={handleChangeEmail}
                      />
                    </FormSet>
                  </LayoutBox>
                </LayoutBox>
                <div className="util-mt-16">
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="sub"
                      color="neutral"
                      size="large"
                      onClick={handleResetSearchCriteria}
                    >
                      クリア
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleSearchUsers}
                    >
                      検索
                    </Button>
                  </LayoutBox>
                </div>
              </Sheet>
              <LayoutBox direction="column" gap="2x" justify="end">
                <div className="util-full-width util-mt-24">
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="secondary"
                      size="medium"
                      icon="person_add_alt_1"
                      onClick={handleAddUser}
                    >
                      医師を追加
                    </Button>
                  </LayoutBox>
                </div>
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="330px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        医師名
                      </TableColumn>
                      <TableColumn width="330px" id="col-3">
                        メールアドレス
                      </TableColumn>

                      <TableColumn width="77px" id="col-4" />
                    </TableRow>
                  }
                  body={hospitalUsers.map((hospitalUser) => (
                    <TableRow
                      key={hospitalUser.id}
                      isSelected={hospitalUser.checked}
                    >
                      <TableCell>
                        <Checkbox
                          label={hospitalUser.userId}
                          checked={hospitalUser.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, hospitalUser.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{hospitalUser.fullName}</TableCell>
                      <TableCell>{hospitalUser.email}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => handleUpdateUser(hospitalUser)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isHospitalDialogOpen && (
        <MIE005
          isOpen={isHospitalDialogOpen}
          setIsOpen={setIsHospitalDialogOpen}
          title="病院情報を編集"
          hospitalData={fetchHospitalResult}
        />
      )}
      {isUserDialogOpen && (
        <MIE006
          isOpen={isUserDialogOpen}
          setIsOpen={setIsUserDialogOpen}
          title={userDialogTitle}
          hospitalId={fetchHospitalResult?.hospital_id}
          hospitalUser={selectHospitalUser}
          setHospitalUser={setSelectHospitalUser}
        />
      )}
    </>
  );
};

export default MIE004;
