import React from "react";

import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import TableCell from "../components/Table/TableCell";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import Table from "../components/Table/Table";
import TimePicker from "../components/TimePicker/TimePicker";
import Switch from "../components/Switch/Switch";
import Text from "../components/Text/Text";
import useMie005DbActions from "../hooks/pages/MIE005/useMie005DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useHospitalInput, {
  checkHospitalInputError,
  setAddress,
  setEmail,
  setHasMri,
  setHasPet,
  setHospitalName,
  setTel,
} from "../hooks/input/useHospitalInput";
import ToggleButton from "../components/ToggleButton/ToggleButton";

type MIE005Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  // eslint-disable-next-line react/require-default-props
  hospitalData?: hospitalNli016StateType | null;
};
type HospitalDayType = (typeof DAYS)[number];

const ITEMS_DATA: HospitalTimeItems[] = [
  {
    id: "0",
    time: "AM",
  },
  {
    id: "1",
    time: "PM",
  },
];
const DAYS = ["mon", "tue", "wed", "thu", "fri", "sta", "sun"];

const TOGGLE_ITEMS = [
  {
    id: "1",
    label: "MRI",
  },
  {
    id: "2",
    label: "FDG PET",
  },
];

// 新規スケジュール初期値
const initSchedule: HospitalScheduleType = {
  mon: { AM: 0, PM: 0 },
  tue: { AM: 0, PM: 0 },
  wed: { AM: 0, PM: 0 },
  thu: { AM: 0, PM: 0 },
  fri: { AM: 0, PM: 0 },
  sta: { AM: 0, PM: 0 },
  sun: { AM: 0, PM: 0 },
};

// 更新の場合のスケジュール変換
const convertToSchedule = (hospitalWeek: string) => {
  const hospitalWeekObj: HospitalWeek = JSON.parse(
    hospitalWeek,
  ) as HospitalWeek;
  const result = Object.fromEntries(
    Object.entries(hospitalWeekObj).map(([key, value]) => [
      key,
      { AM: value.AM, PM: value.PM },
    ]),
  );

  return result;
};

// 初期時間
const startOfDay = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  return date;
};

// 更新の場合の時間設定
const setDateWithHourMinute = (timeString: string) => {
  const [hour, minute] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hour, minute, 0, 0);

  return date;
};

const MIE005: React.FC<MIE005Props> = ({
  isOpen,
  setIsOpen,
  title,
  hospitalData = null,
}) => {
  const [items] = React.useState(ITEMS_DATA);
  const [days] = React.useState(DAYS);
  const [schedule, setSchedule] =
    React.useState<HospitalScheduleType>(initSchedule);
  const [startAmTerm, setStartAmTerm] = React.useState(startOfDay());
  const [endAmTerm, setEndAmTerm] = React.useState(startOfDay());
  const [startPmTerm, setStartPmTerm] = React.useState(startOfDay());
  const [endPmTerm, setEndPmTerm] = React.useState(startOfDay());
  const [{ hospitalName, address, tel, email, hasMri, hasPet }] =
    useHospitalInput();
  const { actionError, handleRegisterHospital, handleUpdateHospital } =
    useMie005DbActions();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  // 編集時の値反映
  React.useEffect(() => {
    if (hospitalData) {
      setHospitalName(hospitalData.hospital_name);
      setTel(hospitalData.tel);
      setAddress(hospitalData.address);
      setEmail(hospitalData.email);
      setSchedule(convertToSchedule(hospitalData.hospital_week));
      const parsedHours: HospitalHour = JSON.parse(
        hospitalData.hospital_hour,
      ) as HospitalHour;
      setStartAmTerm(setDateWithHourMinute(parsedHours.am.start));
      setEndAmTerm(setDateWithHourMinute(parsedHours.am.end));
      setStartPmTerm(setDateWithHourMinute(parsedHours.pm.start));
      setEndPmTerm(setDateWithHourMinute(parsedHours.pm.end));
      setHasMri(hospitalData.hasMri);
      setHasPet(hospitalData.hasPet);
    }
  }, [hospitalData]);

  // スイッチ切替
  const toggleSwitch = (day: HospitalDayType, time: HospitalTimeType) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [time]: prev[day][time] === 0 ? 1 : 0,
      },
    }));
  };

  // 閉じるアイコン、キャンセル
  const handleCloseButton = () => {
    if (!hospitalData) {
      setSchedule(initSchedule);
      setStartAmTerm(startOfDay());
    }
    setIsOpen(false);
  };

  // 保存ボタン
  const handleRegisterHospitalButton = () => {
    if (hospitalData) {
      // 更新
      handleUpdateHospital(
        hospitalData.hospital_id,
        hospitalName.value,
        startAmTerm,
        endAmTerm,
        startPmTerm,
        endPmTerm,
        schedule,
        tel.value,
        address.value,
        email.value,
        hasMri,
        hasPet,
      );
    } else {
      // 登録
      handleRegisterHospital(
        hospitalName.value,
        startAmTerm,
        endAmTerm,
        startPmTerm,
        endPmTerm,
        schedule,
        tel.value,
        address.value,
        email.value,
        hasMri,
        hasPet,
      );
      setSchedule(initSchedule);
      setStartAmTerm(startOfDay());
      setHospitalName("");
      setTel("");
      setAddress("");
      setEmail("");
    }
    setIsOpen(false);
  };

  return (
    <div className="admin-area">
      <div className="admin-inner">
        <DialogCustom
          title={title}
          open={isOpen}
          size="large"
          height="622px"
          closeDialog={handleCloseButton}
          footerRight={
            <LayoutBox>
              <Button
                type="sub"
                color="neutral"
                size="large"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
              <Button
                size="large"
                onClick={handleRegisterHospitalButton}
                disabled={checkHospitalInputError()}
              >
                保存
              </Button>
            </LayoutBox>
          }
        >
          <LayoutBox fullWidth direction="column">
            <FormSet
              label="病院名"
              base
              errorTextList={[hospitalName.validationMessage]}
            >
              <Input
                width="100%"
                placeholder="病院名"
                size="default"
                value={hospitalName.value}
                onChange={(value) => setHospitalName(value)}
                error={hospitalName.isError}
              />
            </FormSet>
            <LayoutBox fullWidth>
              <FormSet
                label="電話番号"
                base
                errorTextList={[tel.validationMessage]}
              >
                <Input
                  width="173px"
                  value={tel.value}
                  placeholder="電話番号"
                  size="default"
                  onChange={(value) => setTel(value)}
                  error={tel.isError}
                />
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet
                label="メールアドレス"
                base
                errorTextList={[email.validationMessage]}
              >
                <Input
                  width="100%"
                  value={email.value}
                  placeholder="メールアドレス"
                  size="default"
                  onChange={(value) => setEmail(value)}
                  error={email.isError}
                />
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet
                label="住所"
                base
                errorTextList={[address.validationMessage]}
              >
                <Input
                  width="100%"
                  value={address.value}
                  placeholder="住所"
                  size="default"
                  onChange={(value) => setAddress(value)}
                  error={address.isError}
                />
              </FormSet>
            </LayoutBox>
            <FormSet label="診療時間" base>
              <LayoutBox direction="column" gap="3x">
                <ToggleButton
                  size="medium"
                  items={TOGGLE_ITEMS}
                  selectedButton="1"
                />
                <LayoutBox fullWidth direction="column">
                  <LayoutBox fullWidth direction="row">
                    <FormSet
                      label="午前"
                      vertical
                      required="icon"
                      formVertical={false}
                    >
                      <TimePicker
                        size="default"
                        defaultHours={9}
                        defaultMinutes={0}
                        status="default"
                        value={startAmTerm}
                        onChange={(date) => setStartAmTerm(date)}
                        showButton={false}
                        dropdownWidth="76px"
                      />
                      <div className="util-ml-4 util-mr-4 ">
                        <Text width="14px">〜</Text>
                      </div>
                      <TimePicker
                        size="default"
                        defaultHours={13}
                        defaultMinutes={0}
                        status="default"
                        value={endAmTerm}
                        onChange={(date) => setEndAmTerm(date)}
                        showButton={false}
                        dropdownWidth="76px"
                      />
                      <div className="util-ml-12">
                        <Text width="14px">/</Text>
                      </div>
                    </FormSet>
                    <FormSet
                      label="午後"
                      vertical
                      required="icon"
                      formVertical={false}
                    >
                      <TimePicker
                        size="default"
                        defaultHours={9}
                        defaultMinutes={0}
                        status="default"
                        value={startPmTerm}
                        onChange={(date) => setStartPmTerm(date)}
                        showButton={false}
                        dropdownWidth="76px"
                      />
                      <div className="util-ml-4 util-mr-4 ">
                        <Text width="14px">〜</Text>
                      </div>
                      <TimePicker
                        size="default"
                        defaultHours={13}
                        defaultMinutes={0}
                        status="default"
                        value={endPmTerm}
                        onChange={(date) => setEndPmTerm(date)}
                        showButton={false}
                        dropdownWidth="76px"
                      />
                    </FormSet>
                  </LayoutBox>
                  <Table
                    type="condensed"
                    width="100%"
                    head={
                      <TableRow>
                        <TableColumn id="col-1" width="44px" />
                        <TableColumn
                          width="102px"
                          id="col-2"
                          textAlign="center"
                        >
                          月
                        </TableColumn>
                        <TableColumn
                          width="102px"
                          id="col-3"
                          textAlign="center"
                        >
                          火
                        </TableColumn>
                        <TableColumn
                          width="102px"
                          id="col-4"
                          textAlign="center"
                        >
                          水
                        </TableColumn>
                        <TableColumn
                          width="102px"
                          id="col-5"
                          textAlign="center"
                        >
                          木
                        </TableColumn>
                        <TableColumn
                          width="102px"
                          id="col-6"
                          textAlign="center"
                        >
                          金
                        </TableColumn>
                        <TableColumn
                          width="102px"
                          id="col-7"
                          textAlign="center"
                        >
                          土
                        </TableColumn>
                        <TableColumn
                          width="102px"
                          id="col-8"
                          textAlign="center"
                        >
                          日
                        </TableColumn>
                      </TableRow>
                    }
                    body={items.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell textAlign="center" background="#EFF4FE">
                          <Text bold>{item.time}</Text>
                        </TableCell>
                        {days.map((day) => (
                          <TableCell key={day} textAlign="center">
                            <Switch
                              checked={schedule[day][item.time] === 1}
                              onChange={() => toggleSwitch(day, item.time)}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  />
                </LayoutBox>
              </LayoutBox>
            </FormSet>
          </LayoutBox>
        </DialogCustom>
      </div>
    </div>
  );
};
export default MIE005;
