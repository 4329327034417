import React from "react";

export type RadioButtonProps = {
  checked?: boolean;
  disabled?: boolean;
  value?: string | number;
  name?: string;
  onSelect: (value: any) => void;
  children?: React.ReactNode;
  withBorder?: boolean;
  width?: string;
};

const RadioButton = ({
  disabled = false,
  checked = false,
  name,
  value = undefined,
  onSelect,
  children,
  withBorder = false,
  width,
}: RadioButtonProps) => {
  const radioButtonClass = React.useMemo(() => {
    const disabledClass = disabled ? ["radio--disabled"] : [];
    const disabledBgClass =
      disabled && withBorder ? ["radio--bg-disabled"] : [];
    const withBorderClass = withBorder ? ["radio--border"] : [];
    const checkedClass = checked && withBorder ? ["radio--border-checked"] : [];

    return [
      "radio",
      ...disabledClass,
      ...checkedClass,
      ...withBorderClass,
      ...disabledBgClass,
    ].join(" ");
  }, [withBorder, checked, disabled]);

  const onClick = React.useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  return (
    <label className={radioButtonClass} style={{ width }}>
      <div className="radio__input-container">
        <input
          // changeEmp
          // defaultChecked={checked}
          checked={checked}
          value={value}
          type="radio"
          className="radio__input"
          disabled={disabled}
          name={name}
          // changeEmp
          // onClick={onClick}
          onChange={onClick}
        />
      </div>

      {children && <span>{children}</span>}
    </label>
  );
};

RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  value: undefined,
  name: "radio",
  children: undefined,
  withBorder: false,
  width: "",
};

export default RadioButton;
