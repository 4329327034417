import { useState } from "react";
import {
  UserStatusID,
  Collection,
  UserTypeID,
  ROLE_KEY,
} from "../../../constants/common";

import { useRealmApp } from "../../../contexts/RealmApp";

import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openDeleteActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { setUserIdError } from "../../input/useUserDetailInput";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

type UserDataType = {
  _id: string;
  user_id: string;
  email: string;
  password: string;
  user_name: string;
  role: number;
  status: number;
};

const useNli004DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDb = getMongoDb(currentUser, Collection.USERS);

  // 新規の場合のuserIdがすでに存在するか確認
  const checkExistingUserId = async (userId: string): Promise<boolean> => {
    const existingDoc = (await mongoDb.findOne({
      user_id: userId,
      user_type: UserTypeID.ADMIN,
    })) as UserDataType | null;
    if (existingDoc) {
      setUserIdError();

      return true;
    }

    return false;
  };

  // 更新の場合のuserIdがすでに存在するか確認
  const checkUpdateExistingUserId = async (
    id: string,
    userId: string,
  ): Promise<boolean> => {
    const existingDoc = (await mongoDb.findOne({
      user_id: userId,
      user_type: UserTypeID.ADMIN,
      _id: { $ne: id },
    })) as UserDataType | null;
    if (existingDoc) {
      setUserIdError();

      return true;
    }

    return false;
  };

  // ユーザ登録
  const handleRegisterUser = (
    userId: string,
    userName: string,
    email: string,
    role: string,
    password: string,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_ADD, currentUser);

        // userIdがすでに存在するか確認
        if (await checkExistingUserId(userId)) return;

        // 登録データ
        const insertData = {
          _id: email,
          user_id: userId,
          full_name: userName,
          email,
          admin_role: Number(role),
          status: UserStatusID.EMAIL_VERIFYING,
          user_type: UserTypeID.ADMIN,
          created_at: new Date(),
        };
        await mongoDb.insertOne(insertData);

        // ファンクション
        await realmAppContext.app.emailPasswordAuth.registerUser({
          email,
          password,
        });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        await mongoDb.deleteOne({ _id: email });
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // ユーザ更新
  const handleUpdateUser = (
    _id: string,
    userId: string,
    userName: string,
    role: string,
    status: string,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, currentUser);

        // UserIdがすでに存在するか確認
        if (await checkUpdateExistingUserId(_id, userId)) return;

        // 更新データ
        const updateData = {
          full_name: userName,
          user_id: userId,
          admin_role: Number(role),
          status: Number(status),
        };

        await mongoDb.updateOne({ _id }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };
  // ユーザ削除
  const handleDeleteUser = (id: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, currentUser);

        // ファンクション
        await realmAppContext.app.currentUser?.functions.deleteUser(id);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterUser,
    handleUpdateUser,
    handleDeleteUser,
  };
};

export default useNli004DbActions;
