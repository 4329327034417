import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  HospitalReservationStatusID,
  ModalityID,
  MriStatusID,
  PetStatusID,
} from "../../../constants/common";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { parseDateString } from "../../../utils/utils";

const useMib002DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 全候補日NG
  const handleUpdateNg = (
    id: string,
    note: string,
    patientId: string,
    modalityNum: number,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // NGの場合、PET、MRIステータス変更無し
        // 更新データ
        const updateData = {
          _id: id,
          note,
          status: HospitalReservationStatusID.NG,
          patient_id: patientId,
          modality: modalityNum,
        };
        // MRI、PET予約API
        await currentUser?.functions.upsertModalityBookApi(updateData);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約確定
  const handleUpdateConfirm = (
    id: string,
    note: string,
    fixBookDate: string,
    patientId: string,
    modalityNum: number,
    otherDate?: Date,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // PET or MRI ステータス
        const petOrMriStatus =
          modalityNum === ModalityID.MRI
            ? { mri_status: MriStatusID.RESERVATION_CONFIRMED }
            : { pet_status: PetStatusID.RESERVATION_CONFIRMED };
        let date;
        if (fixBookDate === "other") {
          date = otherDate;
        } else {
          date = parseDateString(fixBookDate);
        }
        // 更新データ
        const updateData = {
          _id: id,
          note,
          status: HospitalReservationStatusID.CONFIRM,
          fix_book_date: date,
          patient_id: patientId,
          modality: modalityNum,
          ...petOrMriStatus,
        };
        // MRI、PET予約API
        await currentUser?.functions.upsertModalityBookApi(updateData);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約取り下げ
  const handleUpdateCancel = (
    id: string,
    note: string,
    patientId: string,
    modalityNum: number,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // PET or MRI ステータス
        const petOrMriStatus =
          modalityNum === ModalityID.MRI
            ? { mri_status: MriStatusID.WITHDRAWN_AFTER_CONFIRMATION }
            : { pet_status: PetStatusID.WITHDRAWN_AFTER_CONFIRMATION };

        // 更新データ
        const updateData = {
          _id: id,
          note,
          status: HospitalReservationStatusID.WITHDRAWAL,
          patient_id: patientId,
          modality: modalityNum,
          ...petOrMriStatus,
        };
        // MRI、PET予約API
        await currentUser?.functions.upsertModalityBookApi(updateData);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateNg,
    handleUpdateConfirm,
    handleUpdateCancel,
  };
};

export default useMib002DbActions;
