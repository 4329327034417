import { MriProblemID } from "../constants/common";
import Icon from "./Icon/Icon";
import Text from "./Text/Text";
import Image from "./Image/Image";

type StatusIconTextProps = {
  icon: "check" | "error";
  iconColor: string;
  color: "success" | "error";
  text: string;
};

const ProblemIcon: React.FC<StatusIconTextProps> = ({
  icon,
  iconColor,
  color,
  text,
}) => (
  <>
    <Icon
      size="large"
      icon={icon}
      color={iconColor}
      className="util-flex--align-center"
    />
    <Text size="small" color={color} width="60px" textAlign="center" bold>
      {text}
    </Text>
  </>
);

const TestResultIcon = ({ status }: { status: MriProblemID }) => {
  switch (status) {
    case MriProblemID.NO_PROBLEM:
      return (
        <ProblemIcon
          icon="check"
          iconColor="#81C784"
          color="success"
          text="問題なし"
        />
      );
    case MriProblemID.PROBLEM:
      return (
        <ProblemIcon
          icon="error"
          iconColor="#E78D87"
          color="error"
          text="問題あり"
        />
      );
    default:
      return <Image width="64px" height="64px" />;
  }
};

export default TestResultIcon;
