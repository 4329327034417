import React from "react";

export type ToggleButtonItemProps = {
  id: string;
  disabled?: boolean;
  type: "regular" | "negative";
  size: "small" | "medium" | "large";
  isSelected: boolean;
  onClick: (id: string) => void;
  children: React.ReactNode;
};

const ToggleButtonItem = ({
  id,
  disabled = false,
  type,
  size,
  isSelected,
  onClick,
  children,
}: ToggleButtonItemProps) => {
  const toggleButtonClass = React.useMemo(() => {
    const typeClass = [`toggle-button--${type}`];
    const sizeClass = [`toggle-button--${size}`];
    const selectedClass = isSelected ? ["toggle-button--selected"] : [];

    const borderClass = isSelected ? [] : ["toggle-button--border"];

    return [
      "toggle-button",
      ...typeClass,
      ...sizeClass,
      ...selectedClass,
      ...borderClass,
    ].join(" ");
  }, [isSelected, size, type]);

  const onClickToggleButtonItem = React.useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <button
      type="button"
      className={toggleButtonClass}
      disabled={disabled}
      onClick={onClickToggleButtonItem}
    >
      <span className="toggle-button__content">{children}</span>
    </button>
  );
};

ToggleButtonItem.defaultProps = {
  disabled: false,
};

export default ToggleButtonItem;
