import { useNavigate } from "react-router-dom";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Header from "../components/Header/Header";
import Link from "../components/Link/Link";
import Button from "../components/Button/Button";
import { ADMIN, HEADER_LINK } from "../constants/pagePaths";

/* メール送信完了画面 */
const NLJ005 = () => {
  const navigate = useNavigate();

  // ログイン画面に遷移
  const handleNavigateLogin = () => {
    navigate(ADMIN.NLA001);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <Header
            leftControl={
              <Link
                url={HEADER_LINK}
                external={false}
                linkClassName="header__left-logo"
              >
                認知症 予兆検知サービス
              </Link>
            }
          />
        </div>
        <div className="util-mb-48" />
        <LayoutBox fullHeight fullWidth justify="center">
          <Sheet
            type="border"
            maxWidth="640px"
            minHeight="247px"
            className="util-flex--align-center util-flex--justify-center"
          >
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              gap="3x"
              fullWidth
            >
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="1x"
                fullWidth
              >
                <Text size="xl" bold>
                  メール送信が完了しました。
                </Text>
                <Text size="base">メールを確認してください。</Text>
                <Button
                  size="large"
                  iconPosition="left"
                  width="230px"
                  onClick={handleNavigateLogin}
                >
                  ログイン
                </Button>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
    </>
  );
};

export default NLJ005;
