import Input from "../Input/Input";
import TableCell from "./TableCell";
import TableRow from "./TableRow";

export type TimeTableItem = {
  id: string;
  hours: number;
  minutes: number;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
};

export type TimeTableRowProps = {
  id: string;
  item: TimeTableItem;
  rowspan: number;
  showHourCell: boolean;
  formattedMinutes: string;
  items: TimeTableItem[];
  setItemsData: (items: TimeTableItem[]) => void;
};

const TimeTableRow = ({
  id,
  item,
  rowspan,
  showHourCell,
  formattedMinutes,
  items,
  setItemsData,
}: TimeTableRowProps) => {
  const time = `${item.hours}:${formattedMinutes}`;

  // 枠の値変更
  const handleChange = (value: string, key: string) => {
    const changeTimeTableItems = items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: value,
        };
      }

      return item;
    });
    setItemsData(changeTimeTableItems);
  };

  return (
    <TableRow>
      {showHourCell && (
        <TableCell bold bottomBorder rowSpan={rowspan} background="#eff4fe">
          {item.hours.toString().padStart(2, "0")}:{formattedMinutes}〜
        </TableCell>
      )}
      <TableCell bottomBorder background="#eff4fe">
        {time}
      </TableCell>

      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.monday}
          size="small"
          onChange={(value) => handleChange(value, "monday")}
        />
      </TableCell>
      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.tuesday}
          size="small"
          onChange={(value) => handleChange(value, "tuesday")}
        />
      </TableCell>
      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.wednesday}
          size="small"
          onChange={(value) => handleChange(value, "wednesday")}
        />
      </TableCell>
      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.thursday}
          size="small"
          onChange={(value) => handleChange(value, "thursday")}
        />
      </TableCell>
      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.friday}
          size="small"
          onChange={(value) => handleChange(value, "friday")}
        />
      </TableCell>
      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.saturday}
          size="small"
          onChange={(value) => handleChange(value, "saturday")}
        />
      </TableCell>
      <TableCell textAlign="center" padding="0 8px">
        <Input
          width="100%"
          value={item.sunday}
          size="small"
          onChange={(value) => handleChange(value, "sunday")}
        />
      </TableCell>
    </TableRow>
  );
};

export default TimeTableRow;
