// 共通処理

import { useMemo } from "react";
import {
  MASTER_MANAGEMENT_OPTIONS,
  ADMIN_MAIN_TAB_ITEMS,
  ADMIN_USER_MGMT_SUB_TAB_ITEMS,
  ADMIN_PAYMENT_SUB_TAB_ITEMS,
  MASTER_ITEMS_SUB_TAB_ITEMS,
  ADMIN_ORDERS_TOP_TAB_ITEMS,
  JST_OFFSET_HOURS,
  HOSPITAL_MAIN_TAB_ITEMS,
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  ADMIN_USER_DETAIL_TAB_ITEMS,
  TEST_RESULT_ITEMS,
  MriStatusID,
  MriStatusLabels,
  PetStatusID,
  PetStatusLabels,
  PatientNouKnowStatusLabels,
  PatientNouKnowStatusID,
  InterviewStatusLabels,
  PatientInterviewStatusID,
  ModalityStatusID,
  ModalityStatusLabels,
  AdminsRoleID,
  UserTypeID,
} from "../constants/common";
import {
  navigateRefresh,
  useNavigateWithCurrentState,
} from "../hooks/base/usePageTransitionCustom";
import {
  redirectToInvalidAccessPage,
  redirectToInvalidCsvFormatPage,
  redirectToInvalidFunctionPage,
} from "../contexts/CustomErrorBoundary";

type Checked = {
  checked: boolean;
};

type CsvDataType<T> = {
  headerValue: string; // ヘッダーに表示したい文字列
  valueKey: keyof T; // 表示するデータオブジェクトのkey
};

type Transformer<SourceType, TargetType> = (source: SourceType) => TargetType;

type SelectYearType = {
  value: string;
  label: string;
};
const CSV_DATA_SETTING = {
  NLC001: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
    { headerValue: "申込日", valueKey: "applicationDate" },
  ],
  NLC002: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
    { headerValue: "申込日", valueKey: "applicationDate" },
  ],
  NLC004: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    {
      headerValue: "最終検査日",
      valueKey: "lastInspectionDate",
      isZeroBase: false,
    },
    { headerValue: "総合点", valueKey: "score" },
    { headerValue: "レポート", valueKey: "reportStatus" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLC005: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "結果", valueKey: "result" },
    {
      headerValue: "MRI予約日",
      valueKey: "appointmentDateMRI",
      isZeroBase: false,
    },
    { headerValue: "病院", valueKey: "hospital" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLC007: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "面談予約日", valueKey: "dateAndTime" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLD001: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "検査", valueKey: "test" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "支払い期日", valueKey: "dueDate" },
    { headerValue: "支払い日", valueKey: "paymentDate" },
    { headerValue: "PET検査日", valueKey: "petScanDate" },
    { headerValue: "電話番号", valueKey: "phoneNum" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLD002: [
    { headerValue: "病院", valueKey: "hospitalName" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "statusStr" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "支払い期日", valueKey: "dueDateDisplay" },
    { headerValue: "支払い日", valueKey: "paymentDate" },
    { headerValue: "病院電話番号", valueKey: "tel" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLD003: [
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "支払い日", valueKey: "paymentDateDisplay" },
    { headerValue: "MRI検査日", valueKey: "executionDateDisplay" },
  ],
  NLD004: [
    { headerValue: "ID", valueKey: "patientId" },
    { headerValue: "お客様名", valueKey: "name" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "金額", valueKey: "amount" },
    { headerValue: "面談予約日", valueKey: "interviewFixDate" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "メールアドレス", valueKey: "email" },
  ],
  NLF001: [
    { headerValue: "コード", valueKey: "id" },
    { headerValue: "お客様名", valueKey: "patientName" },
    { headerValue: "ステータス", valueKey: "status" },
    { headerValue: "合計", valueKey: "totalAmount" },
    { headerValue: "更新", valueKey: "renewal" },
    { headerValue: "注文日時", valueKey: "settlementTime" },
    { headerValue: "配送", valueKey: "deliveryMethod" },
  ],
  NLF005: [
    { headerValue: "SKU名", valueKey: "sku_name" },
    { headerValue: "商品名", valueKey: "item_name" },
    { headerValue: "数量", valueKey: "quantity" },
    { headerValue: "金額", valueKey: "sku_amount" },
    { headerValue: "合計", valueKey: "total_amount" },
  ],
  NLF006: [
    { headerValue: "売り上月", valueKey: "paymentDate" },
    { headerValue: "件数", valueKey: "count" },
    { headerValue: "売り上げ", valueKey: "totalAmount" },
  ],
  NLF007: [
    { headerValue: "コード", valueKey: "orderId" },
    { headerValue: "お客様名", valueKey: "patientName" },
    { headerValue: "合計", valueKey: "amount" },
    { headerValue: "注文日時", valueKey: "settlementTime" },
  ],
  NLI003: [
    { headerValue: "ID", valueKey: "userId" },
    { headerValue: "ユーザー名", valueKey: "fullName" },
    { headerValue: "権限", valueKey: "roleStr" },
    { headerValue: "メールアドレス", valueKey: "email" },
    { headerValue: "ステータス", valueKey: "statusCsv" },
  ],
  NLI016: [
    { headerValue: "ID", valueKey: "id" },
    { headerValue: "病院", valueKey: "hospitalName" },
    { headerValue: "電話番号", valueKey: "tel" },
    { headerValue: "住所", valueKey: "address" },
    { headerValue: "月", valueKey: "monday" },
    { headerValue: "火", valueKey: "tuesday" },
    { headerValue: "水", valueKey: "wednesday" },
    { headerValue: "木", valueKey: "thursday" },
    { headerValue: "金", valueKey: "friday" },
    { headerValue: "土", valueKey: "saturday" },
    { headerValue: "日", valueKey: "sunday" },
  ],
};

// 表示値からCSVのデータに変換
const _convertDisplayToCsv = <T extends Checked>(
  userInfoArr: T[],
  csvDataArr: CsvDataType<T>[],
): string[][] => {
  // ヘッダー作成
  const headerArr = csvDataArr.map(({ headerValue }) => headerValue);

  // 表示値作成
  const contentArr = userInfoArr
    .map((userInfo) => {
      const { checked } = userInfo;
      // チェックの付いたもののみCSVに変換
      if (checked) {
        return csvDataArr.map(({ valueKey }) => {
          // 空文字の場合‐を表示
          const contentValue = userInfo[valueKey] || "-";

          if (typeof contentValue === "string") {
            // 先頭0始まりの値は式化して0の削除を回避
            if (contentValue.charAt(0) === "0") return `="${contentValue}"`;
            // ,が含まれる場合は文字列化
            if (contentValue.includes(",")) return `"${contentValue}"`;

            return contentValue;
          }

          return redirectToInvalidFunctionPage();
        });
      }

      return [];
    })
    .filter((data) => data.length);

  return [headerArr, ...contentArr];
};

// csvダウンロード処理
const _downloadCsv = (data: string[][], fileName: string) => {
  const csvContent = `\uFEFF${data.map((row) => row.join(",")).join("\n")}`;

  // Blobオブジェクトを作成
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // ダウンロード可能なURLを作成
  const url = URL.createObjectURL(blob);

  // ダウンロードリンクを作成
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${fileName}.csv`;

  // ダウンロードリンクをクリックしてダウンロードを開始
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// 配列オブジェクトを引数の型に沿ってフラットにして返す
const _transformArray = <SourceType, TargetType>(
  arr: SourceType[],
  transformer: Transformer<SourceType, TargetType>,
): TargetType[] => arr.map(transformer);

// csvアップロード用エクスプローラ表示 引数のloadFuncにファイル読み取り時のイベントを返す
export const fileUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  loadFunc: (event: ProgressEvent<FileReader>) => void,
) => {
  const { files } = e.target;
  if (files && files[0]) {
    const reader = new FileReader();

    reader.onload = (event) => {
      loadFunc(event);
    };

    reader.readAsText(files[0]);
  }
};

// ファイルアップロード時のファイルデータをCSVファイル形式に変換
export const convertFileToCsv = (event: ProgressEvent<FileReader>) => {
  const csvStr = event.target?.result;
  if (csvStr && typeof csvStr === "string") {
    return csvStr.split("\n").map((csvDataRowStr) => csvDataRowStr.split(","));
  }
  // csvファイルが不正の場合
  redirectToInvalidCsvFormatPage();

  return "";
};

export const downloadCsvNlc001 = (
  userInfoArr: Nlc001TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc001TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC001 as CsvDataType<Nlc001TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

// ユーザ管理、本人確認(NLC002)画面のcsvダウンロード処理 NLC003でも使用可
export const downloadCsvNlc002 = (
  userInfoArr: UserNlc002TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<UserNlc002TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC002 as CsvDataType<UserNlc002TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc004 = (
  userInfoArr: Nlc004TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc004TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC004 as CsvDataType<Nlc004TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc005 = (
  userInfoArr: Nlc005TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc005TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC005 as CsvDataType<Nlc005TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlc007 = (
  userInfoArr: Nlc007TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlc007TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLC007 as CsvDataType<Nlc007TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld001 = (
  userInfoArr: Nld001TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld001TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD001 as CsvDataType<Nld001TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld002 = (
  userInfoArr: Nld002TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld002TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD002 as CsvDataType<Nld002TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld003 = (
  userInfoArr: Nld003TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld003TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD003 as CsvDataType<Nld003TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNld004 = (
  userInfoArr: Nld004TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nld004TableType>(
    userInfoArr,
    CSV_DATA_SETTING.NLD004 as CsvDataType<Nld004TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf001 = (
  orderArr: Nlf001TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlf001TableType>(
    orderArr,
    CSV_DATA_SETTING.NLF001 as CsvDataType<Nlf001TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf005 = (
  transactions: TransactionInfoDisplay[],
  fileName: string,
) => {
  // 配列内のオブジェクトをフラットにする関数
  const toFlatFormat: Transformer<
    TransactionInfoDisplay,
    TransactionInfoCsv
  > = (item) => ({
    _id: item._id,
    sku_name: item.sku_name,
    sku_amount: item.sku_amount,
    item_name: item.item_name,
    quantity: item.quantity,
    total_amount: item.total_amount,
    checked: item.checked,
  });
  // toFlatFormatに沿って変換
  const flatTransactions = _transformArray(transactions, toFlatFormat);

  const csvDataArr = _convertDisplayToCsv<TransactionInfoCsv>(
    flatTransactions,
    CSV_DATA_SETTING.NLF005 as CsvDataType<TransactionInfoCsv>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf006 = (
  salesArr: Nlf006TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlf006TableType>(
    salesArr,
    CSV_DATA_SETTING.NLF006 as CsvDataType<Nlf006TableType>[],
  );
  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNlf007 = (
  salesArr: Nlf007TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nlf007TableType>(
    salesArr,
    CSV_DATA_SETTING.NLF007 as CsvDataType<Nlf007TableType>[],
  );
  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNli003 = (
  userArr: Nli003TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nli003TableType>(
    userArr,
    CSV_DATA_SETTING.NLI003 as CsvDataType<Nli003TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

export const downloadCsvNli016 = (
  hospitalArr: Nli016TableType[],
  fileName: string,
) => {
  const csvDataArr = _convertDisplayToCsv<Nli016TableType>(
    hospitalArr,
    CSV_DATA_SETTING.NLI016 as CsvDataType<Nli016TableType>[],
  );

  _downloadCsv(csvDataArr, fileName);
};

// マスタ管理の選択肢クリック時のイベント
export const handleSelectMasterInfo = (selectValue: string) => {
  const selectInfo = MASTER_MANAGEMENT_OPTIONS.find(
    ({ value }) => selectValue === value,
  );
  navigateRefresh(selectInfo?.url);
};

// 病院マスタ管理の選択肢クリック時のイベント
export const handleSelectHospitalMasterInfo = (selectValue: string) => {
  const selectInfo = HOSPITAL_MASTER_MANAGEMENT_OPTIONS.find(
    ({ value }) => selectValue === value,
  );
  navigateRefresh(selectInfo?.url);
};

// メインタブクリック時のイベント
export const handleSelectMainTab = (index: number) => {
  const selectInfo = ADMIN_MAIN_TAB_ITEMS[index];
  navigateRefresh(selectInfo?.url);
};

// Adminユーザ詳細のタブクリック時のイベント
export const useHandleSelectAdminUserDetailTab = () => {
  const navigate = useNavigateWithCurrentState();
  const navigateTo = (index: number) => {
    const selectInfo = ADMIN_USER_DETAIL_TAB_ITEMS[index];
    const path = selectInfo?.url;
    if (path) navigate(path);
  };

  return navigateTo;
};

// 病院側メインタブクリック時のイベント
export const handleSelectHospitalMainTab = (index: number) => {
  const selectInfo = HOSPITAL_MAIN_TAB_ITEMS[index];
  navigateRefresh(selectInfo?.url);
};

// 病院マスタタブクリック時のイベント
export const handleSelectHospitalMasterTab = (index: number) => {
  const selectInfo = HOSPITAL_MASTER_MANAGEMENT_OPTIONS[index];
  navigateRefresh(selectInfo?.url);
};

// タブクリック時の遷移イベント
export const handleSelectTab = (
  selectId: string,
  subTabItems: { id: string; url: string }[],
) => {
  const selectInfo = subTabItems.find(({ id }) => id === selectId);
  navigateRefresh(selectInfo?.url);
};
// ユーザ詳細のタブクリック時の遷移イベント
export const useHandleSelectUserInfoTab = () => {
  const navigate = useNavigateWithCurrentState();
  const navigateTo = (
    selectId: string,
    subTabItems: { id: string; url: string }[],
  ) => {
    const selectInfo = subTabItems.find(({ id }) => id === selectId);
    const path = selectInfo?.url;
    if (path) navigate(path);
  };

  return navigateTo;
};

// ユーザ管理のサブタブクリック時のイベント
export const handleSelectUserMgmtSubTab = (selectId: string) =>
  handleSelectTab(selectId, ADMIN_USER_MGMT_SUB_TAB_ITEMS);
// 支払・請求のサブタブクリック時のイベント
export const handleSelectPaymentSubTab = (selectId: string) =>
  handleSelectTab(selectId, ADMIN_PAYMENT_SUB_TAB_ITEMS);
// マスタ管理のサブタブクリック時のイベント
export const handleSelectMasterMgmtSubTab = (selectId: string) =>
  handleSelectTab(selectId, MASTER_MANAGEMENT_OPTIONS);
// マスタ管理の商品管理のサブタブクリック時のイベント
export const handleSelectMasterMgmtItemsSubTab = (index: number) => {
  const selectInfo = MASTER_ITEMS_SUB_TAB_ITEMS[index];
  navigateRefresh(selectInfo?.url);
};
// EC管理のサブタブクリック時のイベント
export const handleSelectECMgmtSubTab = (selectId: string) =>
  handleSelectTab(selectId, ADMIN_ORDERS_TOP_TAB_ITEMS);

// ユーザ詳細時テスト結果のサブタブクリック時のイベント
export const useHandleTestResultSubTab = () => {
  const navigate = useHandleSelectUserInfoTab();
  const navigateTo = (selectId: string) => {
    navigate(selectId, TEST_RESULT_ITEMS);
  };

  return navigateTo;
};

// 名前検索用に変換 スペース、/を削除
// eslint-disable-next-line no-irregular-whitespace
export const convertSearchName = (name: string) => name.replace(/[ 　/]/g, "");

// 検索欄のチェック状態から選択中のステータスIDを取得
export const useMemoStatusArr = (
  searchInfo: { id: number; flgKey: string }[],
  status: { [x: string]: boolean },
) =>
  useMemo(
    () =>
      searchInfo.flatMap(({ id, flgKey }) =>
        status[flgKey as keyof typeof status] ? [id] : [],
      ),
    [status, searchInfo],
  );

// クリップボードにテキストをコピー
export const copyClipboard = (text: string) => {
  if (!navigator.clipboard) {
    // eslint-disable-next-line no-alert
    alert("このブラウザは対応していません");

    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      // eslint-disable-next-line no-alert
      alert("文章をコピーしました。");
    },
    () => {
      // eslint-disable-next-line no-alert
      alert("コピーに失敗しました。");
    },
  );
};

// URLのパラメータ取得
export const getUrlParams = (target: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(target);
  if (!param) redirectToInvalidAccessPage();

  return param;
};

// Date型からhh:mmの時間を返す
export const formatHourTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

// 全角を半角に変換
export const toHalfWidth = (str: string) =>
  str.replace(/[！-～]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

// 文字列を数値に変換 全角の数値も適用するため半角に変換後数値化
export const convertStrToNum = (str: string) =>
  Number(toHalfWidth(str).replace(/,/g, ""));

// 入力された文字列が数値化をチェック
export const checkStrArrIsNum = (strArr: string[]) =>
  strArr.every((str) => !Number.isNaN(convertStrToNum(str)));

// 年のセレクトボックスの値をデータから作成
export const extractUniqueYearsFromData = <T>({
  key,
  data,
}: {
  key: keyof T;
  data: T[];
}): SelectYearType[] => {
  const yearsSet = new Set(
    data
      .filter((item) => item[key])
      .map((item) => {
        const jstDate = new Date(item[key] as unknown as string);
        jstDate.setHours(jstDate.getHours() + JST_OFFSET_HOURS);

        return jstDate.getUTCFullYear().toString();
      }),
  );
  // ソート
  const sortedYears = [...yearsSet].sort((a, b) => parseInt(a) - parseInt(b));

  return sortedYears.map((year) => ({
    value: year,
    label: year,
  }));
};

// YYYYMMを+1、-1にしたYYYYMMを返す
export const adjustMonth = (
  yearMonth: string,
  operation: "before" | "after",
): string => {
  let year = parseInt(yearMonth.substring(0, 4), 10);
  let month = parseInt(yearMonth.substring(4, 6), 10);

  switch (operation) {
    case "before":
      month -= 1;
      if (month === 0) {
        year -= 1;
        month = 12;
      }
      break;

    case "after":
      month += 1;
      if (month === 13) {
        year += 1;
        month = 1;
      }
      break;

    default:
      return redirectToInvalidFunctionPage();
  }

  return `${year}${month.toString().padStart(2, "0")}`;
};

// 文字列YYYY/MM/DD HH:MMをDate型に変換
export const parseDateString = (dateString: string): Date | null => {
  const match = /(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2})/.exec(dateString);

  if (!match) {
    return null;
  }

  const [_, year, month, day, hour, minute] = match.map(Number);

  return new Date(year, month - 1, day, hour, minute);
};

// 画像URLをbase64に変換する処理
export const convertUrlToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
// 画像URL一覧をbase64に変換する処理
export const convertUrlsToBase64 = async (
  urls: string[],
): Promise<string[]> => {
  const base64Promises = urls.map((url) => convertUrlToBase64(url));

  return Promise.all(base64Promises);
};

// 日付と時間を結合する処理
export const combineDateAndTime = (date: Date, time: Date) => {
  const combined = new Date(date);
  combined.setHours(time.getHours());
  combined.setMinutes(time.getMinutes());
  combined.setSeconds(time.getSeconds());
  combined.setMilliseconds(time.getMilliseconds());

  return combined;
};

export const getMriStatusTagState = (status: string) => {
  switch (status) {
    case MriStatusLabels[MriStatusID.DONE_AI_DIAGNOSING]:
      return "default";
    case MriStatusLabels[MriStatusID.DONE_AI_DIAGNOSED]:
      return "error";
    case MriStatusLabels[MriStatusID.DONE_DOCTOR_DIAGNOSED]:
      return "warning";
    default:
      return "neutral";
  }
};

// 2次以降フェーズ対応
export const getPetStatusTagState = (status: string) => {
  switch (status) {
    case PetStatusLabels[PetStatusID.RESERVED]:
      return "error";
    case PetStatusLabels[PetStatusID.RESERVATION_CONFIRMED]:
      return "success";
    case PetStatusLabels[PetStatusID.EXAMINED]:
      return "warning";
    default:
      return "neutral";
  }
};

export const getNouKnowTagState = (status: string) => {
  switch (status) {
    case PatientNouKnowStatusLabels[PatientNouKnowStatusID.PRETEST]:
    case PatientNouKnowStatusLabels[PatientNouKnowStatusID.CONFIRMED]:
      return "default";
    case PatientNouKnowStatusLabels[PatientNouKnowStatusID.COMMENTED]:
      return "success";
    default:
      return "neutral";
  }
};

export const getInterviewTagState = (status: string) => {
  switch (status) {
    case InterviewStatusLabels[PatientInterviewStatusID.NEW_RESERVATION]:
    case ModalityStatusLabels[ModalityStatusID.NEW_RESERVATION]:
      return "error";
    default:
      return "neutral";
  }
};

export const getNouknowColor = (
  rank: string,
): {
  badge: "primary" | "red" | "warning";
  other: "primary" | "error" | "warning";
} => {
  if (rank === "A") {
    return { badge: "primary", other: "primary" };
  }
  if (rank === "B") {
    return { badge: "warning", other: "warning" };
  }
  if (rank === "C") {
    return { badge: "red", other: "error" };
  }

  return { badge: "primary", other: "primary" };
};

export const getAdminRole = (currentUser: Realm.User | null) => {
  const adminRoleObject = currentUser?.customData
    .admin_role as usersNumberIntType;
  const adminRole = adminRoleObject
    ? (Number(adminRoleObject.$numberInt) as AdminsRoleID)
    : null;

  return adminRole;
};

export const getUserType = (currentUser: Realm.User | null) => {
  const userTypeObject = currentUser?.customData
    .user_type as usersNumberIntType;
  const userType = userTypeObject
    ? (Number(userTypeObject.$numberInt) as UserTypeID)
    : null;

  return userType;
};
