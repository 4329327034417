import React from "react";
import { getHours, getMinutes } from "date-fns";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";

const HOUR_OPTIONS = new Array(48).fill(1).map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? "00" : "30";
  const label = `${hour.toString().padStart(2, "0")}:${minute}`;
  const value = `${hour}:${minute}`;

  return { label, value };
});

const HOUR_OPTIONS_NO_INTERVAL = new Array(24).fill(1).map((_, i) => {
  const label = i.toString().padStart(2, "0");
  const value = i.toString();

  return { label, value };
});

const MINUTE_OPTIONS = new Array(60).fill(1).map((_, i) => ({
  label: i.toString().length === 1 ? `0${i}` : `${i}`,
  value: `${i}`,
}));

export type TimePickerProps = {
  value: Date;
  size?: "default" | "small";
  status?: "default" | "error";
  defaultHour1?: string;
  defaultHour2?: string;
  type?: "hoursAndMinutes" | "hoursAndHours" | "hoursOnly";
  disabled?: boolean;
  defaultHours?: number;
  defaultMinutes?: number;
  showButton?: boolean;
  onChange: (value: Date) => void;
  dropdownWidth?: string;
};

const TimePicker = ({
  value = new Date(),
  onChange,
  defaultHours,
  defaultHour1,
  defaultHour2,
  defaultMinutes,
  size = "default",
  status = "default",
  disabled,
  showButton = true,
  dropdownWidth = "96px",
  type = "hoursAndMinutes",
}: TimePickerProps) => {
  const [date, setDate] = React.useState(value);

  React.useEffect(() => {
    if (value) setDate(value);
  }, [value]);

  React.useEffect(() => {
    const initialDateTime = new Date();
    if (!defaultHours || !defaultMinutes) return;

    if (defaultHours >= 0 && defaultMinutes >= 0) {
      initialDateTime.setHours(defaultHours, defaultMinutes, 0, 0);
    } else {
      initialDateTime.setHours(0, 0, 0, 0);
    }
    setDate(initialDateTime);
  }, [defaultHours, defaultMinutes]);

  const onChangeHours = React.useCallback(
    (hours: string) => {
      const changeDate = !Number.isNaN(date.getTime()) ? date : new Date();
      changeDate.setHours(parseInt(hours));
      onChange(changeDate);
    },
    [date, onChange],
  );

  const onChangeMinutes = React.useCallback(
    (minutes: string) => {
      const changeDate = !Number.isNaN(date.getTime()) ? date : new Date();
      changeDate.setMinutes(parseInt(minutes));
      onChange(changeDate);
    },
    [date, onChange],
  );
  React.useEffect(() => {
    const initialDateTime = new Date();

    if (defaultHour1 !== undefined && defaultHour2 !== undefined) {
      const hour1Number = parseInt(defaultHour1);
      const hour2Number = parseInt(defaultHour2);

      if (!Number.isNaN(hour1Number) && !Number.isNaN(hour2Number)) {
        initialDateTime.setHours(hour1Number, hour2Number, 0, 0);
      } else {
        initialDateTime.setHours(0, 0, 0, 0);
      }
    } else {
      initialDateTime.setHours(0, 0, 0, 0);
    }

    setDate(initialDateTime);
  }, [defaultHour1, defaultHour2]);

  const onChangeHour1 = React.useCallback(
    (hour1: string) => {
      const changeDate = new Date(date);
      const selectedMinute = hour1.includes("30") ? 30 : 0;
      changeDate.setHours(parseInt(hour1), selectedMinute);
      setDate(changeDate);
      onChange(changeDate);
    },
    [date, onChange],
  );

  const onChangeHour2 = React.useCallback(
    (hour2: string) => {
      const changeDate = new Date(date);
      const selectedMinute = hour2.includes("30") ? 30 : 0;
      changeDate.setHours(parseInt(hour2), selectedMinute);
      setDate(changeDate);
      onChange(changeDate);
    },
    [date, onChange],
  );

  const timePickerClass = React.useMemo(() => {
    const sizeClass = [`time-picker--${size}`];
    const stateClass = status !== "default" ? [`time-picker--${status}`] : [];
    const disabledClass = disabled ? ["time-picker--disabled"] : [];

    return ["time-picker", ...sizeClass, ...stateClass, ...disabledClass].join(
      " ",
    );
  }, [disabled, size, status]);

  const buttonSize = React.useMemo(() => {
    if (size === "default") {
      return "medium";
    }
    if (size === "small") {
      return "small";
    }

    return "medium";
  }, [size]);

  const onClick = React.useCallback(() => {
    onChange(new Date());
  }, [onChange]);

  return (
    <div className={timePickerClass}>
      {type === "hoursAndMinutes" ? (
        <>
          <Dropdown
            items={HOUR_OPTIONS_NO_INTERVAL}
            width={dropdownWidth}
            size={size}
            value={`${getHours(date)}`}
            onChange={onChangeHours}
            status={status}
            disabled={disabled}
          />
          :
          <Dropdown
            items={MINUTE_OPTIONS}
            width={dropdownWidth}
            size={size}
            value={`${getMinutes(date)}`}
            onChange={onChangeMinutes}
            status={status}
            disabled={disabled}
          />
        </>
      ) : type === "hoursAndHours" ? (
        <>
          <Dropdown
            items={HOUR_OPTIONS}
            width={dropdownWidth}
            size={size}
            value={defaultHour1 !== undefined ? defaultHour1.toString() : ""}
            onChange={onChangeHour1}
            status={status}
            disabled={disabled}
          />
          〜
          <Dropdown
            items={HOUR_OPTIONS}
            width={dropdownWidth}
            size={size}
            value={defaultHour2 !== undefined ? defaultHour2.toString() : ""}
            onChange={onChangeHour2}
            status={status}
            disabled={disabled}
          />
        </>
      ) : type === "hoursOnly" ? (
        <>
          <Dropdown
            items={HOUR_OPTIONS}
            width={dropdownWidth}
            size={size}
            value={defaultHour1 !== undefined ? defaultHour1.toString() : ""}
            onChange={onChangeHour1}
            status={status}
            disabled={disabled}
          />
          〜
        </>
      ) : (
        ""
      )}
      {showButton && (
        <Button
          className="time-picker__button"
          size={buttonSize}
          disabled={disabled}
          color="main"
          type="sub"
          onClick={onClick}
        >
          現在時刻
        </Button>
      )}
      {showButton && (
        <Button
          className="time-picker__button"
          size={buttonSize}
          disabled={disabled}
          color="main"
          type="sub"
          onClick={onClick}
        >
          現在時刻
        </Button>
      )}
    </div>
  );
};
TimePicker.defaultProps = {
  size: "default",
  status: "default",
  disabled: false,
  defaultHours: undefined,
  defaultMinutes: undefined,
  defaultHour1: undefined,
  defaultHour2: undefined,
  showButton: true,
  dropdownWidth: "96px",
  type: "hoursAndMinutes",
};

export default TimePicker;
