import React from "react";

import Header from "./Header";
import Link from "../Link/Link";
import Button from "../Button/Button";
import AccountMenu from "../Menu/AccountMenu";
import InfoMenu from "../Menu/InfoMenu";
import NLI005 from "../../pages/NLI005";
import MIE007 from "../../pages/MIE007";
import { useRealmApp } from "../../contexts/RealmApp";
import NLI027 from "../../pages/NLI027";
import { convertAdminRole } from "../../utils/convertDisplay";
import { HEADER_LINK } from "../../constants/pagePaths";

// const USER = {
//   id: "AAAAAA",
//   position: "一般ユーザ",
//   name: "山田太郎",
// };

// 2次以降フェーズ対応: phase2に対応予定
// const INFOS = [
//   {
//     date: "2022/07/22",
//     content: "アップデートを行いました",
//     type: "お知らせ",
//   },
//   {
//     date: "2022/07/22",
//     content: "アップデートを行いました",
//     type: "お知らせ",
//   },
//   {
//     date: "2022/07/22",
//     content: "メンテナンスを行います",
//     type: "重要",
//   },
// ];

const CommonHeader = () => {
  const appContext = useRealmApp();
  const [isUserDetailDialog, setIsUserDetailDialog] = React.useState(false);
  const [isPassDialog, setIsPassDialog] = React.useState(false);
  const [isEmailDialog, setIsEmailDialog] = React.useState(false);

  // 権限変換
  const adminRoleObject = appContext.currentUser?.customData
    .admin_role as usersNumberIntType;
  const adminRoleString = adminRoleObject ? adminRoleObject.$numberInt : null;
  const adminRole = adminRoleString ? Number(adminRoleString) : 0;

  // ユーザー表示
  const user = {
    id: appContext.currentUser?.customData.user_id as string,
    position: adminRole ? convertAdminRole(adminRole) : "",
    name: appContext.currentUser?.customData.full_name as string,
  };

  // プロフィール
  const handleShowProfile = () => {
    setIsUserDetailDialog(true);
  };

  // ログアウト
  const handleLogout = () => {
    void appContext.logOut();
  };

  // ベル
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShowInfo = (index: number) => {
    // 2次以降フェーズ対応: phase2に対応予定
  };

  // パスワード変更
  const handleChangePass = () => {
    setIsPassDialog(true);
  };

  // ヘルプ
  const handleShowHelp = () => {
    // 2次以降フェーズ対応: 外部リンクを想定
  };

  // メールアドレス変更
  const handleChangeEmail = () => {
    setIsEmailDialog(true);
  };

  return (
    <Header
      leftControl={
        <Link
          url={HEADER_LINK}
          external={false}
          linkClassName="header__left-logo"
        >
          認知症 予兆検知サービス
        </Link>
      }
      rightControl={
        <>
          <InfoMenu infos={[]} onClick={handleShowInfo} />
          <div className="header__right-icon">
            <Button
              type="secondary"
              color="background-dark"
              size="medium"
              icon="help_outline"
              onClick={handleShowHelp}
              borderLess
            />
          </div>
          <AccountMenu
            user={user}
            onClickProfile={handleShowProfile}
            onClickChangePass={handleChangePass}
            onClickLogout={handleLogout}
            onClickChangeEmail={handleChangeEmail}
          />
          {isUserDetailDialog && (
            <NLI005
              isOpen={isUserDetailDialog}
              setIsOpen={setIsUserDetailDialog}
            />
          )}
          {isPassDialog && (
            <MIE007 isOpen={isPassDialog} setIsOpen={setIsPassDialog} />
          )}
          {isEmailDialog && (
            <NLI027 isOpen={isEmailDialog} setIsOpen={setIsEmailDialog} />
          )}
        </>
      }
    />
  );
};

export default CommonHeader;
