import React, { useEffect } from "react";
import Dialog from "../components/Dialog/Dialog";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import useEmailInput, {
  checkEmailInputError,
  setEmail,
} from "../hooks/input/useEmailInput";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNli027DbActions from "../hooks/pages/NLI027/useNli027DbActions";
import { useRealmApp } from "../contexts/RealmApp";

type NLI027Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const NLI027: React.FC<NLI027Props> = ({ isOpen, setIsOpen }) => {
  const realmAppContext = useRealmApp();
  const [{ email }] = useEmailInput();
  const [id, setId] = React.useState("");

  const { actionError, handleUpdate } = useNli027DbActions();

  useCheckErrorThrowError([actionError]);

  useEffect(() => {
    setEmail(realmAppContext.currentUser?.customData.email as string);
    setId(realmAppContext.currentUser?.customData._id as string);
  }, [
    realmAppContext.currentUser?.customData.email,
    realmAppContext.currentUser?.customData._id,
  ]);

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // 登録ボタン押下
  const handleUpdateButton = () => {
    handleUpdate(id, email.value, () => setIsOpen(false));
  };

  return (
    <>
      <Dialog
        title="メールアドレス変更"
        size="medium"
        height="232px"
        open={isOpen}
        closeDialog={handleCloseButton}
        footerRight={
          <LayoutBox>
            <Button
              color="neutral"
              type="sub"
              width="108px"
              size="large"
              onClick={handleCloseButton}
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              width="64px"
              size="large"
              disabled={checkEmailInputError()}
              onClick={handleUpdateButton}
            >
              登録
            </Button>
          </LayoutBox>
        }
      >
        <LayoutBox fullWidth>
          <FormSet
            label="メールアドレス"
            vertical
            errorTextList={[email.validationMessage]}
          >
            <Input
              size="default"
              placeholder="メールアドレス"
              width="100%"
              value={email.value}
              onChange={(value) => setEmail(value)}
              error={email.isError}
            />
          </FormSet>
        </LayoutBox>
      </Dialog>
    </>
  );
};

export default NLI027;
