import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectMasterMgmtSubTab,
} from "../utils/utils";
import { MASTER_MANAGEMENT_OPTIONS, ROLE_KEY } from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNli001DbActions from "../hooks/pages/NLI001/useNli001DbActions";
import { convertNli001 } from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import Checkbox from "../components/Checkbox/Checkbox";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLI001 = () => {
  const [roles, setRoles] = useStateCustomObj<Nli001TableType[]>([]);
  const [counts, setCounts] = React.useState<{ totalCount: number }[]>([]);
  const [submittedRoles, setSubmittedRoles] = useStateCustomObj<
    MasterRolesStateType[]
  >([]);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  const {
    masterRoles,
    error: masterRolesError,
    handleUpdate,
  } = useNli001DbActions();

  const hasEditRole = useCheckHasRole(ROLE_KEY.MASTER_AUTHORITY_EDIT);

  useCheckErrorThrowError([mainTabError, masterRolesError]);

  useEffect(() => {
    const convertResults = convertNli001(masterRoles);
    // レイアウト調整のため、必要
    const totalCounts = convertResults.map((item) => {
      const categoryMediumCounts = item.category2.reduce(
        (acc, category) => acc + category.details.length,
        0,
      );

      return {
        totalCount: categoryMediumCounts,
      };
    });
    setCounts(totalCounts);
    setRoles(convertResults);
  }, [masterRoles, setRoles]);

  useEffect(() => {
    setSubmittedRoles(masterRoles);
  }, [masterRoles, setSubmittedRoles]);

  // 編集ボタン
  const handleBulkEditing = () => {
    setIsEdit(true);
  };

  // チェックボックス
  const handleChangeCheck = (
    checked: boolean,
    id: string,
    checkedItem: string,
  ) => {
    setSubmittedRoles((prevStates) =>
      prevStates.map((prevState) => {
        if (prevState._id === id) {
          return {
            ...prevState,
            [checkedItem]: checked,
          };
        }

        return prevState;
      }),
    );
  };

  // キャンセルボタン
  const handleCancel = () => {
    setSubmittedRoles(masterRoles);
    setIsEdit(false);
  };

  // 登録ボタン
  const handleUpdateButton = () => {
    handleUpdate(submittedRoles, () => setIsEdit(false));
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-py-12">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox justify="between" fullWidth>
                  <LayoutBox align="center" justify="start" fullWidth>
                    <div className="util-mr-16">
                      <ToggleButton
                        size="large"
                        items={MASTER_MANAGEMENT_OPTIONS}
                        selectedButton="1"
                        onClick={handleSelectMasterMgmtSubTab}
                      />
                    </div>
                  </LayoutBox>
                  {!isEdit && (
                    <Button
                      type="primary"
                      size="large"
                      icon="edit"
                      width="88px"
                      onClick={handleBulkEditing}
                      disabled={!hasEditRole}
                    >
                      編集
                    </Button>
                  )}
                  {isEdit && (
                    <>
                      <Button
                        type="sub"
                        size="large"
                        width="108px"
                        onClick={handleCancel}
                      >
                        キャンセル
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        width="64px"
                        onClick={handleUpdateButton}
                      >
                        保存
                      </Button>
                    </>
                  )}
                </LayoutBox>
                <Table
                  type="condensed"
                  width="100%"
                  roleKey={ROLE_KEY.MASTER_AUTHORITY_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="200px" id="col-1">
                        大カテゴリ
                      </TableColumn>
                      <TableColumn width="200px" id="col-2">
                        中カテゴリ
                      </TableColumn>
                      <TableColumn width="806px" id="col-3">
                        権限
                      </TableColumn>
                      <TableColumn textAlign="center" width="221px" id="col-4">
                        管理者
                      </TableColumn>
                      <TableColumn textAlign="center" width="221px" id="col-5">
                        ドクター
                      </TableColumn>
                      <TableColumn textAlign="center" width="221px" id="col-6">
                        一般ユーザー
                      </TableColumn>
                    </TableRow>
                  }
                  body={
                    <>
                      {roles.map((row, index) => (
                        <React.Fragment key={index}>
                          {row.category2.map((catMedium, indexCatMedium) => (
                            <React.Fragment key={indexCatMedium}>
                              {catMedium.details.map((detail, indexDetail) => (
                                <TableRow key={indexDetail}>
                                  {indexCatMedium === 0 &&
                                    indexDetail === 0 && (
                                      <TableColumn
                                        className="table-list__row-header"
                                        rowSpan={counts[index].totalCount}
                                      >
                                        {row.category1}
                                      </TableColumn>
                                    )}
                                  {indexDetail === 0 && (
                                    <TableColumn
                                      className="table-list__row-header"
                                      rowSpan={catMedium.details.length}
                                    >
                                      {catMedium.value}
                                    </TableColumn>
                                  )}

                                  <TableColumn className="table-list__cell">
                                    {detail.categoryFunc}
                                  </TableColumn>
                                  <TableCell textAlign="center">
                                    <LayoutBox align="center" justify="center">
                                      {isEdit ? (
                                        <Checkbox
                                          checked={detail.hasAdmin}
                                          onChecked={(checked) =>
                                            handleChangeCheck(
                                              checked,
                                              detail.id,
                                              "has_admin",
                                            )
                                          }
                                        />
                                      ) : detail.hasAdmin ? (
                                        <Icon icon="check" color="#096AE2" />
                                      ) : (
                                        <Icon icon="remove" color="#B4BBC6" />
                                      )}
                                    </LayoutBox>
                                  </TableCell>
                                  <TableCell textAlign="center">
                                    <LayoutBox align="center" justify="center">
                                      {isEdit ? (
                                        <Checkbox
                                          checked={detail.hasDoctor}
                                          onChecked={(checked) =>
                                            handleChangeCheck(
                                              checked,
                                              detail.id,
                                              "has_doctor",
                                            )
                                          }
                                        />
                                      ) : detail.hasDoctor ? (
                                        <Icon icon="check" color="#096AE2" />
                                      ) : (
                                        <Icon icon="remove" color="#B4BBC6" />
                                      )}
                                    </LayoutBox>
                                  </TableCell>
                                  <TableCell textAlign="center">
                                    <LayoutBox align="center" justify="center">
                                      {isEdit ? (
                                        <Checkbox
                                          checked={detail.hasUser}
                                          onChecked={(checked) =>
                                            handleChangeCheck(
                                              checked,
                                              detail.id,
                                              "has_user",
                                            )
                                          }
                                        />
                                      ) : detail.hasUser ? (
                                        <Icon icon="check" color="#096AE2" />
                                      ) : (
                                        <Icon icon="remove" color="#B4BBC6" />
                                      )}
                                    </LayoutBox>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </>
                  }
                />
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLI001;
