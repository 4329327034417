import { useState, useEffect } from "react";

import { getMongoDb, getAggregateMid001 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
  SEARCH_INFO,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Mid001FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.MID001;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.MID001;
};

const useMid001DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Mid001FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<Mid001StateType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);

  const { id, tel } = submittedSearchCriteria;
  const patientName = convertSearchName(submittedSearchCriteria.patientName);

  // ステータスのチェック状態
  const statusArray = useMemoStatusArr(
    SEARCH_INFO.MID001,
    submittedSearchCriteria.status,
  );

  // sortを取得
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "fix_book_date", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateMid001(
          hospitalId,
          id,
          patientName,
          tel,
          statusArray,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultAllDataType<Mid001StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    setFetchResult,
    statusArray,
    patientName,
    tel,
    hospitalId,
    id,
  ]);

  return {
    fetchResult,
    fetchError,
  };
};

export default useMid001DbActions;
