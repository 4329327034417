import React from "react";
import Text from "../Text/Text";
import Icon from "../Icon/Icon";
import RadioButton from "./RadioButton";

export type RadioButtonIconProps = {
  checked?: boolean;
  disabled?: boolean;
  value?: string | number;
  name?: string;
  onSelect: (value: any) => void;
  children?: React.ReactNode;
  withBorder?: boolean;
  title?: string;
  width?: string;
};

const RadioButtonIcon = ({
  disabled = false,
  checked = false,
  name,
  value = undefined,
  title,
  onSelect,
  children,
  withBorder = false,
  width,
}: RadioButtonIconProps) => {
  const radioButtonClass = React.useMemo(() => {
    const disabledClass = disabled ? ["radio--disabled"] : [];
    const disabledBgClass =
      disabled && withBorder ? ["radio--bg-disabled"] : [];
    const withBorderClass = withBorder ? ["radio--border"] : [];
    const checkedClass =
      checked && withBorder && !disabled ? ["radio--border-checked"] : [];

    return [
      "radio",
      ...disabledClass,
      ...checkedClass,
      ...withBorderClass,
      ...disabledBgClass,
    ].join(" ");
  }, [withBorder, checked, disabled]);

  const onClick = React.useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  const getIcon = React.useMemo(() => {
    if (disabled) {
      return <Icon icon="close" color="#E78D87" size="large" />;
    }

    return <Icon icon="check" color="#81C784" size="large" />;
  }, [disabled]);

  return (
    <div className="radio-button-icon">
      <Text bold size="xl">
        {title}
      </Text>
      <div className={radioButtonClass} style={{ width }}>
        <RadioButton onSelect={onClick} name={name}>
          <div className="radio-button-icon__icon">{getIcon}</div>
          {children}
        </RadioButton>
      </div>
    </div>
  );
};

RadioButtonIcon.defaultProps = {
  checked: false,
  disabled: false,
  value: undefined,
  title: "",
  name: "radioIcon",
  children: undefined,
  withBorder: false,
  width: "",
};

export default RadioButtonIcon;
