import { useState, useEffect } from "react";
import { Collection, ROLE_KEY } from "../../constants/common";
import { useRealmApp } from "../../contexts/RealmApp";
import { getMongoDb, getAggregateNlh003 } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useId from "./useId";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../base/useLoadingAction";
import useNlh002Dialog, {
  closeNlh002Dialog,
  reOpenNlh002Dialog,
} from "../pages/NLH002/useNlh002Dialog";
import { FileType } from "../../components/FileUpload/FileUpload";
import useForceUpdate from "./useForceUpdate";
import {
  checkFetchErr,
  checkActionErr,
  redirectToNoDataPage,
  redirectToNoRolePage,
} from "../../contexts/CustomErrorBoundary";
import { convertUrlToBase64 } from "../../utils/utils";
import {
  checkMasterRole,
  useCheckHasRole,
  useCheckMasterRoleFetching,
} from "./useMasterRoleDbActions";

// NLH001画面のデータ取得処理
const useCampaignInfoAction = (isCheck = false) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [dialogInfo] = useNlh002Dialog();

  const [fetchResult, setFetchResult] =
    useState<CampaignsNlh003StateType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);

  // 権限チェック
  const isFetchingRole = useCheckMasterRoleFetching(
    ROLE_KEY.CAMPAIGN_LIST_BROWSING,
  );
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.CAMPAIGN_LIST_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!hasBrowsingRole) redirectToNoRolePage();

        // 初期値取得処理
        const mongoDbCampaigns = getMongoDb(currentUser, Collection.CAMPAIGNS);
        const aggregate = getAggregateNlh003(id);
        const result = (await mongoDbCampaigns.aggregate(
          aggregate,
        )) as CampaignsNlh003StateType[];

        if (isCheck && result.length < 1) redirectToNoDataPage();
        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限取得中はデータ取得しない
    if (isFetchingRole) return;

    if (id) void fetchData();
  }, [currentUser, id, forceUpdate, isCheck, hasBrowsingRole, isFetchingRole]);

  // 新規登録・更新処理
  const updateCampaign = ({
    title,
    url,
    uploadFiles,
    uploadFiles2,
    deliveryStartDate,
    isLimited,
    deliveryEndDate,
  }: {
    title: string;
    url: string;
    uploadFiles: FileType[];
    uploadFiles2: FileType[];
    deliveryStartDate: Date;
    isLimited: boolean;
    deliveryEndDate: Date;
  }) => {
    void (async () => {
      closeNlh002Dialog();
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        const currentRoleKey =
          dialogInfo === "add"
            ? ROLE_KEY.CAMPAIGN_LIST_ADD
            : ROLE_KEY.CAMPAIGN_DETAIL_EDIT;
        await checkMasterRole(currentRoleKey, currentUser);

        // 更新
        const pcImage = uploadFiles[0]
          ? await convertUrlToBase64(uploadFiles[0].url)
          : "";
        const spImage = uploadFiles2[0]
          ? await convertUrlToBase64(uploadFiles2[0].url)
          : "";
        const updateData = {
          _id: id,
          title,
          url,
          pc_image: pcImage,
          sp_image: spImage,
          delivery_date: deliveryStartDate,
          is_limited: isLimited,
          suspend_date: deliveryEndDate,
          is_registered: true,
          last_updated_admin_id: currentUser?.id,
        };
        await currentUser?.functions.upsertCampaignApi(updateData);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(
          checkActionErr(err, () => reOpenNlh002Dialog(dialogInfo)),
        );
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { fetchResult, fetchError, actionError, updateCampaign };
};

export default useCampaignInfoAction;
