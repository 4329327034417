import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
// import generateUniqueId from "../../../utils/generateUniqueId";
import useForceUpdate from "../../common/useForceUpdate";
import { formatHourTime } from "../../../utils/utils";
import {
  closeActionLoading,
  openCreateActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useGenerateSequenceId from "../../common/useGenerateSequenceId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

// 診療時間取得
const getHospitalHours = (
  startAmTerm: Date,
  endAmTerm: Date,
  startPmTerm: Date,
  endPmTerm: Date,
) => ({
  am: {
    start: formatHourTime(startAmTerm),
    end: formatHourTime(endAmTerm),
  },
  pm: {
    start: formatHourTime(startPmTerm),
    end: formatHourTime(endPmTerm),
  },
});

const useMie005DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDb = getMongoDb(currentUser, Collection.HOSPITALS);
  const { generateId } = useGenerateSequenceId();

  // 医療機関登録
  const handleRegisterHospital = (
    hospitalName: string,
    startAmTerm: Date,
    endAmTerm: Date,
    startPmTerm: Date,
    endPmTerm: Date,
    schedule: HospitalScheduleType,
    tel: string,
    address: string,
    email: string,
    hasMri: boolean,
    hasPet: boolean,
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_ADD, currentUser);

        // 病院ID取得
        const id = await generateId(
          { field: "hospital_id", value: "hospital_no" },
          { field: "hospital_no", incrementValue: 1 },
        );

        // 診療時間
        const hospitalHour = getHospitalHours(
          startAmTerm,
          endAmTerm,
          startPmTerm,
          endPmTerm,
        );

        // 登録データ
        const insertData = {
          _id: id.toString(),
          hospital_name: hospitalName,
          address,
          tel,
          email,
          status: 0,
          hospital_hour: JSON.stringify(hospitalHour),
          hospital_term: "",
          hospital_week: JSON.stringify(schedule),
          hasMri,
          hasPet,
        };

        await mongoDb.insertOne(insertData);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 医療機関更新
  const handleUpdateHospital = (
    _id: string,
    hospitalName: string,
    startAmTerm: Date,
    endAmTerm: Date,
    startPmTerm: Date,
    endPmTerm: Date,
    schedule: HospitalScheduleType,
    tel: string,
    address: string,
    email: string,
    hasMri: boolean,
    hasPet: boolean,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 診療時間
        const hospitalHour = getHospitalHours(
          startAmTerm,
          endAmTerm,
          startPmTerm,
          endPmTerm,
        );

        // 更新データ
        const updateData = {
          hospital_name: hospitalName,
          address,
          tel,
          email,
          hospital_hour: JSON.stringify(hospitalHour),
          hospital_week: JSON.stringify(schedule),
          hasMri,
          hasPet,
        };

        await mongoDb.updateOne({ _id }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospital,
    handleUpdateHospital,
  };
};

export default useMie005DbActions;
