import { useState, useEffect } from "react";

import { getAggregateNlf007, getMongoDb } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection } from "../../../constants/common";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { adjustMonth } from "../../../utils/utils";

const useNlf007DbActions = (salesId: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchResult, setFetchResult] = useStateCustomObj<
    SalesNlf007StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [prevMonthExists, setPrevMonthExists] = useState<boolean>(false);
  const [nextMonthExists, setNextMonthExists] = useState<boolean>(false);

  const colName = "settlement_time";
  const sortDirection = "asc";

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.SALES);
        const aggregate = getAggregateNlf007(salesId, colName, sortDirection);
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as SalesNlf007StateType[];

        // 前月、次月のデータ有無検索
        const prevMonthId = adjustMonth(salesId, "before");
        const nextMonthId = adjustMonth(salesId, "after");
        const prevMonthData = (await mongoDb.aggregate(
          getAggregateNlf007(prevMonthId, colName, sortDirection),
        )) as SalesNlf007StateType[];
        const nextMonthData = (await mongoDb.aggregate(
          getAggregateNlf007(nextMonthId, colName, sortDirection),
        )) as SalesNlf007StateType[];

        setPrevMonthExists(prevMonthData.length > 0);
        setNextMonthExists(nextMonthData.length > 0);

        setFetchResult(aggregateResult);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [currentUser, colName, sortDirection, setFetchResult, salesId]);

  return {
    fetchResult,
    fetchError,
    prevMonthExists,
    nextMonthExists,
  };
};

export default useNlf007DbActions;
