import { type ReactElement, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ADMIN, COMMON, HOSPITAL } from "./constants/pagePaths";
import "./App.css";
import "./scss/index.scss";
import NotFound from "./pages/NotFound";
import IndexPage from "./pages/IndexPage";
import MIB001 from "./pages/MIB001";
import MIC001 from "./pages/MIC001";
import MID001 from "./pages/MID001";
import MIE001 from "./pages/MIE001";
import MID002 from "./pages/MID002";
import MIE004 from "./pages/MIE004";
import NLA001 from "./pages/NLA001";
import NLB001 from "./pages/NLB001";
import NLC001 from "./pages/NLC001";
import NLC004 from "./pages/NLC004";
import NLC005 from "./pages/NLC005";
import NLC007 from "./pages/NLC007";
import NLC008 from "./pages/NLC008";
import NLC011 from "./pages/NLC011";
import NLC014 from "./pages/NLC014";
import NLC017 from "./pages/NLC017";
import NLC018 from "./pages/NLC018";
import NLC019 from "./pages/NLC019";
import NLC020 from "./pages/NLC020";
import NLC023 from "./pages/NLC023";
import NLD002 from "./pages/NLD002";
import NLD003 from "./pages/NLD003";
import NLD004 from "./pages/NLD004";
import NLE001 from "./pages/NLE001";
import NLE002 from "./pages/NLE002";
import NLF001 from "./pages/NLF001";
import NLF005 from "./pages/NLF005";
import NLF006 from "./pages/NLF006";
import NLG001 from "./pages/NLG001";
import NLG003 from "./pages/NLG003";
import NLH001 from "./pages/NLH001";
import NLH003 from "./pages/NLH003";
import NLI001 from "./pages/NLI001";
import NLI003 from "./pages/NLI003";
import NLI007 from "./pages/NLI007";
import NLI009 from "./pages/NLI009";
import NLI011 from "./pages/NLI011";
import NLI016 from "./pages/NLI016";
import NLI017 from "./pages/NLI017";
import Spinner from "./components/Loading/Spinner";
import RequiredLoginElement from "./components/RequiredLoginElement";
import PublicElement from "./components/PublicElement";
import RealmApolloProvider from "./contexts/RealmApolloProvider";
import PageCommon from "./contexts/PageCommon";
import CustomErrorBoundary from "./contexts/CustomErrorBoundary";
import NLJ003 from "./pages/NLJ003";
import NLJ004 from "./pages/NLJ004";
import NLJ005 from "./pages/NLJ005";
import NLJ006 from "./pages/NLJ006";
import NLJ007 from "./pages/NLJ007";
import LoginProcessing from "./pages/LoginProcessing";
import NLJ008 from "./pages/NLJ008";

// ログイン不要画面設定一覧
const publicPagesSettingArr = [{ path: ADMIN.NLA001, element: <NLA001 /> }];
// ログイン必須画面設定一覧
const requiredLoginPagesSettingArr = [
  { path: HOSPITAL.MIB001, element: <MIB001 /> },
  { path: HOSPITAL.MIC001, element: <MIC001 /> },
  { path: HOSPITAL.MID001, element: <MID001 /> },
  { path: HOSPITAL.MID002, element: <MID002 /> },
  { path: HOSPITAL.MIE001, element: <MIE001 /> },
  { path: HOSPITAL.MIE004, element: <MIE004 /> },
  { path: ADMIN.NLB001, element: <NLB001 /> },
  { path: ADMIN.NLC001, element: <NLC001 /> },
  { path: ADMIN.NLC004, element: <NLC004 /> },
  { path: ADMIN.NLC005, element: <NLC005 /> },
  { path: ADMIN.NLC007, element: <NLC007 /> },
  { path: ADMIN.NLC008, element: <NLC008 /> },
  { path: ADMIN.NLC011, element: <NLC011 /> },
  { path: ADMIN.NLC014, element: <NLC014 /> },
  { path: ADMIN.NLC017, element: <NLC017 /> },
  { path: ADMIN.NLC018, element: <NLC018 /> },
  { path: ADMIN.NLC019, element: <NLC019 /> },
  { path: ADMIN.NLC020, element: <NLC020 /> },
  { path: ADMIN.NLC023, element: <NLC023 /> },
  { path: ADMIN.NLD002, element: <NLD002 /> },
  { path: ADMIN.NLD003, element: <NLD003 /> },
  { path: ADMIN.NLD004, element: <NLD004 /> },
  { path: ADMIN.NLE001, element: <NLE001 /> },
  { path: ADMIN.NLE002, element: <NLE002 /> },
  { path: ADMIN.NLF001, element: <NLF001 /> },
  { path: ADMIN.NLF005, element: <NLF005 /> },
  { path: ADMIN.NLF006, element: <NLF006 /> },
  { path: ADMIN.NLG001, element: <NLG001 /> },
  { path: ADMIN.NLG003, element: <NLG003 /> },
  { path: ADMIN.NLH001, element: <NLH001 /> },
  { path: ADMIN.NLH003, element: <NLH003 /> },
  { path: ADMIN.NLI001, element: <NLI001 /> },
  { path: ADMIN.NLI003, element: <NLI003 /> },
  { path: ADMIN.NLI007, element: <NLI007 /> },
  { path: ADMIN.NLI009, element: <NLI009 /> },
  { path: ADMIN.NLI011, element: <NLI011 /> },
  { path: ADMIN.NLI016, element: <NLI016 /> },
  { path: ADMIN.NLI017, element: <NLI017 /> },
  { path: COMMON.LOGIN_PROCESSING, element: <LoginProcessing /> },
];

const App = (): ReactElement => (
  <BrowserRouter>
    <CustomErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <RealmApolloProvider>
          <PageCommon>
            <Routes>
              {process.env.NODE_ENV === "development" && (
                <Route path="/" element={<IndexPage />} />
              )}
              {/* メール認証 */}
              <Route path={COMMON.NLJ003} element={<NLJ003 />} />
              <Route path={COMMON.NLJ004} element={<NLJ004 />} />
              <Route path={COMMON.NLJ005} element={<NLJ005 />} />
              <Route path={COMMON.NLJ006} element={<NLJ006 />} />
              <Route path={COMMON.NLJ007} element={<NLJ007 />} />
              <Route path={COMMON.NLJ008} element={<NLJ008 />} />
              {/* ページ Not Found */}
              <Route path={COMMON.NOT_FOUND} element={<NotFound />} />
              {/* ログイン不要画面 */}
              {publicPagesSettingArr.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={<PublicElement element={element} />}
                />
              ))}
              {/* ログイン必須画面 */}
              {requiredLoginPagesSettingArr.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <RequiredLoginElement element={element} path={path} />
                  }
                />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </PageCommon>
        </RealmApolloProvider>
      </Suspense>
    </CustomErrorBoundary>
  </BrowserRouter>
);

export default App;
