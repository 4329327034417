import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  hospitalName: ValidationResultType<string>;
  tel: ValidationResultType<string>;
  address: ValidationResultType<string>;
  email: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  hasMri: boolean;
  hasPet: boolean;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  hospitalName: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tel: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  address: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  hasMri: false,
  hasPet: false,
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useHospitalInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setHospitalName = (
  value: DefaultValidationType["hospitalName"]["value"],
) => {
  _setValidation("hospitalName", value, VALIDATION_TYPE.HOSPITAL_NAME_REQUIRED);
};
export const setTel = (value: DefaultValidationType["tel"]["value"]) => {
  _setValidation("tel", value, VALIDATION_TYPE.TEL_REQUIRED);
};
export const setAddress = (
  value: DefaultValidationType["address"]["value"],
) => {
  _setValidation("address", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};
export const setHasMri = (value: DefaultType["hasMri"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    hasMri: value,
  });
};
export const setHasPet = (value: DefaultType["hasPet"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    hasPet: value,
  });
};

// 値をすべて更新
export const setHospitalInput = ({
  hospitalName,
  tel,
  address,
  email,
  hasMri,
  hasPet,
}: {
  hospitalName: DefaultValidationType["hospitalName"]["value"];
  tel: DefaultValidationType["tel"]["value"];
  address: DefaultValidationType["address"]["value"];
  email: DefaultValidationType["email"]["value"];
  hasMri: DefaultType["hasMri"];
  hasPet: DefaultType["hasPet"];
}) => {
  setHospitalName(hospitalName);
  setTel(tel);
  setAddress(address);
  setEmail(email);
  setHasMri(hasMri);
  setHasPet(hasPet);
};

// 初期化
export const setInitHospitalInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkHospitalInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { hasMri: _hasMri, hasPet: _hasPet, ...validationInfos } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useHospitalInput;
