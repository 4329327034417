import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import Input from "../components/Input/Input";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ListItem from "../components/List/ListItem";
import FormSet from "../components/FormSet/FormSet";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import {
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
} from "../utils/utils";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  HospitalInspectionStatusID,
  ModalityID,
} from "../constants/common";
import { HOSPITAL } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useMid002DbActions from "../hooks/pages/MID002/useMid002DbActions";
import {
  convertMid002HospitalUser,
  convertMid002Modality,
  convertMid002User,
} from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import useHospitalTimeIntervalFetch from "../hooks/useHospitalTimeIntervalFetch";

const initUser = {
  id: "",
  fullNameSlash: "",
  email: "",
  gender: "",
  tel: "",
  birthDay: "",
};
const initModality = {
  attendingHospitalUserId: "",
  fixBookDateDisplay: "",
  examDate: "",
  hospitalName: "",
  id: "",
  modalityStr: "",
  modalityNum: ModalityID.MRI,
  mriDicomImagePath: "",
  mriDoctorNote: "",
  mriDoctorReview: "",
  statusNum: HospitalInspectionStatusID.NOT_INSPECTED,
  statusStr: "",
};

const MID002 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);
  const [selectedModalityId, setSelectedModalityId] = React.useState("");
  const [selectedModality, setSelectedModality] =
    useStateCustomObj(initModality);
  const [user, setUser] = useStateCustomObj(initUser);
  const [modalities, setModalities] = useStateCustomObj<
    Mid002ModalityTableType[]
  >([]);
  const [hospitalUserList, setHospitalUserList] = React.useState<
    SelectBoxType[]
  >([]);
  const {
    fetchUserResult,
    fetchModalityResult,
    fetchHospitalUserResult,
    handleUpdateModality,
    fetchError,
  } = useMid002DbActions();
  const { hospitalInfo, error: hospitalError } = useHospitalTimeIntervalFetch();

  // 患者情報
  useEffect(() => {
    const convertUser = convertMid002User(fetchUserResult);
    setUser(convertUser);
  }, [fetchUserResult, setUser]);

  // 検査履歴
  useEffect(() => {
    const convertModalities = convertMid002Modality(
      fetchModalityResult,
      hospitalInfo,
    );
    setModalities(convertModalities);
  }, [fetchModalityResult, setModalities, hospitalInfo]);

  // 担当医セレクトボックス
  useEffect(() => {
    const convertHospitalUserList = convertMid002HospitalUser(
      fetchHospitalUserResult,
    );
    setHospitalUserList(convertHospitalUserList);
  }, [fetchHospitalUserResult]);

  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError, hospitalError]);

  // 検査履歴リスト押下
  const handleListItemClick = (modality: Mid002ModalityTableType) => {
    setSelectedModalityId(modality.id);
    setSelectedModality(modality);
  };

  // 担当医変更
  const handleChangeHospitalUser = (value: string) => {
    setSelectedModality((prevState) => ({
      ...prevState,
      attendingHospitalUserId: value,
    }));
  };

  // 医師所見変更
  const handleChangeReview = (value: string) => {
    setSelectedModality((prevState) => ({
      ...prevState,
      mriDoctorReview: value,
    }));
  };

  // 備考変更
  const handleChangeDoctorNote = (value: string) => {
    setSelectedModality((prevState) => ({
      ...prevState,
      mriDoctorNote: value,
    }));
  };

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(HOSPITAL.MID001);

  // 登録ボタン押下
  const handleUpdate = () => {
    handleUpdateModality(
      selectedModalityId,
      selectedModality.attendingHospitalUserId,
      selectedModality.mriDoctorReview,
      selectedModality.mriDoctorNote,
      uploadFiles,
    );
  };

  // キャンセルボタン押下
  const handleInputCancel = () => {
    setSelectedModality(initModality);
    setSelectedModalityId("");
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(true)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <Button
                type="sub"
                size="large"
                icon="arrow_back"
                onClick={handleGoBack}
              >
                一覧へ戻る
              </Button>
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column">
                        <span className="util-font-neutral--light font-component-chip">
                          {user.id}
                        </span>
                        <Text size="2xl" bold>
                          {user.fullNameSlash}
                        </Text>
                      </LayoutBox>
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {user.birthDay}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {user.gender}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {user.tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {user.email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <div className="admin-user__inspection-results">
                        <Sheet
                          type="header"
                          className="util-mb-16"
                          padding="8px"
                        >
                          <Text bold size="xl">
                            検査履歴
                          </Text>
                        </Sheet>
                        <List height="100%">
                          {modalities.map((modality) => (
                            <ListItem
                              key={modality.id}
                              id={modality.id}
                              isSelected={selectedModalityId === modality.id}
                              onClick={() => handleListItemClick(modality)}
                              center={
                                <>
                                  <LayoutBox direction="column" fullWidth>
                                    <LayoutBox fullWidth direction="row">
                                      <Text size="small" color="neutralLight">
                                        受検日：{modality.examDate}{" "}
                                        {modality.hospitalName}
                                      </Text>
                                      <Tag
                                        label={modality.statusStr}
                                        showIcon={false}
                                        state={
                                          modality.statusNum ===
                                          HospitalInspectionStatusID.NOT_INSPECTED
                                            ? "error"
                                            : "success"
                                        }
                                      />
                                    </LayoutBox>
                                    <Text size="large" bold>
                                      {modality.modalityStr}
                                    </Text>
                                  </LayoutBox>
                                </>
                              }
                            />
                          ))}
                        </List>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <div className="admin-user__info-detail">
            <div className="util-px-24 util-py-8 admin-user__info-detail--form">
              <LayoutBox direction="column" gap="2x" minWidth="476px">
                <List height="100%">
                  <ListItem
                    id="1"
                    center={
                      <>
                        <LayoutBox direction="column" fullWidth>
                          <LayoutBox fullWidth direction="row">
                            <Text size="small" color="neutralLight">
                              予約日:{selectedModality.fixBookDateDisplay}
                            </Text>
                            {selectedModality.statusStr && (
                              <Tag
                                label={selectedModality.statusStr}
                                showIcon={false}
                                state={
                                  selectedModality.statusNum ===
                                  HospitalInspectionStatusID.NOT_INSPECTED
                                    ? "error"
                                    : "success"
                                }
                              />
                            )}
                          </LayoutBox>
                          <Text size="large" bold>
                            {selectedModality.modalityStr}
                          </Text>
                        </LayoutBox>
                      </>
                    }
                  />
                </List>
                <LayoutBox fullHeight direction="column" fullWidth>
                  <FormSet label="担当医" vertical>
                    <Dropdown
                      width="180px"
                      items={hospitalUserList}
                      value={selectedModality.attendingHospitalUserId}
                      onChange={handleChangeHospitalUser}
                      disabled={!selectedModality.id}
                    />
                  </FormSet>
                  {selectedModality.id &&
                    selectedModality.modalityNum === ModalityID.MRI && (
                      <FormSet label="診断画像アップロード" vertical>
                        <FileUpload
                          uploadFiles={uploadFiles}
                          setUploadFiles={setUploadFiles}
                        />
                      </FormSet>
                    )}

                  <FormSet label="医師所見" vertical>
                    <Input
                      multiLine
                      width="100%"
                      placeholder="内容"
                      value={selectedModality.mriDoctorReview}
                      onChange={handleChangeReview}
                      disabled={!selectedModality.id}
                    />
                  </FormSet>
                  <FormSet label="備考" vertical>
                    <Input
                      multiLine
                      width="100%"
                      placeholder="内容"
                      value={selectedModality.mriDoctorNote}
                      onChange={handleChangeDoctorNote}
                      disabled={!selectedModality.id}
                    />
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
            </div>
            <div className="admin-user__info-detail--footer">
              <div className="admin-user__info-detail--footer-button">
                <LayoutBox align="center" justify="center">
                  <Button
                    size="large"
                    color="neutral"
                    type="sub"
                    onClick={handleInputCancel}
                    disabled={!selectedModality.id}
                  >
                    キャンセル
                  </Button>
                  <Button
                    size="large"
                    onClick={handleUpdate}
                    disabled={!selectedModality.id}
                  >
                    登録
                  </Button>
                </LayoutBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MID002;
