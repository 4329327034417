import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import Text from "../components/Text/Text";
import Divider from "../components/Divider/Divider";
import Link from "../components/Link/Link";
import Dropdown from "../components/Dropdown/Dropdown";
import Legend from "../components/Legend/Legend";
import BarChart from "../components/Charts/BarChart";
import FormSet from "../components/FormSet/FormSet";
import LineChart from "../components/Charts/LineChart";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import {
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  MONTH_OPTIONS,
  Nlb001CategoryID,
} from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNlb001DbActions from "../hooks/pages/NLB001/useNlb001DbActions";
import { ADMIN } from "../constants/pagePaths";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertNlb001Task } from "../utils/convertDisplay";
import { setId } from "../hooks/common/useId";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";

const TAB_SEARCH_ITEMS = [{ title: "検査予約" }, { title: "面談予約" }];

const TOGGLE_DWM_ITEMS = [
  {
    id: "1",
    label: "日",
  },
  {
    id: "2",
    label: "週",
  },
  {
    id: "3",
    label: "月",
  },
];

const TOGGLE_WMY_ITEMS = [
  {
    id: "1",
    label: "週",
  },
  {
    id: "2",
    label: "月",
  },
  {
    id: "3",
    label: "年",
  },
];

const CATEGORY_ITEMS = [
  {
    value: Nlb001CategoryID.ALL,
    label: "すべて",
  },
  {
    value: Nlb001CategoryID.CHAT,
    label: "医師チャット",
  },
  {
    value: Nlb001CategoryID.NOUKNOW,
    label: "スクリーニングテスト",
  },
  {
    value: Nlb001CategoryID.MRI,
    label: "MRI",
  },
  {
    value: Nlb001CategoryID.INTERVIEw,
    label: "面談",
  },
  {
    value: Nlb001CategoryID.EC,
    label: "EC",
  },
];

const NLB001 = () => {
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLB001,
  );
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [tasks, setTasks] = useStateCustomObj<Nlb001TaskDisplayType[]>([]);

  const {
    fetchNumberResult,
    fetchError,
    userChartResult,
    appointmentChatResult,
    taskResult,
  } = useNlb001DbActions({
    searchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError]);

  useEffect(() => {
    const convertTaskResult = convertNlb001Task(taskResult);
    setTasks(convertTaskResult);
  }, [taskResult, setTasks]);

  // 件数 トグルチェンジ
  const handleNumberToggleChange = (
    newToggle: React.SetStateAction<string>,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      number: {
        ...prevState.number,
        selectedToggle: newToggle as string,
      },
    }));
  };

  // 件数 日付選択
  const handleChangeNumberDay = React.useCallback((date: Date) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      number: { ...prevState.number, searchDay: date },
    }));
  }, []);

  // 予約 タブ
  const handleAppointmentTab = (index: number) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      appointment: {
        ...prevState.appointment,
        selectedIndex: index,
      },
    }));
  };

  // 予約 トグルチェンジ
  const handleAppointmentToggleChange = (
    newToggle: React.SetStateAction<string>,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      appointment: {
        ...prevState.appointment,
        selectedAppointmentToggle: newToggle as string,
      },
    }));
  };

  // 寄席 トグルチェンジ 再生回数無し
  // const handleYoseToggleChange = (newToggle: React.SetStateAction<string>) => {
  //   setSearchCriteria((prevState) => ({
  //     ...prevState,
  //     yose: {
  //       ...prevState.yose,
  //       selectedYoseToggle: newToggle as string,
  //     },
  //   }));
  // };

  // 会員数 トグルチェンジ
  const handleMembershipToggleChange = (
    newToggle: React.SetStateAction<string>,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        selectedMembershipToggle: newToggle as string,
      },
    }));
  };

  // 年セレクトボックス変更
  const handleChangeYear = (value: string, key: "user" | "appointment") => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        year: value,
      },
    }));
  };

  // 月セレクトボックス変更
  const handleChangeMonth = (value: string, key: "user" | "appointment") => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        month: value,
      },
    }));
  };

  // 週日付変更
  const handleChangeDay = (value: Date, key: "user" | "appointment") => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        day: value,
      },
    }));
  };

  // タスク カテゴリ変更
  const handleChangeCategory = (value: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      task: { ...prevState.task, category: value },
    }));
  };

  // タスク 明細押下
  const navigate = useNavigateWithUrl();
  const handleTaskClick = (category: string, patientId: string) => {
    switch (category) {
      case "interview":
        setId(patientId);
        navigate(ADMIN.NLC020);
        break;
      case "mri":
        setId(patientId);
        navigate(ADMIN.NLC014);
        break;
      case "ec":
        setId(patientId);
        navigate(ADMIN.NLF005);
        break;
      case "nouknow":
        setId(patientId);
        navigate(ADMIN.NLC011);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={0}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <div className="admin-dashboard">
            <LayoutBox direction="column" gap="3x">
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="2x">
                  <LayoutBox justify="start" align="center" gap="2x">
                    <ToggleButton
                      size="small"
                      items={TOGGLE_DWM_ITEMS}
                      selectedButton={searchCriteria.number.selectedToggle}
                      onClick={handleNumberToggleChange}
                    />
                    <div className="admin-dashboard__search">
                      <DatePicker
                        width="154px"
                        selectedDate={searchCriteria.number.searchDay}
                        onChangeDate={handleChangeNumberDay}
                      />
                    </div>
                  </LayoutBox>
                  <LayoutBox
                    align="center"
                    justify="between"
                    gap="2x"
                    fullWidth
                  >
                    {/* 新規会員登録 */}
                    <div className="admin-dashboard__info">
                      <LayoutBox align="center" justify="between">
                        <LayoutBox align="center">
                          <Icon icon="person_add" color="#096AE2" />
                          <Text bold size="large">
                            新規会員登録
                          </Text>
                        </LayoutBox>
                        <Tag
                          label={fetchNumberResult.user.differenceCountStr}
                          state={
                            fetchNumberResult.user.differenceCount > 0
                              ? "success"
                              : fetchNumberResult.user.differenceCount === 0
                              ? "neutral"
                              : "error"
                          }
                          icon={
                            fetchNumberResult.user.differenceCount > 0
                              ? "trending_up"
                              : fetchNumberResult.user.differenceCount === 0
                              ? "trending_flat"
                              : "trending_down"
                          }
                        />
                      </LayoutBox>
                      <LayoutBox direction="column">
                        <div className="admin-dashboard__info-result">
                          <Text bold size="3xl">
                            {fetchNumberResult.user.todayCount}
                          </Text>
                          <Text bold size="xl" color="neutralLight">
                            件
                          </Text>
                        </div>
                      </LayoutBox>
                    </div>
                    <Divider
                      vertical
                      height="104px"
                      width="1px"
                      margin="17px"
                    />
                    {/* 面談予約 */}
                    <div className="admin-dashboard__info">
                      <LayoutBox align="center" justify="between">
                        <LayoutBox align="center">
                          <Icon icon="event" color="#096AE2" />
                          <Text bold size="large">
                            面談予約
                          </Text>
                        </LayoutBox>
                        <Tag
                          label={fetchNumberResult.interview.differenceCountStr}
                          state={
                            fetchNumberResult.interview.differenceCount > 0
                              ? "success"
                              : fetchNumberResult.interview.differenceCount ===
                                0
                              ? "neutral"
                              : "error"
                          }
                          icon={
                            fetchNumberResult.interview.differenceCount > 0
                              ? "trending_up"
                              : fetchNumberResult.interview.differenceCount ===
                                0
                              ? "trending_flat"
                              : "trending_down"
                          }
                        />
                      </LayoutBox>
                      <LayoutBox direction="column">
                        <div className="admin-dashboard__info-result">
                          <Text bold size="3xl">
                            {fetchNumberResult.interview.todayCount}
                          </Text>
                          <Text bold size="xl" color="neutralLight">
                            件
                          </Text>
                        </div>
                      </LayoutBox>
                    </div>
                    <Divider
                      vertical
                      height="104px"
                      width="1px"
                      margin="17px"
                    />
                    {/* MRI検査予約 */}
                    <div className="admin-dashboard__info">
                      <LayoutBox align="center" justify="between">
                        <LayoutBox align="center">
                          <Icon icon="event" color="#096AE2" />
                          <Text bold size="large">
                            MRI検査予約
                          </Text>
                        </LayoutBox>
                        <Tag
                          label={fetchNumberResult.mri.differenceCountStr}
                          state={
                            fetchNumberResult.mri.differenceCount > 0
                              ? "success"
                              : fetchNumberResult.mri.differenceCount === 0
                              ? "neutral"
                              : "error"
                          }
                          icon={
                            fetchNumberResult.mri.differenceCount > 0
                              ? "trending_up"
                              : fetchNumberResult.mri.differenceCount === 0
                              ? "trending_flat"
                              : "trending_down"
                          }
                        />
                      </LayoutBox>
                      <LayoutBox direction="column">
                        <div className="admin-dashboard__info-result">
                          <Text bold size="3xl">
                            {fetchNumberResult.mri.todayCount}
                          </Text>
                          <Text bold size="xl" color="neutralLight">
                            件
                          </Text>
                        </div>
                      </LayoutBox>
                    </div>
                    <Divider
                      vertical
                      height="104px"
                      width="1px"
                      margin="17px"
                    />
                    {/* FDG PET検査予約 */}
                    <div className="admin-dashboard__info">
                      <LayoutBox align="center" justify="between">
                        <LayoutBox align="center">
                          <Icon icon="event" color="#096AE2" />
                          <Text bold size="large">
                            FDG PET検査予約
                          </Text>
                        </LayoutBox>
                        <Tag
                          label={fetchNumberResult.pet.differenceCountStr}
                          state={
                            fetchNumberResult.pet.differenceCount > 0
                              ? "success"
                              : fetchNumberResult.pet.differenceCount === 0
                              ? "neutral"
                              : "error"
                          }
                          icon={
                            fetchNumberResult.pet.differenceCount > 0
                              ? "trending_up"
                              : fetchNumberResult.pet.differenceCount === 0
                              ? "trending_flat"
                              : "trending_down"
                          }
                        />
                      </LayoutBox>
                      <LayoutBox direction="column">
                        <div className="admin-dashboard__info-result">
                          <Text bold size="3xl">
                            {fetchNumberResult.pet.todayCount}
                          </Text>
                          <Text bold size="xl" color="neutralLight">
                            件
                          </Text>
                        </div>
                      </LayoutBox>
                    </div>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>

              <LayoutBox gap="3x" fullWidth fullHeight>
                <LayoutBox direction="column" gap="2x" align="stretch">
                  {/* 検査予約 */}
                  <Sheet className="admin-dashboard__sheet">
                    <div className="util-full-width">
                      <LayoutBox direction="column" gap="2x">
                        <div className="util-full-width">
                          <LayoutBox align="center" justify="between" gap="2x">
                            <div className="util-border-bottom-gray util-bg-neutral--white admin-dashboard__search-tab">
                              <Tabs
                                selectedTab={0}
                                tabs={TAB_SEARCH_ITEMS}
                                onClick={handleAppointmentTab}
                              />
                            </div>
                            <div className="util-full-width admin-dashboard__search-links">
                              <LayoutBox align="center" gap="2x" justify="end">
                                {searchCriteria.appointment.selectedIndex ===
                                  0 && (
                                  <>
                                    <LayoutBox align="center">
                                      <Link url={ADMIN.NLC005} external={false}>
                                        2次スクリーニング
                                      </Link>
                                      <Icon
                                        icon="keyboard_arrow_right"
                                        size="xs"
                                        color="#096AE2"
                                      />
                                    </LayoutBox>
                                    <LayoutBox align="center">
                                      <Link url={ADMIN.NLC006} external={false}>
                                        3次スクリーニング
                                      </Link>
                                      <Icon
                                        icon="keyboard_arrow_right"
                                        size="xs"
                                        color="#096AE2"
                                      />
                                    </LayoutBox>
                                  </>
                                )}
                                {searchCriteria.appointment.selectedIndex ===
                                  1 && (
                                  <LayoutBox align="center">
                                    <Link url={ADMIN.NLC007} external={false}>
                                      面談予約
                                    </Link>
                                    <Icon
                                      icon="keyboard_arrow_right"
                                      size="xs"
                                      color="#096AE2"
                                    />
                                  </LayoutBox>
                                )}
                              </LayoutBox>
                            </div>
                          </LayoutBox>
                          <LayoutBox direction="column" gap="3x">
                            <div className="util-py-16 util-full-width">
                              <LayoutBox
                                align="center"
                                justify="between"
                                gap="2x"
                              >
                                <div className="admin-dashboard__select-period">
                                  <LayoutBox align="center" gap="2x">
                                    <ToggleButton
                                      size="small"
                                      items={TOGGLE_WMY_ITEMS}
                                      selectedButton={
                                        searchCriteria.appointment
                                          .selectedAppointmentToggle
                                      }
                                      onClick={handleAppointmentToggleChange}
                                    />
                                    <div className="admin-dashboard__search">
                                      <LayoutBox align="center">
                                        {searchCriteria.appointment
                                          .selectedAppointmentToggle !==
                                          "1" && (
                                          <>
                                            <Dropdown
                                              value={
                                                searchCriteria.appointment.year
                                              }
                                              width="96px"
                                              size="small"
                                              items={
                                                appointmentChatResult.yearOption
                                              }
                                              onChange={(value) =>
                                                handleChangeYear(
                                                  value,
                                                  "appointment",
                                                )
                                              }
                                            />
                                            <Text>年</Text>
                                          </>
                                        )}
                                        {searchCriteria.appointment
                                          .selectedAppointmentToggle ===
                                          "2" && (
                                          <>
                                            <Dropdown
                                              width="64px"
                                              items={MONTH_OPTIONS}
                                              value={
                                                searchCriteria.appointment.month
                                              }
                                              onChange={(value) =>
                                                handleChangeMonth(
                                                  value,
                                                  "appointment",
                                                )
                                              }
                                            />
                                            <Text>月</Text>
                                          </>
                                        )}
                                        {searchCriteria.appointment
                                          .selectedAppointmentToggle ===
                                          "1" && (
                                          <DatePicker
                                            width="100%"
                                            size="small"
                                            selectedDate={
                                              searchCriteria.appointment.day
                                            }
                                            onChangeDate={(value) =>
                                              handleChangeDay(
                                                value,
                                                "appointment",
                                              )
                                            }
                                          />
                                        )}
                                      </LayoutBox>
                                    </div>
                                  </LayoutBox>
                                </div>
                                <LayoutBox
                                  align="center"
                                  justify="end"
                                  gap="3x"
                                  wrap
                                >
                                  {searchCriteria.appointment.selectedIndex ===
                                    0 && (
                                    <>
                                      <LayoutBox align="center">
                                        <Legend
                                          height="16px"
                                          items={[{ no: "1" }]}
                                        />
                                        <Text bold color="neutralLight">
                                          MRI予約
                                        </Text>
                                        <Text bold size="3xl">
                                          {appointmentChatResult.mriCount}
                                        </Text>
                                        <Text
                                          bold
                                          size="large"
                                          color="neutralLight"
                                        >
                                          人
                                        </Text>
                                      </LayoutBox>
                                      <LayoutBox align="center">
                                        <Legend
                                          height="16px"
                                          items={[{ no: "2" }]}
                                        />
                                        <Text bold color="neutralLight">
                                          FDG PET予約
                                        </Text>
                                        <Text bold size="3xl">
                                          {appointmentChatResult.petCount}
                                        </Text>
                                        <Text
                                          bold
                                          size="large"
                                          color="neutralLight"
                                        >
                                          人
                                        </Text>
                                      </LayoutBox>
                                    </>
                                  )}
                                  {searchCriteria.appointment.selectedIndex ===
                                    1 && (
                                    <LayoutBox align="center">
                                      <Legend
                                        height="16px"
                                        items={[{ no: "1" }]}
                                      />
                                      <Text
                                        bold
                                        size="large"
                                        color="neutralLight"
                                      >
                                        面談予約
                                      </Text>
                                      <Text bold size="3xl">
                                        {appointmentChatResult.interviewCount}
                                      </Text>
                                      <Text
                                        bold
                                        size="large"
                                        color="neutralLight"
                                      >
                                        人
                                      </Text>
                                    </LayoutBox>
                                  )}
                                </LayoutBox>
                              </LayoutBox>
                            </div>
                            <div className="admin-dashboard__sheet-chart">
                              <BarChart
                                unit="人"
                                unitDirection="right"
                                maxYScale={appointmentChatResult.maxYScale}
                                chartData={appointmentChatResult.chartData}
                              />
                            </div>
                          </LayoutBox>
                        </div>
                      </LayoutBox>
                    </div>
                  </Sheet>
                  {/* 会員数 */}
                  <Sheet className="admin-dashboard__sheet">
                    <div className="util-full-width">
                      <LayoutBox direction="column" gap="2x">
                        <div className="util-full-width">
                          <LayoutBox align="center" justify="between">
                            <Text bold size="xl">
                              会員数
                            </Text>
                            <LayoutBox align="center">
                              <Link url={ADMIN.NLC001} external={false}>
                                ユーザー管理
                              </Link>
                              <Icon
                                icon="keyboard_arrow_right"
                                size="xs"
                                color="#096AE2"
                              />
                            </LayoutBox>
                          </LayoutBox>
                        </div>

                        <div className="util-full-width">
                          <LayoutBox direction="column" gap="2x">
                            <div className="util-py-16 util-full-width">
                              <LayoutBox
                                align="center"
                                justify="between"
                                gap="2x"
                              >
                                <div className="admin-dashboard__select-period">
                                  <LayoutBox align="center" gap="2x">
                                    <ToggleButton
                                      size="small"
                                      items={TOGGLE_WMY_ITEMS}
                                      selectedButton={
                                        searchCriteria.user
                                          .selectedMembershipToggle
                                      }
                                      onClick={handleMembershipToggleChange}
                                    />
                                    <div className="admin-dashboard__search">
                                      <LayoutBox align="center">
                                        {searchCriteria.user
                                          .selectedMembershipToggle !== "1" && (
                                          <>
                                            <Dropdown
                                              value={searchCriteria.user.year}
                                              width="96px"
                                              size="small"
                                              items={userChartResult.yearOption}
                                              onChange={(value) =>
                                                handleChangeYear(value, "user")
                                              }
                                            />
                                            <Text>年</Text>
                                          </>
                                        )}
                                        {searchCriteria.user
                                          .selectedMembershipToggle === "2" && (
                                          <>
                                            <Dropdown
                                              width="64px"
                                              items={MONTH_OPTIONS}
                                              value={searchCriteria.user.month}
                                              onChange={(value) =>
                                                handleChangeMonth(value, "user")
                                              }
                                            />
                                            <Text>月</Text>
                                          </>
                                        )}
                                        {searchCriteria.user
                                          .selectedMembershipToggle === "1" && (
                                          <DatePicker
                                            width="100%"
                                            size="small"
                                            selectedDate={
                                              searchCriteria.user.day
                                            }
                                            onChangeDate={(value) =>
                                              handleChangeDay(value, "user")
                                            }
                                          />
                                        )}
                                      </LayoutBox>
                                    </div>
                                  </LayoutBox>
                                </div>
                                <LayoutBox
                                  align="center"
                                  justify="end"
                                  gap="3x"
                                  wrap
                                >
                                  <LayoutBox align="center">
                                    <Legend
                                      height="16px"
                                      items={[{ no: "6" }]}
                                    />
                                    <Text bold color="neutralLight" noWrap>
                                      会員数
                                    </Text>
                                    <Text bold size="3xl">
                                      {userChartResult.userCount}
                                    </Text>
                                    <Text
                                      bold
                                      size="large"
                                      color="neutralLight"
                                    >
                                      人
                                    </Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </LayoutBox>
                            </div>
                            <div className="admin-dashboard__sheet-chart">
                              <LineChart
                                unit="人"
                                unitDirection="right"
                                maxYScale={userChartResult.maxYScale}
                                chartData={userChartResult.chartData}
                              />
                            </div>
                          </LayoutBox>
                        </div>
                      </LayoutBox>
                    </div>
                  </Sheet>
                </LayoutBox>

                <LayoutBox gap="2x" align="stretch" fullHeight>
                  {/* タスク */}
                  <Sheet className="admin-dashboard__sheet">
                    <div className="util-full-width">
                      <LayoutBox direction="column" gap="2x">
                        <div className="util-full-width">
                          <LayoutBox align="center" justify="between">
                            <Text bold size="xl">
                              タスク
                            </Text>
                            <div className="admin-dashboard__search">
                              <LayoutBox align="center">
                                <FormSet
                                  label="カテゴリ"
                                  labelWidth="88px"
                                  base
                                >
                                  <Dropdown
                                    width="172px"
                                    value="1"
                                    items={CATEGORY_ITEMS}
                                    onChange={handleChangeCategory}
                                  />
                                </FormSet>
                              </LayoutBox>
                            </div>
                          </LayoutBox>
                        </div>
                        <div className="util-full-width">
                          {tasks.length > 0 && (
                            <div className="admin-dashboard__sheet-task">
                              <List height="100%">
                                {tasks.map((task) => (
                                  <ListItem
                                    key={task.id}
                                    id={task.id}
                                    onClick={() =>
                                      handleTaskClick(
                                        task.category,
                                        task.patientId,
                                      )
                                    }
                                    center={
                                      <>
                                        <LayoutBox>
                                          <Text
                                            size="small"
                                            color="neutralLight"
                                          >
                                            {task.updatedAt}
                                          </Text>
                                          <Tag
                                            showIcon={false}
                                            state="neutral"
                                            label={task.tag1}
                                          />
                                          <Tag
                                            showIcon={false}
                                            state="error"
                                            label={task.tag2}
                                          />
                                        </LayoutBox>
                                        <Text>{task.description}</Text>
                                        <Text size="small" color="neutralLight">
                                          {task.patientName}
                                        </Text>
                                      </>
                                    }
                                    right={
                                      <LayoutBox align="center">
                                        <Icon
                                          icon="keyboard_arrow_right"
                                          size="small"
                                          color="#096AE2"
                                        />
                                      </LayoutBox>
                                    }
                                  />
                                ))}
                              </List>
                            </div>
                          )}
                          {tasks.length === 0 && (
                            <div className="admin-dashboard__sheet-task--no-exists">
                              <LayoutBox direction="column" align="center">
                                <Icon icon="inbox" />
                                <div className="util-font-neutral--light">
                                  表示する結果がありません
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </div>
                      </LayoutBox>
                    </div>
                  </Sheet>
                  {/* 再生回数無し */}
                  {/* <Sheet className="admin-dashboard__sheet">
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <div className="util-full-width">
                        <LayoutBox align="center" justify="between">
                          <Text bold size="xl">
                            オンライン寄席再生回数
                          </Text>
                          <LayoutBox align="center">
                            <Link url={ADMIN.NLE001} external={false}>
                              オンライン寄席
                            </Link>
                            <Icon
                              icon="keyboard_arrow_right"
                              size="xs"
                              color="#096AE2"
                            />
                          </LayoutBox>
                        </LayoutBox>
                        <LayoutBox direction="column" gap="3x">
                          <div className="util-py-16 util-full-width">
                            <LayoutBox
                              align="center"
                              justify="between"
                              gap="2x"
                            >
                              <LayoutBox align="center" gap="2x">
                                <ToggleButton
                                  size="small"
                                  items={TOGGLE_WMY_ITEMS}
                                  selectedButton={
                                    searchCriteria.yose.selectedYoseToggle
                                  }
                                  onClick={handleYoseToggleChange}
                                />
                                <div className="admin-dashboard__search">
                                  <LayoutBox align="center">
                                    {searchCriteria.yose.selectedYoseToggle !==
                                      "1" && (
                                      <>
                                        <Dropdown
                                          value={searchCriteria.yose.year}
                                          width="96px"
                                          size="small"
                                          items={yoseChartResult.yearOption}
                                          onChange={(value) =>
                                            handleChangeYear(value, "yose")
                                          }
                                        />
                                        <Text>年</Text>
                                      </>
                                    )}
                                    {searchCriteria.yose.selectedYoseToggle ===
                                      "2" && (
                                      <>
                                        <Dropdown
                                          width="64px"
                                          items={MONTH_OPTIONS}
                                          value={searchCriteria.yose.month}
                                          onChange={(value) =>
                                            handleChangeMonth(value, "yose")
                                          }
                                        />
                                        <Text>月</Text>
                                      </>
                                    )}
                                    {searchCriteria.yose.selectedYoseToggle ===
                                      "1" && (
                                      <DatePicker
                                        width="100%"
                                        size="small"
                                        selectedDate={searchCriteria.yose.day}
                                        onChangeDate={(value) =>
                                          handleChangeDay(value, "yose")
                                        }
                                      />
                                    )}
                                  </LayoutBox>
                                </div>
                              </LayoutBox>
                              <LayoutBox align="center" gap="3x">
                                <LayoutBox align="center">
                                  <Legend height="16px" items={[{ no: "1" }]} />
                                  <Text bold size="large" color="neutralLight">
                                    再生回数
                                  </Text>
                                  <Text bold size="3xl">
                                    {yoseChartResult.yoseCount}
                                  </Text>
                                  <Text bold size="large" color="neutralLight">
                                    回
                                  </Text>
                                </LayoutBox>
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                          <div className="admin-dashboard__sheet-chart">
                            <BarChart
                              unit="回"
                              unitDirection="right"
                              maxYScale={yoseChartResult.maxYScale}
                              chartData={yoseChartResult.chartData}
                              barWidth={0.3}
                            />
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </Sheet> */}
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </div>
        </div>
      </div>
    </>
  );
};

export default NLB001;
