import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import ListItem from "../components/List/ListItem";
import {
  INSPECT_NOUKNOW_STATUS_OPTIONS,
  MASTER_MANAGEMENT_OPTIONS,
  PatientNouKnowStatusID,
  PatientNouKnowStatusLabels,
  TEST_RESULT_ITEMS,
  USER_EVALUATION,
} from "../constants/common";
import {
  getNouKnowTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
  useHandleTestResultSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import Divider from "../components/Divider/Divider";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Pagination from "../components/Pagination/Pagination";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import useNlc011Conditions, {
  NOUKNOW_RESULT_OPTIONS,
  setProblem,
  setStatus,
} from "../hooks/pages/NLC011/useNlc011Conditions";
import useNlc011DbActions from "../hooks/pages/NLC011/useNlc011DbActions";
import { openNlc012Dialog } from "../hooks/pages/NLC012/useNlc012Dialog";
import NLC012 from "./NLC012";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";

const NLC011 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const {
    nouknowTestResult,
    error: fetchError,
    actionError,
    sendResult,
  } = useNlc011DbActions();

  const [{ problem, status }] = useNlc011Conditions();

  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  const { id, name, birth, genderStr, tel, email, address, evaluation } =
    convertPatientInfo(patientInfo);

  useCheckErrorThrowError([
    fetchError,
    actionError,
    patientFetchError,
    mainTabError,
    userDetailTabError,
    updateEvaluationError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();
  const handleTestResultSubTab = useHandleTestResultSubTab();

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(true)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC004}
              />
              <Sheet padding="24px 24px 12px 24px">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <span className="util-font-neutral--light font-component-chip">
                          {id}
                        </span>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          <div className="util-half-width">
                            <LayoutBox align="center">
                              <Label base size="medium" width="120px">
                                ユーザー評価
                              </Label>
                              <LayoutBox>
                                <RadioButtonGroup
                                  name="userRatingRadioButton"
                                  rowItems={USER_EVALUATION}
                                  onChange={handleChangeEvaluation}
                                  selectedValue={selectedValue}
                                  radioGap="16px"
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  住所1
                                </Label>
                                {address}
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <Tabs
                        selectedTab={1}
                        tabs={userDetailTabItems}
                        size="medium"
                        bottomBorder
                        onClick={handleSelectAdminUserDetailTab}
                      />
                      <ToggleButton
                        selectedButton="1"
                        items={TEST_RESULT_ITEMS}
                        onClick={handleTestResultSubTab}
                      />
                      <LayoutBox gap="2x">
                        <div className="admin-user__info-detail--footer-select">
                          <span className="font-component-button-small">
                            結果
                          </span>
                          <Dropdown
                            value={problem}
                            width="148px"
                            items={NOUKNOW_RESULT_OPTIONS}
                            onChange={setProblem}
                          />
                        </div>
                        <div className="admin-user__info-detail--footer-select">
                          <span className="font-component-button-small">
                            ステータス
                          </span>
                          <Dropdown
                            value={status.toString()}
                            width="148px"
                            items={INSPECT_NOUKNOW_STATUS_OPTIONS}
                            onChange={setStatus}
                          />
                        </div>
                      </LayoutBox>
                      <div className="admin-user__inspection-results">
                        <List height="">
                          {nouknowTestResult.map((resultInfo) => (
                            <ListItem
                              key={resultInfo._id}
                              id={resultInfo._id}
                              center={
                                <>
                                  <LayoutBox>
                                    <Text size="small" color="neutralLight">
                                      受験日:{resultInfo.date}
                                    </Text>
                                    <Tag
                                      showIcon={false}
                                      label={
                                        PatientNouKnowStatusLabels[
                                          resultInfo.status as keyof typeof PatientNouKnowStatusLabels
                                        ]
                                      }
                                      state={getNouKnowTagState(
                                        PatientNouKnowStatusLabels[
                                          resultInfo.status as keyof typeof PatientNouKnowStatusLabels
                                        ],
                                      )}
                                    />
                                  </LayoutBox>
                                  <Text size="large" bold>
                                    スクリーニングテスト
                                  </Text>
                                  <LayoutBox align="center">
                                    <LayoutBox gap="1/2x">
                                      <Text size="small" bold>
                                        評価:
                                      </Text>
                                      <Text size="small">
                                        集中力スコア:{" "}
                                        {resultInfo.concentrationScore} /
                                        記憶力スコア: {resultInfo.memoryScore}
                                      </Text>
                                    </LayoutBox>
                                  </LayoutBox>
                                </>
                              }
                              right={
                                <LayoutBox align="center">
                                  {resultInfo.status ===
                                    PatientNouKnowStatusID.CONFIRMED && (
                                    <Button
                                      onClick={() => sendResult(resultInfo._id)}
                                    >
                                      ユーザーに結果を送信
                                    </Button>
                                  )}
                                  <Button
                                    type="secondary"
                                    onClick={() =>
                                      openNlc012Dialog(resultInfo._id)
                                    }
                                  >
                                    レポート
                                  </Button>
                                </LayoutBox>
                              }
                            />
                          ))}
                        </List>
                        <div className="util-mb-16" />
                        {nouknowTestResult.length === 0 && (
                          <div className="util-py-40">
                            <LayoutBox
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              <Icon icon="inbox" />
                              <div className="util-font-neutral--light">
                                表示する結果がありません
                              </div>
                            </LayoutBox>
                          </div>
                        )}
                        <LayoutBox justify="end" fullWidth>
                          <Pagination input={false} />
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <EventHistories />
        </div>
      </div>
      <NLC012 />
    </>
  );
};

export default NLC011;
