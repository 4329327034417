import React, { useEffect } from "react";
import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import Divider from "../components/Divider/Divider";
import DatePicker from "../components/DatePicker/DatePicker";
import TimePicker from "../components/TimePicker/TimePicker";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import {
  closeNlh002Dialog,
  getIsOpenNlh002,
} from "../hooks/pages/NLH002/useNlh002Dialog";
import useNlh002DbActions from "../hooks/pages/NLH002/useNlh002DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";

const RADIO_BUTTON_ITEMS = [
  {
    column: 4,
    items: [
      {
        label: "制限あり",
        value: "1",
      },
      {
        label: "無制限",
        value: "2",
      },
    ],
  },
];

const NLH002 = () => {
  const isOpen = getIsOpenNlh002();
  const [title, setTitle] = React.useState<string>("");
  const [url, setUrl] = React.useState<string>("");
  // PC表示画像
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);
  // スマホ表示画像
  const [uploadFiles2, setUploadFiles2] = React.useState<FileType[]>([]);
  // 配信日時
  const [deliveryStartDate, setDeliveryStartDate] = React.useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  // 終了日時のラジオボタン選択肢
  const [selectedValue, setSelectedValue] = React.useState(
    RADIO_BUTTON_ITEMS[0].items[0].value,
  );
  const isLimited = RADIO_BUTTON_ITEMS[0].items[0].value === selectedValue;
  // 終了日時
  const [deliveryEndDate, setDeliveryEndDate] = React.useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  const { fetchResult, actionError, updateCampaign } = useNlh002DbActions();
  useCheckErrorThrowError([actionError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    if (fetchResult) {
      const {
        title: fetchTitle,
        url,
        pc_image: pcImage,
        sp_image: spImage,
        delivery_date: deliveryDate,
        suspend_date: suspendDate,
        is_limited: isLimited,
      } = fetchResult;
      setTitle(fetchTitle);
      setUrl(url);
      if (pcImage)
        setUploadFiles([
          {
            name: "pc",
            percentage: 100,
            url: pcImage,
          },
        ]);
      if (spImage)
        setUploadFiles2([
          {
            name: "sp",
            percentage: 100,
            url: spImage,
          },
        ]);
      if (deliveryDate) setDeliveryStartDate(new Date(deliveryDate));
      setSelectedValue(
        isLimited
          ? RADIO_BUTTON_ITEMS[0].items[0].value
          : RADIO_BUTTON_ITEMS[0].items[1].value,
      );
      if (suspendDate) setDeliveryEndDate(new Date(suspendDate));
    }
  }, [fetchResult]);

  const clickRegister = () => {
    updateCampaign({
      title,
      url,
      uploadFiles,
      uploadFiles2,
      deliveryStartDate,
      isLimited,
      deliveryEndDate,
    });
  };

  return (
    <DialogCustom
      title="マイページトップ"
      open={isOpen}
      size="large"
      height="1017px"
      closeDialog={closeNlh002Dialog}
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={closeNlh002Dialog}
          >
            キャンセル
          </Button>
          <Button onClick={clickRegister} size="large">
            登録
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox gap="2x" direction="column" fullWidth>
        <FormSet
          label="キャンペーン"
          labelWidth="90px"
          minWidth="259px"
          vertical
        >
          <Input
            placeholder="キャンペーン"
            width="100%"
            value={title}
            onChange={setTitle}
          />
        </FormSet>
        <FormSet label="URL" labelWidth="90px" minWidth="259px" vertical>
          <Input placeholder="URL" width="100%" value={url} onChange={setUrl} />
        </FormSet>

        <FormSet label="PC表示画像" labelWidth="90px" minWidth="259px" vertical>
          <FileUpload
            type="single"
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
          />
        </FormSet>
        <FormSet
          label="スマホ表示画像"
          labelWidth="90px"
          minWidth="259px"
          vertical
        >
          <FileUpload
            type="single"
            uploadFiles={uploadFiles2}
            setUploadFiles={setUploadFiles2}
          />
        </FormSet>
        <Divider />
      </LayoutBox>

      <div className="util-full-width util-mt-16">
        <LayoutBox gap="3x" direction="column">
          <FormSet label="配信日時" labelWidth="90px" vertical>
            <LayoutBox gap="2x">
              <DatePicker
                placeholder="配信日"
                selectedDate={deliveryStartDate}
                onChangeDate={setDeliveryStartDate}
                width="165px"
              />
              <TimePicker
                value={deliveryStartDate}
                onChange={setDeliveryStartDate}
                dropdownWidth="74px"
                showButton={false}
              />
            </LayoutBox>
          </FormSet>
          <FormSet label="終了日時" labelWidth="90px" vertical>
            <LayoutBox direction="column" gap="1/2x">
              <RadioButtonGroup
                name="endTypeRadioButton"
                rowItems={RADIO_BUTTON_ITEMS}
                onChange={setSelectedValue}
                selectedValue={selectedValue}
              />
              <LayoutBox gap="2x">
                <DatePicker
                  placeholder="配信日"
                  selectedDate={deliveryEndDate}
                  onChangeDate={setDeliveryEndDate}
                  width="165px"
                />
                <TimePicker
                  value={deliveryEndDate}
                  onChange={setDeliveryEndDate}
                  dropdownWidth="74px"
                  showButton={false}
                />
              </LayoutBox>
            </LayoutBox>
          </FormSet>
        </LayoutBox>
      </div>
    </DialogCustom>
  );
};

export default NLH002;
