/* eslint-disable no-console */
import React from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import Dropdown from "../components/Dropdown/Dropdown";
import Link from "../components/Link/Link";
import Image from "../components/Image/Image";
import Tooltip from "../components/Tooltip/Tooltip";
import { MASTER_MANAGEMENT_OPTIONS } from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";

const SORT_EXAMPLES = [
  { id: "col-1", sortDirection: "" },
  { id: "col-5", sortDirection: "" },
];

const YEAR_ITEMS = [
  { value: "すべて", label: "すべて" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
];

const USERS_DATA = [
  {
    id: "000000031",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "新着",
    perfomer: "林家 何某",
    views: "1,000",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/1",
    unsubscribeDate: "2023/1/1",
    checked: false,
  },
  {
    id: "000000032",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "新着",
    perfomer: "林家 何某",
    views: "1,000",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/1",
    unsubscribeDate: "2023/1/1",
    checked: false,
  },
  {
    id: "000000033",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "アーカイブ",
    perfomer: "林家 何某",
    views: "1,000",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/1",
    unsubscribeDate: "2023/1/1",
    checked: false,
  },
  {
    id: "000000034",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "アーカイブ",
    perfomer: "林家 何某",
    views: "1,000",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/1",
    unsubscribeDate: "2023/1/1",
    checked: false,
  },
  {
    id: "000000035",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "公開終了",
    perfomer: "林家 何某",
    views: "1,000",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/1",
    unsubscribeDate: "2023/1/1",
    checked: false,
  },
  {
    id: "000000036",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "公開終了",
    perfomer: "林家 何某",
    views: "1,001",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/2",
    unsubscribeDate: "2023/1/2",
    checked: false,
  },
  {
    id: "000000037",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "公開終了",
    perfomer: "林家 何某",
    views: "1,001",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/2",
    unsubscribeDate: "2023/1/2",
    checked: false,
  },
  {
    id: "000000038",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "公開終了",
    perfomer: "林家 何某",
    views: "1,001",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/2",
    unsubscribeDate: "2023/1/2",
    checked: false,
  },
  {
    id: "000000039",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "公開終了",
    perfomer: "林家 何某",
    views: "1,001",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/2",
    unsubscribeDate: "2023/1/2",
    checked: false,
  },
  {
    id: "000000040",
    videoName: "ダミーテキストダミーテキストダミーテキスト",
    url: "https://xxxxxxxxxxxxxx",
    status: "公開終了",
    perfomer: "林家 何某",
    views: "1,001",
    deliveryPeriod: "1ヶ月",
    deliveryDate: "2022/1/2",
    unsubscribeDate: "2023/1/2",
    checked: false,
  },
];

const INIT_SEARCH_CRITERIA = {
  id: "",
  videoName: "",
  phoneNum: "",
  status: {
    isActive: false,
    isNoCoveredInsurance: false,
    isOpen: false,
    isArchived: false,
    isPrivate: false,
  },
};

const NLE001 = () => {
  const [users] = React.useState(USERS_DATA);
  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES);
  const [searchCriteria, setSearchCriteria] =
    React.useState(INIT_SEARCH_CRITERIA);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([mainTabError]);

  const handleChangeId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, id: value }));
  };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  const onChange = React.useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  const onChangeRangeDate = React.useCallback(
    (startDate: Date, endDate: Date) => {
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [],
  );

  const handleSearchUsers = () => {
    console.log("ユーザー検索実行");
  };

  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA);

  const handleAdd = () => {
    console.log("Add");
  };

  const handleChangeYear = () => {
    console.log("Change Year");
  };

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={3}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox gap="3x" fullWidth>
                <FormSet label="動画名" labelWidth="90px" base minWidth="259px">
                  <Input
                    value={searchCriteria.videoName}
                    placeholder="動画名"
                    width="100%"
                    onChange={handleChangeId}
                  />
                </FormSet>
                <FormSet
                  label="配信日"
                  labelWidth="90px"
                  base
                  minWidth="293.33"
                >
                  <DatePicker
                    type="range"
                    placeholder="開始日"
                    placeholderEnd="終了日"
                    width="100%"
                    selectedDate={selectedDate}
                    onChangeDate={onChange}
                    onChangeRangeDate={onChangeRangeDate}
                    selectedDateRange={{ startDate, endDate }}
                  />
                </FormSet>
                <FormSet
                  label="配信停止日"
                  labelWidth="90px"
                  base
                  minWidth="259.33px"
                >
                  <Dropdown
                    placeholder="すべて"
                    value="すべて"
                    items={YEAR_ITEMS}
                    onChange={handleChangeYear}
                    width="100%"
                  />
                </FormSet>
                <FormSet
                  label="ステータス"
                  labelWidth="90px"
                  base
                  minWidth="350px"
                >
                  <LayoutBox justify="start" gap="2x">
                    <Checkbox
                      label="公開中"
                      checked={searchCriteria.status.isOpen}
                      onChecked={(checked) =>
                        handleChangeCheckItem(checked, "isOpen")
                      }
                    />
                    <Checkbox
                      label="アーカイブ"
                      checked={searchCriteria.status.isArchived}
                      onChecked={(checked) =>
                        handleChangeCheckItem(checked, "isArchived")
                      }
                    />
                    <Checkbox
                      label="非公開"
                      checked={searchCriteria.status.isPrivate}
                      onChecked={(checked) =>
                        handleChangeCheckItem(checked, "isPrivate")
                      }
                    />
                  </LayoutBox>
                </FormSet>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox
                direction="column"
                gap="2x"
                justify="end"
                align="end"
                fullWidth
              >
                <LayoutBox align="center" justify="end">
                  <Button
                    type="secondary"
                    size="medium"
                    icon="add"
                    onClick={handleAdd}
                  >
                    新規動画登録
                  </Button>
                </LayoutBox>
                <Table
                  type="relaxed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="356px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        動画名
                      </TableColumn>
                      <TableColumn width="68px" id="col-2" />
                      <TableColumn width="93px" id="col-3" />

                      <TableColumn width="355.5px" id="col-4">
                        URL
                      </TableColumn>
                      <TableColumn
                        width="123px"
                        id="col-5"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        onClickSort={onClickSort}
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn width="166px" id="col-6">
                        出演者
                      </TableColumn>
                      <TableColumn width="166px" id="col-7">
                        再生回数
                      </TableColumn>
                      <TableColumn width="165px" id="col-8">
                        配信期間
                      </TableColumn>
                      <TableColumn width="166px" id="col-9">
                        配信日
                      </TableColumn>
                      <TableColumn width="166px" id="col-10">
                        配信停止日
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id} isSelected={user.checked}>
                      <TableCell>{user.videoName}</TableCell>
                      <TableCell>
                        <Button type="sub" size="small">
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Image />
                      </TableCell>
                      <TableCell>
                        <LayoutBox justify="between" align="center" fullWidth>
                          <Link url={user.url} external={false}>
                            {user.url}
                          </Link>
                          <Tooltip content="URLをコピー" direction="top">
                            <Button
                              type="sub"
                              shape="circle"
                              icon="content_copy"
                              size="small"
                            />
                          </Tooltip>
                        </LayoutBox>
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={user.status}
                          showIcon={false}
                          state={
                            user.status === "新着"
                              ? "success"
                              : user.status === "アーカイブ"
                              ? "warning"
                              : user.status === "公開終了"
                              ? "error"
                              : undefined
                          }
                          emphasis={user.status === "保険対象外"}
                        />
                      </TableCell>

                      <TableCell>{user.perfomer}</TableCell>
                      <TableCell>{user.views}</TableCell>
                      <TableCell>{user.deliveryPeriod}</TableCell>
                      <TableCell>{user.deliveryDate}</TableCell>
                      <TableCell>{user.unsubscribeDate}</TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLE001;
