import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useUserEditInput from "../../input/useUserEditInput";
import { Collection } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";

const useNlc025DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [id] = useId();
  const [userInfo] = useUserEditInput();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 更新処理
  const handleUpdateUserInfo = (setIsOpen: (isOpen: boolean) => void) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const mongoDb = getMongoDb(currentUser, Collection.PATIENTS);
        // 更新データ
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const updateData = {
          // name_sei: userInfo.nameSei,
          // name_mei: userInfo.nameMei,
          // name_sei_kana: userInfo.nameSeiKana,
          // nama_mei_kana: userInfo.nameMeiKana,
          gender: userInfo.gender,
          birth_year: Number(userInfo.birthYear),
          birth_month: Number(userInfo.birthMonth),
          birth_day: Number(userInfo.birthDay),
          tel: userInfo.tel.value,
          // email: userInfo.email,
          zipcode: userInfo.zipcode.value,
          pref: userInfo.pref,
          city: userInfo.city.value,
          town1: userInfo.town1.value,
          town2: userInfo.town2,
        };

        // await mongoDb.updateOne({ _id: id }, { $set: updateData });

        // FIXME: ユーザ情報変更API
        await currentUser?.functions.actionDummyApi(id);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });

        setIsOpen(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateUserInfo,
  };
};

export default useNlc025DbActions;
