/* eslint-disable @typescript-eslint/no-explicit-any */
enum FirebaseAuthResultStatus {
  Successful,
  EmailAlreadyExists,
  WrongPassword,
  InvalidEmail,
  UserNotFound,
  UserDisabled,
  OperationNotAllowed,
  TooManyRequests,
  RequireRecentLogin,
  Undefined,
}

const getException = (e: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (e.code) {
    case "auth/invalid-email":
      return FirebaseAuthResultStatus.InvalidEmail;
    case "auth/wrong-password":
      return FirebaseAuthResultStatus.WrongPassword;
    case "auth/user-not-found":
      return FirebaseAuthResultStatus.UserNotFound;
    case "auth/user-disabled":
      return FirebaseAuthResultStatus.UserDisabled;
    case "auth/too-many-requests":
      return FirebaseAuthResultStatus.TooManyRequests;
    case "auth/operation-not-allowed":
      return FirebaseAuthResultStatus.OperationNotAllowed;
    case "auth/email-already-in-use":
      return FirebaseAuthResultStatus.EmailAlreadyExists;
    case "auth/requires-recent-login":
      return FirebaseAuthResultStatus.RequireRecentLogin;
    default:
      return FirebaseAuthResultStatus.Undefined;
  }
};

const handleException = (e: any) => {
  const result = getException(e);
  switch (result) {
    case FirebaseAuthResultStatus.InvalidEmail:
      return "メールアドレスが間違っています。";
    case FirebaseAuthResultStatus.WrongPassword:
    case FirebaseAuthResultStatus.UserNotFound:
      return "IDまたはパスワードが正しくありません";
    case FirebaseAuthResultStatus.UserDisabled:
      return "このメールアドレスは無効になっています。";
    case FirebaseAuthResultStatus.TooManyRequests:
      return "回線が混雑しています。もう一度試してみてください。";
    case FirebaseAuthResultStatus.OperationNotAllowed:
      return "メールアドレスとパスワードでのログインは有効になっていません。";
    case FirebaseAuthResultStatus.EmailAlreadyExists:
      return "このメールアドレスはすでに登録されています。";
    case FirebaseAuthResultStatus.RequireRecentLogin:
      return "ログインからの経過時間が長すぎるためこの操作はできません。一旦ログアウトしてください。";
    default:
      return "認証プロセス内で予期せぬエラーが発生しました。";
  }
};

export default handleException;
