import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Header from "../components/Header/Header";
import Link from "../components/Link/Link";
import { useRealmApp } from "../contexts/RealmApp";
import { ADMIN, COMMON, HEADER_LINK } from "../constants/pagePaths";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import usePasswordInput, {
  checkPasswordInputError,
  setPassword,
  setPasswordError,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";

/* メールリンクからの画面 */
const NLJ003 = () => {
  const navigate = useNavigate();
  const realmAppContext = useRealmApp();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";
  const tokenId = queryParams.get("tokenId") || "";
  const mode = queryParams.get("mode") || "";
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [{ password, rePassword }] = usePasswordInput();

  useEffect(() => {
    const confirmUser = async () => {
      try {
        await realmAppContext.app.emailPasswordAuth.confirmUser({
          token,
          tokenId,
        });
        setIsConfirmed(true);
      } catch (error) {
        // メール再送画面に遷移
        navigate(COMMON.NLJ004, { state: { isFailed: true } });
      }
    };

    if (mode === "verifyEmail") {
      void confirmUser();
    } else if (mode === "resetPassword") {
      setIsResetPassword(true);
    }
  }, [token, tokenId, realmAppContext.app.emailPasswordAuth, navigate, mode]);

  // ログイン画面に遷移
  const handleNavigateLogin = () => {
    navigate(ADMIN.NLA001);
  };

  // パスワード送信
  const handleSendPassword = async () => {
    // パスワードバリデーション
    if (password.value !== rePassword.value) {
      setPasswordError();

      return;
    }
    try {
      addLoadCount();
      // ファンクションの実行
      await realmAppContext.app.emailPasswordAuth.resetPassword({
        token,
        tokenId,
        password: password.value,
      });
      // 変更完了
      navigate(COMMON.NLJ008);
    } catch (error) {
      // メール再送画面に遷移
      navigate(COMMON.NLJ004, { state: { isFailed: true } });
    } finally {
      decrementLoadCount();
    }
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <Header
            leftControl={
              <Link
                url={HEADER_LINK}
                external={false}
                linkClassName="header__left-logo"
              >
                認知症 予兆検知サービス
              </Link>
            }
          />
        </div>
        {isConfirmed && (
          <>
            <div className="util-mb-48" />
            <LayoutBox fullHeight fullWidth justify="center">
              <Sheet
                type="border"
                maxWidth="640px"
                minHeight="247px"
                className="util-flex--align-center util-flex--justify-center"
              >
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                  fullWidth
                >
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    gap="1x"
                    fullWidth
                  >
                    <Text size="xl" bold>
                      メールアドレスを認証しました。
                    </Text>
                    <Text size="base">
                      ログインしてサービスを利用できます。
                    </Text>
                  </LayoutBox>
                  <Button
                    size="large"
                    iconPosition="left"
                    width="230px"
                    onClick={handleNavigateLogin}
                  >
                    ログイン
                  </Button>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </>
        )}
        {isResetPassword && (
          <>
            <div className="util-mb-48" />
            <LayoutBox fullHeight fullWidth justify="center">
              <Sheet
                type="border"
                maxWidth="500px"
                minHeight="247px"
                className="util-flex--align-center util-flex--justify-center"
              >
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                  fullWidth
                >
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    gap="1x"
                    fullWidth
                  >
                    <Text size="xl" bold>
                      パスワードを再設定します。
                    </Text>
                    <Text size="base">
                      パスワードを入力後、パスワード再設定ボタンを押下してください。
                    </Text>

                    <FormSet
                      label="新しいパスワード"
                      vertical
                      errorTextList={[password.validationMessage]}
                    >
                      <Input
                        size="default"
                        width="259px"
                        password
                        rightButton="password"
                        autoComplete="new-password"
                        value={password.value}
                        onChange={(value) => setPassword(value)}
                        error={password.isError}
                      />
                    </FormSet>
                    <FormSet
                      label="新しいパスワード（確認用）"
                      vertical
                      errorTextList={[rePassword.validationMessage]}
                    >
                      <Input
                        size="default"
                        width="259px"
                        password
                        rightButton="password"
                        autoComplete="new-password"
                        value={rePassword.value}
                        onChange={(value) => setRePassword(value)}
                        error={rePassword.isError}
                      />
                    </FormSet>
                  </LayoutBox>
                  <Button
                    size="large"
                    iconPosition="left"
                    width="230px"
                    disabled={checkPasswordInputError()}
                    onClick={() => {
                      void handleSendPassword();
                    }}
                  >
                    パスワード再設定
                  </Button>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </>
        )}
      </div>
    </>
  );
};

export default NLJ003;
