import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Divider from "../components/Divider/Divider";
import {
  INIT_EVALUATION,
  MASTER_MANAGEMENT_OPTIONS,
  USER_EVALUATION,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";
import { ADMIN } from "../constants/pagePaths";

const NLC008 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(INIT_EVALUATION);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();

  // 患者情報取得
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const {
    id,
    name,
    birth,
    genderStr,
    tel,
    email,
    evaluation,
    planName,
    planType,
    mriPetConditions,
    notification,
    invitation,
    address,
  } = convertPatientInfo(patientInfo);

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(true)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC001}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <span className="util-font-neutral--light font-component-chip">
                          {id}
                        </span>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          <div className="util-half-width">
                            <LayoutBox align="center">
                              <Label base size="medium" width="120px">
                                ユーザー評価
                              </Label>
                              <LayoutBox>
                                <RadioButtonGroup
                                  name="userRatingRadioButton"
                                  rowItems={USER_EVALUATION}
                                  onChange={handleChangeEvaluation}
                                  selectedValue={selectedValue}
                                  radioGap="16px"
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  住所1
                                </Label>
                                {address}
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <Tabs
                      selectedTab={0}
                      tabs={userDetailTabItems}
                      size="medium"
                      bottomBorder
                      onClick={handleSelectAdminUserDetailTab}
                    />
                    <LayoutBox gap="3x" direction="column" fullWidth>
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <Sheet
                          type="header"
                          className="util-px-12 util-py-12"
                          padding="4px 8px"
                        >
                          <Text bold size="xl">
                            プラン
                          </Text>
                        </Sheet>
                        <LayoutBox gap="none" direction="column">
                          <Text lineHeight="20px">{planName}</Text>
                          <Text lineHeight="20px">{planType}</Text>
                        </LayoutBox>
                      </LayoutBox>
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <Sheet
                          type="header"
                          className="util-px-12 util-py-12"
                          padding="4px 8px"
                        >
                          <Text bold size="xl">
                            MRI検査・PET検査の受検条件について
                          </Text>
                        </Sheet>
                        <Text lineHeight="40px">{mriPetConditions}</Text>
                      </LayoutBox>
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <Sheet
                          type="header"
                          className="util-px-12 util-py-12"
                          padding="4px 8px"
                        >
                          <Text bold size="xl">
                            お知らせ
                          </Text>
                        </Sheet>
                        <Text lineHeight="40px">{notification}</Text>
                      </LayoutBox>
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <Sheet
                          type="header"
                          className="util-px-12 util-py-12"
                          padding="4px 8px"
                        >
                          <Text bold size="xl">
                            お支払い方法
                          </Text>
                        </Sheet>
                        <LayoutBox gap="1x" direction="column">
                          <LayoutBox gap="2x" align="center">
                            <Label base width="120px">
                              招待コード
                            </Label>
                            {invitation}
                          </LayoutBox>
                        </LayoutBox>
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <EventHistories />
        </div>
      </div>
    </>
  );
};

export default NLC008;
