import React, { useCallback, useEffect } from "react";
import Text from "../Text/Text";
import Dropdown from "../Dropdown/Dropdown";
import Table from "./Table";
import TableRow from "./TableRow";
import TableColumn from "./TableColumn";
import LayoutBox from "../LayoutBox/LayoutBox";
import FormSet from "../FormSet/FormSet";
import Input from "../Input/Input";
import Button from "../Button/Button";
import TimeTableRow, { TimeTableItem } from "./TimeTableRow";
import useMie001Conditions, {
  setMriTimeFrame,
  setMriTimeInterval,
  setMriTimeItems,
  setPetTimeFrame,
  setPetTimeInterval,
  setPetTimeItems,
} from "../../hooks/pages/MIE001/useMie001Conditions";
import { InspectionTabID } from "../../constants/common";

const ITEMS_DATA = [
  {
    id: "1",
    hours: 9,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "2",
    hours: 9,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "3",
    hours: 9,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "4",
    hours: 9,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "5",
    hours: 10,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "6",
    hours: 10,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "7",
    hours: 10,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "8",
    hours: 10,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "9",
    hours: 11,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "10",
    hours: 11,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "11",
    hours: 11,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "12",
    hours: 11,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "13",
    hours: 12,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "14",
    hours: 12,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "15",
    hours: 12,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "16",
    hours: 12,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "17",
    hours: 13,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "18",
    hours: 13,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "19",
    hours: 13,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "20",
    hours: 13,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "21",
    hours: 14,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "22",
    hours: 14,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "23",
    hours: 14,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "24",
    hours: 14,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "25",
    hours: 15,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "26",
    hours: 15,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "27",
    hours: 15,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "28",
    hours: 15,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "29",
    hours: 16,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "30",
    hours: 16,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "31",
    hours: 16,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "32",
    hours: 16,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "33",
    hours: 17,
    minutes: 0,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "34",
    hours: 17,
    minutes: 15,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "35",
    hours: 17,
    minutes: 30,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
  {
    id: "36",
    hours: 17,
    minutes: 45,
    monday: "4",
    tuesday: "4",
    wednesday: "4",
    thursday: "4",
    friday: "4",
    saturday: "4",
    sunday: "4",
  },
] as TimeTableItem[];

const interval30Id = [
  "1",
  "3",
  "5",
  "7",
  "9",
  "11",
  "13",
  "15",
  "17",
  "19",
  "21",
  "23",
  "25",
  "27",
  "29",
  "31",
  "33",
  "35",
];
const interval60Id = ["1", "5", "9", "13", "17", "21", "25", "29", "33"];
const interval90Id = ["1", "7", "13", "19", "25", "31"];
const interval120Id = ["1", "9", "17", "25", "33"];

const DROPDOWN_ITEMS = [
  {
    value: "45",
    label: "15",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "0",
    label: "60",
  },
  {
    value: "90",
    label: "90",
  },
  {
    value: "120",
    label: "120",
  },
];

const TimeTable = () => {
  const [
    {
      mriTimeItems: mriItems,
      petTimeItems: petItems,
      mriTimeInterval,
      petTimeInterval,
      mriTimeFrame,
      petTimeFrame,
      inspectionType,
    },
  ] = useMie001Conditions();
  const [manualInput, setManualInput] = React.useState<string>("4");

  // 枠時間更新関数をセット
  const setTimeFrame =
    inspectionType === InspectionTabID.MRI ? setMriTimeFrame : setPetTimeFrame;
  const setTimeInterval =
    inspectionType === InspectionTabID.MRI
      ? setMriTimeInterval
      : setPetTimeInterval;

  // アイテムセット
  const items = inspectionType === InspectionTabID.MRI ? mriItems : petItems;

  // 時間単位セット
  const selectedOption =
    inspectionType === InspectionTabID.MRI ? mriTimeInterval : petTimeInterval;
  const timeFrame =
    inspectionType === InspectionTabID.MRI ? mriTimeFrame : petTimeFrame;

  // 時間単位更新関数セット
  const setItemsData =
    inspectionType === InspectionTabID.MRI ? setMriTimeItems : setPetTimeItems;

  // 初期値セット
  useEffect(() => {
    if (inspectionType === InspectionTabID.MRI && !mriItems.length) {
      setItemsData(ITEMS_DATA);
    }
    if (inspectionType === InspectionTabID.PET && !petItems.length) {
      setItemsData(ITEMS_DATA);
    }
  }, [setItemsData, inspectionType, mriItems.length, petItems.length]);

  const getRowspan = useCallback((): number => {
    switch (timeFrame) {
      case 0:
        return 1;
      case 30:
        return 2;
      case 45:
        return 4;
      case 90:
        return 1;
      case 120:
        return 1;
      default:
        return 1;
    }
  }, [timeFrame]);

  // 全体に適用ボタン
  const handleApplyButtonClick = () => {
    const inputValue = Number(manualInput);
    const updatedItems = ITEMS_DATA.map((item) => {
      switch (selectedOption) {
        case "30":
          if (interval30Id.includes(item.id)) {
            return {
              ...item,
              monday: inputValue.toString(),
              tuesday: inputValue.toString(),
              wednesday: inputValue.toString(),
              thursday: inputValue.toString(),
              friday: inputValue.toString(),
              saturday: inputValue.toString(),
              sunday: inputValue.toString(),
            };
          }

          return {
            ...item,
            monday: "0",
            tuesday: "0",
            wednesday: "0",
            thursday: "0",
            friday: "0",
            saturday: "0",
            sunday: "0",
          };

        case "0":
          if (interval60Id.includes(item.id)) {
            return {
              ...item,
              monday: inputValue.toString(),
              tuesday: inputValue.toString(),
              wednesday: inputValue.toString(),
              thursday: inputValue.toString(),
              friday: inputValue.toString(),
              saturday: inputValue.toString(),
              sunday: inputValue.toString(),
            };
          }

          return {
            ...item,
            monday: "0",
            tuesday: "0",
            wednesday: "0",
            thursday: "0",
            friday: "0",
            saturday: "0",
            sunday: "0",
          };
        case "90":
          if (interval90Id.includes(item.id)) {
            return {
              ...item,
              monday: inputValue.toString(),
              tuesday: inputValue.toString(),
              wednesday: inputValue.toString(),
              thursday: inputValue.toString(),
              friday: inputValue.toString(),
              saturday: inputValue.toString(),
              sunday: inputValue.toString(),
            };
          }

          return {
            ...item,
            monday: "0",
            tuesday: "0",
            wednesday: "0",
            thursday: "0",
            friday: "0",
            saturday: "0",
            sunday: "0",
          };
        case "120":
          if (interval120Id.includes(item.id)) {
            return {
              ...item,
              monday: inputValue.toString(),
              tuesday: inputValue.toString(),
              wednesday: inputValue.toString(),
              thursday: inputValue.toString(),
              friday: inputValue.toString(),
              saturday: inputValue.toString(),
              sunday: inputValue.toString(),
            };
          }

          return {
            ...item,
            monday: "0",
            tuesday: "0",
            wednesday: "0",
            thursday: "0",
            friday: "0",
            saturday: "0",
            sunday: "0",
          };
        default:
          return {
            ...item,
            monday: inputValue.toString(),
            tuesday: inputValue.toString(),
            wednesday: inputValue.toString(),
            thursday: inputValue.toString(),
            friday: inputValue.toString(),
            saturday: inputValue.toString(),
            sunday: inputValue.toString(),
          };
      }
    });
    setItemsData(updatedItems);
    setTimeFrame(Number(selectedOption) as 0 | 15 | 30 | 45 | 90 | 120);
  };

  const leftTableRows = React.useMemo(() => {
    let timeFrameRowsOnly;
    if (timeFrame === 90) {
      const leftItemsOnly = items.filter(
        (item) => item.hours === 9 || item.hours === 10 || item.hours === 12,
      );
      timeFrameRowsOnly = leftItemsOnly.filter((item) => {
        if (item.hours === 9) {
          return item.minutes === 0;
        }
        if (item.hours === 10) {
          return item.minutes === 30;
        }
        if (item.hours === 12) {
          return item.minutes === 0;
        }

        return false;
      });
    } else if (timeFrame === 120) {
      const leftItemsOnly = items.filter(
        (item) => item.hours === 9 || item.hours === 11,
      );
      timeFrameRowsOnly = leftItemsOnly.filter((item) => {
        if (item.hours === 9) {
          return item.minutes === 0;
        }
        if (item.hours === 11) {
          return item.minutes === 0;
        }

        return false;
      });
    } else {
      const leftItemsOnly = items.filter((item) => item.hours <= 12);
      timeFrameRowsOnly = leftItemsOnly.filter((item) => {
        if (timeFrame === 30) {
          return item.minutes === 0 || item.minutes === 30;
        }

        return item.minutes <= timeFrame;
      });
    }
    const rowspan = getRowspan();

    return timeFrameRowsOnly.map((item) => (
      <TimeTableRow
        id={item.id}
        showHourCell={
          item.minutes === 0 || (timeFrame === 90 && item.minutes === 30)
        }
        rowspan={rowspan}
        key={`${item.hours}-${item.minutes}`}
        item={item}
        items={items}
        setItemsData={setItemsData}
        formattedMinutes={item.minutes.toString().padStart(2, "0")}
      />
    ));
  }, [items, timeFrame, getRowspan, setItemsData]);

  const rightTableRows = React.useMemo(() => {
    let timeFrameRowsOnly;
    if (timeFrame === 90) {
      const rightItemsOnly = items.filter(
        (item) => item.hours === 13 || item.hours === 15 || item.hours === 16,
      );
      timeFrameRowsOnly = rightItemsOnly.filter((item) => {
        if (item.hours === 13) {
          return item.minutes === 30;
        }
        if (item.hours === 15) {
          return item.minutes === 0;
        }
        if (item.hours === 16) {
          return item.minutes === 30;
        }

        return false;
      });
    } else if (timeFrame === 120) {
      const rightItemsOnly = items.filter(
        (item) => item.hours === 13 || item.hours === 15 || item.hours === 17,
      );
      timeFrameRowsOnly = rightItemsOnly.filter((item) => {
        if (item.hours === 13) {
          return item.minutes === 0;
        }
        if (item.hours === 15) {
          return item.minutes === 0;
        }
        if (item.hours === 17) {
          return item.minutes === 0;
        }

        return false;
      });
    } else {
      const righttItemsOnly = items.filter((item) => item.hours > 12);
      timeFrameRowsOnly = righttItemsOnly.filter((item) => {
        if (timeFrame === 30) {
          return item.minutes === 0 || item.minutes === 30;
        }

        return item.minutes <= timeFrame;
      });
    }
    const rowspan = getRowspan();

    return timeFrameRowsOnly.map((item) => (
      <TimeTableRow
        id={item.id}
        showHourCell={
          item.minutes === 0 || (timeFrame === 90 && item.minutes === 30)
        }
        rowspan={rowspan}
        key={`${item.hours}-${item.minutes}`}
        item={item}
        items={items}
        setItemsData={setItemsData}
        formattedMinutes={item.minutes.toString().padStart(2, "0")}
      />
    ));
  }, [items, timeFrame, getRowspan, setItemsData]);

  return (
    <LayoutBox direction="column" gap="2x" fullWidth>
      <LayoutBox justify="start" fullWidth direction="column">
        <FormSet
          label="枠テンプレート"
          labelWidth="120px"
          labelSize="large"
          base
        >
          <LayoutBox fullWidth align="center">
            <FormSet
              label="時間単位"
              vertical
              labelSize="small"
              maxWidth="80px"
            >
              <Dropdown
                onChange={(value) => setTimeInterval(value)}
                items={DROPDOWN_ITEMS}
                width="80px"
                value={selectedOption}
              />
            </FormSet>
            <div className="util-mt-24">
              <Text>分に</Text>
            </div>
            <FormSet label="枠数" vertical labelSize="small" maxWidth="80px">
              <Input
                value={manualInput}
                onChange={(value) => setManualInput(value)}
                width="80px"
              />
            </FormSet>
            <div className="util-mt-24">
              <Text>枠</Text>
            </div>
            <div className="util-mt-24">
              <Button
                onClick={handleApplyButtonClick}
                size="medium"
                type="primary"
                width="92px"
              >
                全体に適用
              </Button>
            </div>
          </LayoutBox>
        </FormSet>
      </LayoutBox>
      <LayoutBox fullWidth gap="5x">
        <Table
          type="condensed"
          width="100%"
          fixedLayout
          head={
            <TableRow>
              <TableColumn
                width="172px"
                id="col-0"
                colSpan={2}
                className="time-table-header--no-right-border"
              />
              <TableColumn
                minWidth="96px"
                id="col-1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                月
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-2"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                火
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-3"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                水
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-4"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                木
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-5"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                金
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-6"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                土
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-7"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                日
              </TableColumn>
            </TableRow>
          }
          body={leftTableRows}
        />
        <Table
          type="condensed"
          width="100%"
          fixedLayout
          head={
            <TableRow>
              <TableColumn
                width="172px"
                id="col-0"
                colSpan={2}
                className="time-table-header--no-right-border"
              />
              <TableColumn
                minWidth="96px"
                id="col-1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                月
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-2"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                火
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-3"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                水
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-4"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                木
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-5"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                金
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-6"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                土
              </TableColumn>
              <TableColumn
                minWidth="96px"
                id="col-7"
                stickyRight="1"
                className="time-table-header--no-right-border"
                padding="0 16px"
              >
                日
              </TableColumn>
            </TableRow>
          }
          body={rightTableRows}
        />
      </LayoutBox>
    </LayoutBox>
  );
};

export default TimeTable;
