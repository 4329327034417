import React from "react";
import Button from "../Button/Button";
// changeEmp: ロード中のスピナー
import Spinner from "../Loading/Spinner";
import { useLoadingTable } from "../../hooks/base/useLoadingTable";
import { useLoadingPage } from "../../hooks/base/useLoadingPage";
import { ROLE_KEY } from "../../constants/common";
import {
  useCheckHasRole,
  useCheckMasterRoleFetching,
} from "../../hooks/common/useMasterRoleDbActions";

export type TableProps = {
  type?: "condensed" | "regular" | "relaxed";
  width?: string;
  height?: string;
  fixedLayout?: boolean;
  colGroups?: JSX.Element;
  head: JSX.Element | string;
  body: JSX.Element | JSX.Element[] | string;
  footer?: JSX.Element | string;
  shortcuts?: {
    id: string;
    label: string;
  }[];
  // changeEmp
  roleKey?: ROLE_KEY;
};

const Table = ({
  type = "regular",
  width = "",
  height = "auto",
  fixedLayout = false,
  colGroups,
  head,
  body,
  footer,
  shortcuts = [],
  // changeEmp
  roleKey,
}: TableProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  // changeEmp
  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(roleKey);
  const isFetchingRole = useCheckMasterRoleFetching(roleKey);
  const isShowMessage = (() => {
    if (roleKey) {
      // 読み込み中は表示しない
      if (isFetchingRole) return false;

      // 権限が無い場合表示
      return !hasBrowsingRole;
    }

    return false;
  })();

  const tableClass = React.useMemo(() => {
    const fixedClass = fixedLayout ? [`table--fixed-layout`] : [];
    const typeClass = type ? [`table--${type}`] : [];
    const overflowingClass = isOverflowing ? [`table--overflowing`] : [];

    return ["table", ...fixedClass, ...typeClass, ...overflowingClass].join(
      " ",
    );
  }, [fixedLayout, isOverflowing, type]);

  const checkOverflow = React.useCallback(() => {
    const container = containerRef.current;
    const table = tableRef.current;
    if (container && table) {
      const containerWidth = container.clientWidth;
      const tableWidth = table.clientWidth;
      if (containerWidth < tableWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, []);

  const onResize = React.useCallback(() => {
    checkOverflow();
  }, [checkOverflow]);

  React.useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  const onClickShortcut = React.useCallback((id: string) => {
    const tableEl = document.getElementById(`table-column-${id}`);
    if (tableEl) {
      tableEl.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, []);

  // changeEmp: ロード中のスピナー
  const [{ isLoadingPage }] = useLoadingPage();
  const [isTableLoading] = useLoadingTable();
  // ページ全体のスピナー表示中はテーブルのローダを表示しない
  const showTableLoading = isLoadingPage ? false : isTableLoading;

  return (
    <>
      {shortcuts.length > 0 && (
        <div className="table-shortcuts" style={{ width }}>
          <span> ショートカット </span>
          <span className="table-shortcuts__buttons">
            {shortcuts.map((shortcut) => (
              <Button
                key={`shortcut-${shortcut.id}`}
                icon="shortcut"
                type="sub"
                size="small"
                onClick={() => onClickShortcut(shortcut.id)}
              >
                {shortcut.label}
              </Button>
            ))}
          </span>
        </div>
      )}

      <div
        ref={containerRef}
        className="table-container"
        style={{ width, height }}
      >
        <table ref={tableRef} className={tableClass}>
          {colGroups !== undefined && colGroups}
          <thead>{head}</thead>
          {/* changeEmp: ロード中テーブルを非表示 */}
          {!isTableLoading && <tbody>{body}</tbody>}
        </table>
        {/* changeEmp: ロード中のスピナー */}
        {showTableLoading && (
          <div className="table-spinner">
            <Spinner size="large" />
          </div>
        )}
      </div>
      {/* changeEmp */}
      {isShowMessage && <div className="table-no-role">権限がありません。</div>}
      {footer && (
        <div className="table-footer" style={{ width }}>
          {footer}
        </div>
      )}
    </>
  );
};

Table.defaultProps = {
  type: "regular",
  width: "",
  height: "auto",
  fixedLayout: false,
  colGroups: undefined,
  footer: undefined,
  shortcuts: [],
  // changeEmp
  roleKey: undefined,
};

export default Table;
