import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import ListItem from "../components/List/ListItem";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Divider from "../components/Divider/Divider";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import {
  INSPECT_MRI_STATUS_OPTIONS,
  INSPECT_MRI_RESULTS_OPTIONS,
  MASTER_MANAGEMENT_OPTIONS,
  MriStatusID,
  MriStatusLabels,
  TEST_RESULT_ITEMS,
  USER_EVALUATION,
} from "../constants/common";
import {
  getMriStatusTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
  useHandleTestResultSubTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import Pagination from "../components/Pagination/Pagination";
import useNlc014DbActions from "../hooks/pages/NLC014/useNlc014DbActions";
import TestResultIcon from "../components/TestResultIcon";
import useNlc014Conditions, {
  setProblem,
  setStatus,
} from "../hooks/pages/NLC014/useNlc014Conditions";
import NLC015 from "./NLC015";
import { openNlc015Dialog } from "../hooks/pages/NLC015/useNlc015Dialog";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";

const NLC014 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const [{ problem, status }] = useNlc014Conditions();

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();

  const { id, name, birth, genderStr, tel, email, address, evaluation } =
    convertPatientInfo(patientInfo);

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  const {
    mriTestResult,
    error: fetchError,
    actionError,
    sendResult,
  } = useNlc014DbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
    fetchError,
    actionError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();
  const handleTestResultSubTab = useHandleTestResultSubTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(true)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC005}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <span className="util-font-neutral--light font-component-chip">
                          {id}
                        </span>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          <div className="util-half-width">
                            <LayoutBox align="center">
                              <Label base size="medium" width="120px">
                                ユーザー評価
                              </Label>
                              <LayoutBox>
                                <RadioButtonGroup
                                  name="userRatingRadioButton"
                                  rowItems={USER_EVALUATION}
                                  onChange={handleChangeEvaluation}
                                  selectedValue={selectedValue}
                                  radioGap="16px"
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  住所1
                                </Label>
                                {address}
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <Tabs
                        selectedTab={1}
                        tabs={userDetailTabItems}
                        size="medium"
                        bottomBorder
                        onClick={handleSelectAdminUserDetailTab}
                      />
                      <ToggleButton
                        selectedButton="2"
                        items={TEST_RESULT_ITEMS}
                        onClick={handleTestResultSubTab}
                      />
                      <LayoutBox gap="2x">
                        <div className="admin-user__info-detail--footer-select">
                          <span className="font-component-button-small">
                            結果
                          </span>
                          <Dropdown
                            value={problem.toString()}
                            width="148px"
                            items={INSPECT_MRI_RESULTS_OPTIONS}
                            onChange={setProblem}
                          />
                        </div>
                        <div className="admin-user__info-detail--footer-select">
                          <span className="font-component-button-small">
                            ステータス
                          </span>
                          <Dropdown
                            value={status.toString()}
                            width="148px"
                            items={INSPECT_MRI_STATUS_OPTIONS}
                            onChange={setStatus}
                          />
                        </div>
                      </LayoutBox>
                      <div className="admin-user__inspection-results">
                        <List height="100%">
                          {mriTestResult.map((mriResultInfo) => (
                            <ListItem
                              key={mriResultInfo._id}
                              id={mriResultInfo._id}
                              left={
                                <LayoutBox
                                  direction="column"
                                  fullWidth
                                  justify="center"
                                  align="center"
                                >
                                  <TestResultIcon
                                    status={mriResultInfo.problem}
                                  />
                                </LayoutBox>
                              }
                              center={
                                <>
                                  <LayoutBox direction="column" fullWidth>
                                    <LayoutBox fullWidth direction="row">
                                      <Text size="small" color="neutralLight">
                                        受験日：{mriResultInfo.date}{" "}
                                        {mriResultInfo.hospital}
                                      </Text>
                                      <Tag
                                        label={
                                          MriStatusLabels[
                                            mriResultInfo.tagLabel as keyof typeof MriStatusLabels
                                          ]
                                        }
                                        showIcon={false}
                                        state={getMriStatusTagState(
                                          MriStatusLabels[
                                            mriResultInfo.tagLabel as keyof typeof MriStatusLabels
                                          ],
                                        )}
                                      />
                                    </LayoutBox>
                                    <Text size="large" bold>
                                      MRI検査
                                    </Text>
                                  </LayoutBox>
                                </>
                              }
                              right={
                                <LayoutBox
                                  align="end"
                                  fullWidth
                                  direction="row"
                                >
                                  {mriResultInfo.tagLabel ===
                                    MriStatusID.DONE_DOCTOR_DIAGNOSED && (
                                    <Button
                                      type="primary"
                                      size="medium"
                                      color="neutral"
                                      onClick={() =>
                                        sendResult(mriResultInfo._id)
                                      }
                                    >
                                      結果送信
                                    </Button>
                                  )}
                                  <Button
                                    type="secondary"
                                    size="medium"
                                    color="neutral"
                                    onClick={() =>
                                      openNlc015Dialog(mriResultInfo._id)
                                    }
                                  >
                                    結果
                                  </Button>
                                </LayoutBox>
                              }
                            />
                          ))}
                        </List>
                        <div className="util-mb-16" />
                        {mriTestResult.length === 0 && (
                          <div className="util-py-40">
                            <LayoutBox
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              <Icon icon="inbox" />
                              <div className="util-font-neutral--light">
                                表示する結果がありません
                              </div>
                            </LayoutBox>
                          </div>
                        )}
                        <LayoutBox justify="end" fullWidth>
                          <Pagination input={false} />
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <EventHistories />
        </div>
      </div>
      <NLC015 />
    </>
  );
};

export default NLC014;
