import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProps,
  DropResult,
} from "react-beautiful-dnd";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import Image from "../components/Image/Image";
import DetailsMenu from "../components/Popover/DetailsMenu";
import {
  MASTER_MANAGEMENT_OPTIONS,
  MASTER_ITEMS_SUB_TAB_ITEMS,
  INIT_SEARCH_CRITERIA,
  INIT_PAGE,
  ROLE_KEY,
} from "../constants/common";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectMasterMgmtItemsSubTab,
  handleSelectMasterMgmtSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNli007Conditions from "../hooks/pages/NLI007/useNli007Conditions";
import {
  setPage,
  setPerPage,
} from "../hooks/component/pagination/usePagination";
import useNli007DbActions from "../hooks/pages/NLI007/useNli007DbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertNli007Table } from "../utils/convertDisplay";
import InformationDialog from "../components/Dialog/InformationDialog";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { initProductMgmtId, setItemId } from "../hooks/common/useProductMgmtId";
import NLI008 from "./NLI008";
import { setInitItemInput } from "../hooks/input/useItemInput";
import { openNli008Dialog } from "../hooks/pages/NLI008/useNli008Dialog";
import { setInitImagesUpload } from "../hooks/component/ImageUpload/useImageUpload";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const DETAILS_ITEM_EDIT_ONLY = [
  {
    id: "1",
    icon: "edit",
    label: "商品種別を編集",
    value: "1",
  },
];
const DETAILS_ITEM = [
  ...DETAILS_ITEM_EDIT_ONLY,
  {
    id: "2",
    icon: "delete",
    label: "削除",
    value: "2",
    danger: true,
  },
];

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const NLI007 = () => {
  const [conditions, setConditions] = useNli007Conditions();
  const [items, setItems] = useStateCustomObj<Nli007TableType[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const {
    fetchResult,
    fetchError,
    checkboxItems,
    fetchItemTagsError,
    deleteItem,
    actionError,
    dragAndDropItem,
  } = useNli007DbActions({
    submittedSearchCriteria,
  });
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    fetchError,
    fetchItemTagsError,
    actionError,
  ]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNli007Table(fetchResult);
    setItems(convertResult);
  }, [fetchResult, setItems]);

  useEffect(() => {
    // 初期はチェック有
    const status: Record<string, boolean> = checkboxItems.reduce(
      (acc, itemTag) => {
        acc[itemTag._id] = true;

        return acc;
      },
      {} as Record<string, boolean>,
    );
    setSearchCriteria((prevState) => ({ ...prevState, status }));
    setSubmittedSearchCriteria((prevState) => ({ ...prevState, status }));
  }, [checkboxItems]);

  useEffect(() => {
    // 1ページあたりの表示数の初期値をセット
    setPerPage(20);
  }, []);

  // 商品コード
  const handleChangeCode = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, itemCode: value }));
  };

  // 商品名
  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, itemName: value }));
  };

  // タグチェック
  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 検索ボタン押下
  const handleSearchItems = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () => {
    const { ...restSearchCriteria } = INIT_SEARCH_CRITERIA.NLI007;
    setSearchCriteria((prevState) => ({
      ...prevState,
      ...restSearchCriteria,
    }));
  };

  const handleNewOrderEntry = () => {
    initProductMgmtId();
    setInitItemInput();
    setInitImagesUpload();
    openNli008Dialog();
  };

  // 削除ダイアログopen
  const openDialog = () => {
    setDialogOpen(true);
  };

  // 削除ダイアログclose
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // 3点リーダー押下
  const handleDetailsMenuItemClick = (rowId: string, value: string) => {
    const selectedItem = DETAILS_ITEM.find((item) => item.label === value);
    if (selectedItem) {
      setItemId(rowId);
      if (selectedItem.value === DETAILS_ITEM[0].value) {
        openNli008Dialog();
      } else if (selectedItem.value === DETAILS_ITEM[1].value) {
        // 削除ポップアップ
        openDialog();
      }
    }
  };

  // 削除処理
  const handleDelete = () => {
    deleteItem();
    closeDialog();
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = items.map((item) => {
      if (item.id === userId) {
        return { ...item, checked };
      }

      return item;
    });

    setItems(newUsers);
    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = items.map((item) => ({ ...item, checked }));
    setItems(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => items.some((user) => user.checked === true),
    [items],
  );

  const isCheckAllId = React.useMemo(
    () => items.every((user) => user.checked === true),
    [items],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToProductDetail = (id: string) => {
    setConditions({
      search: submittedSearchCriteria,
    });
    setItemId(id);
    navigate(ADMIN.NLI011);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // アイテムを移動する
    const updatedUsers = [...items];
    const [removed] = updatedUsers.splice(source.index, 1);
    updatedUsers.splice(destination.index, 0, removed);

    // itemsからソートの値を取得して降順でソートする
    const sortedValues = items.map(({ sort }) => sort).sort((a, b) => b - a);

    // ソートされたソートの値を再割り当てする
    const newData = updatedUsers.map((item, index) => ({
      ...item,
      sort: sortedValues[index],
    }));
    setItems(newData);

    const sortAndIdValues = [...newData].map(({ sort, id }) => ({
      sort,
      id,
    }));
    dragAndDropItem(sortAndIdValues);
  };

  // 権限チェック
  const hasAddRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_ADD);
  const hasEditRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_EDIT);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Text size="2xl" bold>
              マスタ管理
            </Text>
            <ToggleButton
              size="large"
              items={MASTER_MANAGEMENT_OPTIONS}
              selectedButton="3"
              onClick={handleSelectMasterMgmtSubTab}
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="start" gap="3x">
                    <FormSet label="コード" labelWidth="96px" base>
                      <Input
                        value={searchCriteria.itemCode}
                        placeholder="コード"
                        width="488px"
                        onChange={handleChangeCode}
                      />
                    </FormSet>
                    <FormSet label="商品名" labelWidth="96px" base>
                      <Input
                        value={searchCriteria.itemName}
                        placeholder="商品名"
                        width="488px"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet label="">{}</FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" justify="start" gap="3x">
                    <FormSet label="タグ" labelWidth="96px" base>
                      <LayoutBox align="center" justify="start" gap="2x">
                        {checkboxItems.map((item) => (
                          <Checkbox
                            key={item._id}
                            label={item.tag_name}
                            checked={
                              !!(
                                searchCriteria.status &&
                                searchCriteria.status[item._id]
                              )
                            }
                            onChecked={(checked) =>
                              handleChangeCheckItem(checked, item._id)
                            }
                          />
                        ))}
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchItems}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox align="center" justify="between" fullWidth>
                  <Tabs
                    selectedTab={0}
                    tabs={MASTER_ITEMS_SUB_TAB_ITEMS}
                    size="medium"
                    bottomBorder
                    onClick={handleSelectMasterMgmtItemsSubTab}
                  />
                  <Button
                    type="secondary"
                    size="medium"
                    icon="add"
                    width="140px"
                    onClick={handleNewOrderEntry}
                    disabled={!hasAddRole}
                  >
                    商品を新規追加
                  </Button>
                </LayoutBox>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef}>
                        <Table
                          type="relaxed"
                          width="100%"
                          roleKey={ROLE_KEY.MASTER_PRODUCT_BROWSING}
                          head={
                            <TableRow>
                              <TableColumn width="40px" id="col-1" />
                              <TableColumn width="267px" id="col-2">
                                <LayoutBox align="center" justify="start">
                                  <Checkbox
                                    checked={isCheckAllUserIds}
                                    label="コード"
                                    indeterminate={isIndeterminate}
                                    onChecked={(checked) =>
                                      handleCheckIsAllUserIds(checked)
                                    }
                                  />
                                </LayoutBox>
                              </TableColumn>
                              <TableColumn width="115px" id="col-3" />
                              <TableColumn width="593px" id="col-4">
                                商品名
                              </TableColumn>
                              <TableColumn width="593px" id="col-5">
                                タグ
                              </TableColumn>
                              <TableColumn
                                width="72px"
                                textAlign="center"
                                id="col-6"
                              >
                                表示
                              </TableColumn>
                              <TableColumn width="72px" id="col-7" />
                              <TableColumn width="72px" id="col-8" />
                            </TableRow>
                          }
                          body={
                            <>
                              {items.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <TableRow
                                      key={item.id}
                                      isSelected={item.checked}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <TableCell
                                        textAlign="center"
                                        {...provided.dragHandleProps}
                                      >
                                        <div className="util-mt-4">
                                          <Icon icon="drag_handle" />
                                        </div>
                                      </TableCell>

                                      <TableCell>
                                        <Checkbox
                                          label={item.itemCode}
                                          checked={item.checked}
                                          onChecked={(checked) =>
                                            handleCheckUserId(checked, item.id)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Image url={item.image} />
                                      </TableCell>
                                      <TableCell>{item.itemName}</TableCell>
                                      <TableCell>
                                        {item.itemTagsInfo.map((itemTag) => (
                                          <Tag
                                            key={itemTag._id}
                                            label={itemTag.tag_name}
                                            showIcon={false}
                                            state="default"
                                          />
                                        ))}
                                      </TableCell>
                                      <TableCell textAlign="center">
                                        {item.status && <Icon icon="check" />}
                                      </TableCell>
                                      <TableCell textAlign="center">
                                        <Button
                                          type="sub"
                                          size="small"
                                          width="38px"
                                          onClick={() =>
                                            navigateToProductDetail(item.id)
                                          }
                                        >
                                          詳細
                                        </Button>
                                      </TableCell>
                                      <TableCell textAlign="center">
                                        {hasEditRole && (
                                          <DetailsMenu
                                            items={
                                              // 商品種別未設定 かつ SKUが1件でもあった場合削除ボタンを非表示
                                              !item.itemCode &&
                                              item.skuCount > 0
                                                ? DETAILS_ITEM_EDIT_ONLY
                                                : DETAILS_ITEM
                                            }
                                            width={141}
                                            onClick={(value: string) => {
                                              handleDetailsMenuItemClick(
                                                item.id,
                                                value,
                                              );
                                              setIsOpen(false);
                                            }}
                                          />
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </>
                          }
                        />
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
                <div className="util-full-width">
                  <LayoutBox justify="end">
                    <Pagination input={false} />
                  </LayoutBox>
                </div>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {/* 新規追加・編集ダイアログ */}
      <NLI008 />
      {/* 削除確認ダイアログ */}
      <InformationDialog
        alertLevel="error"
        open={dialogOpen}
        closeDialog={closeDialog}
        title="削除してもよろしいですか？"
        footer={
          <LayoutBox>
            <Button color="danger" size="medium" onClick={handleDelete}>
              削除
            </Button>
            <Button
              color="neutral"
              type="sub"
              size="medium"
              onClick={closeDialog}
            >
              キャンセル
            </Button>
          </LayoutBox>
        }
      >
        この操作は取り消せません。
      </InformationDialog>
    </>
  );
};

export default NLI007;
