import { useState, useEffect } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
  SalesCategoryID,
  SalesStatusID,
} from "../../../constants/common";
import { getMongoDb, getAggregateNld004 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nld004FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLD004;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLD004;
};

// NLD004画面のデータ取得処理
const useNld004DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nld004FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    unpaidUserCount: number;
    result: PatientNld004StateType[];
    error: Error | null;
  }>({
    unpaidUserCount: 0,
    result: [],
    error: null,
  });

  // 検索条件をquery用に整形
  const { id } = submittedSearchCriteria;
  const name = convertSearchName(submittedSearchCriteria.name);
  const tel = submittedSearchCriteria.phoneNum;

  // 検索のチェック状態をquery用に整形
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLD004,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合はIDの昇順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "patient_id", sortDirection = "asc" } = currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.SALES);
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNld004(
          id,
          name,
          tel,
          memoStatusArr,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        // mongoDBのデータ
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNld004StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        // 未払いのユーザの数
        const unpaidUserCount = await mongoDb.count({
          category: SalesCategoryID.INTERVIEW,
          status: SalesStatusID.UNPAID,
        });

        setTotal(total);
        setErrorAndResult({ unpaidUserCount, result, error: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({
          unpaidUserCount: 0,
          result: [],
          error: checkFetchErr(err),
        });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    colName,
    currentUser,
    id,
    memoStatusArr,
    name,
    perPage,
    skip,
    sortDirection,
    tel,
  ]);

  return errorAndResult;
};

export default useNld004DbActions;
