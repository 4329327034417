import { TimeTableItem } from "../../../components/Table/TimeTableRow";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  mriTimeItems: TimeTableItem[];
  petTimeItems: TimeTableItem[];
  mriTimeInterval: string;
  petTimeInterval: string;
  mriTimeFrame: number;
  petTimeFrame: number;
  originData: {
    mriTimeItems: TimeTableItem[];
    petTimeItems: TimeTableItem[];
    mriTimeInterval: string;
    petTimeInterval: string;
    mriTimeFrame: number;
    petTimeFrame: number;
  };
  inspectionType: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  mriTimeItems: [],
  petTimeItems: [],
  mriTimeInterval: "45",
  petTimeInterval: "45",
  mriTimeFrame: 45,
  petTimeFrame: 45,
  originData: {
    mriTimeItems: [],
    petTimeItems: [],
    mriTimeInterval: "45",
    petTimeInterval: "45",
    mriTimeFrame: 45,
    petTimeFrame: 45,
  },
  inspectionType: "1",
});

const useMie001Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

// MRI枠更新用関数
export const setMriTimeItems = (value: TimeTableItem[]) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, mriTimeItems: value });
};

// PET枠更新用関数
export const setPetTimeItems = (value: TimeTableItem[]) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, petTimeItems: value });
};

// MRI時間単位更新用関数
export const setMriTimeInterval = (value: string) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, mriTimeInterval: value });
};

// PET時間単位更新用関数
export const setPetTimeInterval = (value: string) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, petTimeInterval: value });
};

// MRI timeFrame更新用関数
export const setMriTimeFrame = (value: number) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, mriTimeFrame: value });
};

// PET timeFrame更新用関数
export const setPetTimeFrame = (value: number) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, petTimeFrame: value });
};

export default useMie001Conditions;
