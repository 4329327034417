import React from "react";
import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import InformationDialog from "../components/Dialog/InformationDialog";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Toast from "../components/Toast/Toast";
import useNlc026DbActions from "../hooks/pages/NLC026/useNlc026DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";

type NLC026Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  name: string;
  genderStr: string;
  birth: string;
  tel: string;
  email: string;
  address: string;
  plan: string;
};

const NLC026: React.FC<NLC026Props> = ({
  isOpen,
  setIsOpen,
  name,
  genderStr,
  birth,
  tel,
  email,
  address,
  plan,
}) => {
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const { handleCancellation, actionError } = useNlc026DbActions();
  const navigate = useNavigateCustom();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  // トーストクローズ
  const closeToast = React.useCallback(() => {
    setIsToastOpen(false);
    setIsOpen(false);
    // ユーザ一覧画面に遷移
    navigate(ADMIN.NLC001);
  }, [setIsOpen, navigate]);

  // 解約ボタン
  const handleCancellationButton = React.useCallback(() => {
    handleCancellation(setIsToastOpen, setIsOpen);
  }, [handleCancellation, setIsToastOpen, setIsOpen]);

  // 閉じる、キャンセルボタン
  const handleCloseButton = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen],
  );

  return (
    <>
      <InformationDialog
        open={isOpen}
        width="464px"
        height="458px"
        title="解約してもよろしいですか？"
        closeDialog={handleCloseButton}
        alertLevel="error"
        footer={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                width="50px"
                color="danger"
                onClick={handleCancellationButton}
              >
                解約
              </Button>
              <Button
                type="sub"
                width="88px"
                color="neutral"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox direction="column" gap="2x" fullHeight fullWidth>
          <LayoutBox gap="none" direction="column" fullWidth fullHeight>
            <Text size="base">解約すると下記データが削除されます</Text>
            <Text size="base">===</Text>
            <Text size="base">■ユーザー情報</Text>
            <Text size="base">・お名前:{name}</Text>
            <Text size="base">・性別:{genderStr}</Text>
            <Text size="base">・生年月日:{birth}</Text>
            <Text size="base">・電話番号:{tel}</Text>
            <Text size="base">・メールアドレス:{email}</Text>
            <Text size="base">・住所:{address}</Text>
            <Text size="base">・プラン:{plan}</Text>
          </LayoutBox>
          <LayoutBox gap="none" direction="column" fullWidth fullHeight>
            <Text size="base">■各種テスト・検査結果</Text>
            <Text size="base">■チャット履歴</Text>
          </LayoutBox>
        </LayoutBox>
      </InformationDialog>
      <Toast open={isToastOpen} onClose={closeToast} displayTime={2000}>
        ユーザーの解約が完了しました
      </Toast>
    </>
  );
};

export default NLC026;
