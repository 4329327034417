import { MriProblemID, MriStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  modalityId: string;
} & PatientNlc015StateType;

const INIT_TEST_DATA: PatientNlc015StateType = {
  status: MriStatusID.GUIDING,
  date: "",
  doctor: "",
  doctorReview: "",
  doctorNote: "",
  problem: MriProblemID.NO_PROBLEM,
  images: [],
  // 2次以降フェーズ対応: 読影
};
// MRI結果画面のidと入力
const stateReactiveVar = createReactiveVar<ConditionsType>({
  modalityId: "",
  ...INIT_TEST_DATA,
});
const useNlc015Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setMriResultId = (modalityId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...INIT_TEST_DATA,
    modalityId,
  });
};

export const setNlc15Data = (data: PatientNlc015StateType) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...data,
  });
};

export default useNlc015Conditions;
