import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Dropdown from "../components/Dropdown/Dropdown";
import DialogCustom from "../components/Dialog/DialogCustom";
import useUserEditInput, {
  checkUserEditInputError,
  setCity,
  setEmail,
  setGender,
  setNameMei,
  setNameMeiKana,
  setNameSei,
  setNameSeiKana,
  setPref,
  setTel,
  setTown1,
  setTown2,
  setUserEditInput,
  setZipcode,
} from "../hooks/input/useUserEditInput";
import { convertPatientInfo } from "../utils/convertDisplay";
import {
  BIRTH_YEAR_OPTION,
  DAY_OPTIONS,
  GENDER_ITEMS,
  MONTH_OPTIONS,
  PREFECTURE_OPTIONS,
} from "../constants/common";
import useNlc025DbActions from "../hooks/pages/NLC025/useNlc025DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";

type NLC025Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  patientInfo: PatientsType | null;
};

const NLC025: React.FC<NLC025Props> = ({ isOpen, setIsOpen, patientInfo }) => {
  const { handleUpdateUserInfo, actionError } = useNlc025DbActions();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      tel,
      email,
      zipcode,
      pref,
      city,
      town1,
      town2,
      birthYear,
      birthMonth,
      birthDay,
      gender,
    },
  ] = useUserEditInput();

  useEffect(() => {
    const {
      genderStr,
      tel,
      email,
      zipcode,
      pref,
      city,
      town1,
      town2,
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      birthYear,
      birthMonth,
      birthDay,
    } = convertPatientInfo(patientInfo);
    setUserEditInput({
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      tel,
      email,
      zipcode,
      pref,
      city,
      town1,
      town2,
      birthYear,
      birthMonth,
      birthDay,
      gender: genderStr,
    });
  }, [patientInfo]);

  // 保存ボタン押下
  const handleUpdateButton = () => {
    handleUpdateUserInfo(setIsOpen);
  };

  return (
    <>
      <DialogCustom
        open={isOpen}
        closeDialog={() => setIsOpen(false)}
        size="large"
        title="ユーザー情報を編集"
        height="864px"
        footerRight={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={() => setIsOpen(false)}
              >
                キャンセル
              </Button>
              <Button
                type="primary"
                size="large"
                width="96px"
                disabled={checkUserEditInputError()}
                onClick={handleUpdateButton}
              >
                保存
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox direction="column">
          <FormSet label="お名前" base formVertical={false}>
            <LayoutBox direction="column">
              <LayoutBox>
                <FormSet
                  label="姓"
                  vertical
                  errorTextList={[nameSei.validationMessage]}
                >
                  <Input
                    value={nameSei.value}
                    width="132px"
                    onChange={(value) => setNameSei(value)}
                    error={nameSei.isError}
                  />
                </FormSet>
                <FormSet
                  label="名"
                  vertical
                  errorTextList={[nameMei.validationMessage]}
                >
                  <Input
                    value={nameMei.value}
                    width="132px"
                    onChange={(value) => setNameMei(value)}
                    error={nameMei.isError}
                  />
                </FormSet>
              </LayoutBox>
              <LayoutBox>
                <FormSet
                  label="セイ"
                  vertical
                  errorTextList={[nameSeiKana.validationMessage]}
                >
                  <Input
                    value={nameSeiKana.value}
                    width="132px"
                    onChange={(value) => setNameSeiKana(value)}
                    error={nameSeiKana.isError}
                  />
                </FormSet>
                <FormSet
                  label="メイ"
                  vertical
                  errorTextList={[nameMeiKana.validationMessage]}
                >
                  <Input
                    value={nameMeiKana.value}
                    width="132px"
                    onChange={(value) => setNameMeiKana(value)}
                    error={nameMeiKana.isError}
                  />
                </FormSet>
              </LayoutBox>
            </LayoutBox>
          </FormSet>
          <FormSet label="性別" base formVertical={false}>
            <LayoutBox>
              <RadioButtonGroup
                name="userRatingRadioButton"
                rowItems={GENDER_ITEMS}
                onChange={(value) => setGender(value)}
                selectedValue={gender}
                radioGap="16px"
              />
            </LayoutBox>
          </FormSet>
          <FormSet label="生年月日" base formVertical={false}>
            <LayoutBox>
              <LayoutBox align="center">
                <Dropdown
                  value={birthYear}
                  width="87px"
                  items={BIRTH_YEAR_OPTION}
                />
                年
              </LayoutBox>
              <LayoutBox align="center">
                <Dropdown
                  value={birthMonth}
                  width="74px"
                  items={MONTH_OPTIONS}
                />
                月
              </LayoutBox>
              <LayoutBox align="center">
                <Dropdown value={birthDay} width="74px" items={DAY_OPTIONS} />日
              </LayoutBox>
            </LayoutBox>
          </FormSet>
          <FormSet
            label="電話番号"
            base
            formVertical={false}
            errorTextList={[tel.validationMessage]}
          >
            <Input
              value={tel.value}
              width="180px"
              onChange={(value) => setTel(value)}
              error={tel.isError}
            />
          </FormSet>
          <FormSet
            label="メールアドレス"
            base
            formVertical={false}
            errorTextList={[email.validationMessage]}
          >
            <Input
              value={email.value}
              width="317px"
              onChange={(value) => setEmail(value)}
              error={email.isError}
            />
          </FormSet>
          <FormSet label="住所" base formVertical={false}>
            <LayoutBox direction="column" fullWidth>
              <FormSet
                label="郵便番号"
                vertical
                errorTextList={[zipcode.validationMessage]}
              >
                <Input
                  value={zipcode.value}
                  width="136px"
                  onChange={(value) => setZipcode(value)}
                  error={zipcode.isError}
                />
              </FormSet>
              <FormSet label="都道府県" vertical>
                <Dropdown
                  value={pref}
                  width="136px"
                  items={PREFECTURE_OPTIONS}
                  onChange={(value) => setPref(value)}
                />
              </FormSet>
              <FormSet
                label="市区町村"
                vertical
                errorTextList={[city.validationMessage]}
              >
                <Input
                  value={city.value}
                  width="373px"
                  onChange={(value) => setCity(value)}
                  error={city.isError}
                />
              </FormSet>
              <FormSet
                label="町域・番地"
                vertical
                errorTextList={[town1.validationMessage]}
              >
                <Input
                  value={town1.value}
                  width="373px"
                  onChange={(value) => setTown1(value)}
                  error={town1.isError}
                />
              </FormSet>
              <FormSet label="建物名など" vertical>
                <Input
                  value={town2}
                  width="373px"
                  onChange={(value) => setTown2(value)}
                />
              </FormSet>
            </LayoutBox>
          </FormSet>
        </LayoutBox>
      </DialogCustom>
    </>
  );
};

export default NLC025;
