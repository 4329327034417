import React from "react";
import ToggleButtonItem from "./ToggleButtonItem";

export type ToggleButtonProps = {
  selectedButton?: string | number;
  type?: "regular" | "negative";
  size?: "small" | "medium" | "large";
  width?: string;
  items: {
    id: string;
    label: string;
  }[];
  // changeEmp
  onClick?: (value: string) => void | (() => void);
};

const ToggleButton = ({
  selectedButton,
  type = "regular",
  size = "medium",
  width,
  items,
  // changeEmp
  onClick = () => {},
}: ToggleButtonProps) => {
  const [selectedId, setSelectedId] = React.useState(selectedButton);

  // changeEmp
  const clickEvent = (id: string) => {
    // 親で設定したクリックイベントにidを渡す
    onClick(id);
    setSelectedId(id);
  };

  const toggleButtonSetClass = React.useMemo(() => {
    const typeClass = [`toggle-button-set--${type}`];

    return ["toggle-button-set", ...typeClass].join(" ");
  }, [type]);

  return (
    <div className={toggleButtonSetClass} style={{ width }}>
      {items.map(({ label, ...item }) => {
        const isSelected = selectedId === item.id;

        return (
          <ToggleButtonItem
            {...item}
            isSelected={isSelected}
            type={type}
            size={size}
            // changeEmp
            onClick={clickEvent}
            key={item.id}
          >
            {label}
          </ToggleButtonItem>
        );
      })}
    </div>
  );
};

ToggleButton.defaultProps = {
  selectedButton: "1",
  type: "regular",
  size: "medium",
  width: "",
  // changeEmp
  onClick: () => {},
};

export default ToggleButton;
