import { ADMIN, HOSPITAL } from "./pagePaths";

export enum ResponseStatusCode {
  OK = 200,
  NG = 400,
  ACCEPT = 202,
  CREATED = 201,
}

export enum ErrorCode {
  NORMAL = 0,
  BAD_REQUEST = 1,
  NOT_UPDATED = 2,
}

export enum Collection {
  ADMINS = "admins",
  CAMPAIGNS = "campaigns",
  HOSPITAL_USERS = "hospital_users",
  HOSPITALS = "hospitals",
  ITEM_TAGS = "item_tags",
  ITEMS = "items",
  NOTIFICATIONS = "notifications",
  ORDERS = "orders",
  PATIENT_BRAINSUITES = "patient_brainsuites",
  PATIENT_INTERVIEWS = "patient_interviews",
  PATIENT_NOUKNOWS = "patient_nouknows",
  PATIENTS = "patients",
  SALES = "sales",
  SKUS = "skus",
  SEQUENCE = "sequence",
  PATIENT_MODALITY_BOOKS = "patient_modality_books",
  OPTION_CONFIGS = "option_configs",
  OPTION_CONTENTS = "option_contents",
  USERS = "users",
  EVENT_HISTORIES = "event_histories",
  MASTER_ROLES = "master_roles",
  SETTINGS = "settings",
}

export const INIT_PAGE = 1;
export const INIT_PER_PAGE = 10;

// ソート可能なカラム情報初期値
export const SORT_EXAMPLES = {
  NLC001: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-3", sortDirection: "", colName: "full_name" },
    // TODO: Backlog No108申し込み日付ソート
    { id: "col-6", sortDirection: "", colName: "full_name" },
  ],
  NLC002: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    { id: "col-7", sortDirection: "", colName: "created_at" },
  ],
  NLC003: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    { id: "col-7", sortDirection: "", colName: "insuranse_entry_date" },
  ],
  NLC004: [
    { id: "col-1", sortDirection: "", colName: "_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_nouknow_info.finished_at",
    },
    {
      id: "col-5",
      sortDirection: "",
      colName: "patient_nouknow_info.bpi1_rank",
    },
    { id: "col-6", sortDirection: "", colName: "patient_nouknow_info.status" },
  ],
  NLC005: [
    { id: "col-1", sortDirection: "", colName: "_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_modality_book_info.mri_status",
    },
    {
      id: "col-6",
      sortDirection: "",
      colName: "patient_modality_book_info.fix_book_date",
    },
  ],
  NLC007: [
    { id: "col-1", sortDirection: "", colName: "_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "interviews_info.interview_fix_date",
    },
  ],
  NLD001: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    // 2次以降フェーズ対応: No162 ステータスはまだ連携されていないので連携され次第対応
    { id: "col-3", sortDirection: "", colName: "status" },
    { id: "col-4", sortDirection: "", colName: "status" },
    { id: "col-7", sortDirection: "", colName: "status" },
  ],
  NLD002: [
    { id: "col-1", sortDirection: "", colName: "hospital_info.hospital_name" },
    { id: "col-2", sortDirection: "", colName: "user_info.full_name" },
    { id: "col-3", sortDirection: "", colName: "inspection_info.status" },
  ],
  NLD003: [
    { id: "col-3", sortDirection: "", colName: "inspection_info.payment_date" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "inspection_info.execution_date",
    },
  ],
  NLD004: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.full_name" },
    { id: "col-3", sortDirection: "", colName: "status" },
  ],
  NLF001: [
    { id: "col-2", sortDirection: "", colName: "patient_name" },
    { id: "col-3", sortDirection: "", colName: "status" },
    { id: "col-6", sortDirection: "", colName: "settlement_time" },
    { id: "col-7", sortDirection: "", colName: "delivery_method" },
  ],
  NLG001: [
    { id: "col-2", sortDirection: "", colName: "status" },
    { id: "col-3", sortDirection: "", colName: "deliveried_at" },
    { id: "col-4", sortDirection: "", colName: "last_updated_at" },
    // TODO: 更新者のソートってどうする？QA中 更新者のusersの_id
    { id: "col-5", sortDirection: "", colName: "changer" },
  ],
  NLI017: [
    { id: "col-1", sortDirection: "", colName: "_id" },
    { id: "col-2", sortDirection: "", colName: "doctor_name" },
  ],
  MIB001: [
    { id: "col-1", sortDirection: "", colName: "patient_name" },
    { id: "col-2", sortDirection: "", colName: "modality" },
    { id: "col-4", sortDirection: "", colName: "created_at" },
    { id: "col-5", sortDirection: "", colName: "status" },
  ],
  MIC001: [
    { id: "col-2", sortDirection: "", colName: "status" },
    { id: "col-3", sortDirection: "", colName: "fix_book_date" },
  ],
  MID001: [
    { id: "col-1", sortDirection: "", colName: "id" },
    { id: "col-2", sortDirection: "", colName: "status" },
    { id: "col-3", sortDirection: "", colName: "patient_name" },
  ],
};
type Status = {
  [key: string]: boolean;
};
export const INIT_SEARCH_CRITERIA = {
  NLB001: {
    number: {
      selectedToggle: "1",
      searchDay: new Date(),
    },
    user: {
      selectedMembershipToggle: "3",
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      day: new Date(),
    },
    // 再生回数無し
    // yose: {
    //   selectedYoseToggle: "3",
    //   year: new Date().getFullYear().toString(),
    //   month: (new Date().getMonth() + 1).toString(),
    //   day: new Date(),
    // },
    appointment: {
      selectedIndex: 0,
      selectedAppointmentToggle: "3",
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      day: new Date(),
    },
    task: {
      category: "1",
    },
  },
  NLC001: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isActive: true,
      isNoCoveredInsurance: true,
    },
  },
  // 検索欄入力情報初期値 NLC003でも使用可
  NLC002: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isUnsupported: true,
      isCompleted: true,
      isNG: true,
    },
  },
  NLC004: {
    id: "",
    name: "",
    phoneNum: "",
    reportStatus: {
      isCompleted: false,
      isUploaded: true,
      isConfirmed: true,
      isCommented: true,
      isReported: true,
      isReportedAndCommented: true,
    } as Status,
  },
  NLC005: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      hasProblem: true,
      hasNoProblem: true,
      isReserved: true,
      hasReservationConfirmed: true,
      isCompletedImageNotLinked: true,
      isCompletedUnderAIDiagnosis: true,
      isCompletedAIDiagnose: true,
      isCompletedDocDiagnosed: true,
      isUserSupported: true,
      withdrawal: false,
    } as Status,
  },
  NLC007: {
    id: "",
    name: "",
    phoneNum: "",
    manager: "すべて",
    status: {
      isNewReservation: true,
      isConfirmedReservation: true,
      isSettled: true,
    } as Status,
  },
  NLD001: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isActive: true,
      isNoCoveredInsurance: true,
      hasMRIExam: true,
      hasFDGPETScan: true,
    },
  },
  NLD002: {
    hospitalName: "",
    name: "",
    phoneNum: "",
    status: {
      isUnpaid: true,
      isPaid: true,
    },
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
  },
  NLD003: {
    name: "",
    status: {
      isUnpaid: true,
      isPaid: true,
    },
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
  },
  NLD004: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isUnpaid: true,
      isPaid: true,
    },
  },
  NLF001: {
    id: "",
    patientName: "",
    orderYear: "",
    orderMonth: "",
    status: {
      isPaymentCompleted: true,
      isShipmentCompleted: true,
      isDeliveryCompleted: true,
    },
    deliveryMethod: {
      isNormalDelivery: true,
      isDateSpecifiedDelivery: true,
    },
    bottomTabId: "1",
  },
  NLF006: {
    salesYear: "",
    salesMonth: "",
  },
  NLG001: {
    title: "",
    deliveryStartDate: undefined as Date | undefined,
    deliveryEndDate: undefined as Date | undefined,
    lastUpdatedStartDate: undefined as Date | undefined,
    lastUpdatedEndDate: undefined as Date | undefined,
    lastUpdatedAdminId: "",
    status: {
      isUnpublished: true,
      isNowOpen: true,
    },
    forceUpdateCount: 0,
  },
  NLI003: {
    search: "",
  },
  NLI007: {
    itemCode: "",
    itemName: "",
  },
  NLI009: {
    id: "",
    name: "",
    skuName: "",
  },
  NLI016: {
    search: "",
  },
  NLI017: {
    id: "",
    hospitalUserId: "",
    doctorName: "",
    email: "",
    role: {
      isAdmin: true,
      isGeneral: true,
    },
  },
  MIB001: {
    patientName: "",
    tel: "",
    applicationDate: undefined as Date | undefined,
    modality: {
      isMRI: true,
      isPET: true,
    },
    status: {
      isNew: true,
      isConfirm: true,
      isNg: true,
      isApplicationForWithdrawal: true,
      isWithdrawal: true,
    },
  },
  MIC001: {
    fixBookDate: undefined as Date | undefined,
    status: {
      isNew: true,
      isConfirm: true,
      isNg: true,
      isApplicationForWithdrawal: true,
      isWithdrawal: true,
    },
  },
  MID001: {
    id: "",
    patientName: "",
    tel: "",
    status: {
      isNotInspected: true,
      isInspected: true,
    },
  },
};

type LabelObjectType = {
  [key: string]: string;
};
type IdType = number | string;
// 各種IDと表示文言を作成 チェックボックス セレクトボックスで使用可能
const _createIdAndLabel = <T extends { [key in K]: IdType }, K extends keyof T>(
  key: K,
  idObject: T,
  labelObject: LabelObjectType,
  flgKey = "",
) => {
  const id = idObject[key];
  const value = id.toString();
  const label = labelObject[id.toString()];

  return { id, label, value, flgKey };
};

// 2次以降フェーズ対応: No150 お客さんに正式なstatusIdを聞く
export enum PatientStatusID {
  // 仮登録
  UNSUPPORTED,
  // 会員登録
  REGISTERED,
  // 保険審査中
  // 保険審査通過
  // 保険審査謝絶
  NG,
}

export enum PatientNouKnowStatusID {
  PRETEST,
  COMPLETED,
  UPLOADED,
  CONFIRMED,
  COMMENTED,
  USER_REPORTED,
  USER_REPORTED_DR_COMMENTED,
  ALL = 999,
}
export const PatientNouKnowStatusLabels = {
  [PatientNouKnowStatusID.PRETEST]: "テスト未実施",
  [PatientNouKnowStatusID.COMPLETED]: "テスト終了",
  [PatientNouKnowStatusID.CONFIRMED]: "医師確認済",
  [PatientNouKnowStatusID.COMMENTED]: "医師コメント済",
  [PatientNouKnowStatusID.USER_REPORTED]: "ユーザー報告済",
  [PatientNouKnowStatusID.USER_REPORTED_DR_COMMENTED]:
    "ユーザー報告済・医師コメント有",
  [PatientNouKnowStatusID.ALL]: "すべて",
};
const Nlc004NouKnowStatus: {
  key: keyof typeof PatientNouKnowStatusID;
  flag: string;
}[] = [
  { key: "COMPLETED", flag: "isCompleted" },
  { key: "CONFIRMED", flag: "isConfirmed" },
  { key: "COMMENTED", flag: "isCommented" },
  { key: "USER_REPORTED", flag: "isReported" },
  { key: "USER_REPORTED_DR_COMMENTED", flag: "isReportedAndCommented" },
];
const InspectNouKnowStatusArr: (keyof typeof PatientNouKnowStatusID)[] = [
  "ALL",
  "PRETEST",
  "COMPLETED",
  "CONFIRMED",
  "COMMENTED",
  "USER_REPORTED",
  "USER_REPORTED_DR_COMMENTED",
];

export enum PatientInterviewStatusID {
  NEW_RESERVATION,
  CONFIRMED_RESERVATION,
  SETTLED,
}
export const InterviewStatusLabels = {
  [PatientInterviewStatusID.NEW_RESERVATION]: "新規予約",
  [PatientInterviewStatusID.CONFIRMED_RESERVATION]: "面談確定",
  [PatientInterviewStatusID.SETTLED]: "面談済",
};
const Nlc007PatientInterviewStatus: {
  key: keyof typeof PatientInterviewStatusID;
  flag: string;
}[] = [
  { key: "NEW_RESERVATION", flag: "isNewReservation" },
  { key: "CONFIRMED_RESERVATION", flag: "isConfirmedReservation" },
  { key: "SETTLED", flag: "isSettled" },
];

export enum NotificationStatusID {
  UNPUBLISHED,
  NOW_OPEN,
  DELETED,
}

export enum CampaignsStatusID {
  UNPUBLISHED,
  NOW_OPEN,
}

export enum EmbeddedPageID {
  MY_PAGE,
  YOSE_LIST,
  YOSE_DETAILS,
  EC_LIST,
  EC_DETAILS,
}

export enum EmbeddedLocationID {
  TOP,
  FOOTER,
}

export enum HospitalRoleID {
  GENERAL,
  ADMIN,
}
export enum OrderStatusID {
  CART_KEPT,
  ORDER_PENDING_PAYMENT_OR_AUTH,
  PAYMENT_COMPLETED,
  SHIPPED,
  DELIVERY_COMPLETED,
  CANCELLED = 9,
}
export enum DeliveryMethodID {
  NORMAL = 1,
  SPECIFIED = 2,
}
export enum ItemsSkusStatusID {
  HIDDEN,
  VISIBLE,
  DELETED,
}
export enum OptionConfigsStatusID {
  HIDDEN,
  VISIBLE,
}

export enum AdminsRoleID {
  ADMIN = 1,
  CMC = 2,
  PUBLIC_USER = 3,
}
export enum ModalityID {
  MRI,
  PET,
}
export enum HospitalReservationStatusID {
  NEW,
  CONFIRM,
  NG,
  APPLICATION_FOR_WITHDRAWAL,
  WITHDRAWAL,
}
export enum HospitalInspectionStatusID {
  NOT_INSPECTED,
  INSPECTED,
}
export enum EventHistoryStatusID {
  DELETED,
  VALID,
}

export enum ModalityStatusID {
  NEW_RESERVATION,
  CONFIRMED_RESERVATION,
  NG,
  WITHDRAWAL_REQUEST,
  WITHDRAWAL,
}
export const ModalityStatusLabels = {
  [ModalityStatusID.NEW_RESERVATION]: "新規予約",
  [ModalityStatusID.CONFIRMED_RESERVATION]: "予約確定",
  [ModalityStatusID.NG]: "NG",
  [ModalityStatusID.WITHDRAWAL_REQUEST]: "予約取り下げ申請",
  [ModalityStatusID.WITHDRAWAL]: "予約取り下げ",
};

export enum PetStatusID {
  NON_TARGET = 0,
  GUIDING = 1,
  RESERVED = 2,
  RESERVATION_CONFIRMED = 3,
  EXAMINED = 4,
  DIAGNOSED = 5,
  WITHDRAWN_AFTER_CONFIRMATION = 6,
  NG_BEFORE_CONFIRMATION = 7,
  ALL = 999,
}
export const PetStatusLabels = {
  [PetStatusID.NON_TARGET]: "対象外",
  [PetStatusID.GUIDING]: "案内中",
  [PetStatusID.RESERVED]: "予約済",
  [PetStatusID.RESERVATION_CONFIRMED]: "予約確定",
  [PetStatusID.EXAMINED]: "検査済",
  [PetStatusID.DIAGNOSED]: "診断済",
  [PetStatusID.WITHDRAWN_AFTER_CONFIRMATION]: "予約取り下げ",
  [PetStatusID.NG_BEFORE_CONFIRMATION]: "NG",
  [PetStatusID.ALL]: "すべて",
};
const InspectPetStatusArr: (keyof typeof PetStatusID)[] = [
  "ALL",
  "NON_TARGET",
  "GUIDING",
  "RESERVED",
  "RESERVATION_CONFIRMED",
  "EXAMINED",
  "DIAGNOSED",
  "WITHDRAWN_AFTER_CONFIRMATION",
  "NG_BEFORE_CONFIRMATION",
];

export enum MriStatusID {
  NON_TARGET = 0,
  GUIDING = 1,
  RESERVED = 2,
  RESERVATION_CONFIRMED = 3,
  DONE_IMAGE_NOT_LINKED = 4,
  DONE_AI_DIAGNOSING = 5,
  DONE_AI_DIAGNOSED = 6,
  DONE_DOCTOR_DIAGNOSED = 7,
  USER_REPORTED = 8,
  WITHDRAWN_AFTER_CONFIRMATION = 9,
  NG_BEFORE_CONFIRMATION = 10,
  ALL = 999,
}
export const MriStatusLabels = {
  [MriStatusID.NON_TARGET]: "対象外",
  [MriStatusID.GUIDING]: "案内中",
  [MriStatusID.RESERVED]: "予約済",
  [MriStatusID.RESERVATION_CONFIRMED]: "予約確定",
  [MriStatusID.DONE_AI_DIAGNOSING]: "済：AI診断中",
  [MriStatusID.DONE_AI_DIAGNOSED]: "済：AI診断済",
  [MriStatusID.DONE_DOCTOR_DIAGNOSED]: "済：ドクター診断済",
  [MriStatusID.USER_REPORTED]: "ユーザー報告済",
  [MriStatusID.WITHDRAWN_AFTER_CONFIRMATION]: "予約取り下げ",
  [MriStatusID.NG_BEFORE_CONFIRMATION]: "NG",
  [MriStatusID.ALL]: "すべて",
};
const Nlc005MriStatus: {
  key: keyof typeof MriStatusID;
  flag: string;
}[] = [
  { key: "RESERVED", flag: "isReserved" },
  { key: "RESERVATION_CONFIRMED", flag: "hasReservationConfirmed" },
  { key: "DONE_AI_DIAGNOSING", flag: "isCompletedUnderAIDiagnosis" },
  { key: "DONE_AI_DIAGNOSED", flag: "isCompletedAIDiagnose" },
  { key: "DONE_DOCTOR_DIAGNOSED", flag: "isCompletedDocDiagnosed" },
  { key: "USER_REPORTED", flag: "isUserSupported" },
  { key: "WITHDRAWN_AFTER_CONFIRMATION", flag: "withdrawal" },
];
const InspectMriStatusArr: (keyof typeof MriStatusID)[] = [
  "ALL",
  "NON_TARGET",
  "GUIDING",
  "RESERVED",
  "RESERVATION_CONFIRMED",
  "DONE_AI_DIAGNOSING",
  "DONE_AI_DIAGNOSED",
  "DONE_DOCTOR_DIAGNOSED",
  "USER_REPORTED",
  "WITHDRAWN_AFTER_CONFIRMATION",
  "NG_BEFORE_CONFIRMATION",
];

export enum MriProblemID {
  EXCLUDE,
  PROBLEM,
  NO_PROBLEM,
  ALL = 999,
}
const MriProblemLabels = {
  [MriProblemID.EXCLUDE]: "対象外",
  [MriProblemID.PROBLEM]: "問題あり",
  [MriProblemID.NO_PROBLEM]: "問題なし",
  [MriProblemID.ALL]: "すべて",
};
const Nlc005PatientModalityBookMriProblem: {
  key: keyof typeof MriProblemID;
  flag: string;
}[] = [
  { key: "PROBLEM", flag: "hasProblem" },
  { key: "NO_PROBLEM", flag: "hasNoProblem" },
];
const InspectMriResultArr: (keyof typeof MriProblemID)[] = [
  "ALL",
  "NO_PROBLEM",
  "PROBLEM",
];

export enum PetProblemID {
  EXCLUDE,
  PROBLEM,
  NO_PROBLEM,
  ALL = 999,
}
const PetProblemLabels = {
  [PetProblemID.EXCLUDE]: "対象外",
  [PetProblemID.PROBLEM]: "問題あり",
  [PetProblemID.NO_PROBLEM]: "問題なし",
  [PetProblemID.ALL]: "すべて",
};
// 2次以降フェーズ対応: NLC006作成後対応
// const Nlc006PatientModalityBookMriProblem: {
//   key: keyof typeof PetProblemID;
//   flag: string;
// }[] = [
//   { key: "PROBLEM", flag: "hasProblem" },
//   { key: "NO_PROBLEM", flag: "hasNoProblem" },
// ];
const InspectPetResultArr: (keyof typeof PetProblemID)[] = [
  "ALL",
  "NO_PROBLEM",
  "PROBLEM",
];

export enum UserTypeID {
  ADMIN = 1,
  PATIENT = 2,
  HOSPITAL = 3,
}

export enum UserStatusID {
  UNREGISTERED,
  ACTIVE,
  EMAIL_VERIFYING,
  CANCELLATION,
  SUSPENDED,
  DELETED,
}

export enum InspectionFeesStatusID {
  UNPAID,
  PAID,
  NOT_NEEDED,
}

export enum PatientTypeID {
  MEMBER = 1,
  INVITATION = 2,
}

export enum PlanSpanID {
  MONTH = 1,
  YEAR = 2,
}

export enum EventHistoriesPriorityID {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export enum SalesCategoryID {
  SUBSCRIPTION,
  INTERVIEW,
  PRODUCT_PURCHASE,
  PET,
  MRI,
}

export enum SalesStatusID {
  UNPAID,
  PAID,
  NOT_NEEDED,
}

export enum BookTypeID {
  INTERVIEW = "interview",
  MODALITY = "modality",
}
export enum InspectionTabID {
  MRI = "1",
  PET = "2",
}

export enum Nlb001CategoryID {
  ALL = "1",
  CHAT = "2",
  NOUKNOW = "3",
  MRI = "4",
  INTERVIEw = "5",
  EC = "6",
}

export const EmbeddedStr = {
  PAGE: {
    [EmbeddedPageID.MY_PAGE]: "マイページ",
    [EmbeddedPageID.YOSE_LIST]: "寄席一覧",
    [EmbeddedPageID.YOSE_DETAILS]: "寄席詳細",
    [EmbeddedPageID.EC_LIST]: "EC一覧",
    [EmbeddedPageID.EC_DETAILS]: "EC詳細",
  },
  LOCATION: {
    [EmbeddedLocationID.TOP]: "トップ",
    [EmbeddedLocationID.FOOTER]: "フッター",
  },
};

export const SEARCH_INFO = {
  // 2次以降フェーズ対応
  NLC001: [
    {
      // MongoDBに対応したID
      id: PatientStatusID.UNSUPPORTED,
      // 画面表示値
      label: "アクティブ",
      // INIT_SEARCH_CRITERIAに対応した検索欄のフラグを取得するkey
      flgKey: "isActive",
    },
    {
      id: PatientStatusID.REGISTERED,
      label: "アクティブ",
      flgKey: "isActive",
    },
    {
      id: PatientStatusID.NG,
      label: "保険対象外",
      flgKey: "isNoCoveredInsurance",
    },
  ],
  // 2次以降フェーズ対応
  NLC002: [
    {
      id: PatientStatusID.UNSUPPORTED,
      label: "未対応",
      flgKey: "isUnsupported",
    },
    {
      id: PatientStatusID.REGISTERED,
      label: "完了",
      flgKey: "isCompleted",
    },
    {
      id: PatientStatusID.NG,
      label: "NG",
      flgKey: "isNG",
    },
  ],
  // 2次以降フェーズ対応
  NLC003: [
    {
      id: PatientStatusID.UNSUPPORTED,
      label: "未対応",
      flgKey: "isUnsupported",
    },
    {
      id: PatientStatusID.REGISTERED,
      label: "完了",
      flgKey: "isCompleted",
    },
    {
      id: PatientStatusID.NG,
      label: "NG",
      flgKey: "isNG",
    },
  ],
  NLC004: Nlc004NouKnowStatus.map((item) =>
    _createIdAndLabel(
      item.key,
      PatientNouKnowStatusID,
      PatientNouKnowStatusLabels,
      item.flag,
    ),
  ),
  NLC005: Nlc005MriStatus.map((item) =>
    _createIdAndLabel(item.key, MriStatusID, MriStatusLabels, item.flag),
  ),
  NLC005_RESULT: Nlc005PatientModalityBookMriProblem.map((item) =>
    _createIdAndLabel(item.key, MriProblemID, MriProblemLabels, item.flag),
  ),
  NLC007: Nlc007PatientInterviewStatus.map((item) =>
    _createIdAndLabel(
      item.key,
      PatientInterviewStatusID,
      MriProblemLabels,
      item.flag,
    ),
  ),
  NLD001: [],
  NLD002: [
    {
      id: InspectionFeesStatusID.UNPAID,
      label: "未払い",
      flgKey: "isUnpaid",
    },
    {
      id: InspectionFeesStatusID.PAID,
      label: "支払い済",
      flgKey: "isPaid",
    },
  ],
  NLD004: [
    {
      id: SalesStatusID.UNPAID,
      label: "未払い",
      flgKey: "isUnpaid",
    },
    {
      id: SalesStatusID.PAID,
      label: "支払い済",
      flgKey: "isPaid",
    },
  ],
  NLF001_STATUS: [
    {
      id: OrderStatusID.PAYMENT_COMPLETED,
      label: "支払完了",
      flgKey: "isPaymentCompleted",
    },
    {
      id: OrderStatusID.SHIPPED,
      label: "出荷済",
      flgKey: "isShipmentCompleted",
    },
    {
      id: OrderStatusID.DELIVERY_COMPLETED,
      label: "配送完了",
      flgKey: "isDeliveryCompleted",
    },
  ],
  NLF001_DELIVERY: [
    {
      id: DeliveryMethodID.NORMAL,
      label: "通常配送",
      flgKey: "isNormalDelivery",
    },
    {
      id: DeliveryMethodID.SPECIFIED,
      label: "お届け日指定",
      flgKey: "isDateSpecifiedDelivery",
    },
  ],
  NLG001: [
    {
      id: NotificationStatusID.UNPUBLISHED,
      label: "未公開",
      flgKey: "isUnpublished",
    },
    {
      id: NotificationStatusID.NOW_OPEN,
      label: "公開中",
      flgKey: "isNowOpen",
    },
  ],
  NLH001: [
    {
      id: CampaignsStatusID.UNPUBLISHED,
      label: "未公開",
      flgKey: "",
    },
    {
      id: CampaignsStatusID.NOW_OPEN,
      label: "公開中",
      flgKey: "",
    },
  ],
  NLI017: [
    {
      id: HospitalRoleID.ADMIN,
      label: "Admin",
      flgKey: "isAdmin",
    },
    {
      id: HospitalRoleID.GENERAL,
      label: "一般ユーザー",
      flgKey: "isGeneral",
    },
  ],
  MIB001_MODALITY: [
    {
      id: ModalityID.MRI,
      label: "MRI検査",
      flgKey: "isMRI",
    },
    {
      id: ModalityID.PET,
      label: "PET検査",
      flgKey: "isPET",
    },
  ],
  MIB001_STATUS: [
    {
      id: HospitalReservationStatusID.NEW,
      label: "新規予約",
      flgKey: "isNew",
    },
    {
      id: HospitalReservationStatusID.CONFIRM,
      label: "確定",
      flgKey: "isConfirm",
    },
    {
      id: HospitalReservationStatusID.NG,
      label: "NG",
      flgKey: "isNg",
    },
    {
      id: HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL,
      label: "取り下げ申請",
      flgKey: "isApplicationForWithdrawal",
    },
    {
      id: HospitalReservationStatusID.WITHDRAWAL,
      label: "取り下げ",
      flgKey: "isWithdrawal",
    },
  ],
  MID001: [
    {
      id: HospitalInspectionStatusID.NOT_INSPECTED,
      label: "未検査",
      flgKey: "isNotInspected",
    },
    {
      id: HospitalInspectionStatusID.INSPECTED,
      label: "検査済",
      flgKey: "isInspected",
    },
  ],
};

// ユーザ管理、ユーザ詳細のテスト結果セレクトボックス選択肢
export const INSPECT_MRI_RESULTS_OPTIONS = InspectMriResultArr.map((key) =>
  _createIdAndLabel(key, MriProblemID, MriProblemLabels),
);
export const INSPECT_PET_RESULTS_OPTIONS = InspectPetResultArr.map((key) =>
  _createIdAndLabel(key, PetProblemID, PetProblemLabels),
);
export const INSPECT_NOUKNOW_STATUS_OPTIONS = InspectNouKnowStatusArr.map(
  (key) =>
    _createIdAndLabel(key, PatientNouKnowStatusID, PatientNouKnowStatusLabels),
);
export const INSPECT_MRI_STATUS_OPTIONS = InspectMriStatusArr.map((key) =>
  _createIdAndLabel(key, MriStatusID, MriStatusLabels),
);
export const INSPECT_PET_STATUS_OPTIONS = InspectPetStatusArr.map((key) =>
  _createIdAndLabel(key, PetStatusID, PetStatusLabels),
);

export const MASTER_MANAGEMENT_OPTIONS = [
  {
    id: "1",
    label: "権限管理",
    value: "1",
    url: ADMIN.NLI001,
  },
  {
    id: "2",
    label: "ユーザー管理",
    value: "2",
    url: ADMIN.NLI003,
  },
  {
    id: "3",
    label: "商品管理",
    value: "3",
    url: ADMIN.NLI007,
  },
  {
    id: "4",
    label: "医療機関",
    value: "4",
    url: ADMIN.NLI016,
  },
];

export const HOSPITAL_MASTER_MANAGEMENT_OPTIONS = [
  {
    id: "1",
    label: "枠管理",
    value: "1",
    url: HOSPITAL.MIE001,
  },
  {
    id: "2",
    label: "医師",
    value: "2",
    url: HOSPITAL.MIE004,
  },
];

export const ADMIN_MAIN_TAB_ITEMS = [
  { title: "ダッシュボード", url: ADMIN.NLB001, counter: 0 },
  { title: "ユーザー管理", url: ADMIN.NLC001, counter: 0 },
  { title: "支払・請求", url: ADMIN.NLD002, counter: 0 },
  { title: "オンライン寄席", url: ADMIN.NLE001, counter: 0 },
  { title: "EC管理", url: ADMIN.NLF001, counter: 0 },
  { title: "お知らせ", url: ADMIN.NLG001, counter: 0 },
  { title: "キャンペーン", url: ADMIN.NLH001, counter: 0 },
];

export const HOSPITAL_MAIN_TAB_ITEMS = [
  { title: "予約受付", url: HOSPITAL.MIB001, counter: 0 },
  { title: "スケジュール", url: HOSPITAL.MIC001, counter: 0 },
  { title: "患者一覧", url: HOSPITAL.MID001, counter: 0 },
];

export const ADMIN_USER_MGMT_SUB_TAB_ITEMS = [
  {
    id: "1",
    label: "すべて",
    url: ADMIN.NLC001,
  },
  {
    id: "2",
    label: "3次スクリーニング",
    url: ADMIN.NLC006,
  },
  {
    id: "3",
    label: "2次スクリーニング",
    url: ADMIN.NLC005,
  },
  {
    id: "4",
    label: "1次スクリーニング",
    url: ADMIN.NLC004,
  },
  {
    id: "5",
    label: "面談",
    url: ADMIN.NLC007,
  },
  // 2次以降フェーズ対応
  // {
  //   id: "6",
  //   label: "本人確認",
  //   url: ADMIN.NLC002,
  // },
  // {
  //   id: "7",
  //   label: "保険会社チェック",
  //   url: ADMIN.NLC003,
  // },
];

export const ADMIN_PAYMENT_SUB_TAB_ITEMS = [
  {
    id: "1",
    label: "検査費用支払い",
    url: ADMIN.NLD002,
  },
  {
    id: "2",
    label: "ユーザー請求",
    url: ADMIN.NLD004,
  },
];

export const MASTER_ITEMS_SUB_TAB_ITEMS = [
  { id: "1", title: "商品種別", url: ADMIN.NLI007 },
  { id: "2", title: "SKU", url: ADMIN.NLI009 },
];

export const ADMIN_ORDERS_TOP_TAB_ITEMS = [
  {
    id: "1",
    label: "注文管理",
    url: ADMIN.NLF001,
  },
  {
    id: "2",
    label: "売上管理",
    url: ADMIN.NLF006,
  },
];

export const ADMIN_ORDERS_BOTTOM_TAB_ITEMS = [
  {
    id: "1",
    label: "すべて",
  },
  {
    id: "2",
    label: "支払い完了",
  },
  {
    id: "3",
    label: "出荷済",
  },
  {
    id: "4",
    label: "配送完了",
  },
];

export const ADMIN_USER_DETAIL_TAB_ITEMS = [
  { title: "登録情報", url: ADMIN.NLC008, counter: 0 },
  { title: "テスト結果", url: ADMIN.NLC011, counter: 0 },
  { title: "検査費用・給付金", url: ADMIN.NLC018, counter: 0 },
  { title: "支払い状況", url: ADMIN.NLC019, counter: 0 },
  { title: "予約状況", url: ADMIN.NLC020, counter: 0 },
  { title: "注文履歴", url: ADMIN.NLC023, counter: 0 },
];

export const TEST_RESULT_ITEMS = [
  {
    id: "1",
    label: "スクリーニングテスト",
    url: ADMIN.NLC011,
  },
  {
    id: "2",
    label: "MRI検査",
    url: ADMIN.NLC014,
  },
  {
    id: "3",
    label: "PET検査",
    url: ADMIN.NLC017,
  },
];

export const ADMIN_USER_DETAIL_CONTACT_ITEMS = [
  { title: "応対履歴", icon: "phone" },
  /* 2次以降フェーズ対応 */
  // { title: "通知", icon: "mail" },
];

export const MONTH_LIST = [
  { value: "01", label: "1" },
  { value: "02", label: "2" },
  { value: "03", label: "3" },
  { value: "04", label: "4" },
  { value: "05", label: "5" },
  { value: "06", label: "6" },
  { value: "07", label: "7" },
  { value: "08", label: "8" },
  { value: "09", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

// 日本時間
export const JST = "+09:00";
export const JST_OFFSET_HOURS = 9;

// 消費税10%
export const TAX_10_PER = {
  label: "消費税10%対象",
  rate: "10%",
  calcTaxNum: 1.1,
};

// 消費税8%
export const TAX_8_PER = {
  label: "消費税8%対象",
  rate: "8%",
  calcTaxNum: 1.08,
};

// 画像アップロード最大個数
export const IMG_DEFAULT_MAX_COUNT = 4;

// 注文状況アイコン
export const ORDER_STEPS = [
  {
    id: "content-1",
    icon: "credit_card",
    label: "",
  },
  {
    id: "content-2",
    icon: "shopping_cart",
    label: "",
  },
];

export const ADMIN_SELECT_ROLE = [
  {
    label: "一般ユーザー",
    value: "3",
  },
  {
    label: "ドクター",
    value: "2",
  },
  {
    label: "Admin",
    value: "1",
  },
];

export const ADMIN_SELECT_STATUS = [
  {
    label: "有効",
    value: "1",
  },

  {
    label: "停止",
    value: "0",
  },
];

export const RECOMMEND_TERMS = [
  {
    value: "99",
    label: "すべて",
  },
  {
    value: "1",
    label: "A",
  },
  {
    value: "2",
    label: "B",
  },
  {
    value: "3",
    label: "C",
  },
  {
    value: "4",
    label: "D",
  },
  {
    value: "5",
    label: "E",
  },
  {
    value: "0",
    label: "設定しない",
  },
];
// すべてと設定しないを除いたRECOMMEND_TERMS
export const RECOMMEND_TERMS_CONDITIONS = RECOMMEND_TERMS.map(({ value }) =>
  Number(value),
).filter((value) => value !== 99 && value !== 0);
export const RECOMMEND_SCOPES = [
  {
    value: "1",
    label: "以上",
  },
  {
    value: "2",
    label: "以下",
  },
  {
    value: "3",
    label: "-",
  },
];

export const TAX_RATE = [
  {
    rate: 8,
    label: "8%",
  },
  {
    rate: 10,
    label: "10%",
  },
];

// ユーザ評価初期値
export const INIT_EVALUATION = "3";

// ユーザ評価選択肢
export const USER_EVALUATION = [
  {
    column: 5,
    items: [
      {
        label: "A",
        value: "1",
      },
      {
        label: "B",
        value: "2",
      },
      {
        label: "C",
        value: "3",
      },
      {
        label: "D",
        value: "4",
      },
      {
        label: "E",
        value: "5",
      },
    ],
  },
];

// 都道府県の選択肢
export const PREFECTURE_OPTIONS = [
  {
    label: "北海道",
    value: "北海道",
  },
  {
    label: "青森県",
    value: "青森県",
  },
  {
    label: "岩手県",
    value: "岩手県",
  },
  {
    label: "宮城県",
    value: "宮城県",
  },
  {
    label: "秋田県",
    value: "秋田県",
  },
  {
    label: "山形県",
    value: "山形県",
  },
  {
    label: "福島県",
    value: "福島県",
  },
  {
    label: "茨城県",
    value: "茨城県",
  },
  {
    label: "栃木県",
    value: "栃木県",
  },
  {
    label: "群馬県",
    value: "群馬県",
  },
  {
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    label: "千葉県",
    value: "千葉県",
  },
  {
    label: "東京都",
    value: "東京都",
  },
  {
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    label: "新潟県",
    value: "新潟県",
  },
  {
    label: "富山県",
    value: "富山県",
  },
  {
    label: "石川県",
    value: "石川県",
  },
  {
    label: "福井県",
    value: "福井県",
  },
  {
    label: "山梨県",
    value: "山梨県",
  },
  {
    label: "長野県",
    value: "長野県",
  },
  {
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    label: "静岡県",
    value: "静岡県",
  },
  {
    label: "愛知県",
    value: "愛知県",
  },
  {
    label: "三重県",
    value: "三重県",
  },
  {
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    label: "京都府",
    value: "京都府",
  },
  {
    label: "大阪府",
    value: "大阪府",
  },
  {
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    label: "奈良県",
    value: "奈良県",
  },
  {
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    label: "島根県",
    value: "島根県",
  },
  {
    label: "岡山県",
    value: "岡山県",
  },
  {
    label: "広島県",
    value: "広島県",
  },
  {
    label: "山口県",
    value: "山口県",
  },
  {
    label: "徳島県",
    value: "徳島県",
  },
  {
    label: "香川県",
    value: "香川県",
  },
  {
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    label: "高知県",
    value: "高知県",
  },
  {
    label: "福岡県",
    value: "福岡県",
  },
  {
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    label: "長崎県",
    value: "長崎県",
  },
  {
    label: "熊本県",
    value: "熊本県",
  },
  {
    label: "大分県",
    value: "大分県",
  },
  {
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    label: "沖縄県",
    value: "沖縄県",
  },
];

// 誕生日の年セレクトボックス値作成
const currentYear = new Date().getFullYear();
const startYear = 1900;
const endYear = currentYear;
export const BIRTH_YEAR_OPTION = Array.from(
  { length: endYear - startYear + 1 },
  (_, index) => ({
    label: (startYear + index).toString(),
    value: (startYear + index).toString(),
  }),
);

// 月セレクトボックス値作成
export const MONTH_OPTIONS = Array.from({ length: 12 }, (_, index) => {
  const monthNumber = index + 1;
  const label = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;

  return {
    label,
    value: monthNumber.toString(),
  };
});

// 日セレクトボックス値作成
export const DAY_OPTIONS = Array.from({ length: 31 }, (_, index) => ({
  label: (index + 1).toString().padStart(2, "0"),
  value: (index + 1).toString(),
}));

// 性別ラジオボタン選択肢
export const GENDER_ITEMS = [
  {
    column: 2,
    items: [
      {
        label: "男性",
        value: "male",
      },
      {
        label: "女性",
        value: "female",
      },
    ],
  },
];

// 応対履歴 カテゴリ
export const EVENT_CATEGORY_OPTIONS = [
  {
    label: "支払い",
    value: "支払い",
  },
  {
    label: "テスト・検査",
    value: "テスト・検査",
  },
  {
    label: "登録",
    value: "登録",
  },
  {
    label: "EC",
    value: "EC",
  },
  {
    label: "寄席",
    value: "寄席",
  },
  {
    label: "その他",
    value: "その他",
  },
];

// 応対履歴 カテゴリ
export const EVENT_CATEGORY_FIND_OPTIONS = [
  {
    label: "すべて",
    value: "ALL",
  },
  {
    label: "支払い",
    value: "支払い",
  },
  {
    label: "テスト・検査",
    value: "テスト・検査",
  },
  {
    label: "登録",
    value: "登録",
  },
  {
    label: "EC",
    value: "EC",
  },
  {
    label: "寄席",
    value: "寄席",
  },
  {
    label: "その他",
    value: "その他",
  },
];

// 応対履歴 重要度
export const EVENT_IMPORTANCE_OPTIONS = [
  {
    label: "低",
    value: "1",
  },
  {
    label: "中",
    value: "2",
  },
  {
    label: "高",
    value: "3",
  },
];

// 応対履歴 ソート
export const EVENT_SORT_OPTIONS = [
  {
    id: "1",
    label: "登録順",
    value: "registered_date",
  },
  {
    id: "2",
    label: "重要度順",
    value: "priority",
  },
];

// 応対履歴 アクション
export const EVENT_RESPONSE_HISTORY_OPTIONS = [
  {
    label: "編集",
    value: "0",
    icon: "edit",
  },
  {
    label: "削除",
    value: "1",
    icon: "delete",
    danger: true,
  },
];

export const NOUKNOW_SCORE_TEXT = {
  A: "正常な状態です",
  B: "ボーダーライン",
  C: "維持向上のための活動を取り⼊れましょう",
};

export const INSPECTION_TOGGLE_ITEMS = [
  {
    id: "1",
    label: "MRI",
  },
  {
    id: "2",
    label: "FDG PET",
  },
];

// 権限検索キー(master_rolesのrole_sort_no)
export enum ROLE_KEY {
  NONE,
  // ユーザ管理
  USER_LIST_CSV,
  USER_EVENT_HISTORY_REGISTER,
  USER_DOC_CHAT_REGISTER,
  USER_DOC_CHAT_REPORT,
  USER_SCREENING_CONFIRM,
  USER_MRI_INTERPRETATION,
  USER_INTERVIEW_RESERVE,
  USER_PET_SEND_RESULT,
  USER_PAY_BROWSING,
  USER_ORDER_HISTORY_BROWSING,
  // 支払・請求
  PAYMENT_LIST_BROWSING,
  PAYMENT_LIST_CSV,
  // 寄席
  YOSE_LIST_BROWSING,
  YOSE_LIST_ADD,
  YOSE_DETAIL_EDIT,
  // EC管理
  EC_ORDER_BROWSING,
  EC_ORDER_DETAIL,
  EC_ORDER_DELIVERY,
  EC_ORDER_DELIVERY_NUM,
  EC_ORDER_CANCEL,
  EC_SALES_BROWSING,
  EC_SALES_DETAIL,
  // お知らせ
  NOTICE_LIST_BROWSING,
  NOTICE_LIST_ADD,
  NOTICE_DETAIL_EDIT,
  // キャンペーン
  CAMPAIGN_LIST_BROWSING,
  CAMPAIGN_LIST_ADD,
  CAMPAIGN_DETAIL_EDIT,
  // マスタ管理 権限管理
  MASTER_AUTHORITY_BROWSING,
  MASTER_AUTHORITY_EDIT,
  // マスタ管理 ユーザ管理
  MASTER_USER_BROWSING,
  MASTER_USER_CSV_DOWNLOAD,
  MASTER_USER_CSV_UPLOAD,
  MASTER_USER_ADD,
  MASTER_USER_EDIT,
  // マスタ管理 商品管理
  MASTER_PRODUCT_BROWSING,
  MASTER_PRODUCT_ADD,
  MASTER_PRODUCT_EDIT,
  // マスタ管理 医療機関
  MASTER_HOSPITAL_BROWSING,
  MASTER_HOSPITAL_CSV_DOWNLOAD,
  MASTER_HOSPITAL_CSV_UPLOAD,
  MASTER_HOSPITAL_ADD,
  MASTER_HOSPITAL_EDIT,
}
