import React from "react";
import Image from "../components/Image/Image";
import ImageDialog from "../components/Dialog/ImageDialog";
import Button from "../components/Button/Button";
import DialogCustom from "../components/Dialog/DialogCustom";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Heading from "../components/Heading/Heading";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import Divider from "../components/Divider/Divider";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";

import Input from "../components/Input/Input";
import useNlc015Dialog, {
  closeNlc015Dialog,
} from "../hooks/pages/NLC015/useNlc015Dialog";
import useNlc015Conditions from "../hooks/pages/NLC015/useNlc015Conditions";
import { convertPatientInfo } from "../utils/convertDisplay";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import TestResultIcon from "../components/TestResultIcon";
import useNlc015DbActions from "../hooks/pages/NLC015/useNlc015DbActions";
import { MriStatusLabels } from "../constants/common";
import { getMriStatusTagState } from "../utils/utils";

const RADIO_BUTTON_ITEMS = [
  {
    column: 2,
    items: [
      {
        label: "問題なし",
        value: "1",
      },
      {
        label: "問題あり",
        value: "2",
      },
    ],
  },
];

const NLC015 = () => {
  const [isOpen] = useNlc015Dialog();
  const [modalityInfo] = useNlc015Conditions();
  const [isImageDialogOpen, setIsImageDialogOpen] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
  const [selectedRadioValue, setSelectedRadioValue] = React.useState("");

  const { fetchError, actionError, confirmSelection } = useNlc015DbActions();

  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { id, name, birth, genderStr, tel, email } =
    convertPatientInfo(patientInfo);

  // エラー処理
  useCheckErrorThrowError([fetchError, actionError, patientFetchError]);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setIsImageDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsImageDialogOpen(false);
  };

  const onChange = React.useCallback(() => {
    setSelectedRadioValue(selectedRadioValue);
  }, [selectedRadioValue]);

  return (
    <DialogCustom
      open={isOpen}
      closeDialog={closeNlc015Dialog}
      size="large"
      title="結果"
      height="1143px"
      footerRight={
        <LayoutBox justify="end" fullWidth>
          <Button
            type="sub"
            color="neutral"
            size="large"
            width="108px"
            onClick={closeNlc015Dialog}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            size="large"
            width="64px"
            onClick={confirmSelection}
          >
            確定
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox direction="column" gap="3x" fullWidth>
        <Sheet type="border-blue" padding="15px 23px 15px 20px">
          <LayoutBox direction="column" gap="2x">
            <LayoutBox direction="column" gap="1x">
              <LayoutBox gap="1x">
                <Text size="small" color="neutralLight">
                  {id}
                </Text>
                <Tag
                  label={
                    MriStatusLabels[
                      modalityInfo.status as keyof typeof MriStatusLabels
                    ]
                  }
                  showIcon={false}
                  state={getMriStatusTagState(
                    MriStatusLabels[
                      modalityInfo.status as keyof typeof MriStatusLabels
                    ],
                  )}
                />
              </LayoutBox>
              <Text size="2xl" bold lineHeight="150%">
                {name}
              </Text>
            </LayoutBox>
            <LayoutBox direction="column" fullWidth>
              <LayoutBox fullWidth>
                <FormSet
                  label="受験日"
                  labelWidth="120px"
                  labelSize="medium"
                  base
                  formVertical={false}
                >
                  <Text width="210px">{`受験日：${modalityInfo.date}`}</Text>
                </FormSet>
              </LayoutBox>
              <LayoutBox fullWidth>
                <FormSet
                  label="生年月日"
                  labelWidth="120px"
                  labelSize="medium"
                  base
                  formVertical={false}
                >
                  <Text width="210px">{birth}</Text>
                </FormSet>
                <FormSet
                  label="性別"
                  labelWidth="120px"
                  labelSize="medium"
                  base
                  formVertical={false}
                >
                  <Text width="210px">{genderStr}</Text>
                </FormSet>
              </LayoutBox>
              <LayoutBox fullWidth>
                <FormSet
                  label="電話番号"
                  labelWidth="120px"
                  labelSize="medium"
                  base
                  formVertical={false}
                >
                  <Text width="210px">{tel}</Text>
                </FormSet>
                <FormSet
                  label="メールアドレス"
                  labelWidth="120px"
                  labelSize="medium"
                  base
                  formVertical={false}
                >
                  <Text width="210px">{email}</Text>
                </FormSet>
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <LayoutBox direction="column" fullWidth gap="2x">
          <Sheet type="header" padding="4px 8px 3px 5px">
            <Heading tag="h2">担当医</Heading>
          </Sheet>
          <Text>{modalityInfo.doctor && `${modalityInfo.doctor}先生`}</Text>
        </LayoutBox>
        <LayoutBox direction="column" fullWidth gap="2x">
          <Sheet type="header" padding="4px 8px 3px 5px">
            <Heading tag="h2">医師所見</Heading>
          </Sheet>
          <Text>{modalityInfo.doctorReview}</Text>
        </LayoutBox>
        <LayoutBox direction="column" fullWidth gap="2x">
          <Sheet type="header" padding="4px 8px 3px 5px">
            <Heading tag="h2">備考</Heading>
          </Sheet>
          <Text>{modalityInfo.doctorNote}</Text>
        </LayoutBox>
        <LayoutBox direction="column" fullWidth gap="2x">
          <Sheet type="header" padding="4px 8px 3px 5px">
            <Heading tag="h2">診断画像</Heading>
          </Sheet>
          <LayoutBox fullWidth wrap justify="between">
            {modalityInfo.images.map((imageUrl, index) => (
              <Image
                key={index}
                type="picture"
                url={imageUrl}
                onClick={() => handleImageClick(index)}
                width="170px"
                height="170.339px"
              />
            ))}
          </LayoutBox>
        </LayoutBox>
        <Divider margin="1px" />
        <List height="100%">
          <ListItem
            noBorder
            alignCenter
            id="1"
            left={
              <LayoutBox
                direction="column"
                gap="1/2x"
                justify="end"
                align="center"
                fullHeight
              >
                <TestResultIcon status={modalityInfo.problem} />
              </LayoutBox>
            }
            center={
              <>
                <LayoutBox>
                  <Text size="small" color="neutralLight">
                    受験日：{modalityInfo.date}
                  </Text>
                </LayoutBox>
                <Text size="large" bold>
                  ブレインスイート診断
                </Text>
              </>
            }
          />
        </List>
        <Sheet type="gray" padding="20px">
          <LayoutBox gap="2x" direction="column">
            <Sheet type="header" padding="4px 8px 3px 5px">
              <Heading tag="h2">読影</Heading>
            </Sheet>
            <LayoutBox>
              <FormSet label="カテゴリ" vertical>
                {/* 2次以降フェーズ対応 */}
                <RadioButtonGroup
                  name="endTypeRadioButton"
                  rowItems={RADIO_BUTTON_ITEMS}
                  onChange={onChange}
                  selectedValue={selectedRadioValue}
                />
              </FormSet>
            </LayoutBox>
            <FormSet label="備考" vertical>
              {/* 2次以降フェーズ対応 */}
              <Input
                multiLine
                placeholder="備考"
                width="100%"
                multiLineHeight="88px"
              />
            </FormSet>
          </LayoutBox>
        </Sheet>
      </LayoutBox>

      {isImageDialogOpen && (
        <ImageDialog
          images={modalityInfo.images}
          currentIndex={selectedImageIndex}
          onClose={handleCloseDialog}
        />
      )}
    </DialogCustom>
  );
};

export default NLC015;
