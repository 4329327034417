import { useEffect, useState, useTransition } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateEventHistories, getMongoDb } from "../../utils/query";
import {
  Collection,
  EventHistoryStatusID,
  ROLE_KEY,
} from "../../constants/common";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import useForceUpdate from "./useForceUpdate";
import generateUniqueId from "../../utils/generateUniqueId";
import {
  openCreateActionLoading,
  closeActionLoading,
  openUpdateActionLoading,
  openDeleteActionLoading,
} from "../base/useLoadingAction";
import { checkMasterRole } from "./useMasterRoleDbActions";

type FetchParams = {
  eventSort: string;
  searchCriteria: string;
  filterCategory: string;
};

// event_histories
const useEventHistoriesDbActions = ({
  eventSort,
  searchCriteria,
  filterCategory,
}: FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [eventHistories, setEventHistories] = useState<
    EventHistoriesStateType[]
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [, startTransition] = useTransition();

  const [id] = useId();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        if (!id) redirectToInvalidAccessPage();
        const mongoDb = getMongoDb(currentUser, Collection.EVENT_HISTORIES);

        const aggregate = getAggregateEventHistories(
          id,
          eventSort,
          filterCategory,
          searchCriteria,
        );
        const results = (await mongoDb.aggregate(
          aggregate,
        )) as EventHistoriesStateType[];

        if (!isCancelled) {
          setEventHistories(results);
        }
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    startTransition(() => {
      void fetchData();
    });

    return () => {
      isCancelled = true;
    };
  }, [
    currentUser,
    id,
    forceUpdate.forceUpdateCount,
    eventSort,
    filterCategory,
    searchCriteria,
  ]);

  // 応対履歴登録・更新
  const handleUpsert = (
    inputObj: {
      eventId?: string;
      category: string;
      priority: string;
      description: string;
    },
    handleClear: () => void,
  ) => {
    void (async () => {
      if (inputObj.eventId) {
        // 更新
        openUpdateActionLoading();
      } else {
        // 登録
        openCreateActionLoading();
      }
      try {
        // 更新可能か権限チェック
        await checkMasterRole(
          ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
          currentUser,
        );

        const mongoDb = getMongoDb(currentUser, Collection.EVENT_HISTORIES);

        if (inputObj.eventId) {
          // 更新データ
          const updateData = {
            category: inputObj.category,
            priority: Number(inputObj.priority),
            description: inputObj.description,
            registered_user_id: currentUser?.customData._id,
            updated_at: new Date(),
          };

          await mongoDb.updateOne(
            { _id: inputObj.eventId },
            { $set: updateData },
          );
        } else {
          const uniqueId = generateUniqueId();

          // 登録データ
          const insertData = {
            _id: uniqueId,
            target_patient_id: id,
            registered_date: new Date(),
            category: inputObj.category,
            priority: Number(inputObj.priority),
            description: inputObj.description,
            registered_user_id: currentUser?.customData._id,
            status: EventHistoryStatusID.VALID,
            created_at: new Date(),
            updated_at: new Date(),
          };

          await mongoDb.insertOne(insertData);
        }

        handleClear();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 応対履歴削除
  const handleDelete = (
    inputObj: {
      eventId: string;
      category: string;
      priority: string;
      description: string;
    },
    handleClear: () => void,
    closeOpenDeleteConfirm: () => void,
  ) => {
    void (async () => {
      openDeleteActionLoading();
      closeOpenDeleteConfirm();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(
          ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
          currentUser,
        );

        const mongoDb = getMongoDb(currentUser, Collection.EVENT_HISTORIES);

        // 論理削除
        await mongoDb.updateOne(
          { _id: inputObj.eventId },
          { $set: { status: EventHistoryStatusID.DELETED } },
        );

        handleClear();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, eventHistories, handleUpsert, handleDelete };
};

export default useEventHistoriesDbActions;
