import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import Icon from "../Icon/Icon";

export type MenuListItemProps = {
  label: string;
  value?: string;
  icon?: string;
  checkbox?: boolean;
  danger?: boolean;
  isSelected?: boolean;
  onClick: (value: string) => void | (() => void);
  onCheck?: (value: string) => void;
};

const MenuListItem = ({
  label = "",
  value = "",
  icon = "",
  checkbox = false,
  danger = false,
  isSelected = false,
  onClick,
  onCheck,
}: MenuListItemProps) => {
  const menuListItemClass = React.useMemo(() => {
    const dangerClass = danger ? ["menulist-item--danger"] : [];
    const selectedClass = isSelected ? ["menulist-item--selected"] : [];

    return ["menulist-item", ...dangerClass, ...selectedClass].join(" ");
  }, [danger, isSelected]);

  const onClickItem = React.useCallback(() => {
    if (!checkbox) {
      onClick(value);
    }
  }, [checkbox, onClick, value]);

  const onChecked = React.useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    onCheck && onCheck(value);
  }, [onCheck, value]);

  return (
    <div className={menuListItemClass} onClick={onClickItem}>
      {checkbox && !danger ? (
        <Checkbox
          checked={isSelected}
          onChecked={onChecked}
          label={
            <>
              {icon !== undefined && <Icon icon={icon} size="2xs" />}
              <span className="menulist-item__label">{label}</span>
            </>
          }
        />
      ) : (
        <>
          {icon !== undefined && <Icon icon={icon} size="2xs" />}
          <span className="menulist-item__label">{label}</span>
        </>
      )}
    </div>
  );
};

MenuListItem.defaultProps = {
  value: "",
  icon: "",
  checkbox: false,
  danger: false,
  isSelected: false,
  onCheck: () => {},
};

export default MenuListItem;
