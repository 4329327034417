import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import Divider from "../components/Divider/Divider";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import {
  MASTER_MANAGEMENT_OPTIONS,
  USER_EVALUATION,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";

const EXAMINATION_DATA = [
  {
    id: "0",
    contents: "PET検査　確定診断給付金",
    amount: "¥100,000",
    status: "未払い",
    pet_scan_date: "2022/01/15",
    payment_date: "-",
  },
  {
    id: "1",
    contents: "PET検査　検査費用",
    amount: "¥100,000",
    status: "支払い済",
    pet_scan_date: "2022/01/15",
    payment_date: "2022/01/15",
  },
];

const SORT_EXAMPLES = [{ id: "col-3", sortDirection: "" }];

const NLC018 = () => {
  const [users] = React.useState(EXAMINATION_DATA);
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { id, name, birth, genderStr, tel, email, address, evaluation } =
    convertPatientInfo(patientInfo);
  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(true)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLD002}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <span className="util-font-neutral--light font-component-chip">
                          {id}
                        </span>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          <div className="util-half-width">
                            <LayoutBox align="center">
                              <Label base size="medium" width="120px">
                                ユーザー評価
                              </Label>
                              <LayoutBox>
                                <RadioButtonGroup
                                  name="userRatingRadioButton"
                                  rowItems={USER_EVALUATION}
                                  onChange={handleChangeEvaluation}
                                  selectedValue={selectedValue}
                                  radioGap="16px"
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  住所1
                                </Label>
                                {address}
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <Tabs
                        selectedTab={2}
                        tabs={userDetailTabItems}
                        size="medium"
                        bottomBorder
                        onClick={handleSelectAdminUserDetailTab}
                      />
                      <div className="admin-user__inspection-results">
                        <Table
                          type="condensed"
                          width="100%"
                          head={
                            <TableRow>
                              <TableColumn width="220px" id="col-1">
                                内容
                              </TableColumn>
                              <TableColumn
                                width="220px"
                                id="col-2"
                                textAlign="right"
                              >
                                金額
                              </TableColumn>
                              <TableColumn
                                width="160px"
                                id="col-3"
                                canSort
                                sortedDirection={sortExamples[0].sortDirection}
                                onClickSort={onClickSort}
                              >
                                支払い状況
                              </TableColumn>
                              <TableColumn width="220px" id="col-4">
                                PET検査日
                              </TableColumn>
                              <TableColumn width="220px" id="col-5">
                                支払い日
                              </TableColumn>
                              <TableColumn width="152px" id="col-6" />
                            </TableRow>
                          }
                          body={users.map((user) => (
                            <TableRow key={user.id}>
                              <TableCell>{user.contents}</TableCell>
                              <TableCell textAlign="right">
                                {user.amount}
                              </TableCell>
                              <TableCell>
                                <Tag
                                  label={user.status}
                                  showIcon={false}
                                  state={
                                    user.status === "支払い済"
                                      ? "success"
                                      : "error"
                                  }
                                />
                              </TableCell>
                              <TableCell>{user.pet_scan_date}</TableCell>
                              <TableCell>{user.payment_date}</TableCell>
                              <TableCell textAlign="center">
                                <Button type="sub" size="small" width="134px">
                                  保険会社に支払い確認
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        />
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <EventHistories />
        </div>
      </div>
    </>
  );
};

export default NLC018;
