/* eslint-disable no-console */
import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import TableCell from "../components/Table/TableCell";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Divider from "../components/Divider/Divider";
import Label from "../components/FormSet/Label";
import {
  BookTypeID,
  InterviewStatusLabels,
  MASTER_MANAGEMENT_OPTIONS,
  ModalityID,
  ModalityStatusLabels,
  PatientInterviewStatusID,
  USER_EVALUATION,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  getInterviewTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import EventHistories from "../features/EventHistories";
import useNlc020DbActions from "../hooks/pages/NLC020/useNlc020DbActions";
import UserDetailHeader from "../features/UserDetailHeader";

// 2次以降フェーズ対応: ボタン押下時のダイアログ表示処理
const clickNewReservation = (id: string) => {
  console.log(id);
};
const clickInterviewDetail = (id: string) => {
  console.log(id);
};
const clickModalityDetail = (id: string) => {
  console.log(id);
};

const _createTable = (bookInfo: PatientNlc020StateType) => {
  const { type, id, content, status, hospital, date } = bookInfo;
  const currentStatus = status as keyof typeof InterviewStatusLabels;
  let contentStr = content;
  let statusStr = "";
  let buttonStr = "";
  let clickFunc = (_id: string) => {};

  // 表示変換
  if (type === BookTypeID.INTERVIEW) {
    statusStr = InterviewStatusLabels[currentStatus];

    const isNewReservation =
      statusStr ===
      InterviewStatusLabels[PatientInterviewStatusID.NEW_RESERVATION];
    buttonStr = isNewReservation ? "予約確認" : "詳細";
    clickFunc = isNewReservation ? clickNewReservation : clickInterviewDetail;
  } else if (type === BookTypeID.MODALITY) {
    statusStr = ModalityStatusLabels[currentStatus];
    buttonStr = "詳細";
    clickFunc = clickModalityDetail;

    if (Number(content) === ModalityID.MRI) {
      contentStr = "MRI検査";
    }
    if (Number(content) === ModalityID.PET) {
      contentStr = "PET検査";
    }
  }

  const tahState = getInterviewTagState(statusStr);

  return (
    <TableRow key={id}>
      <TableCell>{contentStr}</TableCell>
      <TableCell textAlign="left">{hospital ?? "-"}</TableCell>
      <TableCell>
        <Tag label={statusStr} showIcon={false} state={tahState} />
      </TableCell>
      <TableCell textAlign="left">{date ?? "-"}</TableCell>
      <TableCell>
        <Button type="sub" size="small" onClick={() => clickFunc(id)}>
          {buttonStr}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const NLC020 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { id, name, birth, genderStr, tel, email, address, evaluation } =
    convertPatientInfo(patientInfo);
  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();
  const { books, fetchError } = useNlc020DbActions();

  // エラー処理
  useCheckErrorThrowError([
    fetchError,
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC007}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <span className="util-font-neutral--light font-component-chip">
                          {id}
                        </span>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          <div className="util-half-width">
                            <LayoutBox align="center">
                              <Label base size="medium" width="120px">
                                ユーザー評価
                              </Label>
                              <LayoutBox>
                                <RadioButtonGroup
                                  name="userRatingRadioButton"
                                  rowItems={USER_EVALUATION}
                                  onChange={handleChangeEvaluation}
                                  selectedValue={selectedValue}
                                  radioGap="16px"
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  {email}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  住所1
                                </Label>
                                {address}
                              </LayoutBox>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <Tabs
                    selectedTab={4}
                    tabs={userDetailTabItems}
                    size="medium"
                    bottomBorder
                    onClick={handleSelectAdminUserDetailTab}
                  />
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="3x">
                      <div className="util-full-width">
                        <LayoutBox direction="column" gap="2x">
                          <LayoutBox direction="column">
                            <Table
                              type="regular"
                              width="100%"
                              head={
                                <TableRow>
                                  <TableColumn width="300.5px" id="col-1">
                                    内容
                                  </TableColumn>
                                  <TableColumn width="300.5px" id="col-2">
                                    医療機関
                                  </TableColumn>
                                  <TableColumn width="300.5px" id="col-3">
                                    ステータス
                                  </TableColumn>
                                  <TableColumn width="300.5px" id="col-4">
                                    予定日
                                  </TableColumn>
                                  <TableColumn width="150.5px" id="col-5" />
                                </TableRow>
                              }
                              body={books.map(_createTable)}
                            />
                          </LayoutBox>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          <EventHistories />
        </div>
      </div>
    </>
  );
};

export default NLC020;
