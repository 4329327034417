import { useState, useEffect } from "react";
import {
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
  SEARCH_INFO,
  Collection,
} from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { getMongoDb, getAggregateNld002 } from "../../../utils/query";

type Nld002FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLD002;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLD002;
};

// NLD002画面のデータ取得処理
const useNld002Actions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nld002FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: PatientNld002StateType[];
    fetchError: Error | null;
  }>({
    result: [],
    fetchError: null,
  });

  // 検索条件をquery用に整形
  const { hospitalName, startDate, endDate } = submittedSearchCriteria;
  const name = convertSearchName(submittedSearchCriteria.name);
  const tel = submittedSearchCriteria.phoneNum;

  // 検索のチェック状態をquery用に整形
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLD002,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合はIDの昇順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "due_date", sortDirection = "desc" } = currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );

        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNld002(
          hospitalName,
          name,
          tel,
          memoStatusArr,
          startDate,
          endDate,
          skip,
          perPage,
          colName,
          sortDirection,
        );

        // mongoDBのデータ
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNld002StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, fetchError: null });
      } catch (err) {
        const fetchError = err as Error;

        setTotal(0);
        setErrorAndResult({ result: [], fetchError });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    colName,
    currentUser,
    hospitalName,
    memoStatusArr,
    name,
    perPage,
    skip,
    sortDirection,
    tel,
    startDate,
    endDate,
  ]);

  return errorAndResult;
};

export default useNld002Actions;
