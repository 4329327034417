import React from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import TimeTable from "../components/Table/TimeTable";
import ContentFooter from "../components/ContentFooter/ContentFooter";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
  handleSelectHospitalMasterTab,
} from "../utils/utils";
import {
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  INSPECTION_TOGGLE_ITEMS,
  InspectionTabID,
} from "../constants/common";
import { convertNli017Hospital } from "../utils/convertDisplay";
import MIE005 from "./MIE005";
import useMie001DbActions from "../hooks/pages/MIE001/useMie001DbActions";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import useMie001Conditions from "../hooks/pages/MIE001/useMie001Conditions";

const MIE001 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHospitalDialogOpen, setIsHospitalDialogOpen] = React.useState(false);
  const {
    fetchResult: fetchHospitalResult,
    handleUpdateScheduleCapacity,
    actionError,
  } = useMie001DbActions();
  const [{ originData, inspectionType }, setCondition] = useMie001Conditions();

  const { hospitalName, tel, address, businessDay, regularHoliday } =
    convertNli017Hospital(fetchHospitalResult);

  // メインタブ
  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([mainTabError, actionError]);

  // 病院情報を編集ボタン押下
  const handleEditHospital = () => {
    setIsHospitalDialogOpen(true);
  };

  // 更新ボタン
  const handleUpdateButton = () => {
    handleUpdateScheduleCapacity();
  };

  // キャンセルボタン
  const handleCancel = () => {
    if (inspectionType === InspectionTabID.MRI) {
      setCondition((prevCondition) => ({
        ...prevCondition,
        mriTimeFrame: originData.mriTimeFrame,
        mriTimeInterval: originData.mriTimeInterval,
        mriTimeItems: originData.mriTimeItems,
      }));
    } else {
      setCondition((prevCondition) => ({
        ...prevCondition,
        petTimeFrame: originData.petTimeFrame,
        petTimeInterval: originData.petTimeInterval,
        petTimeItems: originData.petTimeItems,
      }));
    }
  };

  // MRI, FDG PET トグルボタン
  const handleChangeFrame = (value: string) => {
    setCondition((prevCondition) => ({
      ...prevCondition,
      inspectionType: value,
    }));
    // 入力内容リセット
    handleCancel();
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mt-16 util-mb-16">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <Sheet type="border-blue" padding="16px">
                <LayoutBox gap="2x" direction="column" fullWidth>
                  <LayoutBox justify="between" fullWidth>
                    <Text size="2xl" bold>
                      {hospitalName}
                    </Text>
                    <Button
                      type="secondary"
                      icon="edit"
                      size="medium"
                      width="140px"
                      onClick={handleEditHospital}
                    >
                      病院情報を編集
                    </Button>
                  </LayoutBox>
                  <LayoutBox direction="column" fullWidth>
                    <LayoutBox fullWidth justify="between" gap="3x">
                      <FormSet
                        label="診療時間"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                      >
                        {businessDay}
                      </FormSet>
                      <FormSet
                        label="定休日"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                      >
                        {regularHoliday}
                      </FormSet>
                      <LayoutBox fullWidth>{}</LayoutBox>
                    </LayoutBox>
                    <FormSet
                      label="電話番号"
                      base
                      labelWidth="120px"
                      labelSize="medium"
                      formVertical={false}
                    >
                      {tel}
                    </FormSet>
                    <FormSet
                      label="住所"
                      base
                      labelWidth="120px"
                      labelSize="medium"
                      formVertical={false}
                    >
                      {address}
                    </FormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <div className="util-mt-24 util-mb-24">
                <Tabs
                  size="medium"
                  selectedTab={0}
                  onClick={handleSelectHospitalMasterTab}
                  tabs={HOSPITAL_MASTER_MANAGEMENT_OPTIONS.map((option) => ({
                    title: option.label,
                  }))}
                  bottomBorder
                />
              </div>
              <LayoutBox direction="column" gap="2x">
                <ToggleButton
                  size="medium"
                  items={INSPECTION_TOGGLE_ITEMS}
                  selectedButton="1"
                  onClick={handleChangeFrame}
                />
                <TimeTable />
              </LayoutBox>
            </Sheet>
          </LayoutBox>
          <div className="util-mb-24" />
        </div>
        <ContentFooter
          footerRight={
            <>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
              <Button
                color="main"
                type="primary"
                size="large"
                width="64px"
                onClick={handleUpdateButton}
              >
                保存
              </Button>
            </>
          }
        />
      </div>
      {isHospitalDialogOpen && (
        <MIE005
          isOpen={isHospitalDialogOpen}
          setIsOpen={setIsHospitalDialogOpen}
          title="病院情報を編集"
          hospitalData={fetchHospitalResult}
        />
      )}
    </>
  );
};

export default MIE001;
