import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Divider from "../components/Divider/Divider";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import Input from "../components/Input/Input";
import DatePicker from "../components/DatePicker/DatePicker";
import DialogCustom from "../components/Dialog/DialogCustom";
import TimePicker from "../components/TimePicker/TimePicker";
import { convertMib002Table } from "../utils/convertDisplay";
import { HospitalReservationStatusID } from "../constants/common";
import useMib002DbActions from "../hooks/pages/MIB002/useMib002DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import MIB004 from "./MIB004";
import { combineDateAndTime } from "../utils/utils";
import useHospitalTimeIntervalFetch from "../hooks/useHospitalTimeIntervalFetch";

type MIB002Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectReservation: Mib001TableType;
  reservationArray: Mib001TableType[];
};

type RadioButtonItem = {
  label: string;
  value: string;
};

// その他の時間制御のため、セット
const initialDate = new Date(1000, 0, 1);

const MIB002: React.FC<MIB002Props> = ({
  isOpen,
  setIsOpen,
  selectReservation,
  reservationArray,
}) => {
  const [reservation, setReservation] =
    useStateCustomObj<Mib001TableType>(selectReservation);
  const [formattedCandidateBookDates, setFormattedCandidateBookDates] =
    React.useState<RadioButtonItem[]>([]);
  const [otherCandidateBookDates, setOtherCandidateBookDates] = React.useState<
    RadioButtonItem[]
  >([]);
  const [selectedValue, setSelectedValue] = React.useState(
    selectReservation.candidateBookDates.first || "",
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    undefined,
  );
  const [timeStr, setTimeStr] = React.useState("");
  const [time, setTime] = React.useState(initialDate);
  const [note, setNote] = React.useState<string>("");
  const [isCancelDialog, setIsCancelDialog] = React.useState(false);
  const {
    handleUpdateNg,
    handleUpdateConfirm,
    handleUpdateCancel,
    actionError,
  } = useMib002DbActions();
  const { hospitalInfo, error: hospitalError } = useHospitalTimeIntervalFetch();
  const [index, setIndex] = React.useState(0);

  useCheckErrorThrowError([actionError, hospitalError]);

  useEffect(() => {
    const convertResult = convertMib002Table(
      reservation.fixBookDate,
      reservation.statusNum,
      hospitalInfo,
      reservation.modalityNum,
      reservation.candidateBookDates,
    );
    setFormattedCandidateBookDates(convertResult.convertCandidateDates);
    setOtherCandidateBookDates([convertResult.otherCandidateDates]);
    if (reservation && reservation.note) {
      setNote(reservation.note);
    }
    if (reservation && reservation.fixBookDate) {
      if (convertResult.isDateInCandidateDates) {
        setSelectedValue(reservation.fixBookDate);
      } else {
        // その他の場合
        setSelectedValue("other");
        setSelectedDate(reservation.fixBookDateOrg);
        if (reservation.fixBookDateOrg) {
          const hours = reservation.fixBookDateOrg
            .getHours()
            .toString()
            .padStart(2, "0");
          const minutes = reservation.fixBookDateOrg
            .getMinutes()
            .toString()
            .padStart(2, "0");
          setTimeStr(`${hours}:${minutes}`);
        }
      }
    }
    // 何番目のデータか
    const num = reservationArray.findIndex(
      (item) => item.id === reservation.id,
    );
    setIndex(num);
  }, [reservation, hospitalInfo, reservationArray]);

  // 次へボタン
  const handleNextReservation = () => {
    setIndex((prevIndex) => prevIndex + 1);
    setReservation(reservationArray[index + 1]);
  };

  // 前へボタン
  const handleBeforeReservation = () => {
    setIndex((prevIndex) => prevIndex - 1);
    setReservation(reservationArray[index - 1]);
  };

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen],
  );

  // 内容
  const handleNoteChange = (value: string) => {
    setNote(value);
  };

  // 日付選択
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  // 時間選択
  const onChangeTime = React.useCallback((date: Date) => {
    setTime(date);
  }, []);

  // ラジオボタン選択
  const handleRadioChange = React.useCallback((value: string) => {
    setSelectedValue(value);
  }, []);

  // 全候補日NG
  const handleNg = () => {
    handleUpdateNg(
      reservation.id,
      note,
      reservation.patientId,
      reservation.modalityNum,
    );
    setIsOpen(false);
  };

  // 予約確定
  const handleConfirm = () => {
    let otherDate;
    if (selectedDate) {
      otherDate = combineDateAndTime(selectedDate, time);
    }
    handleUpdateConfirm(
      reservation.id,
      note,
      selectedValue,
      reservation.patientId,
      reservation.modalityNum,
      otherDate,
    );
    setIsOpen(false);
  };

  // 予約取り下げ
  const handleCancel = () => {
    handleUpdateCancel(
      reservation.id,
      note,
      reservation.patientId,
      reservation.modalityNum,
      () => setIsCancelDialog(true),
    );
  };

  return (
    <DialogCustom
      title="予約確認"
      open={isOpen}
      size="large"
      height="840px"
      closeDialog={handleCloseButton}
      headerRight={
        <LayoutBox fullWidth align="center">
          <Button
            type="secondary"
            iconPosition="left"
            icon="arrow_back"
            disabled={index === 0}
            onClick={handleBeforeReservation}
          >
            前へ
          </Button>
          <Button
            type="secondary"
            iconPosition="right"
            icon="arrow_forward"
            disabled={reservationArray.length - 1 === index}
            onClick={handleNextReservation}
          >
            次へ
          </Button>
        </LayoutBox>
      }
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={handleCloseButton}
          >
            キャンセル
          </Button>
          {reservation.statusNum === HospitalReservationStatusID.NEW && (
            <Button
              type="secondary"
              color="danger"
              size="large"
              onClick={handleNg}
            >
              全候補日NG
            </Button>
          )}
          {reservation.statusNum === HospitalReservationStatusID.NEW && (
            <Button
              size="large"
              onClick={handleConfirm}
              disabled={
                (selectedValue === "other" && selectedDate === undefined) ||
                (selectedValue === "other" && time === initialDate)
              }
            >
              予約確定
            </Button>
          )}
          {reservation.statusNum === HospitalReservationStatusID.CONFIRM && (
            <Button
              type="secondary"
              color="danger"
              size="large"
              onClick={handleCancel}
            >
              予約取り下げ
            </Button>
          )}
        </LayoutBox>
      }
    >
      <Sheet type="border-blue" padding="16px 24px">
        <LayoutBox direction="column" gap="2x">
          <LayoutBox direction="column" gap="1x">
            <Tag
              label={reservation.status}
              showIcon={false}
              state={
                reservation.statusNum === HospitalReservationStatusID.NEW
                  ? "warning"
                  : reservation.statusNum ===
                    HospitalReservationStatusID.CONFIRM
                  ? "success"
                  : reservation.statusNum ===
                    HospitalReservationStatusID.WITHDRAWAL
                  ? "neutral"
                  : reservation.statusNum ===
                    HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL
                  ? "neutral"
                  : "error"
              }
            />
            <Text size="2xl" bold lineHeight="150%">
              {reservation.patientName}
            </Text>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth>
            <LayoutBox fullWidth>
              <FormSet label="申込日" labelWidth="120px" base>
                <Text width="210px">{reservation.createdAt}</Text>
              </FormSet>
              <FormSet label="内容" labelWidth="120px" base>
                <Text width="210px">{reservation.modalityStr}</Text>
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet label="生年月日" labelWidth="120px" base>
                <Text width="210px">{reservation.patientBirthday}</Text>
              </FormSet>
              <FormSet label="性別" labelWidth="120px" base>
                <Text width="210px">{reservation.patientGender}</Text>
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet label="電話番号" labelWidth="120px" base>
                <Text width="210px">{reservation.patientTel}</Text>
              </FormSet>
              <FormSet label="メールアドレス" labelWidth="120px" base>
                <Text width="210px">{reservation.patientEmail}</Text>
              </FormSet>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      </Sheet>
      <div className="util-mt-32 util-full-width">
        <LayoutBox gap="3x">
          <LayoutBox direction="column">
            <RadioButtonGroup
              name="radio"
              items={formattedCandidateBookDates}
              onChange={handleRadioChange}
              selectedValue={selectedValue}
              withBorder
              width="487px"
            />

            <div className="util-mt-16">
              <RadioButtonGroup
                name="radio"
                items={otherCandidateBookDates}
                onChange={handleRadioChange}
                selectedValue={selectedValue}
                withBorder
                width="487px"
              />
            </div>

            <LayoutBox direction="row" align="center" fullWidth gap="2x">
              <div>
                <DatePicker
                  size="default"
                  selectedDate={selectedDate}
                  onChangeDate={handleDateChange}
                  disabled={
                    selectedValue !== "other" ||
                    reservation.statusNum ===
                      HospitalReservationStatusID.CONFIRM ||
                    reservation.statusNum ===
                      HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
                    reservation.statusNum ===
                      HospitalReservationStatusID.WITHDRAWAL
                  }
                />
              </div>

              <TimePicker
                type="hoursOnly"
                size="default"
                defaultHour1={timeStr}
                value={time}
                onChange={onChangeTime}
                showButton={false}
                dropdownWidth="115px"
                disabled={
                  selectedValue !== "other" ||
                  reservation.statusNum ===
                    HospitalReservationStatusID.CONFIRM ||
                  reservation.statusNum ===
                    HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
                  reservation.statusNum ===
                    HospitalReservationStatusID.WITHDRAWAL
                }
              />
            </LayoutBox>
          </LayoutBox>
          <Divider vertical width="1px" height="406px" />
          <LayoutBox direction="row" fullWidth>
            <FormSet label="内容" vertical>
              <Input
                multiLine
                width="488px"
                placeholder="内容"
                value={note}
                onChange={handleNoteChange}
              />
            </FormSet>
          </LayoutBox>
        </LayoutBox>
      </div>
      {isCancelDialog && (
        <MIB004
          isOpen={isCancelDialog}
          setIsOpen={setIsCancelDialog}
          setIsParentOpen={setIsOpen}
          name={reservation.patientName}
          tel={reservation.patientTel}
        />
      )}
    </DialogCustom>
  );
};
export default MIB002;
