import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";

const useNli027DbActions = () => {
  const realmAppContext = useRealmApp();
  const [error, setError] = useState<Error | null>(null);

  const handleUpdate = (
    _id: string,
    email: string,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // ファンクション
        await realmAppContext.app.currentUser?.functions.changeEmail({
          userId: _id,
          newEmail: email,
          isAdmin: true,
        });

        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdate,
  };
};

export default useNli027DbActions;
