import React from "react";

export type SheetProps = {
  type?:
    | "default"
    | "border"
    | "gray"
    | "border-blue"
    | "header"
    | "gray-custom";
  className?: string;
  children: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  padding?: string;
  footer?: JSX.Element;
};

const Sheet = ({
  type = "default",
  className = "",
  children,
  footer,
  minWidth,
  maxWidth,
  minHeight,
  padding = "24px",
}: SheetProps) => {
  const sheetClass = React.useMemo(() => {
    const typeClass = [`sheet--${type}`];

    return [`sheet ${className}`, ...typeClass].join(" ");
  }, [type, className]);

  const isShowFooter = React.useMemo(() => footer !== undefined, [footer]);

  return (
    <div
      className={sheetClass}
      style={{ minWidth, padding, minHeight, maxWidth }}
    >
      {children}
      {isShowFooter && (
        <div className="sheet--footer">
          <div>{footer}</div>
        </div>
      )}
    </div>
  );
};

Sheet.defaultProps = {
  type: "default",
  className: "",
  minWidth: "",
  maxWidth: "",
  padding: "24px",
  footer: undefined,
  minHeight: "",
};

export default Sheet;
