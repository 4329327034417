import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type Nlh002DialogType = "add" | "update" | "";

const stateReactiveVar = createReactiveVar<Nlh002DialogType>("");

// キャンペーン登録ダイアログの開閉状態を管理
const useNlh002Dialog = (): ReactiveVarHooks<Nlh002DialogType> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlh002AddDialog = () => {
  stateReactiveVar("add");
};
export const openNlh002UpdateDialog = () => {
  stateReactiveVar("update");
};
export const reOpenNlh002Dialog = (action: Nlh002DialogType) => {
  stateReactiveVar(action);
};

export const getIsOpenNlh002 = () => Boolean(stateReactiveVar());

export const closeNlh002Dialog = () => {
  stateReactiveVar("");
};

export default useNlh002Dialog;
