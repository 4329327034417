import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";
import Image from "../Image/Image";
import useImageUpload from "../../hooks/component/ImageUpload/useImageUpload";
import { IMG_DEFAULT_MAX_COUNT } from "../../constants/common";

export type ImageUploadProps = {
  width?: string;
  height?: string;
  onImageRemoved?: (index: number) => void;
  urls?: {
    id: string;
    url: string;
  }[];
  maxCount?: number;
};

const ImageUpload = ({
  width,
  height,
  onImageRemoved,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  urls,
  // changeEmp 画像最大数
  maxCount = IMG_DEFAULT_MAX_COUNT,
}: ImageUploadProps) => {
  const [newImages, setNewImages] = useImageUpload();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const fileArray = Array.from(files);
      const imageUrls = fileArray.map((file) => URL.createObjectURL(file));
      setNewImages((prevImages) => [...prevImages, ...imageUrls]);
    }
  };

  const handleTextClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.onchange = (event) =>
      handleImageUpload(
        event as unknown as React.ChangeEvent<HTMLInputElement>,
      );
    input.click();
  };

  const handleRemoveImage = (index: number) => {
    setNewImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      const imageId = index + 1;
      if (onImageRemoved) onImageRemoved(imageId);

      return updatedImages;
    });
  };

  return (
    <>
      {/* changeEmp */}
      {newImages.length < maxCount && (
        <div
          className="image-upload"
          onClick={handleTextClick}
          style={{ width, height }}
        >
          <>
            <LayoutBox direction="column" align="center" fullWidth>
              <Icon icon="add" size="small" color="#096AE2" />
              <Text bold color="primary">
                新規追加
              </Text>
            </LayoutBox>
          </>
        </div>
      )}
      {newImages.map((imageUrl, index) => (
        <Image
          key={index}
          type="imageUpload"
          url={imageUrl}
          onClick={() => handleRemoveImage(index)}
          width="80px"
          height="80px"
        />
      ))}
    </>
  );
};

ImageUpload.defaultProps = {
  height: "80px",
  width: "80px",
  onImageRemoved: () => {},
  urls: [],
  // changeEmp
  maxCount: IMG_DEFAULT_MAX_COUNT,
};

export default ImageUpload;
