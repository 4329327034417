import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import {
  ADMIN_USER_MGMT_SUB_TAB_ITEMS,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  SEARCH_INFO,
} from "../constants/common";
import {
  getNouKnowTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectUserMgmtSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useNlc004Fetch from "../hooks/pages/NLC004/useNlc004Fetch";
import { convertNlc004Table } from "../utils/convertDisplay";
import { ADMIN } from "../constants/pagePaths";
import { setPage } from "../hooks/component/pagination/usePagination";
import useNlc004Conditions from "../hooks/pages/NLC004/useNlc004Conditions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setId } from "../hooks/common/useId";

const NLC004 = () => {
  // 表示、検索条件をページ間で保持
  const [conditions, setConditions] = useNlc004Conditions();
  const [users, setUsers] = React.useState<Nlc004TableType[]>([]);
  // ソートの状態
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { result, error: fetchError } = useNlc004Fetch({
    sortExamples,
    submittedSearchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNlc004Table(result);
    setUsers(convertResult);
  }, [result]);

  // 検索欄処理
  const handleChangeId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, id: value }));
  };
  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };
  const handleChangePhoneNum = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, phoneNum: value }));
  };
  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      reportStatus: {
        ...searchCriteria.reportStatus,
        [checkedItem]: checked,
      },
    }));
  };
  // 検索ボタン押下時のイベント
  const handleSearchUsers = () => {
    // 検索条件を確定
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };
  // クリアボタン押下時のイベント
  const handleResetSearchCriteria = () => {
    const { reportStatus: _, ...restSearchCriteria } =
      INIT_SEARCH_CRITERIA.NLC004;
    setSearchCriteria((prevState) => ({ ...prevState, ...restSearchCriteria }));
  };

  const handleCheckUserId = (checked: boolean, nouknowId: string) => {
    const newUsers = users.map((user) => {
      if (user.nouknowId === nouknowId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);

    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);

    const newUsers = users.map((user) => ({ ...user, checked }));

    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToUserDetails = (id: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
    });
    setId(id);
    navigate(ADMIN.NLC011);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <ToggleButton
              size="large"
              selectedButton="4"
              items={ADMIN_USER_MGMT_SUB_TAB_ITEMS}
              onClick={handleSelectUserMgmtSubTab}
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet label="ID" labelWidth="90px" base>
                      <Input
                        value={searchCriteria.id}
                        placeholder="ID"
                        width="494px"
                        onChange={handleChangeId}
                      />
                    </FormSet>
                    <FormSet label="お客様名" labelWidth="90px" base>
                      <Input
                        value={searchCriteria.name}
                        placeholder="お客様名"
                        width="494px"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet label="電話番号" labelWidth="90px" base>
                      <Input
                        value={searchCriteria.phoneNum}
                        placeholder="電話番号"
                        width="494px"
                        onChange={handleChangePhoneNum}
                      />
                    </FormSet>
                  </LayoutBox>
                </div>
                <LayoutBox align="center" justify="start" gap="2x">
                  <FormSet
                    label="ステータス"
                    labelWidth="90px"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" justify="start" gap="2x">
                      {SEARCH_INFO.NLC004.map((statusInfo) => (
                        <Checkbox
                          key={statusInfo.id}
                          label={statusInfo.label}
                          checked={
                            searchCriteria.reportStatus[statusInfo.flgKey]
                          }
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, statusInfo.flgKey)
                          }
                        />
                      ))}
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="267px"
                        minWidth="152px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="267px"
                        minWidth="180px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>
                      <TableColumn width="77px" id="col-3" />
                      <TableColumn
                        width="267px"
                        minWidth="100px"
                        canSort
                        id="col-4"
                        sortedDirection={sortExamples[2].sortDirection}
                        onClickSort={onClickSort}
                      >
                        最終検査日
                      </TableColumn>
                      <TableColumn
                        width="267px"
                        minWidth="230px"
                        canSort
                        id="col-5"
                        sortedDirection={sortExamples[3].sortDirection}
                        onClickSort={onClickSort}
                      >
                        評価
                      </TableColumn>
                      <TableColumn
                        width="144px"
                        canSort
                        id="col-6"
                        sortedDirection={sortExamples[4].sortDirection}
                        onClickSort={onClickSort}
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn width="267px" id="col-7">
                        電話番号
                      </TableColumn>
                      <TableColumn width="267px" id="col-8">
                        メールアドレス
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.nouknowId} isSelected={user.checked}>
                      <TableCell>
                        <Checkbox
                          label={user.id}
                          checked={user.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => navigateToUserDetails(user.id)}
                          type="sub"
                          size="small"
                        >
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>{user.lastInspectionDate}</TableCell>
                      <TableCell>{user.score}</TableCell>
                      <TableCell>
                        <Tag
                          label={user.reportStatus}
                          showIcon={false}
                          state={getNouKnowTagState(user.reportStatus)}
                        />
                      </TableCell>
                      <TableCell>{user.phoneNum}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))}
                />
                <div className="util-full-width">
                  <LayoutBox justify="end">
                    <Pagination input={false} />
                  </LayoutBox>
                </div>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLC004;
