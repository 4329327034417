import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import {
  openNlj002Dialog,
  setMessage,
  setIsError,
} from "../hooks/pages/NLJ002/useNlj002Dialog";
import NLJ001 from "../pages/NLJ001";

export const ERROR_INFO = {
  // 不正アクセス
  INVALID_ACCESS: {
    MESSAGE: "Invalid access. Please access from the correct route.",
  },
  // 該当データ無し
  NO_DATA: {
    MESSAGE: "No data found.",
  },
  // 実装エラー
  INVALID_FUNCTION: {
    MESSAGE:
      "An unexpected error occurred. We'd appreciate it if you could report this issue to our system administrator.",
  },
  // CSVフォーマット
  INVALID_CSV_FORMAT: {
    MESSAGE:
      "The uploaded file format is invalid. Please upload a file in the correct format.",
  },
  NO_ROLE: {
    MESSAGE:
      "You do not have permission to view this page. Please contact your administrator if you believe this is an error.",
  },
  // ネットワークエラー
  NETWORK_ERROR: {
    MESSAGE:
      "You are not connected to the network. Please check your connection and reload the page.",
  },
  // 不正なリクエスト
  BAD_REQUEST: {
    MESSAGE: "Your request is invalid. Please check your input.",
  },
  // 不正な権限
  NO_ROLE_ACTION: {
    MESSAGE: "Your request is invalid. Please check your input.",
  },
  // 予期せぬエラー
  UNEXPECTED_ERROR: {
    MESSAGE: "An unexpected error has occurred.",
  },
};

const errorMessages = Object.values(ERROR_INFO).map(({ MESSAGE }) => MESSAGE);

// エラーページ遷移
export const redirectToInvalidAccessPage = () => {
  throw new Error(ERROR_INFO.INVALID_ACCESS.MESSAGE);
};
export const redirectToNoDataPage = () => {
  throw new Error(ERROR_INFO.NO_DATA.MESSAGE);
};
export const redirectToInvalidFunctionPage = () => {
  throw new Error(ERROR_INFO.INVALID_FUNCTION.MESSAGE);
};
export const redirectToInvalidCsvFormatPage = () => {
  throw new Error(ERROR_INFO.INVALID_CSV_FORMAT.MESSAGE);
};
export const redirectToNoRolePage = () => {
  throw new Error(ERROR_INFO.NO_ROLE.MESSAGE);
};

// エラーダイアログを表示
export const openNoRoleDialog = () => {
  throw new Error(ERROR_INFO.NO_ROLE_ACTION.MESSAGE);
};

export const checkFetchErr = (err: unknown): Error => {
  const error = err as Error;
  const errorMessage = error.message;

  // 設定されているエラーの場合
  if (errorMessages.includes(errorMessage)) {
    return error;
  }

  // MongoDB由来のエラーの場合
  if (errorMessage.includes("Failed to fetch")) {
    return new Error(ERROR_INFO.NETWORK_ERROR.MESSAGE);
  }

  return new Error(ERROR_INFO.UNEXPECTED_ERROR.MESSAGE);
};

// エラーメッセージに応じてダイアログを表示
const _openErrorDialog = (
  errorMessage: string,
  clickDialogButton = () => {},
) => {
  // 権限がないエラー
  const isNoRoleErr = errorMessage === ERROR_INFO.NO_ROLE_ACTION.MESSAGE;
  // 認証メールエラーまたはパスワード再設定エラー
  const isAuthenticationErr =
    errorMessage.includes("already confirmed") ||
    errorMessage.includes("user not found");
  // MongoDB由来のエラー
  const isMongoErr =
    errorMessage.includes("Failed to fetch") ||
    errorMessage.includes("Request failed");

  let message = "";
  let title = "";
  if (isNoRoleErr) {
    title = "権限がありません。";
    message = "管理者にお問い合わせください。";
  } else if (isAuthenticationErr) {
    title = "処理に失敗しました。";
    message = "メールアドレスが有効ではありません。";
  } else if (isMongoErr) {
    title = "処理に失敗しました。";
    message = "もう一度お試しください。";
  }

  // エラーダイアログを表示
  if (title) {
    setMessage(title, message);
    setIsError(true);
    clickDialogButton();
    openNlj002Dialog();
  }

  return Boolean(title);
};

export const checkActionErr = (
  err: unknown,
  clickDialogButton = () => {},
): Error | null => {
  const error = err as Error;
  const errorMessage = error.message;

  if (_openErrorDialog(errorMessage, clickDialogButton)) return null;

  return new Error(ERROR_INFO.UNEXPECTED_ERROR.MESSAGE);
};

const ErrorFallback: React.FC<FallbackProps> = ({
  error: rawError,
  resetErrorBoundary,
}) => {
  const error = rawError as Error;
  const errorMessage = error.message;

  switch (errorMessage) {
    case ERROR_INFO.INVALID_ACCESS.MESSAGE:
    case ERROR_INFO.NO_DATA.MESSAGE:
    case ERROR_INFO.INVALID_FUNCTION.MESSAGE:
    case ERROR_INFO.INVALID_CSV_FORMAT.MESSAGE:
      return <NLJ001 resetErrorBoundary={resetErrorBoundary} />;
    default:
      return <NLJ001 resetErrorBoundary={resetErrorBoundary} />;
  }
};

const CustomErrorBoundary = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
);

export default CustomErrorBoundary;
