import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import {
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  HospitalReservationStatusID,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
} from "../constants/common";
import {
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
} from "../utils/utils";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useMic001DbActions from "../hooks/pages/MIC001/useMic001DbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertMic001Table } from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import useHospitalTimeIntervalFetch from "../hooks/useHospitalTimeIntervalFetch";

const MIC001 = () => {
  const [schedules, setSchedules] = useStateCustomObj<Mic001TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES.MIC001);
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.MIC001,
  );
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.MIC001,
  );
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { fetchResult, fetchError } = useMic001DbActions({
    sortExamples,
    submittedSearchCriteria,
  });
  const { hospitalInfo, error: hospitalError } = useHospitalTimeIntervalFetch();
  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError, hospitalError]);

  useEffect(() => {
    const convertResult = convertMic001Table(fetchResult, hospitalInfo);
    setSchedules(convertResult);
  }, [fetchResult, setSchedules, hospitalInfo]);

  // 予約日変更
  const handleChangeDate = React.useCallback((date: Date) => {
    setSearchCriteria((prevState) => ({ ...prevState, fixBookDate: date }));
  }, []);

  // ステータスチェックボックス
  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 今日ボタン押下
  const handleSetFixBookDate = () => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      fixBookDate: new Date(),
    }));
  };

  // 絞り込みボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.MIC001);

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(true)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <LayoutBox align="center" justify="start" gap="2x" fullWidth>
                  <LayoutBox>
                    <FormSet label="予約日" labelWidth="96px" base>
                      <div>
                        <DatePicker
                          width="187px"
                          selectedDate={searchCriteria.fixBookDate}
                          onChangeDate={handleChangeDate}
                        />
                      </div>
                    </FormSet>
                  </LayoutBox>

                  <Button type="secondary" onClick={handleSetFixBookDate}>
                    今日
                  </Button>
                  <FormSet
                    label="ステータス"
                    labelWidth="96px"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" justify="start" gap="2x">
                      <Checkbox
                        label="新規予約"
                        checked={searchCriteria.status.isNew}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isNew")
                        }
                      />
                      <Checkbox
                        label="確定"
                        checked={searchCriteria.status.isConfirm}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isConfirm")
                        }
                      />
                      <Checkbox
                        label="NG"
                        checked={searchCriteria.status.isNg}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isNg")
                        }
                      />
                      <Checkbox
                        label="取り下げ申請"
                        checked={
                          searchCriteria.status.isApplicationForWithdrawal
                        }
                        onChecked={(checked) =>
                          handleChangeCheckItem(
                            checked,
                            "isApplicationForWithdrawal",
                          )
                        }
                      />
                      <Checkbox
                        label="取り下げ"
                        checked={searchCriteria.status.isWithdrawal}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isWithdrawal")
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="sub"
                      color="neutral"
                      size="large"
                      onClick={handleResetSearchCriteria}
                    >
                      クリア
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleSearchUsers}
                    >
                      絞り込み
                    </Button>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <Table
                width="100%"
                head={
                  <TableRow>
                    <TableColumn width="208px" id="col-1">
                      お客様名
                    </TableColumn>
                    <TableColumn
                      width="144px"
                      id="col-2"
                      canSort
                      sortedDirection={sortExamples[0].sortDirection}
                      onClickSort={onClickSort}
                    >
                      ステータス
                    </TableColumn>
                    <TableColumn
                      width="400px"
                      id="col-3"
                      canSort
                      sortedDirection={sortExamples[1].sortDirection}
                      onClickSort={onClickSort}
                    >
                      予約日
                    </TableColumn>
                    <TableColumn width="200px" id="col-4">
                      電話番号
                    </TableColumn>
                    <TableColumn width="350px" id="col-5">
                      メールアドレス
                    </TableColumn>
                  </TableRow>
                }
                body={schedules.map((schedule) => (
                  <TableRow key={schedule.id}>
                    <TableCell>{schedule.patientName}</TableCell>
                    <TableCell>
                      <Tag
                        label={schedule.statusStr}
                        showIcon={false}
                        state={
                          schedule.statusNum === HospitalReservationStatusID.NEW
                            ? "warning"
                            : schedule.statusNum ===
                              HospitalReservationStatusID.CONFIRM
                            ? "success"
                            : schedule.statusNum ===
                              HospitalReservationStatusID.WITHDRAWAL
                            ? "neutral"
                            : schedule.statusNum ===
                              HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL
                            ? "neutral"
                            : "error"
                        }
                        emphasis={
                          schedule.statusNum ===
                            HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL ||
                          schedule.statusNum === HospitalReservationStatusID.NG
                            ? true
                            : undefined
                        }
                      />
                    </TableCell>
                    <TableCell>{schedule.fixBookDateDisplay}</TableCell>
                    <TableCell>{schedule.patientTel}</TableCell>
                    <TableCell>{schedule.patientEmail}</TableCell>
                  </TableRow>
                ))}
              />
              <div className="util-full-width">
                <LayoutBox justify="end">
                  <Pagination input={false} />
                </LayoutBox>
              </div>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default MIC001;
